import { createContext, useRef, useEffect, useContext, } from 'react'

import { join, } from '@velogik/helper-classnames'

import style from './Breadcrumb.module.scss'

const breadcrumbContext = createContext({})
const itemContext = createContext({})

export function Breadcrumb ({ className, children, }) {
  const breadcrumbRef = useRef()

  return (
    <div className={join(style.root, className)} ref={breadcrumbRef}>
      <breadcrumbContext.Provider value={{ breadcrumbRef: breadcrumbRef }}>
        {children}
      </breadcrumbContext.Provider>
    </div>
  )
}

function Item ({ className, active, color: preferredColor = 'gray', focus, children, }) {
  const itemRef = useRef()
  const { breadcrumbRef } = useContext(breadcrumbContext)

  useEffect(() => {
    if (focus && breadcrumbRef.current) {
      function updateSize() {
        const breadcrumbCenter = breadcrumbRef.current.getBoundingClientRect().width / 2
        const itemOffsetCenter = itemRef.current.offsetLeft + (itemRef.current.getBoundingClientRect().width / 2)
        breadcrumbRef.current.scrollLeft = itemOffsetCenter - breadcrumbCenter
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }
  }, [focus, breadcrumbRef])

  const color = active ? preferredColor : 'gray'

  return (
    <itemContext.Provider value={{ color }}>
      <div className={join(style.Item, style[color], className)} ref={itemRef}>
        {children}
      </div>
    </itemContext.Provider>
  )
}

function Icon ({ className, name, }) {
  const { color } = useContext(itemContext)

  return <div className={join(style.Icon, className)}>
    {name && color && <img src={require(`assets/icons/${name}-${color}.svg`)} alt="" />}
  </div>
}

function Label ({ className, children, }) {
  return <div className={join(style.Label, className)}>{children}</div>
}

Breadcrumb.Item = Item
Breadcrumb.Icon = Icon
Breadcrumb.Label = Label
