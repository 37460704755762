import { useEffect, useState, useMemo, useCallback, useReducer, } from 'react'
import { useHistory, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Page, } from '@velogik/component-page'
import { SelectInput, SelectFilter, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'
import { useDebounceValue, } from '@velogik/hook-debounce'

import { BackButton, } from 'components/BackButton'

import style from './NetworkAdmin.Asvs.module.scss'
import { noop } from '@velogik/helper-utils'

function asvReducer (state, action) {
  switch (action.type) {
    case 'merge': return {
      ...state,
      ...action.payload,
    }
    default: return state
  }
}

function asvInitializer ({
  savId,
  velocareSubsidiaryId,
  velocareBusinessId,
  velocareCategoryId,
  velocareFamilyId,
}) {
  return {
    savId,
    businessId: (velocareSubsidiaryId && velocareBusinessId) ? `${velocareSubsidiaryId}-${velocareBusinessId}` : undefined,
    familyId: (velocareCategoryId && velocareFamilyId) ? `${velocareCategoryId}_${velocareFamilyId}` : undefined,
  }
}

export function NetworkAdminAsvs () {
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const api = useApi()
  const t = useT()
  const tNew = useT('new')
  const formatError = useFormatError()

  const [asvNewState, asvNewDispatch] = useReducer(asvReducer, {})
  const [postState, setPostState] = useState({ status: STATUS.IDLE })
  const [networks, setNetworks] = useState({ status: STATUS.INITIALIZING })
  
  const [brandList, setBrandList] = useState({ status: STATUS.INITIALIZING })
  const [vlcrBusiness, setVlcrBusiness] = useState({ status: STATUS.INITIALIZING })
  const [vlcrFamilies, setVlcrFamilies] = useState({ status: STATUS.IDLE })

  const [brandFilter, setBrandFilter] = useState('')
  const brandFilterDebounced = useDebounceValue(brandFilter, 250)

  const [asvList, setAsvList] = useState({ status: STATUS.INITIALIZING })
  const [asvEditing, setAsvEditing] = useState()

  const selectedNetwork = parseInt(query.get('networkId'))
  const selectedBrand = asvNewState.brandId && brandList.status === STATUS.LOADED &&
    brandList.payload.find(brand => brand.value === asvNewState.brandId)
  const selectedBusiness = asvNewState.businessId && vlcrBusiness.status === STATUS.LOADED &&
    vlcrBusiness.payload.find(business => business.value === asvNewState.businessId)
  const selectedFamily = asvNewState.familyId && vlcrFamilies.status === STATUS.LOADED &&
    vlcrFamilies.payload.find(family => family.value === asvNewState.familyId)

  useEffect(() => {
    wait(api.get('/networks/list'))
      .then(payload => setNetworks({ status: STATUS.LOADED, payload, }))
      .catch(payload => setNetworks({ status: STATUS.ERROR, payload: payload, }))
      
    wait(api.get('/equipments/brands'))
      .then(payload => setBrandList({
        status: STATUS.LOADED,
        payload: payload.brands.map(brand => ({ label: brand.brandName, value: brand.brandId }))
      }))
      .catch(payload => setBrandList({ status: STATUS.ERROR, value: payload, }))

    wait(api.get('/velocare/business'))
      .then(payload => setVlcrBusiness({
        status: STATUS.LOADED,
        payload: payload.map(business => {
          const [subsidiaryId, businessId] = business.id.split('-')
          return {
            value: business.id,
            label: business.name,
            subsidiaryId, businessId,
          }
        }),
      }))
      .catch(payload => setVlcrBusiness({ status: STATUS.ERROR, payload }))
  }, [api, ])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const networkId = query.get('networkId')

    if (!networkId && networks.status === STATUS.LOADED) {
      if (networks.payload.length > 0) {
        const query = new URLSearchParams({ 'networkId': networks.payload[0].networkId })
        history.push(`?${query}`)
      } else {
        setNetworks({ status: STATUS.ERROR, payload: { code: 'noNetworks', prefix: 'front' }, })
      }
    }
  }, [history, networks, location.search, ])

  useEffect(() => setPostState({ status: STATUS.IDLE }), [selectedNetwork, selectedBrand, selectedBusiness, selectedFamily])

  useEffect(() => {
    if (selectedNetwork) {
      setAsvList({ status: STATUS.INITIALIZING })

      api.get('/networks/sav', undefined, { networkId: selectedNetwork })
        .then(payload => setAsvList({ status: STATUS.LOADED, payload: payload.result, }))
        .catch(payload => setAsvList({ status: STATUS.ERROR, payload }))
    }
  }, [api, selectedNetwork,])

  useEffect(() => {
    setVlcrFamilies({ status: STATUS.IDLE })
    if (selectedBusiness) {
      setVlcrFamilies({ status: STATUS.INITIALIZING })

      api.get('/velocare/categoriesFamilies', undefined, selectedBusiness)
        .then(payload => setVlcrFamilies({
          status: STATUS.LOADED,
          payload: payload.map(family => {
            const [categoryId, familyId] = family.value.split('_')
            return {
              value: family.value,
              label: family.label,
              categoryId, familyId,
            }
          }),
        }))
        .catch(payload => setVlcrFamilies({ status: STATUS.ERROR, payload }))
    }
  }, [api, selectedBusiness, ])

  const brandListFiltered = useMemo(() => {
    return brandList.status === STATUS.LOADED
      ? brandList.payload.filter(brand =>
        brand.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(brandFilterDebounced.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      : []
  }, [brandList, brandFilterDebounced])

  const brandIdCustomOptionProps = useMemo(() => ({
    placeholder: tNew({ id: 'brandId.filter' }),
    value: brandFilter,
    onChange: ({ target: { value } }) => setBrandFilter(value)
  })
  , [brandFilter, tNew])

  const handleAsvNewUpdate = useCallback(({ target: { name, value }}) => {
    switch (name) {
      case 'businessId': return asvNewDispatch({ type: 'merge', payload: {
        [name]: value,
        familyId: undefined,
      } })
      default: return asvNewDispatch({ type: 'merge', payload: { [name]: value } })
    }
  }, [])

  const postAsv = useCallback(payload => {
    setPostState({ status: STATUS.INITIALIZING })
    wait(api.post('/networks/sav', { body: JSON.stringify({
      networkId: selectedNetwork,
      ...payload
    }) }))
      .then(payload => setAsvList({ status: STATUS.LOADED, payload: payload.result }))
      .then(() => setPostState({ status: STATUS.IDLE }))
      .then(() => setAsvEditing())
      .catch(payload => setPostState({ status: STATUS.ERROR, payload }))
  }, [api, selectedNetwork, ])

  const removeAsv = useCallback(payload => postAsv({ ...payload, delete: true, }), [postAsv, ])

  function handleAsvCreate () {
    const payload = {
      brandId: selectedBrand.value,
      velocareBusinessId: selectedBusiness ? Number(selectedBusiness.businessId) : undefined,
      velocareSubsidiaryId: selectedBusiness ? Number(selectedBusiness.subsidiaryId) : undefined,
      velocareCategoryId: selectedFamily ? Number(selectedFamily.categoryId) : undefined,
      velocareFamilyId: selectedFamily ? Number(selectedFamily.familyId) : undefined,
    }

    postAsv(payload)
  }

  const error = [networks, brandList, vlcrBusiness, asvList].find(state => state.status === STATUS.ERROR)

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content className={style.content}>
        {networks.status === STATUS.LOADED && networks.payload.length > 1 && (
          <Block className={'fill-grid'}>
            <Block.Title>{t({ id: 'networks.title' })}</Block.Title>
            <Block.Content>
              <SelectInput name="networkId"
                value={selectedNetwork}
                onChange={({ target: { value } }) => {
                  const query = new URLSearchParams({ 'networkId': value })
                  history.push(`?${query}`)
                }}
                disabled={false}
                options={networks.payload.map(option => ({
                  value: option.networkId,
                  label: option.networkName,
                }))}
              />
              {!selectedNetwork && <Alert type="warning">{t({ id: 'missingNetwork' })}</Alert>}
            </Block.Content>
          </Block>
        )}

        {asvList.status === STATUS.INITIALIZING && <Block.Loader />}
        {asvList.status === STATUS.LOADED && asvList.payload.length > 0 && asvList.payload.map(asv => (
          <ASV key={asv.savId}
            brandList={brandList}
            vlcrBusiness={vlcrBusiness}
            payload={asv}
            disabled={postState.status === STATUS.INITIALIZING || (asvEditing && asvEditing !== asv.savId)}
            updateCallback={postAsv}
            removeCallback={removeAsv}
            editCallback={setAsvEditing}
            editing={asvEditing === asv.savId} />
        ))}
          
        <Block>
          <Block.Title>{tNew({ id: 'title' })}</Block.Title>
          <Block.Content>
            <SelectInput
              name="brandId"
              label={tNew({ id: 'brandId.label' })}
              placeholder={tNew({ id: 'brandId.placeholder' })}
              disabled={brandList.status === STATUS.INITIALIZING || postState.status === STATUS.INITIALIZING || asvEditing}
              value={asvNewState.brandId}
              onChange={handleAsvNewUpdate}
              options={brandListFiltered}
              customOption={SelectFilter}
              customOptionProps={brandIdCustomOptionProps}
            />
            <SelectInput
              name="businessId"
              label={tNew({ id: 'businessId.label' })}
              placeholder={tNew({ id: 'businessId.placeholder' })}
              disabled={vlcrBusiness.status !== STATUS.LOADED || postState.status === STATUS.INITIALIZING || asvEditing}
              value={asvNewState.businessId}
              onChange={handleAsvNewUpdate}
              options={vlcrBusiness.status === STATUS.LOADED ? vlcrBusiness.payload : []}
              all={tNew({ id: 'businessId.all' })}
            />
            <SelectInput
              name="familyId"
              label={tNew({ id: 'familyId.label' })}
              placeholder={tNew({ id: vlcrFamilies.status === STATUS.INITIALIZING ? 'loading' : 'familyId.placeholder' })}
              disabled={vlcrFamilies.status !== STATUS.LOADED || !selectedBusiness || postState.status === STATUS.INITIALIZING || asvEditing}
              value={asvNewState.familyId}
              onChange={handleAsvNewUpdate}
              options={vlcrFamilies.status === STATUS.LOADED ? vlcrFamilies.payload : []}
              all={tNew({ id: 'familyId.all' })}
            />
          </Block.Content>
          <Block.Actions>
            <Button onClick={handleAsvCreate} disabled={postState.status === STATUS.INITIALIZING || asvEditing}>{tNew({ id: 'actions.create' })}</Button>
          </Block.Actions>
        </Block>

        {error && <Banner sticky
          type="danger"
          className={'fill-grid'}
          description={formatError(error.payload)}
        />}
        {postState.status === STATUS.ERROR && <Banner sticky
          type="danger"
          className={'fill-grid'}
          description={formatError(postState.payload)}
          actions={[
            { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
          ]}
        />}
      </Page.Content>
    </Page>
  )
}

function ASV ({
  brandList, vlcrBusiness,
  updateCallback = noop,
  removeCallback = noop,
  editCallback = noop,
  editing = false,
  disabled = false,
  payload: {
    savId,
    brandId,
    velocareSubsidiaryId,
    velocareBusinessId,
    velocareCategoryId,
    velocareFamilyId,
  }
}) {
  const api = useApi()
  const tList = useT('list')
  const formatError = useFormatError()

  const [asvState, asvDispatch] = useReducer(asvReducer, {
    savId,
    velocareSubsidiaryId,
    velocareBusinessId,
    velocareCategoryId,
    velocareFamilyId,
  }, asvInitializer)
  const [vlcrFamilies, setVlcrFamilies] = useState({ status: STATUS.IDLE })

  const [confirmRemove, setConfirmRemove] = useState(false)

  const selectedBrand = brandId && brandList.status === STATUS.LOADED &&
      brandList.payload.find(brand => brand.value === brandId)
  const selectedBusiness = asvState.businessId && vlcrBusiness.status === STATUS.LOADED &&
      vlcrBusiness.payload.find(business => business.value === asvState.businessId)
  const selectedFamily = asvState.familyId && vlcrFamilies.status === STATUS.LOADED &&
      vlcrFamilies.payload.find(family => family.value === asvState.familyId)

  useEffect(() => {
    if (selectedBusiness) {
      setVlcrFamilies({ status: STATUS.INITIALIZING })
      api.get('/velocare/categoriesFamilies', undefined, selectedBusiness)
        .then(payload => {
          setVlcrFamilies({ status: STATUS.LOADED, payload: payload.map(family => {
            const [categoryId, familyId] = family.value.split('_')
            return {
              value: family.value,
              label: family.label,
              categoryId, familyId,
            }
          }), })
        })
        .catch(payload => setVlcrFamilies({ status: STATUS.ERROR, payload }))
    }
  }, [api, selectedBusiness])

  const handleAsvUpdate = useCallback(({ target: { name, value }}) => {
    switch (name) {
      case 'businessId': return asvDispatch({ type: 'merge', payload: {
        [name]: value,
        familyId: undefined,
      } })
      default: return asvDispatch({ type: 'merge', payload: { [name]: value } })
    }
  }, [])

  const handleBusinessValueRender = useCallback((value) => vlcrBusiness.status === STATUS.LOADED
    ? vlcrBusiness.payload.find(business => business.value === value)
    : { value: '', label: tList({ id: 'loading' }) }
  , [vlcrBusiness, tList, ])

  const handleFamilyValueRender = useCallback((value) => vlcrFamilies.status === STATUS.LOADED
    ? vlcrFamilies.payload.find(family => family.value === value)
    : { value: '', label: tList({ id: 'loading' }) }
  , [vlcrFamilies, tList, ])

  function buildPayload () {
    return {
      savId: savId,
      brandId: brandId,
      velocareBusinessId: selectedBusiness ? Number(selectedBusiness.businessId) : undefined,
      velocareSubsidiaryId: selectedBusiness ? Number(selectedBusiness.subsidiaryId) : undefined,
      velocareCategoryId: selectedFamily ? Number(selectedFamily.categoryId) : undefined,
      velocareFamilyId: selectedFamily ? Number(selectedFamily.familyId) : undefined,
    }
  }

  function handleSave () {
    updateCallback(buildPayload())
  }

  function handleRemove () {
    removeCallback(buildPayload())
  }

  function handleConfirmRemove (value) {
    editCallback(value ? savId : undefined)
    setConfirmRemove(value)
  }

  return (
    <Block key={savId}>
      {brandList.status !== STATUS.LOADED && <Block.Loader />}
      {brandList.status === STATUS.LOADED && <>
        <Block.Title>{selectedBrand && selectedBrand.label}</Block.Title>
        <Block.Content>
          <SelectInput
            id={`businessId-${savId}`}
            name="businessId"
            label={tList({ id: 'businessId.label' })}
            placeholder={tList({ id: vlcrBusiness.status === STATUS.INITIALIZING ? 'loading' : 'businessId.placeholder' })}
            disabled={vlcrBusiness.status !== STATUS.LOADED || disabled || confirmRemove || !editing}
            value={asvState.businessId}
            onChange={handleAsvUpdate}
            options={vlcrBusiness.status === STATUS.LOADED ? vlcrBusiness.payload : []}
            valueRender={handleBusinessValueRender}
            all={tList({ id: 'businessId.all' })}
          />
          <SelectInput
            id={`familyId-${savId}`}
            name="familyId"
            label={tList({ id: 'familyId.label' })}
            placeholder={tList({ id: vlcrFamilies.status === STATUS.INITIALIZING ? 'loading' : 'familyId.placeholder' })}
            disabled={vlcrFamilies.status !== STATUS.LOADED || !selectedBusiness || disabled || confirmRemove || !editing}
            value={asvState.familyId}
            onChange={handleAsvUpdate}
            options={vlcrFamilies.status === STATUS.LOADED ? vlcrFamilies.payload : []}
            valueRender={handleFamilyValueRender}
            all={tList({ id: 'familyId.all' })}
          />
        </Block.Content>
        <Block.Actions>
          {!confirmRemove && !editing && (
            <Button disabled={disabled || vlcrFamilies.status === STATUS.ERROR} onClick={() => editCallback(savId)}>
              {tList({ id: 'actions.edit' })}
            </Button>)}
          {!confirmRemove && !editing && (
            <Button disabled={disabled || vlcrFamilies.status === STATUS.ERROR} onClick={() => handleConfirmRemove(true)} color="danger" outlined>
              {tList({ id: 'actions.remove' })}
            </Button>)}
          {!confirmRemove && editing && (
            <Button disabled={disabled || vlcrFamilies.status === STATUS.ERROR} onClick={() => editCallback()} outlined>
              {tList({ id: 'actions.cancel' })}
            </Button>)}
          {!confirmRemove && editing && (
            <Button disabled={disabled || vlcrFamilies.status === STATUS.ERROR} onClick={handleSave}>
              {tList({ id: 'actions.save' })}
            </Button>)}
          {confirmRemove && (
            <Button disabled={disabled || vlcrFamilies.status === STATUS.ERROR} onClick={handleRemove} color="danger">
              {tList({ id: 'actions.confirmRemove' })}
            </Button>)}
          {confirmRemove && (
            <Button disabled={disabled || vlcrFamilies.status === STATUS.ERROR} onClick={() => handleConfirmRemove(false)}>
              {tList({ id: 'actions.abortRemove' })}
            </Button>)}
          {vlcrFamilies.status === STATUS.ERROR && <Banner
            type="danger"
            className={'fill-grid'}
            description={formatError(vlcrFamilies.payload)}
          />}
        </Block.Actions>
      </>}
    </Block>
  )
}
