import { useHistory, useLocation, } from 'react-router-dom'

import { join, } from '@velogik/helper-classnames'
import { useT, } from '@velogik/component-intl'
import { Url, } from '@velogik/component-link'

import style from './ResultTable.module.scss'

export function ResultTable ({ keyExtractor, columns, result, order }) {
  const t = useT()

  return (<div className={style.root}>
    <table className={style.table}>
      <thead className={style.header}>
        <tr className={style.row}>
          {columns.map(column => <ColumnHeader key={column.name} data={column} order={order} /> )}
        </tr>
      </thead>
      <tbody className={style.body}>
        {result.map(item => <tr key={keyExtractor(item)} className={style.row}>
          {columns.map(column => (
            <td key={`${keyExtractor(item)}-${column.name}`} className={join(style.data, column.bodyClassName)}>
              {column.component ? <column.component item={item} column={column} /> : t({ id: `result.item.${column.name}` }, column.useItem ? column.useItem(item) : item)}
            </td>
          ))}
        </tr>)}
      </tbody>
    </table>
  </div>)
}

function ColumnHeader ({ data, order }) {
  const history = useHistory()
  const location = useLocation()
  const t = useT()

  const direction = order && data.orderKey && order.column === data.orderKey && order.dir

  function handleOrderClick () {
    const query = new URLSearchParams(location.search)
    query.set('order[column]', data.orderKey)
    query.set('order[dir]', direction ? direction === 'asc' ? 'desc' : 'asc' : 'asc')

    history.push(`?${query}`)
  }

  return (
    <th className={join(style.data, data.headerClassName)}>
      {data.orderKey
        ? <Url onClick={handleOrderClick} className={join(style.ordered, style[direction])}>{t({ id: `result.header.${data.name}` })}</Url>
        : t({ id: `result.header.${data.name}` })}
    </th>
  )
}
