import { useEffect, useState, useMemo, } from 'react'
import { useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Filters, } from '@velogik/component-filters'
import { Button, } from '@velogik/component-button'
import { Input, } from '@velogik/component-input'
import { Page, } from '@velogik/component-page'
import { formatDate, } from '@velogik/helper-date'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { DayInput } from 'components/DayInput'
import { Result, } from 'components/Result'

import style from './Invoices.module.scss'

const filters = [
  { name: 'shopId', queryName: 'shopId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'filter', type: 'search', },
  { name: 'dateStart', type: 'day', },
  { name: 'dateEnd', type: 'day', },
]

export function Invoices () {
  const api = useApi()
  const t = useT()
  const location = useLocation()

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const columns = useMemo(() => [
    { name: 'date', useItem: item => ({ date: new Date(item.dateCreated) }) },
    { name: 'interventionId', headerClassName: style.interventionId },
    { name: 'velocenterIdentifier' },
    { name: 'userName' },
    { name: 'totalNt' },
    { name: 'totalWt', bodyClassName: style.totalWt },
    { name: 'btn.download', component: ({ item, column }) => (
      <Button tag="a" href={item.billing.url} target="_blank" rel="noopener noreferrer">
        {t({ id: `result.item.${column.name}` }, item.billing)}
      </Button>
    )},
  ], [t])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/invoices/list', undefined, {
      p: query.get('p'),
      ipp: query.get('ipp'),
      ...filters.reduce((acc, item) => ({ ...acc, [item.name]: query.get(item.queryName || item.name) }), {}),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        <Filters data={dataState} filters={filters}
          searchRender={SearchFilter}
          dayRender={DayFilter}
        />
        <Result
          keyExtractor={item => item.interventionId}
          data={dataState} columns={columns} />
      </Page.Content>
    </Page>
  )
}

function SearchFilter ({ payload, filter, applyFilter }) {
  const t = useT()
  const [value, setValue] = useState(payload.filters[filter.name] || '')

  function submit (e) {
    e.preventDefault()

    applyFilter({ [filter.queryName || filter.name]: value })
  }

  return (
    <form onSubmit={submit}>
      <Input name={filter.name}
        className={style.search} inputClassName={style.input}
        label={t({ id: `filters.${filter.name}.label` })}
        placeholder={t({ id: `filters.${filter.name}.placeholder` })}
        value={value}
        onChange={({ target: { value } }) => setValue(value)}>
        <Button className={style.icon}
          onClick={submit} />
      </Input>
    </form>
  )
}

function DayFilter ({ payload, filter, applyFilter }) {
  const t = useT()

  return (
    <DayInput name={filter.name} canClear={payload.filters[filter.name]}
      label={t({ id: `filters.${filter.name}.label` })}
      placeholder={t({ id: `filters.${filter.name}.placeholder` })}
      value={payload.filters[filter.name] || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value ? formatDate(value) : '' })}
    />
  )
}
