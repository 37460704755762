import { useT, } from '@velogik/component-intl'
import { Link, Url, } from '@velogik/component-link'
import { join, } from '@velogik/helper-classnames'

import { useHasProfile, } from 'hooks/use-user'

import style from './Footer.module.scss'
import logo from './assets/logo-full-dark.svg'

export function Footer() {
  const t = useT('Footer', true)

  const isAdminVelocenter = useHasProfile('admin_velocenter')

  return (
    <div className={style.wrapper}>
      <nav className={style.root}>
        <div className={style.category}>
          <div className={style.title}>{t({ id: 'legal.title' })}</div>
          <Url className={style.link} href="https://velocenter.com/legal/terms" rel="noopener">{t({ id: 'legal.terms' })}</Url>
          <Url className={style.link} href="https://velocenter.com/legal/privacy" rel="noopener">{t({ id: 'legal.privacy' })}</Url>
          <Url className={style.link} href="https://velocenter.com/legal/mentions" rel="noopener">{t({ id: 'legal.mentions' })}</Url>
        </div>
        <div className={style.category}>
          <div className={style.title}>{t({ id: 'commercial.title' })}</div>
          <Link className={style.link} to="/contact">{t({ id: 'commercial.contact' })}</Link>
          <Link className={style.link} to="/services">{t({ id: 'commercial.services' })}</Link>
          <Link className={style.link} to="/faq">{t({ id: 'commercial.faq' })}</Link>
        </div>
        {isAdminVelocenter && (
          <div className={style.category}>
            <div className={style.title}>{t({ id: 'admin.title' })}</div>
            <Link className={style.link} to="/admin/networks">{t({ id: 'admin.networks' })}</Link>
          </div>
        )}
        <div className={join(style.category, style.logo)}>
          <img src={logo} alt="" />
          <div className={style.version}>{t({ id: 'version' }, { version: process.env.REACT_APP_VERSION })}</div>
        </div>
      </nav>
    </div>
  )
}
