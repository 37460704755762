import { Loading, } from '@velogik/component-loading'
import { Alert, } from '@velogik/component-alert'
import { ResultInfos, } from '@velogik/component-result-infos'
import { ResultPagination, } from '@velogik/component-result-pagination'
import { join, } from '@velogik/helper-classnames'
import { STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'

import { ResultTable, } from './ResultTable'

import style from './Result.module.scss'

export function Result ({ data: { payload, status }, columns, keyExtractor, className }) {
  const t = useT()
  const formatError = useFormatError()

  switch (status) {
    case STATUS.ERROR:
      return <Alert>{formatError(payload)}</Alert>
    case STATUS.LOADED:
      return (
        payload.total === 0
          ? (
            <div className={join(style.root, className)}>
              <Alert type="warning">{t({ id: 'noresult' })}</Alert>
            </div>
          ) : (
            <div className={join(style.root, className)}>
              {payload.itemPerPage && (
                <ResultInfos
                  ipp={payload.itemPerPage}
                  total={payload.total} />
              )}
              <ResultTable
                result={payload.result} 
                order={payload.order}
                columns={columns}
                keyExtractor={keyExtractor} />
              {payload.currentPage && (
                <ResultPagination
                  totalPage={payload.totalPage}
                  currentPage={payload.currentPage}
                  className={style.ResultPagination} />
              )}
            </div>
          )
      )
    case STATUS.INITIALIZING:
    default:
      return <Loading>{t({ id: status })}</Loading>
  }
}
