import { useEffect, useState, useMemo, } from 'react'
import { Link, useLocation, useHistory, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Filters, } from '@velogik/component-filters'
import { Input, } from '@velogik/component-input'
import { useIntl, useT, useFormatError, } from '@velogik/component-intl'
import { Loading, } from '@velogik/component-loading'
import { Modal, } from '@velogik/component-modal'
import { Page, } from '@velogik/component-page'
import { PhoneInput, } from '@velogik/component-phone-input'
import { wait, STATUS } from '@velogik/helper-promise'

import { isPossiblePhoneNumber } from 'react-phone-number-input'

import { BackButton, } from '../components/BackButton'
import { Result, } from '../components/Result'

import style from './SelectClient.module.scss'

const filters = [
  { name: 'filter', type: 'text' },
  { name: 'clientPhone', type: 'phone' },
]

export function SelectClient() {
  const api = useApi()
  const location = useLocation()
  const t = useT()
  const formatError = useFormatError()

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })
  const [selectedClient, setSelectedClient] = useState(undefined)

  const equipmentId = useMemo(() => {
    const query = new URLSearchParams(location.search)
    return query.get('equipmentId') || ''
  }, [location.search])

  const columns = useMemo(() => [
    { name: 'name' },
    { name: 'phoneNumber' },
    { name: 'email' },
    { name: 'btn.select', component: ({ item, }) => <Button onClick={() => setSelectedClient(item)}>{t({ id: 'result.item.btn.select' })}</Button>},
  ], [t])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/interventions/client', undefined, {
      filter: query.get('filter'),
      clientPhone: query.get('clientPhone'),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location.search,])

  const selectEquipmentPath = useMemo(() =>
    equipmentId && equipmentId !== 'unknown'
      ? `/clients/new?equipmentId=${equipmentId}`
      : '/clients/new'
  , [equipmentId])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        <Page.Header.Actions>
          <Button tag={Link} to={selectEquipmentPath}>{t({ id: 'notFound' })}</Button>
        </Page.Header.Actions>
      </Page.Header>

      <Page.Content>
        {dataState.status === STATUS.ERROR && <Alert>{formatError(dataState.payload)}</Alert>}
        {dataState.status === STATUS.INITIALIZING && <Loading>{t({ id: 'initializing' })}</Loading>}
        {dataState.status === STATUS.LOADED && <Filters
          data={dataState} filters={filters}
          phoneRender={PhoneRender} />}
        {dataState.status === STATUS.LOADED && <Result
          keyExtractor={item => item.clientId}
          data={dataState} columns={columns} />}
      </Page.Content>

      {selectedClient && (
        <SelectedClientModal
          client={selectedClient}
          equipmentId={equipmentId}
          close={() => setSelectedClient()} />
      )}
    </Page>
  )
}

function SelectedClientModal ({ client, equipmentId: _equipmentId, close }) {
  const t = useT('SelectedClientModal')
  const api = useApi()
  const location = useLocation()
  const history = useHistory()

  const [clientState, setClientState] = useState({ status: STATUS.INITIALIZING, payload: undefined })
  const [equipmentId, setEquipmentId] = useState(_equipmentId)
  
  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get('userId') || ''

  useEffect(() => {
    if (!_equipmentId || _equipmentId === 'unknown') {
      wait(api.get('/clients/details', undefined, { clientId: client.clientId }))
        .then(payload => setClientState({ status: STATUS.LOADED, payload }))
        .catch(payload => setClientState({ status: STATUS.ERROR, payload }))
    }
  }, [api, history, client, _equipmentId])

  if (_equipmentId && _equipmentId !== 'unknown') {
    history.push(`/interventions/new?${new URLSearchParams({
      userId,
      equipmentId: _equipmentId,
      clientId: client.clientId,
    }).toString()}`)
  }

  return (
    <Modal isOpen={Boolean(client)} toggle={close}>
      <Block className={style.selectEquipment}>
        <Block.Title>{t({ id: 'title' })}</Block.Title>
        {clientState.status === STATUS.INITIALIZING && <Block.Loader />}
        {clientState.status === STATUS.LOADED && (
          <Block.Content>
            <div>{t({ id: 'disclaimer' })}</div>
            <div>
              {clientState.payload.equipments.map(equipment =>
                <Input
                  key={`select-equipment-${equipment.equipmentId}`}
                  id={`select-equipment-${equipment.equipmentId}`}
                  type="radio"
                  name="selectEquipment"
                  label={(
                    <div className={style.label}>
                      {t({ id: 'list.equipment' }, {
                        ...equipment,
                        title: chunk => <div className={style.title}>{chunk}</div>,
                        content: chunk => <div className={style.content}>{chunk}</div>,
                      })}
                    </div>
                  )}
                  value={equipment.equipmentId}
                  matchValue={equipmentId}
                  onChange={({ target: { value }}) => setEquipmentId(value)}
                />
              )}
              <Input
                key={'selectequipment-unknown'}
                id={'select-equipment-unknown'}
                type="radio"
                name="selectEquipment"
                label={t({ id: 'list.other' })}
                value={'unknown'}
                matchValue={equipmentId}
                onChange={({ target: { value }}) => setEquipmentId(value)}
              />
            </div>
          </Block.Content>
        )}

        <Block.Actions>
          <Button onClick={close} outlined>
            {t({ id: 'actions.cancel' })}
          </Button>
          <Button
            tag={Link}
            to={{
              ...location,
              pathname: '/interventions/new',
              search: new URLSearchParams({
                userId,
                equipmentId: equipmentId,
                clientId: client.clientId,
              }).toString(),
            }}
            disabled={clientState.status !== STATUS.LOADED}
          >
            {t({ id: 'actions.confirm' })}
          </Button>
        </Block.Actions>
      </Block>
    </Modal>
  )
}

function PhoneRender ({ filter, payload, applyFilter }) {
  const intl = useIntl()
  const t = useT()

  function handleChange (newPhoneNumber) {
    applyFilter({ [filter.queryName || filter.name]: ((newPhoneNumber && isPossiblePhoneNumber(newPhoneNumber)) ? newPhoneNumber : '') })
  }

  return (
    <PhoneInput
      label={t({ id: 'filters.clientPhone.label' })}
      placeholder={t({ id: 'filters.clientPhone.placeholder' })}
      displayInitialValueAsLocalNumber={true}
      defaultCountry={intl.locale.toUpperCase()}
      name={filter.name}
      value={payload.filters[filter.name] || ''}
      onChange={handleChange}
    />
  )
}
