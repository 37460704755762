import { useState, useCallback, useEffect, } from 'react'
import { useParams, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { AddressInput, } from '@velogik/component-address-input'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, } from '@velogik/component-intl'
import { NumberInput, } from '@velogik/component-number-input'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'

import { BackButton, } from '../components/BackButton'

export function ShopLegal() {
  const api = useApi()
  const routeParams = useParams()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const post = useCallback(() => {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/shops/legal', { body: JSON.stringify(dataState.current) }))
      .then(payload => {
        dataDispatch({ type: FORM_ACTION.INIT, value: payload, })
        setPostState({ status: STATUS.LOADED, payload })
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }, [api, dataState, dataDispatch])

  useEffect(() => {
    wait(api.get('/shops/legal', undefined, { shopId: routeParams.shopId }))
      .then(payload => dataDispatch({ type: FORM_ACTION.INIT, value: payload }))
      .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
  }, [api, dataDispatch, routeParams.shopId])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'partial' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Content>
                <Input name="shopLegalName" type="text"
                  label={t({ id: 'shopLegalName.label' })}
                  placeholder={t({ id: 'shopLegalName.placeholder' })} />
                <Input name="shopVatNumber" type="text"
                  label={t({ id: 'shopVatNumber.label' })}
                  placeholder={t({ id: 'shopVatNumber.placeholder' })} />
                <Input name="shopCompanyNumber" type="text"
                  label={t({ id: 'shopCompanyNumber.label' })}
                  placeholder={t({ id: 'shopCompanyNumber.placeholder' })} />
                <AddressInput name="addressFull"
                  label={t({ id: 'addressFull.label' })}
                  placeholder={t({ id: 'addressFull.placeholder' })} />
                <Input name="addressComplement" type="text"
                  label={t({ id: 'addressComplement.label' })}
                  placeholder={t({ id: 'addressComplement.placeholder' })} />
                <Input name="legalNotice" type="text"
                  label={t({ id: 'legalNotice.label' })}
                  placeholder={t({ id: 'legalNotice.placeholder' })} />
                <NumberInput name="vatRateMo"
                  label={t({ id: 'vatRateMo.label' })}
                  placeholder={t({ id: 'vatRateMo.placeholder' })} />
                <NumberInput name="vatRatePc"
                  label={t({ id: 'vatRatePc.label' })}
                  placeholder={t({ id: 'vatRatePc.placeholder' })} />
              </Block.Content>
            </Block>
          </Form.Content>

          {/* TODO: Success state */}
          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
