import { useState, useReducer, } from 'react'
import { useApi, } from 'react-rest-api'

import { Banner, } from '@velogik/component-banner'
import { Button, } from '@velogik/component-button'
import { Block, } from '@velogik/component-block'
import { FormProvider, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useFormatError, useParseNumber, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { STATUS } from '@velogik/helper-promise'

import style from './InterventionDetails.CreateItvcode.Modal.module.scss'

function dataReducer (state, action) {
  switch (action.type) {
    case 'merge': return { ...state, current: { ...state.current, ...action.payload } }
    default: return state
  }
}

const DATA_INITIAL = Object.freeze({
  type: 'workforce',
  itvcodeIdentifier: '',
  itvcodeLabel: '',
  duration: 0,
  durationDisplay: '',
  price: 0,
  priceDisplay: '',
  persist: false,
})

function dataInitializer (data) {
  return { initital: data, current: data, }
}

export function CreateItvcode ({
  close,
  props: {
    t,
    shopId,
    interventionId,
    equipmentType,
    canCreateWorkforce = true,
    canCreatePiece = true,
    closeCallback = () => {},
  },
}) {
  const api = useApi()
  const formatError = useFormatError()
  const parseNumber = useParseNumber()

  const [postState, setPostState] = useState({ status: STATUS.IDLE})
  const [dataState, dataDispatch] = useReducer(dataReducer, {
    ...DATA_INITIAL,
    type: canCreateWorkforce ? 'workforce' : canCreatePiece ? 'piece' : undefined
  }, dataInitializer)

  function handleChange ({ target }) {
    if (target.type === 'checkbox') {
      dataDispatch({ type: 'merge', payload: { [target.name]: target.checked } })
    }
    else if (['duration', 'price'].includes(target.name)) {
      const parsedValue = target.value ? parseNumber(target.value) : 0

      if (!isNaN(parsedValue)) {
        dataDispatch({ type: 'merge', payload: {
          [`${target.name}Display`]: target.value,
          [target.name]: parsedValue
        } })
      }
    }
    else {
      dataDispatch({ type: 'merge', payload: { [target.name]: target.value } })
    }
  }

  function create () {
    setPostState({ status: STATUS.LOADING })
    api.post('/itvcodes/details', { body: JSON.stringify({
      shopId, interventionId, equipmentType,
      type: dataState.current.type,
      itvcodeIdentifier: dataState.current.itvcodeIdentifier,
      itvcodeLabel: dataState.current.itvcodeLabel,
      priceWt: dataState.current.price,
      persist: dataState.current.persist,
      duration: dataState.current.type === 'workforce' ? dataState.current.duration : undefined, // Optional
    }) })
      .then(payload => closeCallback && closeCallback(payload))
      .then(() => close())
      .catch(payload => setPostState({ status: STATUS.ERROR, payload }))
  }

  return (
    <FormProvider
      state={dataState} dispatcher={dataDispatch}
      disabled={postState.status === STATUS.LOADING}
    >
      <Block className={style.root}>
        <Block.Title>{t({ id: 'title' })}</Block.Title>
        <Block.Content>
          <Label className={style.mandatory}>{t({ id: 'mandatory' })}</Label>
        </Block.Content>
        <Block.Content className={style.type}>
          {canCreateWorkforce && <Input type="radio"
            name="type"
            className={style.input}
            id={'type-workforce'}
            value={'workforce'}
            onChange={handleChange}
            label={t({ id: 'type.workforce' })} />}
          {canCreatePiece && <Input type="radio"
            name="type"
            className={style.input}
            id={'type-piece'}
            value={'piece'}
            onChange={handleChange}
            label={t({ id: 'type.piece' })} />}
        </Block.Content>
        <Block.Content className={style.labels}>
          <Input
            type="text"
            name="itvcodeIdentifier"
            label={t({ id: 'itvcodeIdentifier.label' })}
            placeholder={t({ id: 'itvcodeIdentifier.placeholder' })}
            onChange={handleChange} />
          <Input
            type="text"
            name="itvcodeLabel"
            label={t({ id: 'itvcodeLabel.label' })}
            placeholder={t({ id: 'itvcodeLabel.placeholder' })}
            onChange={handleChange} />
        </Block.Content>
        <Block.Content className={style.values}>
          <Input
            type="text"
            name="price"
            label={t({ id: 'price.label' })}
            onChange={handleChange} />
          {dataState.current.type === 'workforce' && <Input
            type="text"
            name="duration"
            label={t({ id: 'duration.label' })}
            onChange={handleChange} />}
        </Block.Content>
        <Block.Actions className={style.actions}>
          {postState.status === STATUS.ERROR && (
            <Banner type="danger"
              className={style.alert}
              description={formatError(postState.payload)}
              actions={[
                { label: t({ id: 'actions.error.dismiss' }),
                  outlined: true,
                  onClick: () => setPostState({ status: STATUS.IDLE })
                }
              ]}
            />
          )}
          <Input
            id="persist"
            className={style.persist}
            type="checkbox"
            name='persist'
            label={t({ id: 'persist' })}
            value={dataState.persist}
            onChange={handleChange} />
          <Button
            className={style.create}
            onClick={create}
          >
            {t({ id: 'actions.create' })}
          </Button>
        </Block.Actions>
      </Block>
    </FormProvider>
  )
}
