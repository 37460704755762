import { useEffect, useState, useMemo, } from 'react'
import { Link, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Filters, } from '@velogik/component-filters'
import { Button, } from '@velogik/component-button'
import { useT, } from '@velogik/component-intl'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'

import { BackButton, } from 'components/BackButton'
import { Result, } from 'components/Result'

import style from './EquipmentList.module.scss'

const filters = [
  { name: 'shopId', queryName: 'shops', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'equipmentType', queryName: 'type', type: 'equipmentType', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'filter', queryName: 'filter', type: 'text' },
  { name: 'showHidden', queryName: 'hidden', type: 'checkbox', inputLabel: true },
]

export function EquipmentList() {
  const api = useApi()

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const t = useT()

  const location = useLocation()

  const columns = useMemo(() => [
    { name: 'velocenterIdentifier',
      headerClassName: style.littleCol,
      component: ({ item, }) =>
        <span title={item.velocenterIdentifier} className={style.velocenterIdentifier}>
          ...{item.velocenterIdentifier.slice(-4)}
        </span>,
      orderKey: 'velocenterIdentifier' },
    { name: 'equipmentType' },
    { name: 'customName', orderKey: 'customName' },
    { name: 'brand', orderKey: 'brand', component: ({ item, }) => item.brandName || item.brand || null },
    { name: 'interventionsCount' },
    { name: 'btn.details', component: ({ item, }) => <Button tag={Link} to={`/equipments/${item.equipmentId}`}>{t({ id: 'result.item.btn.details' })}</Button> },
  ], [t,])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/equipments/list', undefined, {
      p: query.get('p'),
      ipp: query.get('ipp'),
      ...filters.reduce((acc, item) => ({ ...acc, [item.name]: query.get(item.queryName) }), {}),
      'order[column]': query.get('order[column]'),
      'order[dir]': query.get('order[dir]'),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {/* TODO: search by equipmentId - found Velocare eq */}
        <Filters data={dataState} filters={filters}
          equipmentTypeRender={EquipmentTypeFilter} />
        <Result
          keyExtractor={item => item.equipmentId}
          data={dataState} columns={columns}
          className={style.content} />
      </Page.Content>
    </Page>
  )
}

function EquipmentTypeFilter ({ filter, applyFilter, payload }) {
  const t = useT()

  const tValue = payload.filters[filter.name].selected
    && payload.filters[filter.name].selected.value
    && t({ id: `filters.${filter.name}.option` }, { option: payload.filters[filter.name].selected.key })

  return (
    <SelectInput
      key={filter.name}
      type="select"
      name={filter.name}
      label={t({ id: `filters.${filter.name}.label`, })}
      value={tValue || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value, })}
      multiple={filter.multiple}
      all={filter.canSelectAll ? t({ id: `filters.${filter.name}.all` }) : undefined}
      placeholder={t({ id: `filters.${filter.name}.placeholder` })}
      options={payload.filters[filter.name].values}
      optionRender={option => t({ id: `filters.${filter.name}.option` }, { option: option.label })}
    />
  )
}
