import { useEffect, useState, useReducer } from 'react'
import { Link, useParams, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'
import { formatPhoneNumber } from 'react-phone-number-input'

import { useApp, } from '@velogik/component-app'
import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Input, } from '@velogik/component-input'
import { Modal, } from '@velogik/component-modal'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { useHasProfile } from 'hooks/use-user'

import style from './ClientView.module.scss'

function confirmNewInterventionReducer (state, action) {
  switch (action.type) {
    case 'open': return { ...state, open: true }
    case 'toggle': return { ...state, open: !state.open, }
    case 'close': return confirmNewInterventionInitial
    case 'select': return { ...state, selected: action.value }
    default: return state
  }
}

const confirmNewInterventionInitial = {
  open: false,
  selected: '',
}

export function ClientView() {
  const api = useApi()
  const routeParams = useParams()
  const t = useT()
  const formatError = useFormatError()
  const { appState } = useApp()

  const isReader = useHasProfile('reader')
  const isAdmin = useHasProfile('admin_network', 'admin_velocenter')

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING })
  const [confirmNewInterventionState, confirmNewInterventionDispatch] = useReducer(confirmNewInterventionReducer, confirmNewInterventionInitial)

  useEffect(() => {
    wait(api.get('/clients/details', undefined, { clientId: routeParams.clientId }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload, }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, routeParams.clientId,])

  function toggleArchive () {
    setDataState({ status: STATUS.INITIALIZING })

    const body = JSON.stringify({
      clientId: routeParams.clientId,
    })

    api.post('/clients/archive', { body })
      .then(payload => setDataState({ status: STATUS.LOADED, payload, }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        {!isReader && (
          <Page.Header.Actions>
            <Button tag={Link} to={`/equipments/new?clientId=${routeParams.clientId}`} outlined>{t({ id: 'actions.newEquipment', })}</Button>
            <Button onClick={() => confirmNewInterventionDispatch({ type: 'open' })}>{t({ id: 'actions.newIntervention', })}</Button>
          </Page.Header.Actions>
        )}
      </Page.Header>

      <Page.Content className={style.pageContent}>
        <Block>
          <Block.Title>{t({ id: 'informations.title' })}</Block.Title>
          {dataState.status === STATUS.INITIALIZING && <Block.Loader />}
          {dataState.status === STATUS.ERROR && <Block.Content>
            <Alert>{formatError(dataState.payload)}</Alert>
          </Block.Content>}
          {dataState.status === STATUS.LOADED && (
            <Block.Content striped>
              <Block.Item
                label={t({ id: 'informations.item.firstName.label' })}
                value={dataState.payload.firstName} />
              <Block.Item
                label={t({ id: 'informations.item.lastName.label' })}
                value={dataState.payload.lastName} />
              <Block.Item
                label={t({ id: 'informations.item.email.label' })}
                value={dataState.payload.email} />
              <Block.Item
                label={t({ id: 'informations.item.phoneNumber.label' })}
                value={formatPhoneNumber(dataState.payload.phoneNumber)} />
              <Block.Item
                label={t({ id: 'informations.item.addressFull.label' })}
                value={dataState.payload.addressFull} />
            </Block.Content>
          )}

          {!isReader && (
            <Block.Actions>
              <Button
                tag={Link}
                to={`/clients/${routeParams.clientId}/edit`}
                disabled={dataState.status !== STATUS.LOADED}
              >
                {t({ id: 'informations.actions.edit', })}
              </Button>
              <Button
                onClick={() => confirmNewInterventionDispatch({ type: 'open' })}
                disabled={dataState.status !== STATUS.LOADED}
                outlined
              >
                {t({ id: 'informations.actions.newItv', })}
              </Button>
            </Block.Actions>
          )}
        </Block>

        {dataState.status === STATUS.LOADED && dataState.payload.equipments.map(equipment => (
          <Block key={equipment.equipmentId}>
            <Block.Title className={style.heading}>{t({ id: 'equipment.title' })}</Block.Title>
            <Block.Content striped>
              <Block.Item
                label={t({ id: 'equipment.item.velocenterIdentifier.label' })}
                value={equipment.velocenterIdentifier} />
              <Block.Item
                label={t({ id: 'equipment.item.equipmentType.label' })}
                value={t({ id: 'equipment.item.equipmentType.value' }, equipment )} />
              <Block.Item
                label={t({ id: 'equipment.item.customName.label' })}
                value={equipment.customName} />
              <Block.Item
                label={t({ id: 'equipment.item.brand.label' })}
                value={equipment.brandName || equipment.brand} />
              <Block.Item
                label={t({ id: 'equipment.item.equipmentStatusId.label' })}
                value={t({ id: `equipmentStatus.${(appState.constants.equipmentStatus.find(status => status.value === equipment.equipmentStatusId) || {}).key}`} )} />
            </Block.Content>

            <Block.Actions>
              <Button tag={Link} to={`/equipments/${equipment.equipmentId}`}>{t({ id: 'equipment.actions.details', })}</Button>
              {!isReader && (
                <Button
                  tag={Link}
                  to={`/interventions/new?clientId=${routeParams.clientId}&equipmentId=${equipment.equipmentId}`}
                  outlined
                >
                  {t({ id: 'equipment.actions.newItv', })}
                </Button>
              )}
            </Block.Actions>
          </Block>
        ))}

        {!isReader && !isAdmin && dataState.status === STATUS.LOADED && (
          <Block className={style.archiveBlock}>
            <Block.Title>{t({ id: 'archive.title' })}</Block.Title>
            <Block.Content className={style.archiveContent}>
              <Button onClick={toggleArchive}
                outlined
                color="danger"
                className={style.archiveButton}
                disabled={dataState.status !== STATUS.LOADED}>
                {t({ id: `archive.${dataState.payload.dateArchived ? 'unarchive' : 'archive'}.action` })}
              </Button>
              {t({ id: `archive.${dataState.payload.dateArchived ? 'unarchive' : 'archive'}.description` })}
            </Block.Content>
          </Block>
        )}
      </Page.Content>

      {dataState.status === STATUS.LOADED && <Modal isOpen={confirmNewInterventionState.open} toggle={() => confirmNewInterventionDispatch({ type: 'toggle' })}>
        <Block className={style.selectEquipment}>
          <Block.Title className={style.title}>{t({ id: 'selectEquipment.title' })}</Block.Title>
          <Block.Content className={style.content}>
            <div>{t({ id: 'selectEquipment.disclaimer' })}</div>
            <div>
              {dataState.payload.equipments.map(equipment => (
                <Input key={equipment.equipmentId}
                  name="selectEquipment" type="radio"
                  label={(
                    <div className={style.label}>
                      {t({ id: 'selectEquipment.equipment.label' }, {
                        ...equipment,
                        title: chunk => <div className={style.title}>{chunk}</div>,
                        content: chunk => <div className={style.content}>{chunk}</div>,
                      })}
                    </div>
                  )}
                  value={`/interventions/new?clientId=${routeParams.clientId}&equipmentId=${equipment.equipmentId}`}
                  matchValue={confirmNewInterventionState.selected}
                  onChange={({ target: { value } }) => confirmNewInterventionDispatch({ type: 'select', value })} />
              ))}

              <Input
                name="selectEquipment" type="radio"
                label={t({ id: 'selectEquipment.other.label' })}
                value={`/interventions/new?clientId=${routeParams.clientId}`}
                matchValue={confirmNewInterventionState.selected}
                onChange={({ target: { value } }) => confirmNewInterventionDispatch({ type: 'select', value })} />
            </div>
          </Block.Content>
          <Block.Actions className={style.blockActions}>
            <Button onClick={() => confirmNewInterventionDispatch({ type: 'close' })} outlined>{t({ id: 'selectEquipment.cancel' })}</Button>
            <Button tag={Link} to={confirmNewInterventionState.selected} disabled={!confirmNewInterventionState.selected}>
              {t({ id: 'selectEquipment.go' })}
            </Button>
          </Block.Actions>
        </Block>
      </Modal>}
    </Page>
  )
}
