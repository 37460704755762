import { useEffect, useState, useMemo, } from 'react'
import { Link, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Button, } from '@velogik/component-button'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { Result, } from 'components/Result'

import style from './NetworkList.module.scss'

export function NetworkList() {
  const api = useApi()
  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const t = useT()
  const location = useLocation()

  const columns = useMemo(() => [
    { name: 'networkId', headerClassName: style.littleCol, },
    { name: 'networkName', },
    { name: 'btn.view', component: ({ item }) => <Button tag={Link} to={`/networks/admin?networkId=${item.networkId}`}>{t({ id: 'result.item.btn.view' })}</Button> },
  ], [t,])

  useEffect(() => {
    wait(api.get('/networks/list'))
      .then(payload => setDataState({ status: STATUS.LOADED, payload: { result: payload } }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        <Page.Header.Actions>
          <Button tag={Link} to={'/networks/new'}>{t({ id: 'new' })}</Button>
        </Page.Header.Actions>
      </Page.Header>

      <Page.Content>
        <Result
          keyExtractor={item => item.networkId}
          data={dataState} columns={columns} />
      </Page.Content>
    </Page>
  )
}
