import { useState, useEffect, } from 'react'
import { useParams, useHistory, useLocation, } from 'react-router-dom'
import { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import { useApi } from 'react-rest-api'

import { AddressInput, } from '@velogik/component-address-input'
import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { Page, } from '@velogik/component-page'
import { PhoneInput, } from '@velogik/component-phone-input'
import { SelectInput, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useIntl, useFormatError, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'

const DEFAULT_VALUE = {
  addressComplement: ''
}

export function ClientEdit() {
  const history = useHistory()
  const location = useLocation()

  const api = useApi()
  const routeParams = useParams()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const intl = useIntl()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })
  const [shops, setShops] = useState({ status: STATUS.INITIALIZING })

  useEffect(() => {
    if (routeParams.clientId) {
      wait(api.get('/clients/details', undefined, { clientId: routeParams.clientId }))
        .then(payload => dataDispatch({ type: FORM_ACTION.INIT, value: payload, }))
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
    } else {
      dataDispatch({ type: FORM_ACTION.INIT, value: DEFAULT_VALUE })
    }
  }, [api, dataDispatch, routeParams.clientId,])

  useEffect(() => {
    wait(api.get('/shops/listlight'))
      .then(payload => setShops({ status: STATUS.LOADED, payload: payload.map(shop => ({ value: shop.shopId, label: shop.shopName })) }))
      .catch(payload => setShops({ status: STATUS.ERROR, payload }))
  }, [api])

  useEffect(() => {
    if (shops.status === STATUS.LOADED && shops.payload.length === 1) {
      dataDispatch({
        target: {
          type: 'select',
          name: 'shopId',
          value: shops.payload[0].value
        }
      })
      dataDispatch({ type: FORM_ACTION.PRISTINE })
    }
  }, [shops, dataDispatch])

  function post (additionalParams = {}) {
    const query = new URLSearchParams(location.search)
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/clients/details', { body: JSON.stringify({
      equipmentId: query.get('equipmentId'),
      validPhoneNumber: dataState.current.isPhoneValidated,
      ...dataState.current,
      ...additionalParams,
    }) }))
      .then(payload => {
        if (routeParams.clientId) {
          dataDispatch({ type: FORM_ACTION.INIT, value: payload, })
          setPostState({ status: STATUS.LOADED, payload })
        } else {
          history.replace(`/clients/${payload.clientId}`)
        }
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function handlePhoneNumberChange (value) {
    dataDispatch({ target: { name: 'isPhoneValidated', value: value === dataState.initial.phoneNumber ? dataState.initial.isPhoneValidated : false, type: 'checkbox' }, })

    return dataDispatch({ target: { name: 'phoneNumber', value, type: 'phone' }, })
  }

  const allowedCountryCallingCodes = [getCountryCallingCode('FR')]
  const userPhoneNumber = dataState.current.phoneNumber && isValidPhoneNumber(dataState.current.phoneNumber) && parsePhoneNumber(dataState.current.phoneNumber)

  const phoneNumberValidable = userPhoneNumber && allowedCountryCallingCodes.includes(userPhoneNumber.countryCallingCode)

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'partial' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Title>{t({ id: 'infos.title' })}</Block.Title>
              {shops.status === STATUS.INITIALIZING && <Block.Loader />}
              {shops.status === STATUS.ERROR && <Block.Content>
                <Alert>{formatError(shops.payload)}</Alert>
              </Block.Content>}
              {shops.status === STATUS.LOADED && <Block.Content>
                <Input name="firstName" type="text" label={t({ id: 'infos.firstName.label' })} placeholder={t({ id: 'infos.firstName.placeholder' })} />
                <Input name="lastName" type="text" label={t({ id: 'infos.lastName.label' })} placeholder={t({ id: 'infos.lastName.placeholder' })} />
                <Input name="email" type="email" label={t({ id: 'infos.email.label' })} placeholder={t({ id: 'infos.email.placeholder' })} />
                <Input name="newsletter" type="checkbox" label={t({ id: 'infos.newsletter.label' })} />
                <SelectInput
                  name="shopId"
                  disabled={shops.payload.length === 1 || routeParams.clientId}
                  label={t({ id: 'infos.shopId.label' })}
                  placeholder={t({ id: 'infos.shopId.placeholder' })}
                  options={shops.payload} />
                <PhoneInput name="phoneNumber" type="phone"
                  label={t({ id: 'infos.phoneNumber.label' })}
                  placeholder={t({ id: 'infos.phoneNumber.placeholder' })}
                  displayInitialValueAsLocalNumber={true}
                  defaultCountry={intl.locale.toUpperCase()}
                  onChange={handlePhoneNumberChange} />
                {phoneNumberValidable && routeParams.clientId && (
                  <Input type="checkbox" name="isPhoneValidated" label={t({ id: 'infos.phoneNumber.validate' })}
                    disabled={dataState.initial.phoneNumber === dataState.current.phoneNumber && dataState.initial.isPhoneValidated} />
                )}
                {phoneNumberValidable && !routeParams.clientId && (
                  <Input type="checkbox" name="validPhoneNumber" label={t({ id: 'infos.phoneNumber.validate' })} />
                )}
                <AddressInput name="addressFull"
                  label={t({ id: 'infos.addressFull.label' })}
                  placeholder={t({ id: 'infos.addressFull.placeholder' })} />
                <Input name="addressComplement" type="text"
                  label={t({ id: 'infos.addressComplement.label' })}
                  placeholder={t({ id: 'infos.addressComplement.placeholder' })} />
              </Block.Content>}
            </Block>
          </Form.Content>

          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[postState.payload.code === 'preventUpdate'
              ? { label: t({ id: 'dirty.forceSave' }), onClick: () => post({ force: postState.payload.reason })}
              : { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && postState.status !== STATUS.LOADING && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              // TODO: reset button should cancel and back to ???
              { label: t({ id: 'dirty.cancel' }), type: 'button', onClick: () => dataDispatch({ type: FORM_ACTION.RESET, }), outlined: true, disabled: postState.status === STATUS.LOADING, },
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING, },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
