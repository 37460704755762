import { useState, useEffect, useMemo, useReducer, } from 'react'
import { Link as RRDLink, Redirect, useLocation, useHistory, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { AddressInput, } from '@velogik/component-address-input'
import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { Loading, } from '@velogik/component-loading'
import { Page, } from '@velogik/component-page'
import { SelectInput, SelectFilter, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'

import { ButtonModal, } from 'components/ButtonModal'
import { EditClientModal } from 'components/EditClientModal'
import { Itvcode, } from 'components/Itvcode'
import { BackButton, } from 'components/BackButton'

import { SelectEquipment, } from 'pages/SelectEquipment'
import { SelectClient, } from 'pages/SelectClient'
import { CreateItvcode, } from 'pages/InterventionDetails.CreateItvcode.Modal'

import style from './InterventionNew.module.scss'

const DEFAULT_VALUE = {
  itvcodes: [],
  description: '',
  additional: false,
  commentsForClient: '',
  commentsForUser: '',
  addressComplement: '',
  isNonQualite: false,
}

const DEFAULT_ITVCODE_VALUE = {
  quantity: 1,
  comments: '',
  dontCharge: false,
  isSav: false,
}

function openReducer (state, action) {
  switch(action.type) {
    case 'onInputClick':
      return !state
    case 'onItemSelected':
      return action.autoclose ? false : state
    default:
      return state
  }
}

export function InterventionNew () {
  const history = useHistory()
  const location = useLocation()

  const api = useApi()
  const t = useT()
  const tCommon = useT('common', true)
  const tConfirmExpress = useT('confirmExpress')
  const tMandatory = useT('Mandatory', true)
  const tCreateItvcodeModal = useT('createItvcodeModal')
  const formatError = useFormatError()
  const { appState, } = useApp()

  const [dataState, dataDispatch] = useFormReducer() // TODO: set dataState as LOADED then update class and disabled on Form
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const [equipmentState, setEquipmentState] = useState({ status: STATUS.INITIALIZING })
  const [clientState, setClientState] = useState({ status: STATUS.INITIALIZING })
  const [shopsState, setShopsState] = useState({ status: STATUS.INITIALIZING })
  const [itvcodesState, setItvcodesState] = useState({ status: STATUS.INITIALIZING })
  const [itvcodesEstimate, setItvcodesEstimate] = useState({ status: STATUS.INITIALIZING })

  const [shopSelected, setShopSelected] = useState()
  const [currentService, setCurrentService] = useState()
  const [itvFilter, setItvFilter] = useState('')
  const [itvOpenState, itvOpenDispatch] = useReducer(openReducer, false)
  const [clientEditing, setClientEditing] = useState(false)

  const dataItvcodes = dataState.current && dataState.current.itvcodes
  const isDataLoaded = [FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status)

  const interventionLocation = (
    (dataState.current && appState.constants.interventionLocation
      .find(_ => _.value === dataState.current.locationId )) || {}
  ).key || ''

  const query = useMemo(() => {
    const query = new URLSearchParams(location.search)
    return {
      equipmentId: query.get('equipmentId') || '',
      clientId: query.get('clientId') || '',
      // userId: query.get('userId') || '', // TODO: handle userId from PlanningUser
    }
  }, [location.search])

  const itvcodeList = useMemo(() => {
    if (isDataLoaded && itvcodesState.status === STATUS.LOADED) {
      return itvcodesState.payload.itvcodes
        .map(itvcode => {
          const added = dataItvcodes.find(_ => _.itvcodeId === itvcode.itvcodeId)
          return {
            value: itvcode.itvcodeId,
            label: [itvcode.itvcodeIdentifier, itvcode.chronocaisseRef, itvcode.itvcodeLabel].filter(_ => _).join(' - '),
            clickable: !added,
            className: added && style.itvcodeAdded,
          }
        })
        .filter(itvcode => itvcode.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(itvFilter.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
    }
  }, [isDataLoaded, itvcodesState, dataItvcodes, itvFilter])

  useEffect(() => {
    if (!query.equipmentId || !query.clientId) {
      return
    }

    const intervention = JSON.parse(sessionStorage.getItem('intervention')) || {}

    wait(Promise.all([
      api.get('/equipments/details', undefined, { clientId: query.clientId, equipmentId: query.equipmentId }),
      api.get('/clients/details', undefined, { clientId: query.clientId }),
      api.get('/shops/listlight', undefined, { withAddress: true }),
    ]))
      .then(([equipmentPayload, clientPayload, shopsPayload]) => {
        setEquipmentState({ status: STATUS.LOADED, payload: equipmentPayload.informations, })
        setClientState({ status: STATUS.LOADED, payload: clientPayload, })

        let shop = shopsPayload.find(shop => shop.shopId === intervention.shopId) || (shopsPayload.length === 1 && shopsPayload[0] ) || undefined
        if (shop) {
          setShopSelected(shop)
        }

        setShopsState({ status: STATUS.LOADED, payload: shopsPayload })
      })
      .then(() => dataDispatch({ type: FORM_ACTION.INIT, value: {
        ...DEFAULT_VALUE,
        equipmentId: query.equipmentId,
        clientId: query.clientId,
        ...intervention,
      } }))
      .then(() => setCurrentService(appState.constants.interventionServicesDetails.find(_ => _.value === Number(intervention.servicesId))))
      .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload }))
  }, [api, appState.constants, query.equipmentId, query.clientId, dataDispatch])

  useEffect(() => {
    setItvcodesState(itvcodesState => {
      if (itvcodesState.payload && itvcodesState.payload.equipmentType && itvcodesState.payload.equipmentType) {
        // TODO: it's not "type: 'text'"" but I need an update of Form package ("custom" type idk)
        dataDispatch({ target: { type: 'text', name: 'itvcodes', value: [] } })
      }
      return { status: STATUS.INITIALIZING }
    })
    if (shopSelected && equipmentState.status === STATUS.LOADED) {
      api.get('/shops/itvcodes', undefined, {
        equipmentId: query.equipmentId,
        equipmentType: equipmentState.payload.equipmentType,
        shopId: shopSelected.shopId
      })
        .then(itvcodesPayload => setItvcodesState({ status: STATUS.LOADED, payload: itvcodesPayload }))
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload }))
    }
  }, [api, query.equipmentId, shopSelected, equipmentState, dataDispatch])

  useEffect(() => {
    if (dataItvcodes) {
      setItvcodesEstimate({ status: STATUS.LOADING })
      api.post('/shops/itvcodesEstimate', { body: JSON.stringify({
        itvcodes: dataItvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          dontCharge: itvcode.dontCharge,
          priceWt: itvcode.priceWt,
          isSav: itvcode.isSav,
        }))
      }) })
        .then(payload => setItvcodesEstimate({
          status: STATUS.LOADED,
          payload: payload.billing
        }))
        .catch(payload => setItvcodesEstimate({
          status: STATUS.ERROR,
          payload
        }))
    }
  }, [api, dataItvcodes, dataDispatch])

  function handleShopSelected (shopId) {
    setShopSelected(shopId ? shopsState.payload.find(shop => String(shop.shopId) === String(shopId)) : undefined)
  }

  function post (payload, callback) {
    setPostState({ status: STATUS.LOADING })

    return wait(api.post('/interventions/details', { body: JSON.stringify({
      shopId: shopSelected && shopSelected.shopId,
      ...dataState.current,
      ...payload,
    }) }))
      .then(payload => {
        const locationSearch = new URLSearchParams(location.search)
        const params = new URLSearchParams()
        if (locationSearch.get('userId')) {
          params.set('userId', locationSearch.get('userId'))
        }
        sessionStorage.removeItem('intervention')
        return history.push({
          pathname: `/interventions/${payload.informations.interventionId}/details`,
          search: params.toString()
        })
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current, callback }))
  }

  function create (additionalParams = {}) {
    post(additionalParams, create)
  }

  function expressIntervention (additionalParams = {}) {
    post({ ...additionalParams, express: true, }, expressIntervention)
  }

  function estimate (additionalParams = {}) {
    setPostState({ status: STATUS.LOADING })

    wait(api.post('/estimates/details', { body: JSON.stringify({
      shopId: shopSelected && shopSelected.shopId,
      ...dataState.current,
      ...additionalParams,
    }) }))
      .then(payload => history.push(`/estimates/${payload.informations.estimateId}/details`))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function handleCancel () {
    sessionStorage.removeItem('intervention')
  }

  function handleServicesIdChange ({ target: { value } }) {
    const service = appState.constants.interventionServicesDetails.find(_ => _.value === Number(value))

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesId',
        value: value
      }
    })

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesDetailsId',
        value: (service && service.values.length === 1) ? [service.values[0]] : []
      }
    })
    setCurrentService(service)
  }

  function handleServicesDetailsChange ({ value }) {
    const servicesDetailsId = (dataState.current.servicesDetailsId && currentService.isMultiple)
      ? dataState.current.servicesDetailsId.indexOf(value) >= 0
        ? dataState.current.servicesDetailsId.filter(_ => _ !== value)
        : dataState.current.servicesDetailsId.concat(value)
      : [value]

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesDetailsId',
        value: servicesDetailsId
      }
    })
  }

  function createItvcodeCallback (itvcode) {
    setItvcodesState({ status: STATUS.INITIALIZING })

    dataDispatch({
      type: 'addItem',
      name: 'itvcodes',
      target: { type: 'list' },
      value: {
        ...DEFAULT_ITVCODE_VALUE,
        ...itvcode,
      },
    })

    api.get('/shops/itvcodes', undefined, {
      equipmentId: query.equipmentId,
      equipmentType: equipmentState.payload.equipmentType,
      shopId: shopSelected.shopId,
    })
      .then(itvcodesPayload => setItvcodesState({ status: STATUS.LOADED, payload: itvcodesPayload }))
      .catch(value => dataDispatch({ type: FORM_ACTION.ERROR, value }))
  }

  function handleItvcodeAdd (itvcodeId) {
    const itvcode = itvcodesState.payload.itvcodes.find(_ => String(_.itvcodeId) === String(itvcodeId)) || {}
    dataDispatch({ type: 'addItem', value: {
      ...DEFAULT_ITVCODE_VALUE,
      ...itvcode,
    }, name: 'itvcodes', target: { type: 'list' }, })
  }

  function handleItvcodeRemove (item) {
    dataDispatch({ type: 'removeItem', value: item, name: 'itvcodes', target: { type: 'list' } })
  }

  function handleItvcodeChange (item, { target: {name, value} },) {
    dataDispatch({ type: 'updateItem', value: item, name: 'itvcodes', target: { name, value }, })
  }

  function handleClientSave (newClientState) {
    setClientEditing(false)
    setClientState({ status: STATUS.LOADED, payload: newClientState, })
  }

  if (!query.equipmentId) {
    return <Redirect to={{
      ...location,
      pathname: '/interventions/new/select-equipment'
    }} />
  }

  if (!query.clientId) {
    return <Redirect to={{
      ...location,
      pathname: '/interventions/new/select-client'
    }} />
  }

  if (query.equipmentId === 'unknown') {
    return <Redirect to={{
      ...location,
      pathname: '/equipments/new'
    }} />
  }

  return (
    <Page className={style.root}>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' }, query)}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'partial' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={() => create()}
          loadingClassName={style.formLoading}
          state={dataState} dispatcher={dataDispatch}
          disabled={![FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status)}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content className={style.formContent}>
            <Block className={style.client}>
              <Block.Title>{t({ id: 'summary.client.title' })}</Block.Title>
              {clientState.status === STATUS.INITIALIZING && <Block.Loader />}
              {clientState.status === STATUS.LOADED && (
                <Block.Content striped>
                  <Block.Item className={style.item}
                    label={t({ id: 'summary.client.content.clientId.label' })}
                    value={clientState.payload.clientId} />
                  <Block.Item className={style.item}
                    label={t({ id: 'summary.client.content.firstName.label' })}
                    value={clientState.payload.firstName} />
                  <Block.Item className={style.item}
                    label={t({ id: 'summary.client.content.lastName.label' })}
                    value={clientState.payload.lastName} />
                  <Block.Item className={style.item}
                    label={t({ id: 'summary.client.content.email.label' })}
                    value={clientState.payload.email} />
                  <Block.Item className={style.item}
                    label={t({ id: 'summary.client.content.phoneNumber.label' })}
                    value={clientState.payload.phoneNumber} />
                  <Block.Item className={style.item}
                    label={t({ id: 'summary.client.content.addressFull.label' })}
                    value={clientState.payload.addressFull} />
                </Block.Content>
              )}
              <Block.Actions>
                <Button outlined onClick={() => setClientEditing(true)}>{t({ id: 'summary.client.actions.edit' })}</Button>
              </Block.Actions>
            </Block>

            <Block className={style.equipment}>
              <Block.Title>{t({ id: 'summary.equipment.title' })}</Block.Title>
              {equipmentState.status === STATUS.INITIALIZING && <Block.Loader />}
              {equipmentState.status === STATUS.LOADED && (
                <Block.Content striped>
                  <Block.Item
                    label={t({ id: 'summary.equipment.content.velocenterIdentifier.label' })}
                    value={equipmentState.payload.velocenterIdentifier} />
                  <Block.Item
                    label={t({ id: 'summary.equipment.content.equipmentType.label' })}
                    value={t({ id: 'summary.equipment.content.equipmentType.value' }, equipmentState.payload)} />
                  <Block.Item
                    label={t({ id: 'summary.equipment.content.customName.label' })}
                    value={t({ id: 'summary.equipment.content.customName.value' }, { customName: equipmentState.payload.customName || null })} />
                  <Block.Item
                    label={t({ id: 'summary.equipment.content.brand.label' })}
                    value={t({ id: 'summary.equipment.content.brand.value' }, { brand: equipmentState.payload.brandName || equipmentState.payload.brand || null })} />
                  <Block.Item
                    label={t({ id: 'summary.equipment.content.equipmentStatus.label' })}
                    value={t({ id: 'summary.equipment.content.equipmentStatus.value' }, { equipmentStatus: equipmentState.payload.statusValue } )}
                  />
                </Block.Content>
              )}
              {/* TODO: outside of MVP but should be back soon */}
              {/* <Block.Actions className={style.actions}>
                <Button outlined onClick={() => alert('TODO: DO IT !')}>{t({ id: 'summary.equipment.actions.edit' })}</Button>
              </Block.Actions> */}
            </Block>

            <Block className={style.newIntervention}>
              <Block.Title className={style.title}>{t({ id: 'form.title' })}</Block.Title>
              <Block.Content className={style.content}>
                {shopsState.status === STATUS.LOADED && shopsState.payload.length > 1 && (
                  <div className={style.inputs}>
                    <SelectInput
                      name="shopId"
                      label={t({ id: 'form.shopId.label' })}
                      placeholder={t({ id: 'form.shopId.placeholder' })}
                      value={shopSelected ? shopSelected.shopId : ''}
                      onChange={({ target: { value } }) => handleShopSelected(value)}
                      options={shopsState.payload.map(shop => ({
                        value: shop.shopId,
                        label: shop.shopName,
                      }))}
                    />
                  </div>
                )}
                <div className={style.inputs}>
                  <SelectInput
                    name="locationId"
                    label={t({ id: 'form.locationId.label' })}
                    placeholder={t({ id: 'form.locationId.placeholder' })}
                    disabled={!shopSelected}
                    options={appState.constants.interventionLocation.map(_ => ({
                      value: _.value,
                      label: t({ id: 'form.locationId.value' }, { value: _.key }),
                    }))} />

                  <SelectInput
                    name="servicesId"
                    label={t({ id: 'form.servicesId.label' })}
                    all={t({ id: 'form.servicesId.all' })}
                    options={appState.constants.interventionServicesDetails.map(_ => ({
                      value: _.value,
                      label: tCommon({ id: 'services' }, { value: _.key }),
                    }))}
                    value={currentService ? currentService.value : ''}
                    onChange={handleServicesIdChange} />

                  {currentService && currentService.values.map(servicesDetails =>
                    <Input key={`servicesDetails-${servicesDetails.key}`}
                      id={`servicesDetails-${servicesDetails.key}`}
                      name={`servicesDetails-${servicesDetails.key}`}
                      label={tCommon({ id: 'servicesDetails' }, { value: `${currentService.key}_${servicesDetails.key}` })}
                      type="checkbox"
                      value={dataState.current.servicesDetailsId && dataState.current.servicesDetailsId.indexOf(servicesDetails.value) >= 0}
                      onChange={() => handleServicesDetailsChange(servicesDetails)}
                    />
                  )}
                </div>
                <div className={style.inputs}>

                  <AddressInput name="address"
                    label={t({ id: 'form.address.label' })}
                    placeholder={t({ id: 'form.address.placeholder' })}
                    disabled={interventionLocation === 'shop'}
                    value={interventionLocation === 'shop'
                      ? shopSelected.addressFull : undefined} />
                  <Input name="addressComplement" type="text"
                    label={t({ id: 'form.addressComplement.label' })}
                    placeholder={t({ id: 'form.addressComplement.placeholder' })} />

                  <Input name="description" type="description" label={t({ id: 'form.description.label' })} placeholder={t({ id: 'form.description.placeholder' })} />

                  <div>
                    <Label>{t({ id: 'form.additional.label' })}</Label>
                    <Input type="radio"
                      name="additional"
                      id={'additional-true'}
                      value={true}
                      onChange={({ target: { type, name } }) => dataDispatch({ target: { type, name, value: true } })}
                      label={t({ id: 'form.additional.true' })} />
                    <Input type="radio"
                      name="additional"
                      id={'additional-false'}
                      value={false}
                      onChange={({ target: { type, name } }) => dataDispatch({ target: { type, name, value: false } })}
                      label={t({ id: 'form.additional.false' })} />
                  </div>
                
                  <Input name="isNonQualite" type="checkbox" label={t({ id: 'form.isNonQuality.label' })} />
                </div>

                <div className={style.newItvcode}>
                  <Label className={style.label}>{t({ id: 'form.itvcodes.add.label' })}</Label>
                  <SelectInput
                    name="itvcodes"
                    value={t({ id: 'form.itvcodes.add.placeholder' })}
                    autoclose={false}
                    onChange={({ target: { value } }) => handleItvcodeAdd(value)}
                    disabled={itvcodesState.status !== STATUS.LOADED}
                    options={itvcodeList}
                    openState={itvOpenState} openDispatch={itvOpenDispatch}
                    customOption={SelectFilter}
                    customOptionProps={{
                      placeholder: t({ id: 'form.itvcodes.filter.placeholder' }),
                      openState: itvOpenState,
                      value: itvFilter,
                      onChange: ({ target: { value } }) => setItvFilter(value)
                    }}
                  />

                  <ButtonModal
                    ModalComponent={CreateItvcode}
                    modalProps={{
                      t: tCreateItvcodeModal,
                      shopId: shopSelected && shopSelected.shopId,
                      equipmentType: equipmentState.status === STATUS.LOADED && equipmentState.payload.equipmentType,
                      canCreateWorkforce: itvcodesState.status === STATUS.LOADED && itvcodesState.payload.canCreateWorkforce,
                      canCreatePiece: itvcodesState.status === STATUS.LOADED && itvcodesState.payload.canCreatePiece,
                      closeCallback: createItvcodeCallback,
                    }}
                    buttonProps={{
                      outlined: true,
                      className: style.createItvcode,
                      disabled: !shopSelected || equipmentState.status !== STATUS.LOADED || itvcodesState.status !== STATUS.LOADED,
                    }}
                  >
                    {t({ id: 'form.itvcodes.new' })}
                  </ButtonModal>
                </div>

                {isDataLoaded && dataItvcodes.map(itvcode => (
                  <Itvcode key={itvcode.itvcodeId}
                    data={itvcode}
                    postState={postState.failedDataState === dataState.current && postState}
                    onRemove={() => handleItvcodeRemove(itvcode)}
                    onChange={e => handleItvcodeChange(itvcode, e)} />
                ))}
                {isDataLoaded && dataItvcodes.length === 0 && <Alert type="warning">{t({ id: 'noItvcodes' })}</Alert>}

                <div className={style.comments}>
                  <Input type="textarea" name="commentsForClient"
                    label={t({ id: 'form.commentsForClient.label' })}
                    placeholder={t({ id: 'form.commentsForClient.placeholder' })} />
                  <Input type="textarea" name="commentsForUser"
                    label={t({ id: 'form.commentsForUser.label' })}
                    placeholder={t({ id: 'form.commentsForUser.placeholder' })} />
                </div>
              </Block.Content>

              <Block.Content className={style.content}>
                {itvcodesEstimate.status === STATUS.ERROR && <Alert>{formatError(itvcodesEstimate.payload)}</Alert>}
                {[STATUS.INITIALIZING, STATUS.LOADING].includes(itvcodesEstimate.status) && <Loading />}
                {itvcodesEstimate.status === STATUS.LOADED && (
                  <div className={style.billing}>
                    <div className={style.details}>
                      <span>{t({ id: 'billing.total_Nt.label' })}</span>
                      <span>{t({ id: 'billing.total_Nt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Taxes.label' })}</span>
                      <span>{t({ id: 'billing.total_Taxes.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Wt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Mo_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Mo_Wt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Pc_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Pc_Wt.value' }, itvcodesEstimate.payload)}</span>
                    </div>
                    <div className={style.result}>
                      <span>{t({ id: 'billing.result.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.result.total_Wt.value' }, itvcodesEstimate.payload)}</span>
                    </div>
                  </div>
                )}
              </Block.Content>
              <Block.Actions className={style.actions}>
                <Button className={style.newIntervention} type="submit">{t({ id: 'form.actions.submit' })}</Button>
                <ButtonModal
                  ModalComponent={ConfirmExpress}
                  modalProps={{
                    t: tConfirmExpress,
                    handlePostCallback: expressIntervention,
                  }}
                  buttonProps={{
                    className: style.expressIntervention,
                    outlined: true,
                  }}
                >
                  {t({ id: 'form.actions.expressIntervention' })}
                </ButtonModal>
                {equipmentState.status === STATUS.LOADED && equipmentState.payload.canEstimate && (
                  <Button className={style.newEstimate} outlined onClick={() => estimate()}>{t({ id: 'form.actions.estimate' })}</Button>
                )}
                <Button className={style.cancel} tag={RRDLink} outlined color="danger" to="/interventions" onClick={handleCancel}>{t({ id: 'form.actions.cancel' })}</Button>
              </Block.Actions>
            </Block>
          </Form.Content>
        </Form>

        <EditClientModal
          isOpen={clientEditing}
          clientState={clientState}
          close={() => setClientEditing(false)}
          onSave={handleClientSave}
        />

        {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
          className={style.stickyBanner}
          type="danger"
          description={formatError(postState.payload)}
          actions={[postState.payload.code === 'preventUpdate'
            ? { label: t({ id: 'dirty.forceSave' }), onClick: () => postState.callback({ force: postState.payload.reason })}
            : { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
          ]}
        />}
      </Page.Content>
    </Page>
  )
}

function ConfirmExpress ({
  close,
  props: {
    t, handlePostCallback,
  },
}) {
  function handlePost () {
    close()
    handlePostCallback()
  }

  return (
    <Block className={style.root}>
      <Block.Title>{t({ id: 'title' })}</Block.Title>
      <Block.Content>{t({ id: 'content' })}</Block.Content>
      <Block.Actions>
        <Button onClick={close} outlined>{t({ id: 'actions.cancel' })}</Button>
        <Button onClick={handlePost} type="submit">{t({ id: 'actions.submit' })}</Button>
      </Block.Actions>
    </Block>
  )
}

InterventionNew.SelectEquipment = SelectEquipment
InterventionNew.SelectClient = SelectClient
