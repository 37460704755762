import { Calendar as RCalendar, } from 'react-calendar'

import { join, } from '@velogik/helper-classnames'

import style from './Calendar.module.scss'

export function Calendar ({ className, showYearNavigation = true, ...props }) {
  return (
    <RCalendar
      className={join(style.root, showYearNavigation && style.showYearNavigation, className)}
      prevLabel=""
      nextLabel=""
      {...props}
    />
  )
}
