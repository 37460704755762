import { useState, useCallback, useEffect, useMemo, } from 'react'
import { useParams, useHistory, useLocation, useRouteMatch, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, ERROR_TYPE, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { Page, } from '@velogik/component-page'
import { SelectInput, SelectFilter, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useDebounceValue, } from '@velogik/hook-debounce'

import { BackButton, } from '../components/BackButton'

import style from './EquipmentEdit.module.scss'

const DEFAULT_VALUE = {
  identificationCode: '',
  model: '',
  purchaseYear: '',
  frontSprocket: '',
  rearSprocket: '',
  wheelSize: '',
}

export function EquipmentEdit() {
  const history = useHistory()
  const location = useLocation()
  const newRouteMatch = useRouteMatch('*/new')
  const isNewRoute = newRouteMatch && newRouteMatch.isExact

  const api = useApi()
  const routeParams = useParams()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const formatError = useFormatError()
  const { appState, } = useApp()

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const [selectedType, setSelectedType] = useState()
  const [business, setBusiness] = useState({ status: STATUS.INITIALIZING })

  const [brandFilter, setBrandFilter] = useState('')
  const brandFilterDebounced = useDebounceValue(brandFilter, 250)
  const [brandList, setBrandList] = useState({ status: STATUS.INITIALIZING })

  const identificationSystemNone = useMemo(() =>
    appState.constants.identificationSystem.find(_ => _.key === 'none')
  , [appState.constants])

  const formattedConstants = useMemo(() => {
    return {
      equipmentTypes: appState.constants.equipmentTypes.map(_ => ({
        value: _.value,
        label: t({ id: 'infos.equipmentType.value' }, { value: _.key }),
      })),
      identificationSystem: appState.constants.identificationSystem.map(_ => ({
        value: _.value,
        label: t({ id: 'infos.identificationSystem.value' }, { value: _.key }),
      })),
    }
  }, [t, appState.constants])

  useEffect(() => {
    api.get('/equipments/brands')
      .then(payload => setBrandList({
        status: STATUS.LOADED,
        payload: payload.brands.map(brand => ({ label: brand.brandName, value: brand.brandId }))
          .concat({ label: 'otherBrand', value: -1 }),
      }))
      .catch(payload => setBrandList({ status: STATUS.ERROR, value: payload, }))

    const query = new URLSearchParams(location.search)
    const clientId = query.get('clientId')

    if (routeParams.equipmentId) {
      wait(api.get('/equipments/details', undefined, { equipmentId: routeParams.equipmentId }))
        .then(payload => dataDispatch({ type: FORM_ACTION.INIT, value: {
          ...payload.informations,
          brandId: !payload.informations.brandId && payload.informations.brand ? -1 : payload.informations.brandId
        }, }))
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
    } else if (isNewRoute && !clientId) {
      dataDispatch({ type: FORM_ACTION.ERROR, value: { code: 'noUserId', prefix: ERROR_TYPE.FRONT } })
    }
  }, [api, dataDispatch, routeParams.equipmentId, location.search, isNewRoute,])

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    switch (selectedType) {
      case 'standard': {
        dataDispatch({ type: FORM_ACTION.INIT, value: { ...DEFAULT_VALUE, clientId: query.get('clientId') || undefined } })
        break
      }
      case 'velocare': {
        dataDispatch({ type: FORM_ACTION.INIT, value: { clientId: query.get('clientId') || undefined } })
        setBusiness({ status: STATUS.INITIALIZING, })
        wait(api.get('/velocare/business'))
          .then(payload => setBusiness({ status: STATUS.LOADED, payload, }))
          .catch(payload => setBusiness({ status: STATUS.ERROR, value: payload, }))
        break
      }
      default: break
    }
  }, [api, location.search, dataDispatch, selectedType])

  const brandListFiltered = useMemo(() => {
    return brandList.status === STATUS.LOADED
      ? brandList.payload.filter(brand =>
        brand.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(brandFilterDebounced.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      : []
  }, [brandList, brandFilterDebounced])

  const brandIdCustomOptionProps = useMemo(() => ({
    placeholder: t({ id: 'infos.brandId.filter' }),
    value: brandFilter,
    onChange: ({ target: { value } }) => setBrandFilter(value)
  })
  , [brandFilter, t])

  const handleBrandIdValueRender = useCallback((value) => {
    const result = brandList.status === STATUS.LOADED ? brandList.payload.find(brand => brand.value === value) : { value: '', label: 'loading' }

    if (result) {
      result.label = t({ id: 'infos.brandId.option' }, { value: result.label })
    }

    return result
  }, [brandList.payload, brandList.status, t])

  const handleBrandIdOptionRender = useCallback((value) => {
    return brandList.status === STATUS.LOADED ? t({ id: 'infos.brandId.option' }, { value: value.label }) : ''
  }, [brandList.status, t, ])

  function post (additionalParams = {}) {
    setPostState({ status: STATUS.LOADING })

    const endpoint = selectedType === 'velocare' ? '/velocare/importEquipment' : '/equipments/details'

    wait(api.post(endpoint, { body: JSON.stringify({
      ...dataState.current,
      ...additionalParams,
      brandId: dataState.current.brandId === -1 ? undefined : dataState.current.brandId,
    }) }))
      .then(payload => {
        if (!routeParams.clientId) {
          history.replace(`/equipments/${payload.equipmentId}`)
        } else {
          dataDispatch({ type: FORM_ACTION.INIT, value: payload, })
          setPostState({ status: STATUS.LOADED, payload })
        }
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {isNewRoute && (
          <div className={style.buttonGroup}>
            <Button
              className={style.button}
              outlined={selectedType !== 'standard'}
              disabled={dataState.status === FORM_STATUS.ERROR}
              onClick={() => setSelectedType('standard')}
            >
              {t({ id: 'typeSelector.standard' })}
            </Button>
            <Button
              className={style.button}
              outlined={selectedType !== 'velocare'}
              disabled={dataState.status === FORM_STATUS.ERROR}
              onClick={() => setSelectedType('velocare')}
            >
              {t({ id: 'typeSelector.velocare' })}
            </Button>
          </div>
        )}
      </Page.Content>

      {(!isNewRoute || selectedType) &&
        <Page.Content>
          {tMandatory({ id: selectedType === 'standard' ? 'partial' : 'full' })}
        </Page.Content>
      }
      {(!isNewRoute || selectedType) && (
        <Page.Content>
          <Form onSubmit={() => post()}
            state={dataState} dispatcher={dataDispatch}
            disabled={postState.status === STATUS.LOADING}
            preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>

            {(!isNewRoute || selectedType === 'standard') && (
              <Form.Content>
                <Block>
                  <Block.Content>
                    <SelectInput
                      name="equipmentType"
                      label={t({ id: 'infos.equipmentType.label' })}
                      placeholder={t({ id: 'infos.equipmentType.placeholder' })}
                      options={formattedConstants.equipmentTypes} />
                    <SelectInput
                      name="identificationSystem"
                      label={t({ id: 'infos.identificationSystem.label' })}
                      placeholder={t({ id: 'infos.identificationSystem.placeholder' })}
                      options={formattedConstants.identificationSystem} />
                    <Input name="identificationCode" type="text"
                      label={t({ id: 'infos.identificationCode.label' })} placeholder={t({ id: 'infos.identificationCode.placeholder' })}
                      disabled={!dataState.current || !dataState.current.identificationSystem || String(dataState.current.identificationSystem) === String(identificationSystemNone.value)} />

                    <SelectInput
                      name="brandId"
                      label={t({ id: 'infos.brandId.label' })}
                      placeholder={t({ id: 'infos.brandId.placeholder' })}
                      disabled={brandList.status === STATUS.INITIALIZING}
                      options={brandListFiltered}
                      valueRender={handleBrandIdValueRender}
                      optionRender={handleBrandIdOptionRender}
                      customOption={SelectFilter}
                      customOptionProps={brandIdCustomOptionProps}
                    />

                    {dataState.current.brandId === -1 &&
                      <Input name="brand" type="text" placeholder={t({ id: 'infos.brand.placeholder' })} />
                    }
                    <Input name="customName" type="text" label={t({ id: 'infos.customName.label' })} placeholder={t({ id: 'infos.customName.placeholder' })} />
                  </Block.Content>
                </Block>

                <Block>
                  <Block.Content>
                    <Input name="model" type="text" label={t({ id: 'infos.model.label' })} placeholder={t({ id: 'infos.model.placeholder' })} />
                    <Input name="purchaseYear" type="text" label={t({ id: 'infos.purchaseYear.label' })} placeholder={t({ id: 'infos.purchaseYear.placeholder' })} />
                    <Input name="frontSprocket" type="text" label={t({ id: 'infos.frontSprocket.label' })} placeholder={t({ id: 'infos.frontSprocket.placeholder' })} />
                    <Input name="rearSprocket" type="text" label={t({ id: 'infos.rearSprocket.label' })} placeholder={t({ id: 'infos.rearSprocket.placeholder' })} />
                    <Input name="wheelSize" type="text" label={t({ id: 'infos.wheelSize.label' })} placeholder={t({ id: 'infos.wheelSize.placeholder' })} />
                  </Block.Content>
                </Block>
              </Form.Content>
            )}

            {isNewRoute && selectedType === 'velocare' && (
              <Form.Content>
                <Block>
                  <Block.Title>{t({ id: 'velocare.business.title' })}</Block.Title>
                  {business.status === STATUS.INITIALIZING && <Block.Loader />}
                  {business.status === STATUS.ERROR && <Block.Content>
                    <Alert>{formatError(business.payload)}</Alert>
                  </Block.Content>}
                  {business.status === STATUS.LOADED && <Block.Content>
                    <div>
                      <Label>{t({ id: 'velocare.business.label' })}</Label>
                      {business.payload.map(b => <Input key={`business-${b.id}`}
                        type="radio"
                        name="businessId"
                        id={`business-${b.id}`}
                        value={b.id}
                        label={b.name} />)}
                    </div>
                    <Input
                      name="id"
                      label={t({ id: 'velocare.equipmentIdentifier.label' })}
                      placeholder={t({ id: 'velocare.equipmentIdentifier.placeholder' })}
                    />
                  </Block.Content>}
                </Block>
              </Form.Content>
            )}

            {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
              type="danger"
              description={formatError(postState.payload)}
              actions={[postState.payload.code === 'preventUpdate'
                ? { label: t({ id: 'dirty.forceSave' }), onClick: () => post({ force: postState.payload.reason })}
                : { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
              ]}
            />}
            {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && postState.status !== STATUS.LOADING && <Banner sticky
              description={t({ id: 'dirty.disclaimer' })}
              actions={[
              // TODO: reset button should cancel and back to ???
                { label: t({ id: 'dirty.cancel' }), type: 'button', onClick: () => dataDispatch({ type: FORM_ACTION.RESET, }), outlined: true, disabled: postState.status === STATUS.LOADING, },
                { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING, },
              ]} />}
          </Form>
        </Page.Content>
      )}
    </Page>
  )
}
