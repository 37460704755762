import { useState, useMemo, useCallback, useEffect, useReducer, } from 'react'
import { useApi, } from 'react-rest-api'
import { Link as RRDLink, useParams, useHistory, } from 'react-router-dom'

import { AddressInput, } from '@velogik/component-address-input'
import { Alert, } from '@velogik/component-alert'
import { useApp, } from '@velogik/component-app'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { useFormReducer, Form, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { Label, } from '@velogik/component-label'
import { Url, } from '@velogik/component-link'
import { Loading, } from '@velogik/component-loading'
import { Modal, } from '@velogik/component-modal'
import { Page, } from '@velogik/component-page'
import { useT, useIntl, useFormatError, } from '@velogik/component-intl'
import { SelectInput, SelectFilter, } from '@velogik/component-select-input'
import { join, } from '@velogik/helper-classnames'
import { wait, STATUS, } from '@velogik/helper-promise'

import { formatPhoneNumber } from 'react-phone-number-input'

import { BackButton, } from 'components/BackButton'
import { Breadcrumb, } from 'components/Breadcrumb'
import { Itvcode, } from 'components/Itvcode'
import { useHasProfile, } from 'hooks/use-user'

import style from './EstimateDetails.module.scss'

const DEFAULT_ITVCODE_VALUE = {
  quantity: 1,
  comments: '',
  dontCharge: false,
  isSav: false,
}
// TODO: Avoid this ugly override in favor of context in SelectInput
function openReducer (state, action) {
  switch(action.type) {
    case 'onInputClick':
      return !state
    case 'onItemSelected':
      return action.autoclose ? false : state
    default:
      return state
  }
}

export function EstimateDetails () {
  const api = useApi()
  const t = useT()
  const tCommon = useT('common', true)
  const tMandatory = useT('Mandatory', true)
  const { formatMessage } = useIntl()
  const formatError = useFormatError()
  const history = useHistory()
  const { appState, } = useApp()
  const isReader = useHasProfile('reader')

  const routeParams = useParams()

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })
  const [clientState, setClientState] = useState({ status: STATUS.INITIALIZING })
  const [shopState, setShopState] = useState({ status: STATUS.INITIALIZING })
  const [historyState, setHistoryState] = useState({ status: STATUS.INITIALIZING })

  const [confirmCancel, setConfirmCancel] = useState(false)

  const [currentService, setCurrentService] = useState()
  const [itvFilter, setItvFilter] = useState('')
  const [itvcodesState, setItvcodesState] = useState({ status: STATUS.INITIALIZING })
  const [itvcodesEstimate, setItvcodesEstimate] = useState({ status: STATUS.INITIALIZING })

  const isDataLoaded = [FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status)
  const preventTransition = dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING
  const estimateStatus = (dataState && dataState.current) ? dataState.current.statusValue : undefined
  const dataItvcodes = dataState.current && dataState.current.itvcodes
  const [itvOpenState, itvOpenDispatch] = useReducer(openReducer, false)

  const itvcodeList = useMemo(() =>
    isDataLoaded && itvcodesState.status === STATUS.LOADED
      ? itvcodesState.payload.itvcodes
        .map(itvcode => {
          const added = dataItvcodes.find(_ => _.itvcodeId === itvcode.itvcodeId)
          return {
            value: itvcode.itvcodeId,
            label: [itvcode.itvcodeIdentifier, itvcode.chronocaisseRef, itvcode.itvcodeLabel].filter(_ => _).join(' - '),
            clickable: !added,
            className: added && style.itvcodeAdded,
          }
        })
        .filter(itvcode => itvcode.label.toLowerCase().includes(itvFilter.toLowerCase()))
      : []
  , [isDataLoaded, itvcodesState, dataItvcodes, itvFilter])

  const formattedConstants = useMemo(() => {
    return {
      interventionServicesDetails: appState.constants.interventionServicesDetails.map(_ => ({
        value: _.key,
        label: t({ id: 'interventionServices' }, { key: _.key }),
        optgroup: true,
        values: _.values.map(__ => ({
          value: __.value,
          label: t({ id: 'interventionServicesDetails' }, { key: `${_.key}_${__.key}` })
        }))
      })),
      interventionLocation: appState.constants.interventionLocation.map(_ => ({
        value: _.value,
        label: t({ id: 'interventionLocation' }, { key: _.key }),
      })),
    }
  }, [t, appState.constants])

  // TODO: consider put it all on the same reducer
  const refreshStates = useCallback((payload) => {
    setClientState({ status: STATUS.LOADED, payload: payload.client })
    setShopState({ status: STATUS.LOADED, payload: payload.shop })
    dataDispatch({ type: FORM_ACTION.INIT, value: payload.informations })

    const serviceDetails = appState.constants.interventionServicesDetails.find(_ => _.value === payload.informations.servicesId)
    setCurrentService(serviceDetails)
  }, [dataDispatch, appState.constants])

  useEffect(() => {
    wait(api.get('/estimates/details', undefined, { estimateId: routeParams.estimateId }))
      .then(estimatePayload =>
        api.get('/shops/itvcodes', undefined, {
          equipmentType: estimatePayload.client.equipmentType,
          shopId: estimatePayload.informations.shopId
        })
          .then(itvcodesPayload => setItvcodesState({ status: STATUS.LOADED, payload: itvcodesPayload }))
          .then(() => refreshStates(estimatePayload))
      )
      .catch(value => dataDispatch({ type: FORM_ACTION.ERROR, value }))
  }, [api, routeParams.estimateId, dataDispatch, refreshStates,])

  useEffect(() => {
    if (dataItvcodes) {
      setItvcodesEstimate({ status: STATUS.LOADING })

      api.post('/shops/itvcodesEstimate', { body: JSON.stringify({
        itvcodes: dataItvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          dontCharge: itvcode.dontCharge,
          priceWt: itvcode.priceWt,
          isSav: itvcode.isSav,
        }))
      }) })
        .then(payload => setItvcodesEstimate({ status: STATUS.LOADED, payload: payload.billing }))
        .catch(payload => setItvcodesEstimate({ status: STATUS.ERROR, payload }))
    }
  }, [api, dataItvcodes, dataDispatch])

  useEffect(() => {
    if (estimateStatus) {
      wait(api.get('/estimates/history', undefined, { estimateId: routeParams.estimateId }))
        .then(historyPayload => setHistoryState({ status: STATUS.LOADED, payload: historyPayload, }))
        .catch(payload => setHistoryState({ status: STATUS.ERROR, payload }))
    }
  }, [api, routeParams.estimateId, estimateStatus])

  /**
   * Check if intervention is loaded then if current intervention is in one of status.
   * if `'any'` in present in status, only check if loaded
   * @param  {...String} status list of status to check
   * @returns true if loaded and matching whith one of status
   */
  function isStatus (...status) {
    return status &&
      (status.includes('any') || status.includes(dataState.current.statusValue))
  }

  function save () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/estimates/details', { body: JSON.stringify(dataState.current) }))
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function send () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/estimates/details', { body: JSON.stringify({
      ...dataState.current,
      nextStep: true,
    }) }))
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }
  function reSend () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/estimates/resend', { body: JSON.stringify(dataState.current) }))
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function duplicate () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/estimates/duplicate', { body: JSON.stringify(dataState.current) }))
      .then(payload => history.push(`/estimates/${payload.informations.estimateId}/details`))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function createIntervention () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/estimates/details', { body: JSON.stringify({
      ...dataState.current,
      nextStep: true,
    }) }))
      .then(payload => history.push(`/interventions/${payload.informations.interventionId}/details`))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function cancel () {
    setConfirmCancel()
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/estimates/details', { body: JSON.stringify({
      estimateId: routeParams.estimateId,
      cancel: true,
    }) }))
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function handleServicesIdChange ({ target: { value } }) {
    const service = appState.constants.interventionServicesDetails.find(_ => _.value === Number(value))

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesId',
        value: value
      }
    })

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesDetailsId',
        value: (service && service.values.length === 1) ? [service.values[0]] : []
      }
    })
    setCurrentService(service)
  }

  function handleServicesDetailsChange ({ value }) {
    const servicesDetailsId = (dataState.current.servicesDetailsId && currentService.isMultiple)
      ? dataState.current.servicesDetailsId.indexOf(value) >= 0
        ? dataState.current.servicesDetailsId.filter(_ => _ !== value)
        : dataState.current.servicesDetailsId.concat(value)
      : [value]

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesDetailsId',
        value: servicesDetailsId
      }
    })
  }

  function handleItvcodeAdd (itvcodeId) {
    const itvcode = itvcodesState.payload.itvcodes.find(_ => String(_.itvcodeId) === String(itvcodeId)) || {}
    dataDispatch({ type: 'addItem', value: {
      ...DEFAULT_ITVCODE_VALUE,
      ...itvcode
    }, name: 'itvcodes', target: { type: 'list' }, })
  }

  function handleItvcodeRemove (item) {
    dataDispatch({ type: 'removeItem', value: item, name: 'itvcodes', target: { type: 'list' }, })
  }

  function handleItvcodeChange (item, { target: {name, value} },) {
    dataDispatch({ type: 'updateItem', value: item, name: 'itvcodes', target: { name, value }, })
  }

  const interventionLocation = (
    (dataState.current && appState.constants.interventionLocation
      .find(_ => _.value === dataState.current.locationId )) || {}
  ).key || ''

  return (
    <Page>
      <BackButton />
      <Page.Header className={style.header}>
        <Page.Header.Title className={style.heading}>{t({ id: 'pageTitle' }, { id: routeParams.estimateId })}</Page.Header.Title>
        <Page.Header.Content>
          <Breadcrumb className={style.breadcrumb}>
            {isStatus('any') && <Breadcrumb.Item
              active={isStatus('new', 'sent', 'done', 'canceled')}
              color="blue"
              focus={isStatus('new')}>
              <Breadcrumb.Icon name="pencil" />
              <Breadcrumb.Label>{t({ id: 'breadcrumb.new.label' })}</Breadcrumb.Label>
            </Breadcrumb.Item>}
            {isStatus('canceled') && (
              <Breadcrumb.Item
                active
                color="red"
                focus={isStatus('canceled')}>
                <Breadcrumb.Icon name="forbidden" />
                <Breadcrumb.Label>{t({ id: 'breadcrumb.canceled.label' })}</Breadcrumb.Label>
              </Breadcrumb.Item>
            )}
            {!isStatus('canceled') && (
              <Breadcrumb.Item
                active={isStatus('sent', 'done')}
                color="blue"
                focus={isStatus('sent')}>
                <Breadcrumb.Icon name="folder" />
                <Breadcrumb.Label>{t({ id: 'breadcrumb.sent.label' })}</Breadcrumb.Label>
              </Breadcrumb.Item>
            )}
            {!isStatus('canceled') && (
              <Breadcrumb.Item
                active={isStatus('done')}
                color="green"
                focus={isStatus('done')}>
                <Breadcrumb.Icon name="done" />
                <Breadcrumb.Label>{t({ id: 'breadcrumb.done.label' })}</Breadcrumb.Label>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </Page.Header.Content>
        {isStatus('done') && (
          <Page.Header.Actions>
            <Button tag={RRDLink} to={`/interventions/${dataState.current.interventionId}/details`}>
              {t({ id: 'actions.goToItv' })}
            </Button>
          </Page.Header.Actions>
        )}
        {!isReader && isStatus('canceled') && (
          <Page.Header.Actions>
            <Button tag={RRDLink} to={{ pathname: '/interventions/new/', search: new URLSearchParams({
              clientId: dataState.current.clientId,
              equipmentId: dataState.current.equipmentId
            }).toString()}}>
              {t({ id: 'actions.newItv' })}
            </Button>
          </Page.Header.Actions>
        )}
      </Page.Header>

      {isStatus('new') && (
        <Page.Content>
          {tMandatory({ id: 'partial' })}
        </Page.Content>
      )}
      <Page.Content>
        <Form state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING || !isStatus('new')}
          preventTransition={preventTransition}>
          <Form.Content className={style.formContent}>
            <Block className={style.summaryClient}>
              <Block.Title>{t({ id: 'summary.client.title' })}</Block.Title>
              {clientState.status === STATUS.INITIALIZING && <Block.Loader />}
              {clientState.status === STATUS.LOADED && (
                <Block.Content striped>
                  <Block.Item
                    label={t({ id: 'summary.client.velocenterIdentifier' })}
                    value={clientState.payload.velocenterIdentifier} />
                  <Block.Item
                    label={t({ id: 'summary.client.customName' })}
                    value={clientState.payload.customName} />
                  <Block.Item
                    label={t({ id: 'summary.client.userName' })}
                    value={clientState.payload.userName} />
                  <Block.Item
                    label={t({ id: 'summary.client.phoneNumber.label' })}
                    value={<Url href={`tel:${clientState.payload.phoneNumber}`}>
                      {t(
                        { id: 'summary.client.phoneNumber.value' },
                        { phoneNumber: formatPhoneNumber(clientState.payload.phoneNumber) },
                      )}
                    </Url>} />
                  <Block.Item
                    label={t({ id: 'summary.client.email' })}
                    value={<Url href={`mailto:${clientState.payload.email}`}>{clientState.payload.email}</Url>} />
                </Block.Content>
              )}
            </Block>

            <Block className={style.coreContent}>
              {estimateStatus && <Block.Title>{t({ id: `coreContent.${estimateStatus}.title` })}</Block.Title>}
              <Block.Content className={style.content}>
                <div className={style.inputs}>
                  <SelectInput
                    name="locationId"
                    label={t({ id: 'coreContent.locationId.label' })}
                    placeholder={t({ id: 'coreContent.locationId.placeholder' })}
                    options={formattedConstants.interventionLocation} />

                  <SelectInput
                    name="servicesId"
                    label={t({ id: 'coreContent.servicesDetailsId.label' })}
                    placeholder={t({ id: 'coreContent.servicesDetailsId.placeholder' })}
                    all={t({ id: 'interventionServices' }, { key: 'all' })}
                    options={appState.constants.interventionServicesDetails.map(_ => ({
                      value: _.value,
                      label: tCommon({ id: 'services' }, { value: _.key }),
                    }))}
                    value={currentService ? currentService.value : ''}
                    onChange={handleServicesIdChange} />
                </div>

                <div className={style.inputs}>
                  {currentService && currentService.values.map(servicesDetails =>
                    <Input key={`servicesDetails-${servicesDetails.key}`}
                      id={`servicesDetails-${servicesDetails.key}`}
                      name={`servicesDetails-${servicesDetails.key}`}
                      label={tCommon({ id: 'servicesDetails' }, { value: `${currentService.key}_${servicesDetails.key}`} )}
                      type="checkbox"
                      value={dataState.current.servicesDetailsId && dataState.current.servicesDetailsId.indexOf(servicesDetails.value) >= 0}
                      onChange={() => handleServicesDetailsChange(servicesDetails)}
                    />
                  )}
                </div>
                  
                <div className={style.inputs}>
                  <AddressInput name="address"
                    label={t({ id: 'coreContent.address.label' })}
                    placeholder={t({ id: 'coreContent.address.placeholder' })}
                    disabled={interventionLocation === 'shop'}
                    value={interventionLocation === 'shop'
                      ? shopState.payload.addressFull : undefined} />
                  <Input name="addressComplement" type="text"
                    label={t({ id: 'coreContent.addressComplement.label' })}
                    placeholder={t({ id: 'coreContent.addressComplement.placeholder' })} />

                  <Input name="description"
                    type="description"
                    label={t({ id: 'coreContent.description.label' })}
                    placeholder={t({ id: 'coreContent.description.placeholder' })} />

                  <div>
                    <Label>{t({ id: 'coreContent.additional.label' })}</Label>
                    <Input type="radio"
                      name="additional"
                      id={'additional-true'}
                      value={true}
                      onChange={({ target: { type, name } }) => dataDispatch({ target: { type, name, value: true } })}
                      label={t({ id: 'coreContent.additional.true' })} />
                    <Input type="radio"
                      name="additional"
                      id={'additional-false'}
                      value={false}
                      onChange={({ target: { type, name } }) => dataDispatch({ target: { type, name, value: false } })}
                      label={t({ id: 'coreContent.additional.false' })} />
                  </div>
                </div>

                {isStatus('new') && (
                  <SelectInput
                    name="itvcodes"
                    label={t({ id: 'coreContent.itvcodes.add.label' })}
                    value={t({ id: 'coreContent.itvcodes.add.placeholder' })}
                    autoclose={false}
                    onChange={({ target: { value } }) => handleItvcodeAdd(value)}
                    options={itvcodeList}
                    openState={itvOpenState} openDispatch={itvOpenDispatch}
                    customOption={SelectFilter}
                    customOptionProps={{
                      placeholder: t({ id: 'coreContent.itvcodes.filter.placeholder' }),
                      openState: itvOpenState,
                      value: itvFilter,
                      onChange: ({ target: { value } }) => setItvFilter(value)
                    }}
                  />
                )}

                {isStatus('new', 'sent', 'done', 'canceled') && dataItvcodes.map(itvcode => (
                  <Itvcode key={itvcode.itvcodeId}
                    data={itvcode}
                    postState={postState.failedDataState === dataState.current && postState}
                    onRemove={() => handleItvcodeRemove(itvcode)}
                    onChange={e => handleItvcodeChange(itvcode, e)} />
                ))}
                {isStatus('new', 'sent', 'done', 'canceled') && dataItvcodes.length === 0 && <Alert type="warning">{t({ id: 'noItvcodes' })}</Alert>}

                <div className={style.comments}>
                  <Input type="textarea" name="commentsForClient"
                    label={t({ id: 'coreContent.commentsForClient.label' })}
                    placeholder={t({ id: 'coreContent.commentsForClient.placeholder' })} />
                  <Input type="textarea" name="commentsForUser"
                    label={t({ id: 'coreContent.commentsForUser.label' })}
                    placeholder={t({ id: 'coreContent.commentsForUser.placeholder' })} />
                </div>
              </Block.Content>

              <Block.Content className={style.content}>
                {!isReader && itvcodesEstimate.status === STATUS.ERROR && <Alert>{formatError(itvcodesEstimate.payload)}</Alert>}
                {[STATUS.INITIALIZING, STATUS.LOADING].includes(itvcodesEstimate.status) && <Loading />}
                {itvcodesEstimate.status === STATUS.LOADED && (
                  <div className={style.billing}>
                    <div className={style.details}>
                      <span>{t({ id: 'billing.total_Nt.label' })}</span>
                      <span>{t({ id: 'billing.total_Nt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Taxes.label' })}</span>
                      <span>{t({ id: 'billing.total_Taxes.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Wt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Mo_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Mo_Wt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Pc_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Pc_Wt.value' }, itvcodesEstimate.payload)}</span>
                    </div>
                    <div className={style.result}>
                      <span>{t({ id: 'billing.result.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.result.total_Wt.value' }, itvcodesEstimate.payload)}</span>
                    </div>
                  </div>
                )}
              </Block.Content>
              <Block.Actions className={style.blockActions}>
                {!isReader && isStatus('sent') && (
                  <Button className={join(style.createIntervention)} forceDisabled={postState.status === STATUS.LOADING} onClick={createIntervention}>
                    {t({ id: 'actions.createIntervention' })}
                  </Button>
                )}
                {!isReader && isStatus('new', 'sent') && (
                  <Button className={style.start} forceDisabled={postState.status === STATUS.LOADING} color="danger" outlined onClick={() => setConfirmCancel(true)}>
                    {t({ id: 'actions.confirmCancel' })}
                  </Button>
                )}
                {isStatus('sent', 'done') && (
                  <Button className={style.middle} forceDisabled={postState.status === STATUS.LOADING} outlined tag={Url} href={dataState.current.attachment.url} target="_blank" rel="noopener noreferrer">
                    {t({ id: 'actions.showPDF' })}
                  </Button>
                )}
                {isStatus('sent') && (
                  <Button className={style.end} forceDisabled={postState.status === STATUS.LOADING} onClick={reSend} outlined>
                    {t({ id: 'actions.resend' })}
                  </Button>
                )}
                {isStatus('sent') && (
                  <Button className={style.end} forceDisabled={postState.status === STATUS.LOADING} onClick={duplicate} outlined>
                    {t({ id: 'actions.duplicate' })}
                  </Button>
                )}
                {!isReader && isStatus('new') && (
                  <Button className={style.end} forceDisabled={postState.status === STATUS.LOADING} onClick={send}>
                    {t({ id: 'actions.send' })}
                  </Button>
                )}
                {isStatus('done') && (
                  <Button className={style.end} forceDisabled={postState.status === STATUS.LOADING} tag={RRDLink} to={`/interventions/${dataState.current.interventionId}/details`}>
                    {t({ id: 'actions.goToItv' })}
                  </Button>
                )}
              </Block.Actions>
            </Block>

            {isStatus('any') && (
              <Block className={style.history}>
                <Block.Title>{t({ id: 'history.title' })}</Block.Title>
                <Block.Content className={style.content}>
                  {historyState.status === STATUS.LOADED && historyState.payload.map(history => (
                    <div key={`${history.status}.${history.dateCreated}`} className={join(style.item, history.status.startsWith('status_') && style.status, style[history.status])}>
                      <span className={style.dateCreated}>{t({ id: 'history.dateCreated' }, { dateCreated: history.dateCreated ? new Date(history.dateCreated) : null })}</span>
                      <span className={style.userName}>{t({ id: 'history.userName' }, history)}</span>
                      <span className={style.status}>{formatMessage({ id: 'constants.estimateHistoryStatus' }, {
                        ...history,
                        toEstimate: chunk => <RRDLink to={`/estimates/${history.value}/details`}>{chunk}</RRDLink>,
                        toIntervention: chunk => <RRDLink to={`/interventions/${history.value}/details`}>{chunk}</RRDLink>,
                      })}</span>
                    </div>
                  ))}
                </Block.Content>
              </Block>
            )}
          </Form.Content>

          {dataState.status === FORM_STATUS.ERROR && (
            <Banner type="danger" sticky
              description={formatError(dataState.payload)}
              actions={[
                { label: t({ id: 'dirty.retry' }), outlined: true, onClick: () => history.go(0) }
              ]}
            />
          )}

          {(postState.failedDataState === dataState.current) && (postState.status === STATUS.ERROR) && (
            <Banner sticky
              type="danger"
              description={formatError(postState.payload)}
              actions={[{ label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }]}
            />
          )}

          {(postState.failedDataState !== dataState.current) && (dataState.initial !== dataState.current) && (postState.status !== STATUS.LOADING) && (
            <Banner sticky
              description={t({ id: 'dirty.disclaimer' })}
              actions={[
              // TODO: reset button should cancel and back to ???
                { label: t({ id: 'dirty.cancel' }), onClick: () => dataDispatch({ type: FORM_ACTION.RESET, }), outlined: true, disabled: postState.status === STATUS.LOADING, },
                { label: t({ id: 'dirty.save' }), onClick: () => save(), disabled: postState.status === STATUS.LOADING, },
              ]} />
          )}

          <Modal isOpen={confirmCancel} toggle={() => setConfirmCancel(_ => !_)}>
            <Block className={style.confirmCancel}>
              <Block.Title className={style.title}>{t({ id: 'confirmCancel.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <div>{t({ id: 'confirmCancel.disclaimer' })}</div>
              </Block.Content>
              <Block.Actions className={style.blockActions}>
                <Button
                  onClick={() => setConfirmCancel()}
                  outlined
                  forceDisabled={postState.status === STATUS.LOADING}
                >
                  {t({ id: 'confirmCancel.actions.cancel' })}
                </Button>
                <Button
                  onClick={cancel}
                  forceDisabled={postState.status === STATUS.LOADING}
                >
                  {t({ id: 'confirmCancel.actions.submit' })}
                </Button>
              </Block.Actions>
            </Block>
          </Modal>
        </Form>
      </Page.Content>
    </Page>
  )
}
