import { useState, useEffect, } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useNotify, } from '@velogik/component-notifications'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, ERROR_TYPE, } from '@velogik/component-intl'

export function NewPassword() {
  const api = useApi()
  const t = useT()
  const formatError = useFormatError()
  const { notify, } = useNotify()

  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)

  const [dataState, dataDispatch] = useFormReducer(FORM_STATUS.INITIALIZED, {
    username: searchParams.get('email')
  })
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  function submit() {
    if (!dataState.current.password) {
      return setPostState({ status: STATUS.ERROR, payload: { code: 'missingPassword', prefix: ERROR_TYPE.FRONT } })
    }

    if (dataState.current.password !== dataState.current.confirmPassword) {
      return setPostState({ status: STATUS.ERROR, payload: { code: 'notMatch', prefix: ERROR_TYPE.FRONT } })
    }

    setPostState({ status: STATUS.LOADING })
    wait(api.post('/auth/reset', {
      body: JSON.stringify({
        newPwd: dataState.current.password,
        resetCode: searchParams.get('code'),
      })
    }))
      .then(() => notify(t({ id: 'saved' })))
      .then(() => history.replace('/'))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  useEffect(() => setPostState(state => ({ status: STATUS.IDLE, payload: state.payload })), [dataState])

  return (
    <Page>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        <Form onSubmit={submit}
          state={dataState} dispatcher={dataDispatch}
          disabled={[STATUS.LOADING, STATUS.LOADED].includes(postState.status)}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Content>
                <Input type="text" name="username"
                  label={t({ id: 'username.label' })}
                  value={searchParams.get('email')} disabled />
                <Input type="password" name="password"
                  label={t({ id: 'password.label' })}
                  placeholder="***" />

                <Input type="password" name="confirmPassword"
                  label={t({ id: 'confirmPassword.label' })}
                  placeholder="***" />

                {postState.status === STATUS.ERROR && <Alert>{formatError(postState.payload)}</Alert>}
                {postState.status === STATUS.LOADED && <Alert type="success">{t({ id: 'success' })}</Alert>}
              </Block.Content>

              <Block.Actions>
                <Button type="submit">{t({ id: 'valid' })}</Button>
              </Block.Actions>
            </Block>
          </Form.Content>
        </Form>
      </Page.Content>
    </Page>
  )
}
