import { useReducer, useEffect, useMemo, } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'

import { useApp, } from '@velogik/component-app'
import { Button, } from '@velogik/component-button'
import { useIntl, } from '@velogik/component-intl'
import { join, } from '@velogik/helper-classnames'

import { useHasService, useHasProfile, } from 'hooks/use-user'

import style from './Header.module.scss'
import logo from './assets/logo-full-dark.svg'

function drawerReducer(state, action) { return action == null ? !state : action }

export function Header() {
  const { appState, signout } = useApp()
  const { formatMessage } = useIntl()

  const isNotBasic = useHasService('admin', 'network', 'premium', 'custom')
  const isUser = useHasProfile('user')
  const isReader = useHasProfile('reader')

  const history = useHistory()

  const [drawer, toggleDrawer] = useReducer(drawerReducer, false)

  const links = useMemo(() => ([
    { to: '/dashboard', label: formatMessage({ id: 'header.dashboard' }) },
    isNotBasic ? { to: '/clients', label: formatMessage({ id: 'header.clients' }) } : undefined,
    (!isUser && !isReader) ? { to: '/shops', label: formatMessage({ id: 'header.shops' }) } : undefined,
    { to: '/planning', label: formatMessage({ id: 'header.planning' }) },
    isNotBasic ? { to: '/equipments', label: formatMessage({ id: 'header.equipments' }) } : undefined,
    { to: '/interventions', label: formatMessage({ id: 'header.interventions' }) },
  ].filter(_ => _)), [formatMessage, isNotBasic, isUser, isReader])

  useEffect(() => { document.body.style.overflow = drawer ? 'hidden' : null }, [drawer])
  useEffect(() => () => { document.body.style.overflow = null }, [])

  useEffect(() => {
    toggleDrawer(false)
    const unlisten = history.listen(() => toggleDrawer(false))

    return () => unlisten()
  }, [history])

  return (
    <nav className={join(style.root, drawer && style.open)}>
      <div className={style.dropdown}>
        <div className={style.header}>
          <img src={logo} alt={formatMessage({ id: 'header.title' })} className={style.logo} />
          <button onClick={() => toggleDrawer(false)} type="button">{formatMessage({ id: 'header.burger' })}</button>
        </div>
        <div className={style.content}>
          {appState.user && links.map(link => (
            <NavLink key={link.to} className={isActive => join(style.bannerLink, isActive && style.active)} to={link.to} onClick={() => toggleDrawer(false)}>{link.label}</NavLink>
          ))}
          {appState.user && <Button tag={Link} className={style.bannerLink} to={'/account'}>{formatMessage({ id: 'header.account' })}</Button>}
          {appState.user && <Button onClick={signout} text>{formatMessage({ id: 'header.signout' })}</Button>}
        </div>
      </div>

      <div className={style.navbar}>
        <Link className={style.logo} to="/">
          <img src={logo} alt={formatMessage({ id: 'header.title' })} />
        </Link>

        {appState.user && links.map(link => (
          <NavLink key={link.to} className={isActive => join(style.bannerLink, isActive && style.active)} to={link.to}>{link.label}</NavLink>
        ))}
        <span className={style.spacer} />
        {appState.user && (
          <NavLink className={style.bannerLink} to="/" onClick={signout}>{formatMessage({ id: 'header.signout' })}</NavLink>
        )}
        {appState.user && (
          <Link className={style.bannerLink} to={'/account'}>{formatMessage({ id: 'header.account' })}</Link>
        )}
        {appState.user && (
          <button className={style.drawerToggle} onClick={() => toggleDrawer()} type="button">
            <span className={style.text}>{formatMessage({ id: 'header.burger' })}</span>
            <div className={style.burger} />
          </button>
        )}
      </div>
    </nav>
  )
}
