import { useState, useEffect, } from 'react'
import { useApi, } from 'react-rest-api'
import { BrowserRouter, Redirect, } from 'react-router-dom'
import { ConfirmTransition } from '@velogik/component-confirm-transition'

import { useApp, APP_STATUS, } from '@velogik/component-app'
import { ErrorRoute, } from '@velogik/component-error-route'
import { useT, } from '@velogik/component-intl'
import { useNotify, } from '@velogik/component-notifications'
import { Route, } from '@velogik/component-route'
import { SwitchProvider, } from '@velogik/component-switch'

import { useHasProfile, useHasService, } from 'hooks/use-user'

import { Analytics, } from 'Analytics'
import { RouteEvents, } from 'RouteEvents'

import { Header, } from 'Header'
import { Footer, } from 'Footer'

import NotFound from 'pages/Notfound'
import { Loading, } from 'pages/Loading'
import { SignIn, } from 'pages/SignIn'
import { ForgotPassword, } from 'pages/ForgotPassword'
import { NewPassword, } from 'pages/NewPassword'
import { Dashboard, } from 'pages/Dashboard'
import { Account, } from 'pages/Account'
import { User, } from 'pages/User'
import { SharedPlanning, } from 'pages/SharedPlanning'
import { Client, } from 'pages/Client'
import { Invoices, } from 'pages/Invoices'
import { Shop, } from 'pages/Shop'
import { Network, } from 'pages/Network'
import { Equipment, } from 'pages/Equipment'
import { Intervention, } from 'pages/Intervention'
import { Estimate, } from 'pages/Estimate'
import { Planning, } from 'pages/Planning'
import { Task, } from 'pages/Task'
import { Contact, } from 'pages/Contact'
import { Services, } from 'pages/Services'
import { FAQ, } from 'pages/FAQ'

const windowTitle = document.querySelector('title')
const metaDescription = document.querySelector('meta[name="description"]')

export function Main() {
  const api = useApi()
  const t = useT()
  let { appState, appDispatch, } = useApp()
  const [confirmCallback, setConfirmCallback] = useState()
  const { notify, } = useNotify()

  const isAdminVelocenter = useHasProfile('admin_velocenter')
  const canCreateShop = useHasProfile('admin_velocenter', 'admin_network')
  const isUser = useHasProfile('user')
  const isReader = useHasProfile('reader')
  const isBasic = useHasService('basic')

  useEffect(() => {
    if (api.config.headers.Authorization) {
      Promise.all([
        api.get('/users/me'),
        api.get('/root/constants')
      ])
        .then(([user, constants]) => appDispatch({ type: 'ready', payload: { user, constants }}))
        .catch(payload => appDispatch({ type: 'error', payload }))
    } else {
      appDispatch({ type: 'ready', payload: { user: undefined, constants: undefined } })
    }
  }, [api, appDispatch])

  function getConfirmation(message, callback) {
    setConfirmCallback({
      to: message,
      callback: (allowTransition) => {
        callback(allowTransition)
        setConfirmCallback()
      }
    })
  }

  function sendAlert (body) {
    return process.env.REACT_APP_DISABLE_SEND_REPORT
      ? Promise.resolve().then(() => console.log(body.err))
      : api.post('/velocenter/alert', {
        body: JSON.stringify(body)
      })
  }

  function formatError (location, error, errorInfo) {
    function formatState (type) {
      switch (type) {
      // TODO: filter props to display once defined
        case 'report':
        default: return JSON.stringify(appState, null, 2)
      }
    }

    return `
Page path: ${window.location.href}
User Agent: ${navigator.userAgent}
AppState:
\`\`\`${formatState('report')}\`\`\`
Location:
\`\`\`${JSON.stringify(location, null, 2)}\`\`\`
Trace:
> ${error.toString().split('\n').join('\n> ')}
\`\`\`${errorInfo.componentStack}\`\`\``
  }

  function guardian ({ match, }) {
    let newMatch

    if (isBasic) {
      switch(match.path) {
        case '/clients':
        case '/clients/new':
        case '/clients/:clientId':
        case '/clients/:clientId/edit':
        case '/invoices':
        case '/shops/:shopId/legal':
        case '/equipments':
        case '/equipments/new':
        case '/equipments/:equipmentId':
        case '/equipments/:equipmentId/edit':
        case '/interventions/new':
        case '/interventions/express':
        case '/interventions/new/select-equipment':
        case '/interventions/new/select-client':
        case '/estimates':
        case '/estimates/:estimateId/details':
          notify(t({ id: 'guard.premium' }))
          newMatch = { pathname: '/services' }
          break
        default:
      }
    }

    const path = (newMatch ? newMatch.pathname : match.path).split('/').filter(_ => _).join('_').replace(':', '')
    if (windowTitle) {
      windowTitle.text = t({ id: 'window.title' }, { path })
    }
    if (metaDescription) {
      metaDescription.setAttribute('content', t({ id: 'meta.description' }, { path }))
    }

    return newMatch
  }

  return (
    <BrowserRouter getUserConfirmation={getConfirmation}>
      <Analytics />
      <RouteEvents />
      {/* TODO: loading screen */}
      {appState.status === APP_STATUS.LOADING && <Loading />}
      {appState.status === APP_STATUS.LOADED && <Header />}
      {appState.status === APP_STATUS.LOADED && (
        <SwitchProvider
          alert={sendAlert}
          formatError={formatError}
          guardian={guardian}
          fallbackEmail={process.env.REACT_APP_FALLBACK_EMAIL}
        >
          {!appState.user && <Route exact path="/login"><SignIn /></Route>}
          {!appState.user && <Route path="/forgot-password"><ForgotPassword /></Route>}
          <Route path="/new-password"><NewPassword /></Route>
          <Route exact path="/contact"><Contact /></Route>
          {!appState.user && <Redirect path="*" to="/login" />}
          {appState.user && <Route exact path="/dashboard"><Dashboard /></Route>}
          {appState.user && <Redirect exact path={['/', '/login']} to="/dashboard" />}
          {appState.user && <Route exact path="/account"><Account /></Route>}
          {!isUser && !isReader && <Route exact path="/users"><User.List /></Route>}
          {!isUser && !isReader && <Route exact path="/users/new"><User.Edit /></Route>}
          {!isUser && !isReader && <Route exact path="/users/:userId"><User.Edit /></Route>}
          {appState.user && <Route exact path="/clients"><Client.List /></Route>}
          {appState.user && <Route exact path="/clients/new"><Client.Edit /></Route>}
          {appState.user && <Route exact path="/clients/:clientId"><Client.View /></Route>}
          {appState.user && !isReader && <Route exact path="/clients/:clientId/edit"><Client.Edit /></Route>}
          {appState.user && <Route exact path="/invoices"><Invoices /></Route>}
          {!isUser && !isReader && <Route exact path="/shops"><Shop.List /></Route>}
          {isAdminVelocenter && <Route exact path="/networks/new"><Network.New /></Route>}
          {canCreateShop && <Route exact path="/networks/admin"><Network.Admin /></Route>}
          {canCreateShop && <Route exact path="/networks/asvs"><Network.Asvs /></Route>}
          {canCreateShop && <Route exact path="/shops/new"><Shop.Edit /></Route>}
          {canCreateShop && <Route exact path="/shops/:shopId/details"><Shop.Edit /></Route>}
          {appState.user && !isUser && !isReader && <Route exact path="/shops/:shopId/legal"><Shop.Legal /></Route>}
          {!isUser && !isReader && <Route exact path="/shops/:shopId/marketplace"><Shop.Marketplace /></Route>}
          {!isUser && !isReader && <Route exact path="/shops/:shopId/pieces"><Shop.Pieces /></Route>}
          {canCreateShop && <Route exact path="/sharedPlannings"><SharedPlanning.List /></Route>}
          {canCreateShop && <Route exact path="/sharedPlannings/new"><SharedPlanning.Edit /></Route>}
          {canCreateShop && <Route exact path="/sharedPlannings/:sharedPlanningId"><SharedPlanning.Edit /></Route>}
          {appState.user && <Route exact path="/equipments"><Equipment.List /></Route>}
          {appState.user && !isReader && <Route exact path="/equipments/new"><Equipment.Edit /></Route>}
          {appState.user && <Route exact path="/equipments/:equipmentId"><Equipment.View /></Route>}
          {appState.user && !isReader && <Route exact path="/equipments/:equipmentId/edit"><Equipment.Edit /></Route>}
          {appState.user && <Route exact path="/interventions"><Intervention.List /></Route>}
          {!isReader && <Route exact path="/interventions/express"><Intervention.Express /></Route>}
          {appState.user && !isReader && <Route exact path="/interventions/new"><Intervention.New /></Route>}
          {appState.user && !isReader && <Route exact path="/interventions/new/select-equipment"><Intervention.New.SelectEquipment /></Route>}
          {appState.user && !isReader && <Route exact path="/interventions/new/select-client"><Intervention.New.SelectClient /></Route>}
          {appState.user && <Route exact path="/interventions/:interventionId/details"><Intervention.Details /></Route>}
          {appState.user && <Route exact path="/sharedInterventions/:interventionId/details"><Intervention.Details /></Route>}
          {appState.user && <Route exact path="/estimates"><Estimate.List /></Route>}
          {appState.user && <Route exact path="/estimates/:estimateId/details"><Estimate.Details /></Route>}
          {appState.user && <Route exact path="/planning"><Planning.All /></Route>}
          {appState.user && <Route exact path="/planning/:userId"><Planning.User /></Route>}
          {appState.user && !isReader && <Route exact path="/tasks/new"><Task.Edit /></Route>}
          {appState.user && !isReader && <Route exact path="/tasks/:taskId/details"><Task.Edit /></Route>}
          {appState.user && <Route exact path="/services"><Services /></Route>}
          {appState.user && <Route exact path="/faq"><FAQ /></Route>}
          {isAdminVelocenter && <Route exact path="/admin/networks"><Network.List /></Route>}
          <Route path="*"><NotFound /></Route>
        </SwitchProvider>
      )}
      {appState.status === APP_STATUS.LOADED && <Footer />}
      {appState.status === APP_STATUS.ERROR && (
        <ErrorRoute state={{ payload: appState.error }} />
      )}
      <ConfirmTransition confirmCallback={confirmCallback} />
    </BrowserRouter>
  )
}
