import { Link, } from 'react-router-dom'

import { Button, } from '@velogik/component-button'
import { useT, } from '@velogik/component-intl'

import style from './NotFound.module.scss'

import img from 'assets/404.svg'

export default function NotFound () {
  const t = useT('/not-found', true)

  return (
    <div className={style.pageContent}>
      <div className={style.img}>
        <img src={img} alt="" />
      </div>
      <div className={style.block}>
        <div className={style.title}>{t({ id: 'title' })}</div>
        <div className={style.content}>{t({ id: 'content' })}</div>
        <Button className={style.button} tag={Link} to='/'>{t({ id: 'button' })}</Button>
      </div>
    </div>
  )
}
