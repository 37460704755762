import { Block, } from '@velogik/component-block'
import { join } from '@velogik/helper-classnames'

import style from './Legend.module.scss'

function LegendBlock ({ title, children, className, titleClassName, contentClassName }) {
  return (
    <Block className={join(style.legend, className)}>
      {title && <Block.Title className={join(titleClassName)}>{title}</Block.Title>}
      <Block.Content className={join(style.contentClassName, contentClassName)}>
        {children}
      </Block.Content>
    </Block>
  )
}

function LegendItem ({ label, className }) {
  return (
    <fieldset className={join(className)}>
      <label>{label}</label>
    </fieldset>
  )
}

export const Legend = {
  Block: LegendBlock,
  Item: LegendItem,
}
