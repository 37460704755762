import { useEffect, useState, useMemo, } from 'react'
import { Link, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Button, } from '@velogik/component-button'
import { Filters, } from '@velogik/component-filters'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { join } from '@velogik/helper-classnames'
import { wait, STATUS } from '@velogik/helper-promise'
import { useT, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { Result, } from 'components/Result'

import style from './UserList.module.scss'

const filters = [
  { name: 'networkId', queryName: 'networkId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'shopId', queryName: 'shopId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'profileId', queryName: 'profileId', type: 'profile', },
  { name: 'filter', queryName: 'q', type: 'text' },
]

export function UserList() {
  const api = useApi()
  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const t = useT()

  const location = useLocation()

  const columns = useMemo(() => [
    { name: 'name', component: ({ item }) => (
      <div className={join(style.result_row, style.item)}>
        <span className={style.label}>{t({ id: 'result.item.name' }, item)}</span>
        <span className={join(style.isLocked, item.isLocked ? style.locked : style.active)}>
          {t({ id: 'result.item.isLocked' }, item)}
        </span>
      </div>
    ) },
    { name: 'email', },
    { name: 'shopName', component: ({ item }) => (
      <div className={join(style.result_row, style.item)}>
        {item.networkName && <span className={style.networkName}>{t({ id: 'result.item.networkName' }, item)}</span>}
        <span className={style.label}>{t({ id: 'result.item.shopName' }, item)}</span>
        <span className={style.profileName}>{t({ id: 'filters.profileId.value' }, item)}</span>
      </div>
    ) },
    { name: 'btn.view', component: ({ item }) => <Button tag={Link} to={`/users/${item.userId}`}>{t({ id: 'result.item.btn.view' })}</Button> },
  ], [t,])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/users/list', undefined, {
      p: query.get('p'),
      ipp: query.get('ipp'),
      ...filters.reduce((acc, item) => ({ ...acc, [item.name]: query.get(item.queryName) }), {}),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload: {
        ...payload,
        result: payload.result.map(user => ({
          ...user,
          profileName: payload.filters.profileId ? (payload.filters.profileId.values.find(profile => profile.value === user.profileId) || {}).label : null,
          networkName: payload.filters.networkId ? (payload.filters.networkId.values.find(network => network.value === user.networkId) || {}).label : null,
        }))
      }, }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload, }))
  }, [api, location])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        <Page.Header.Actions>
          <Button tag={Link} to={'/users/new'} outlined>{t({ id: 'new' })}</Button>
        </Page.Header.Actions>
      </Page.Header>

      <Page.Content>
        <Filters data={dataState} filters={filters}
          profileRender={ProfileRender}
        />
        <Result
          keyExtractor={item => item.userId}
          data={dataState} columns={columns} />
      </Page.Content>
    </Page>
  )
}

function ProfileRender ({ filter, applyFilter, payload }) {
  const t = useT()

  const tValue = payload.filters[filter.name].selected
    && payload.filters[filter.name].selected.value
    && t({ id: `filters.${filter.name}.value` }, { profileName: payload.filters[filter.name].selected.label })

  return (
    <SelectInput
      key={filter.name}
      name={filter.name}
      label={t({ id: `filters.${filter.name}.label`, })}
      value={tValue || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value, })}
      all={t({ id: `filters.${filter.name}.all` })}
      placeholder={t({ id: `filters.${filter.name}.placeholder` })}
      options={payload.filters[filter.name].values}
      optionRender={option => t({ id: `filters.${filter.name}.value` }, { profileName: option.label })}
    />
  )
}
