import { useEffect, useState, useRef, forwardRef } from 'react'
import { useApi } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { useT, useFormatError, } from '@velogik/component-intl'
import { Url, } from '@velogik/component-link'
import { join } from '@velogik/helper-classnames'
import { STATUS, } from '@velogik/helper-promise'

import style from './PlanningTooltipItem.module.scss'

export const PlanningTooltipItem = forwardRef(({item, ...props}, ref) => {
  switch (item.type) {
    case 'interventions': return <Intervention ref={ref} item={item} {...props} />
    case 'sharedInterventions': return <SharedIntervention ref={ref} item={item} {...props} />
    case 'tasks': return <Task ref={ref} item={item} {...props} />
    default: return null
  }
})

const Intervention = forwardRef(({ item, className, onDataLoaded, ...props }, ref) => {
  const api = useRef(useApi())
  const t = useT('PlanningItem.Intervention', true)
  const tCommon = useT('common', true)
  const formatError = useFormatError()
  const { appState, } = useApp()

  const [dataState, setDataState] = useState({ status: STATUS.LOADING })

  const id = item.interventionId

  useEffect(() => {
    let isSubscribed = true

    api.current.get('/interventions/details', null, { interventionId: id, })
      .then(payload => {
        const status = (appState.constants.interventionStatus.find(type => type.value === payload.informations.statusId) || {}).key
        const location = (appState.constants.interventionLocation.find(type => type.value === payload.informations.locationId) || {}).key
        const service = (appState.constants.interventionServices.find(type => type.value === payload.informations.servicesId) || {}).key
        const serviceCategory = service && (appState.constants.interventionServicesDetails.find(type => type.key === service) || {}).values
        const serviceDetails = serviceCategory && payload.informations.servicesDetailsId
          .map(servicesDetailsId => (serviceCategory.find(type => type.value === servicesDetailsId) || {}).key)
          .filter(_ => _)
        const dateBikeReceipt = payload.informations.isBikeReceivable && payload.informations.dateBikeReceipt
        const dateScheduledReturn = payload.informations.dateScheduledReturn ? new Date(payload.informations.dateScheduledReturn) : undefined

        isSubscribed && setDataState({ status: STATUS.LOADED, payload: {
          status,
          interventionId: payload.informations.interventionId,
          date: new Date(payload.informations.datePlanned),
          duration: payload.informations.duration,
          location,
          userName: payload.client.userName,
          phoneNumber: payload.client.phoneNumber,
          address: payload.informations.addressFull,
          equipmentIdentifier: payload.client.velocenterIdentifier,
          equipmentCustomName: payload.client.customName || null,
          service,
          serviceCategory,
          serviceDetails: serviceDetails || null,
          isBikeReceivable: payload.informations.isBikeReceivable ? 'true' : 'false',
          isBikeReceived: dateBikeReceipt ? 'true' : 'false',
          dateBikeReceipt: dateBikeReceipt && new Date(payload.informations.dateBikeReceipt),
          firstName: dateBikeReceipt && payload.informations.userBikeReceipt.firstName,
          lastName: dateBikeReceipt && payload.informations.userBikeReceipt.lastName,
          dateScheduledReturn: dateScheduledReturn,
        } })
      })
      .then(() => isSubscribed && onDataLoaded())
      .catch(payload => isSubscribed && setDataState({ status: STATUS.ERROR, payload }))

    return () => { isSubscribed = false }
  }, [api, id, onDataLoaded, appState.constants])

  return (
    <Block ref={ref} className={join(className, style.tooltip)} {...props}>
      {dataState.status === STATUS.LOADING && <Block.Loader className={style.loader} />}
      {dataState.status === STATUS.LOADED && (
        <Block.Content className={style.content}>
          <div>
            <div>{t({ id: 'interventionId.tooltipLabel' }, dataState.payload)} <b>{t({ id: 'interventionId.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'date.label' }, dataState.payload)} <b>{t({ id: 'date.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'duration.label' }, dataState.payload)} <b>{t({ id: 'duration.value' }, dataState.payload)}</b></div>
            {dataState.payload.userName && <div>{t({ id: 'userName.label' }, dataState.payload)} <b>{t({ id: 'userName.value' }, dataState.payload)}</b></div>}
            {dataState.payload.phoneNumber && <div>{t({ id: 'phoneNumber.label' }, dataState.payload)} <b>{t({ id: 'phoneNumber.value' }, {
              ...dataState.payload,
              tel: chunk => <Url href={`tel:${dataState.payload.phoneNumber}`}>{chunk}</Url>
            })}</b></div>}
            <div>{t({ id: 'location.label' }, dataState.payload)} <b>{t({ id: 'location.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'equipment.label' }, dataState.payload)} <b>
              {dataState.payload.equipmentCustomName || ''}
              {dataState.payload.equipmentCustomName && dataState.payload.equipmentIdentifier && ' - '}
              {dataState.payload.equipmentIdentifier}
            </b></div>
            <div>{t({ id: 'service.label' }, dataState.payload)} <b>{t({ id: 'service.value' }, {
              services: tCommon({ id: 'services' }, { value: dataState.payload.service }),
              servicesDetails: dataState.payload.serviceDetails ? dataState.payload.serviceDetails
                .map(_ => tCommon({ id: 'servicesDetails' }, { value: `${dataState.payload.service}_${_}`}))
                .join(' - ') : undefined
            })}</b></div>
            {dataState.payload.status !== 'express' && <>
              <div>{t({ id: 'equipmentReceivable.label' }, dataState.payload)} <b>{t({ id: 'equipmentReceivable.value' }, dataState.payload)}</b></div>
              {dataState.payload.dateScheduledReturn && (
                <div>{t({ id: 'dateSchedule.label' }, dataState.payload)} <b>{t({ id: 'dateSchedule.value' }, dataState.payload)}</b></div>
              )}
            </>}
          </div>
        </Block.Content>
      )}
      {dataState.status === STATUS.ERROR && (
        <Block.Content>
          <Alert>{formatError(dataState.payload)}</Alert>
        </Block.Content>
      )}
    </Block>
  )
})

const SharedIntervention = forwardRef(({ item, className, onDataLoaded, ...props }, ref) => {
  const api = useRef(useApi())
  const t = useT('PlanningItem.Intervention', true)
  const formatError = useFormatError()
  const { appState, } = useApp()

  const [dataState, setDataState] = useState({ status: STATUS.LOADING })

  const id = item.interventionId

  useEffect(() => {
    let isSubscribed = true

    api.current.get('/velocare/sharedintervention', null, { interventionId: id, })
      .then(payload => {
        const location = (appState.constants.interventionLocation.find(type => type.value === payload.informations.locationId) || {}).key
        const dateScheduledReturn = payload.informations.dateScheduledReturn ? new Date(payload.informations.dateScheduledReturn) : undefined

        isSubscribed && setDataState({ status: STATUS.LOADED, payload: {
          interventionId: payload.informations.interventionId,
          date: new Date(payload.informations.datePlanned),
          duration: payload.informations.duration,
          location,
          userName: payload.client.userName,
          phoneNumber: payload.client.phoneNumber,
          address: payload.informations.addressFull,
          equipmentCustomName: payload.client.customName || null,
          dateScheduledReturn: dateScheduledReturn,
        } })
      })
      .then(() => isSubscribed && onDataLoaded())
      .catch(payload => isSubscribed && setDataState({ status: STATUS.ERROR, payload }))

    return () => { isSubscribed = false }
  }, [api, id, onDataLoaded, appState.constants])

  return (
    <Block ref={ref} className={join(className, style.tooltip)} {...props}>
      {dataState.status === STATUS.LOADING && <Block.Loader className={style.content} />}
      {dataState.status === STATUS.LOADED && (
        <Block.Content className={style.content}>
          <div>
            <div>{t({ id: 'interventionId.tooltipLabel' }, dataState.payload)} <b>{t({ id: 'interventionId.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'date.label' }, dataState.payload)} <b>{t({ id: 'date.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'duration.label' }, dataState.payload)} <b>{t({ id: 'duration.value' }, dataState.payload)}</b></div>
            {dataState.payload.userName && <div>{t({ id: 'userName.label' }, dataState.payload)} <b>{t({ id: 'userName.value' }, dataState.payload)}</b></div>}
            {dataState.payload.phoneNumber && <div>{t({ id: 'phoneNumber.label' }, dataState.payload)} <b>{t({ id: 'phoneNumber.value' }, {
              ...dataState.payload,
              tel: chunk => <Url href={`tel:${dataState.payload.phoneNumber}`}>{chunk}</Url>
            })}</b></div>}
            <div>{t({ id: 'location.label' }, dataState.payload)} <b>{t({ id: 'location.value' }, dataState.payload)}</b></div>
            {dataState.payload.equipmentCustomName && <div>{t({ id: 'equipment.label' }, dataState.payload)} <b>{dataState.payload.equipmentCustomName || ''}</b></div>}
          </div>
        </Block.Content>
      )}
      {dataState.status === STATUS.ERROR && (
        <Block.Content>
          <Alert>{formatError(dataState.payload)}</Alert>
        </Block.Content>
      )}
    </Block>
  )
})

const Task = forwardRef(({ item, className, onDataLoaded, ...props }, ref) => {
  const api = useRef(useApi())
  const t = useT('PlanningItem.Task', true)
  const formatError = useFormatError()
  const { appState, } = useApp()

  const [dataState, setDataState] = useState({ status: STATUS.LOADING })

  const id = item.taskId

  useEffect(() => {
    let isSubscribed = true

    api.current.get('/tasks/details', undefined, { taskId: id })
      .then(payload => {
        isSubscribed && setDataState({ status: STATUS.LOADED, payload: {
          taskId: payload.taskId,
          firstName: payload.firstName,
          lastName: payload.lastName,
          date: new Date(payload.datePlanned),
          duration: payload.duration,
          description: payload.description,
        } })
      })
      .then(() => isSubscribed && onDataLoaded())
      .catch(payload => isSubscribed && setDataState({ status: STATUS.ERROR, payload }))

    return () => { isSubscribed = false }
  }, [api, id, onDataLoaded, appState.constants])

  return (
    <Block ref={ref} className={join(className, style.tooltip)} {...props}>
      {dataState.status === STATUS.LOADING && <Block.Loader className={style.content} />}
      {dataState.status === STATUS.LOADED && (
        <Block.Content className={style.content}>
          <div>
            <div>{t({ id: 'taskId.tooltipLabel' }, dataState.payload)} <b>{t({ id: 'taskId.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'date.label' }, dataState.payload)} <b>{t({ id: 'date.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'duration.label' }, dataState.payload)} <b>{t({ id: 'duration.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'description.label' }, dataState.payload)} <b>{t({ id: 'description.value' }, dataState.payload)}</b></div>
          </div>
        </Block.Content>
      )}
      {dataState.status === STATUS.ERROR && (
        <Block.Content>
          <Alert>{formatError(dataState.payload)}</Alert>
        </Block.Content>
      )}
    </Block>
  )
})
