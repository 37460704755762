import { useEffect, useState, useMemo, } from 'react'
import { Link as RRDLink, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Filters, } from '@velogik/component-filters'
import { Button, } from '@velogik/component-button'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { formatDate, } from '@velogik/helper-date'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useIntl, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { DayInput } from 'components/DayInput'
import { Result, } from 'components/Result'

import { useHasProfile, } from 'hooks/use-user'

import style from './EstimateList.module.scss'

const filters = [
  { name: 'shopId', queryName: 'shopId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'filter', type: 'text', },
  { name: 'dateSince', type: 'day', },
  { name: 'dateTo', type: 'day', },
  { name: 'statusId', queryName: 'statusId', type: 'status', },
]

export function EstimateList () {
  const api = useApi()
  const t = useT()
  const intl = useIntl()
  const location = useLocation()
  const isReader = useHasProfile('reader')

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const columns = useMemo(() => [
    { name: 'estimateId', headerClassName: style.estimateId },
    { name: 'date', useItem: item => ({ date: new Date(item.dateCreated) }) },
    { name: 'velocenterIdentifier' },
    { name: 'userName' },
    { name: 'status', component: ({ item }) => t({ id: 'statusValue' }, item )},
    { name: 'totalNt', component: ({ item }) => intl.formatNumber(item.total_Nt, { style: 'currency', currency: 'EUR' }) },
    { name: 'totalWt', component: ({ item }) => intl.formatNumber(item.total_Wt, { style: 'currency', currency: 'EUR' }), bodyClassName: style.totalWt },
    { name: 'view', component: ({ item, column }) => (
      <Button tag={RRDLink} to={`/estimates/${item.estimateId}/details`}>
        {t({ id: 'result.item.view' })}
      </Button>
    )},
  ], [t, intl])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/estimates/list', undefined, {
      p: query.get('p'),
      ipp: query.get('ipp'),
      ...filters.reduce((acc, item) => ({ ...acc, [item.name]: query.get(item.queryName || item.name) }), {}),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        {!isReader && (
          <Page.Header.Actions>
            <Button tag={RRDLink} to={'/interventions/new'}>
              {t({ id: 'actions.new' })}
            </Button>
          </Page.Header.Actions>
        )}
      </Page.Header>

      <Page.Content>
        <Filters data={dataState} filters={filters}
          statusRender={StatusRender}
          dayRender={DayFilter}
        />
        <Result
          keyExtractor={item => item.estimateId}
          data={dataState} columns={columns} />
      </Page.Content>
    </Page>
  )
}

function StatusRender ({ filter, applyFilter, payload }) {
  const t = useT()

  const tValue = payload.filters[filter.name].selected
    && payload.filters[filter.name].selected.value
    && t({ id: 'statusValue' }, { statusValue: payload.filters[filter.name].selected.key })

  return (
    <SelectInput
      key={filter.name}
      name={filter.name}
      label={t({ id: `filters.${filter.name}.label`, })}
      value={tValue || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value, })}
      all={t({ id: `filters.${filter.name}.all` })}
      placeholder={t({ id: `filters.${filter.name}.placeholder` })}
      options={payload.filters[filter.name].values}
      optionRender={option => t({ id: 'statusValue' }, { statusValue: option.label })}
    />
  )
}

function DayFilter ({ payload, filter, applyFilter }) {
  const t = useT()

  return (
    <DayInput name={filter.name} canClear={payload.filters[filter.name]}
      label={t({ id: `filters.${filter.name}.label` })}
      placeholder={t({ id: `filters.${filter.name}.placeholder` })}
      value={payload.filters[filter.name] || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value ? formatDate(value) : '' })}
    />
  )
}
