import { useState, useEffect, useRef, } from 'react'

import { Button, } from '@velogik/component-button'
import { useForm, } from '@velogik/component-form'
import { Label, } from '@velogik/component-label'
import { join, } from '@velogik/helper-classnames'
import { useIntl, } from '@velogik/component-intl'

import { Calendar, } from './Calendar'

import style from './DayInput.module.scss'

export function DayInput ({
  label, className, placeholder,
  canClear, autoclose = true,
  ...props
}) {
  const intl = useIntl()
  const form = useForm()

  const inputDate = useRef()
  const [value, setValue] = useState()
  const [inputDateText, setInputDateText] = useState('')

  const pattern = intl.formatMessage({ id: 'datePattern' })

  const [open, setOpen] = useState(false)

  const onChange = props.onChange || form.dispatcher
  const disabled = props.disabled || form.disabled

  useEffect(() => {
    // TODO: translation error when placeholder is undefined
    setInputDateText(value ? intl.formatMessage({ id: 'DayInput.value' }, { value }) : placeholder)
  }, [intl, value, placeholder])

  useEffect(() => {
    const stringValue = props.value !== undefined ? props.value : form.state.current ? form.state.current[props.name] : undefined
    setValue(stringValue ? new Date(stringValue) : '')
  }, [props.name, props.value, form])

  function handleOnChange (value) {
    if (autoclose) {
      setOpen(false)
    }

    onChange({ target: { type: 'day', name: props.name, value } })
  }

  function handleOnTypeChange (value) {
    const match = value.match(pattern)
    if (inputDate.current.validity.valid && match) {
      const groups = match.groups
      const valueDate = new Date(Number(groups.year), Number(groups.month) - 1, Number(groups.day))
      onChange({ target: { type: 'day', name: props.name, value: new Date(valueDate) } })
    } else {
      setInputDateText(value)
    }
  }

  return (
    <fieldset className={join(style.root, className)}>
      <input checked={open} onChange={() => setOpen(!open)} id={props.id || props.name} disabled={disabled} type="checkbox" className={style.checkbox} />
      <div className={join(style.selected, !value && style.placeholder)}>
        {canClear && (
          <Button
            className={style.clearIcon}
            onClick={() => handleOnChange('')} />
        )}
        <input
          type="text"
          ref={inputDate}
          readOnly={disabled}
          onClick={() => !disabled && setOpen(!open)}
          disabled={disabled}
          placeholder={placeholder}
          className={style.input}
          value={inputDateText}
          pattern={pattern}
          onChange={({ target: { value } }) => handleOnTypeChange(value)}
        />
      </div>
      {open && <Calendar className={style.calendar}
        value={value}
        showYearNavigation={false}
        onChange={value => handleOnChange(value)}
      />}
      {label && <Label className={style.label} htmlFor={props.id || props.name}>{label}</Label>}
    </fieldset>
  )
}
