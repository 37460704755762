import { useState, useEffect, Fragment, } from 'react'
import { Link, useParams, useHistory, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, ERROR_TYPE, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'

import { BackButton, } from 'components/BackButton'
import { useHasProfile, } from 'hooks/use-user'

import { Unavailabilities, } from './UserEdit.Unavailabilities'

const DEFAULT_VALUE = {}

export function UserEdit() {
  const history = useHistory()

  const api = useApi()
  const routeParams = useParams()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const formatError = useFormatError()

  const canCreateShop = useHasProfile('admin_velocenter', 'admin_network')

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const [workshops, setWorkshops] = useState({ status: STATUS.INITIALIZING })
  const [profiles, setProfiles] = useState({ status: STATUS.INITIALIZING })
  const [networks, setNetworks] = useState({ status: STATUS.INITIALIZING })

  useEffect(() => {
    if (routeParams.userId) {
      wait(api.get('/users/details', undefined, { userId: routeParams.userId }))
        .then(payload => dataDispatch({ type: FORM_ACTION.INIT, value: payload, }))
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
    } else {
      dataDispatch({ type: FORM_ACTION.INIT, value: DEFAULT_VALUE })
    }
  }, [api, dataDispatch, routeParams.userId,])

  useEffect(() => {
    wait(api.get('/shops/listlight'))
      .then(payload => setWorkshops({ status: STATUS.LOADED, payload }))
      .catch(payload => setWorkshops({ status: STATUS.ERROR, payload }))

    wait(api.get('/profiles/list'))
      .then(payload => setProfiles({ status: STATUS.LOADED, payload, }))
      .catch(payload => setProfiles({ status: STATUS.ERROR, payload, }))

    wait(api.get('/networks/list'))
      .then(payload => setNetworks({ status: STATUS.LOADED, payload, }))
      .catch(payload => setNetworks({ status: STATUS.ERROR, payload, }))
  }, [api,])

  function post() {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/users/details', { body: JSON.stringify(dataState.current) }))
      .then(payload => {
        if (!routeParams.userId) {
          history.replace(`/users/${payload.userId}`)
        } else {
          dataDispatch({ type: FORM_ACTION.INIT, value: payload, })
          setPostState({ status: STATUS.LOADED, payload })
        }
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  const isLoading = dataState.status === FORM_STATUS.INITIALIZING || [workshops, profiles, networks].find(state => state.status === STATUS.INITIALIZING)
  const erroredState = [workshops, profiles, networks].find(state => state.status === STATUS.ERROR)

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'full' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={isLoading || erroredState}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Title>{t({ id: 'workshops.title' })}</Block.Title>
              {workshops.status === STATUS.INITIALIZING && <Block.Loader />}
              {workshops.status === STATUS.ERROR && <Block.Content>
                <Alert>{formatError(workshops.payload)}</Alert>
              </Block.Content>}
              {workshops.status === STATUS.LOADED && <Block.Content>
                <div>
                  <Label>{t({ id: 'workshops.label' })}</Label>
                  {workshops.payload.map(workshop => <Input key={`shop-${workshop.shopId}`}
                    type="radio"
                    name="shopId"
                    id={`shopId-${workshop.shopId}`}
                    value={workshop.shopId}
                    label={workshop.shopName} />)}
                </div>
              </Block.Content>}
              {canCreateShop && (
                <Block.Actions>
                  <Button tag={Link} to='/shops/new' type="button" outlined>{t({ id: 'workshops.new' })}</Button>
                </Block.Actions>
              )}
            </Block>

            <Block>
              <Block.Title>{t({ id: 'infos.title' })}</Block.Title>
              <Block.Content>
                <Input name="firstName" type="text" label={t({ id: 'infos.firstName.label' })} placeholder={t({ id: 'infos.firstName.placeholder' })} />
                <Input name="lastName" type="text" label={t({ id: 'infos.lastName.label' })} placeholder={t({ id: 'infos.lastName.placeholder' })} />
                <Input name="email" type="email" label={t({ id: 'infos.email.label' })} placeholder={t({ id: 'infos.email.placeholder' })} disabled={Boolean(routeParams.userId)} />
                {routeParams.userId && (
                  <Input name="isLocked" type="checkbox" label={t({ id: 'infos.isLocked.label' })} />
                )}
              </Block.Content>
            </Block>

            <Block>
              <Block.Title>{t({ id: 'profile.title' })}</Block.Title>
              {[profiles.status, networks.status].includes(STATUS.INITIALIZING) && <Block.Loader />}
              {profiles.status === STATUS.ERROR && <Block.Content>
                <Alert>{formatError(profiles.payload)}</Alert>
              </Block.Content>}
              {networks.status === STATUS.ERROR && <Block.Content>
                <Alert>{formatError(networks.payload)}</Alert>
              </Block.Content>}
              {[profiles.status, networks.status].every(status => status === STATUS.LOADED) && <Block.Content>
                <div>
                  <Label>{t({ id: 'profile.title' })}</Label>
                  {profiles.payload.map(profile => <Fragment key={`shop-${profile.profileSlug}`}>
                    <Input type="radio" value={profile.profileSlug}
                      id={`profileSlug-${profile.profileSlug}`} name="profileSlug"
                      label={t({ id: 'profile.profileSlug.value' }, profile)} />
                    {profile.profileSlug === 'admin_network' && dataState.current && dataState.current.profileSlug === 'admin_network' && (
                      <SelectInput name="networkId"
                        placeholder={t({ id: 'networkId.placeholder', })}
                        options={networks.payload.map(option => ({
                          value: option.networkId,
                          label: option.networkName,
                        }))}
                      />
                    )}
                  </Fragment>)}
                </div>
              </Block.Content>}
            </Block>
            {routeParams.userId && dataState.current.profileSlug !== 'reader' && <Unavailabilities errorHandler={setPostState} />}
          </Form.Content>

          {erroredState && <Banner type="danger" sticky
            description={formatError({ code: 'unknown', prefix: ERROR_TYPE.FRONT })}
            actions={[
              { label: t({ id: 'dirty.retry' }), outlined: true, onClick: () => history.go(0) }
            ]}
          />}
          {/* TODO: Success state */}
          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
