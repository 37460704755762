import { Link, } from 'react-router-dom'

import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Page, } from '@velogik/component-page'
import { useT, } from '@velogik/component-intl'

import style from './FAQ.module.scss'

export function FAQ () {
  const t = useT()

  return (
    <Page className={style.page}>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>
      <Page.Content>
        {t({ id: 'questions' }, {
          Accordion: chunk => <Block className={style.q}>{chunk}</Block>,
          AccordionTitle: chunk => {
            const id = Math.random().toString(36).substr(2, 9)
            return <AccordionTitle id={id}>{chunk}</AccordionTitle>
          },
          AccordionContent: chunk => <>
            <Block.Content className={style.content}>{chunk}</Block.Content>
            <Block.Actions className={style.actions}>
              <Button tag={Link}
                to={{
                  pathname: '/contact',
                  state: { type: 'other', },
                }}>
                {t({ id: 'contact' })}
              </Button>
            </Block.Actions>
          </>,
          title: chunk => <div className={style.title}>{chunk}</div>,
          p: chunk => <div className={style.paragraph}>{chunk}</div>,
        })}
      </Page.Content>
    </Page>
  )
}

function AccordionTitle ({ id, children }) {
  return (
    <>
      <input id={id} type="checkbox" name="q" value={id} className={style.input} />
      <Block.Title tag="label" htmlFor={id} className={style.blockLabel}>{children}</Block.Title>
    </>
  )
}
