import { useEffect, useState, useMemo, } from 'react'
import { Link, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Button, } from '@velogik/component-button'
import { Filters, } from '@velogik/component-filters'
import { Page, } from '@velogik/component-page'
import { wait, STATUS } from '@velogik/helper-promise'
import { useT, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { Result, } from 'components/Result'
import { useHasProfile, } from 'hooks/use-user'

// import style from './ClientList.module.scss'

const filters = [
  { name: 'shopId', queryName: 'shopId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'nameFilter', queryName: 'name', type: 'text' },
  { name: 'emailFilter', queryName: 'email', type: 'text' },
]

export function ClientList() {
  const api = useApi()

  const isReader = useHasProfile('reader')

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const t = useT()

  const location = useLocation()

  const columns = useMemo(() => [
    { name: 'clientId', orderKey: 'clientId' },
    { name: 'name', orderKey: 'firstName' },
    { name: 'email', orderKey: 'email' },
    // { name: 'equipmentsCount', headerClassName: style.littleCol },
    { name: 'btn.interventions', component: ({ item }) => <Button tag={Link} to={`/interventions?clientId=${item.clientId}`} outlined>{t({ id: 'result.item.btn.interventions' })}</Button> },
    { name: 'btn.details', component: ({ item }) => <Button tag={Link} to={`/clients/${item.clientId}`}>{t({ id: 'result.item.btn.details' })}</Button> },
  ], [t])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/clients/list', undefined, {
      p: query.get('p'),
      ipp: query.get('ipp'),
      ...filters.reduce((acc, item) => ({ ...acc, [item.name]: query.get(item.queryName) }), {}),
      'order[column]': query.get('order[column]'),
      'order[dir]': query.get('order[dir]'),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        {!isReader && (
          <Page.Header.Actions>
            <Button tag={Link} to={'/clients/new'}>{t({ id: 'new' })}</Button>
          </Page.Header.Actions>
        )}
      </Page.Header>

      <Page.Content>
        <Filters data={dataState} filters={filters} />
        <Result
          keyExtractor={item => item.clientId}
          data={dataState} columns={columns} />
      </Page.Content>
    </Page>
  )
}
