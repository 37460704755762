import { useState, } from 'react'
import { useApi, } from 'react-rest-api'
import { useHistory, useLocation, } from 'react-router-dom'

import { useApp } from '@velogik/component-app'
import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, } from '@velogik/component-intl'
import { Link, } from '@velogik/component-link'
import { Page, } from '@velogik/component-page'
import { STATUS, } from '@velogik/helper-promise'

import style from './SignIn.module.scss'

export function SignIn() {
  const api = useApi()
  const history = useHistory()
  const location = useLocation()
  const { signin } = useApp()
  const t = useT()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer(FORM_STATUS.INITIALIZED)
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const to = (location.state && location.state.from) || { pathname: '/' }

  function signinCallback () {
    setPostState({ status: STATUS.LOADING })
    api.post('/auth/signin', { body: JSON.stringify(dataState.current) })
      .then(response => signin(response.accessToken))
      .then(() => history.replace(to))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload }))
  }

  return (
    <Page>
      <Page.Content className={style.pageContent}>
        <Form onSubmit={signinCallback}
          className={style.form}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}>

          <Block>
            <Block.Title>{t({ id: 'pageTitle' })}</Block.Title>
            <Block.Content>
              <Input name="email"  type="email"
                label={t({ id: 'email.label' })}
                placeholder={t({ id: 'email.placeholder' })} />
              <div>
                <Input name="password" type="password"
                  label={t({ id: 'password.label' })}
                  placeholder={t({ id: 'password.placeholder' })} />
                <Link to="/forgot-password" className={style.forgotPassword}>{t({ id: 'forgotPassword' })}</Link>
              </div>
              {postState.status === STATUS.ERROR && <Alert>{formatError(postState.payload)}</Alert>}
            </Block.Content>

            <Block.Actions>
              <Button type="submit">{t({ id: 'submit' })}</Button>
            </Block.Actions>
          </Block>
        </Form>
      </Page.Content>
    </Page>
  )
}
