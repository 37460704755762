import { useState, useEffect, useCallback, Fragment, } from 'react'
import { useParams, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { ButtonIcon, } from '@velogik/component-button-icon'
import { FileInput, } from '@velogik/component-file-input'
import { Form, useFormListReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { useT, useIntl, useFormatError, useParseNumber, ERROR_TYPE, } from '@velogik/component-intl'
import { Modal, } from '@velogik/component-modal'
import { useNotify, } from '@velogik/component-notifications'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { Spinner, } from '@velogik/component-spinner'
import { hasOne, } from '@velogik/helper-bitmask'
import { join, } from '@velogik/helper-classnames'
import { wait, STATUS, } from '@velogik/helper-promise'

import { BackButton, } from '../components/BackButton'

import style from './ShopPieces.module.scss'

const PRICE_TYPES = ['marketplace', 'workforce', 'piece']

const DEFAULT_ITEM = {
  itvcodeIdentifier: '',
  itvcodeLabel: '',
  priceWt: 0,
}

export function ShopPieces () {
  const { shopId, } = useParams()

  const api = useApi()
  const { appState, } = useApp()
  const t = useT()
  const formatError = useFormatError()

  const { notify } = useNotify()

  const [dataState, dataDispatch] = useFormListReducer()
  const [importState, setImportState] = useState({ status: STATUS.INITIALIZING })
  const [syncState, setSyncState] = useState({ status: STATUS.IDLE })
  const [postImportState, setPostImportState] = useState({ status: STATUS.IDLE })
  const [postState, setPostState] = useState({ status: STATUS.IDLE })
  const [config, setConfig] = useState({ status: STATUS.INITIALIZING })

  const [selectedRepairEquipment, setSelectedRepairEquipment] = useState()
  const [selectedPriceType, setSelectedPriceType] = useState(PRICE_TYPES[0])
  const [selectedImportFile, setSelectedImportFile] = useState()

  const refreshItvcodes = useCallback(() => {
    return wait(api.get('/shops/itvcodes', undefined, {
      shopId,
      repairEquipments: selectedRepairEquipment,
      priceType: selectedPriceType,
    }))
      .then(payload => dataDispatch({ type: FORM_ACTION.INIT, value: payload.itvcodes.map(code => ({
        ...code,
        key: `${code.itvcodeId}.${code.itvcodeIdentifier}.${new Date().getTime()}`
      })), }))
      .catch(value => dataDispatch({ type: FORM_ACTION.ERROR, value, }))
  }, [api, dataDispatch, shopId, selectedRepairEquipment, selectedPriceType])

  useEffect(() => {
    if (selectedRepairEquipment === undefined) {
      return
    }

    refreshItvcodes()
  }, [refreshItvcodes, selectedRepairEquipment])

  useEffect(() => {
    setImportState({ status: STATUS.LOADING, })

    if (selectedRepairEquipment === undefined) {
      return
    }

    wait(api.get('/itvcodes/import', undefined, {
      shopId,
      repairEquipments: selectedRepairEquipment,
      priceType: selectedPriceType,
    }))
      .then(payload => setImportState({ status: STATUS.IDLE, value: payload, }))
      .catch(value => setImportState({ status: STATUS.ERROR, value, }))
  }, [api, shopId, setImportState, selectedRepairEquipment, selectedPriceType])

  useEffect(() => {
    wait(api.get('/shops/config', undefined, { shopId }))
      .then(payload => {
        const firstFound = appState.constants.shopRepairEquipments.find(eq => hasOne(eq.bit, payload.repairEquipments))
        setSelectedRepairEquipment(firstFound ? firstFound.bit : undefined)
        setConfig({ status: STATUS.LOADED, payload })
      })
      .catch(payload => {
        setConfig({ status: STATUS.ERROR, payload })
        dataDispatch({ type: FORM_ACTION.ERROR, value: { code: 'cannotLoadConfig', prefix: ERROR_TYPE.FRONT }, })
      })
  }, [api, dataDispatch, shopId, appState.constants.shopRepairEquipments])

  function post () {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/shops/itvcodes', { body: JSON.stringify({
      shopId,
      repairEquipments: selectedRepairEquipment,
      itvcodes: dataState.current,
      priceType: selectedPriceType,
    }) }))
      .then(payload => {
        dataDispatch({ type: FORM_ACTION.INIT, value: payload.map(code => ({
          ...code,
          key: `${code.itvcodeId}.${code.itvcodeIdentifier}.${new Date().getTime()}`
        })), })
        setPostState({ status: STATUS.LOADED, payload })
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function exportPrice (type) {
    setPostImportState({ status: STATUS.LOADING })
    api.put('/itvcodes/import', { body: selectedImportFile }, {
      shopId,
      repairEquipments: selectedRepairEquipment,
      priceType: selectedPriceType,
      type
    })
      .then(() => {
        setSelectedImportFile()
        setPostImportState({ status: STATUS.IDLE })
        refreshItvcodes()
      })
      .catch(payload => {
        setPostImportState({ status: STATUS.ERROR, payload })
      })
  }

  function closeImportModal () {
    setSelectedImportFile()
    setPostImportState({ status: STATUS.IDLE })
  }

  function sync () {
    setSyncState({ status: STATUS.LOADING })
    // TODO: define final
    wait(api.post('/shops/itvcodesSync', { body: JSON.stringify({ shopId }) }))
      .then(() => refreshItvcodes())
      .then(() => notify(t({ id: 'synced' })))
      .then(() => setSyncState({ status: STATUS.IDLE }))
      .catch(payload => setSyncState({ status: STATUS.ERROR, payload }))
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        <div className={style.selector}>
          {/* TODO: Use Loader component */}
          {config.status === STATUS.INITIALIZING && <Spinner />}
          {config.status === STATUS.ERROR && <Alert>{formatError(config.payload)}</Alert>}
          {config.status === STATUS.LOADED && (
            <SelectInput
              name="repairEquipments"
              label={t({ id: 'repairEquipments.label' })}
              className={style.repairEquipment}
              value={selectedRepairEquipment}
              onChange={({ target: { value } }) => setSelectedRepairEquipment(value)}
              options={appState.constants.shopRepairEquipments.filter(_ => hasOne(_.bit, config.payload.repairEquipments)).map(eq => ({
                label: t({ id: 'repairEquipments.value' }, { value: eq.key }),
                value: eq.bit
              }))} />
          )}
        </div>

        <Block>
          <Block.Content>
            <SelectInput
              name="priceType"
              label={t({ id: 'priceType.label' })}
              disabled={config.status !== STATUS.LOADED}
              className={style.repairEquipment}
              value={selectedPriceType}
              // onChange={({ target: { value } }) => setSelectedRepairEquipment(constants.shopRepairEquipments.find(_ => _.key === value))}
              onChange={({ target: { value } }) => setSelectedPriceType(value)}
              options={PRICE_TYPES.map(priceType => ({
                label: t({ id: 'priceType.value' }, { value: priceType }),
                value: priceType
              }))} />
          </Block.Content>
        </Block>

        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content className={style.formContent}>

            {selectedPriceType === PRICE_TYPES[0] && (
              <Block>
                <Block.Title className={style.title}>{t({ id: 'marketplace.title', })}</Block.Title>
                <Block.Content className={style.blockContent}>
                  <div className={join(style.items, style.marketplaces)}>
                    <span className={style.header}>{t({ id: 'marketplace.repairService', })}</span>
                    <span className={style.header}>{t({ id: 'marketplace.repairServicesDetails', })}</span>
                    <span className={style.header}>{t({ id: 'marketplace.duration', })}</span>
                    <span className={style.header}>{t({ id: 'marketplace.priceWt', })}</span>
                    {/* TODO: Consider a loader line */}
                    {[FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status) && dataState.current.filter(_ => _.type === PRICE_TYPES[0]).map((item, i) => (
                      <Marketplace
                        key={`marketplace-${item.key || i}`}
                        item={item}
                        i={i}
                        onChange={e => dataDispatch({ type: 'updateItem', value: item, event: e, })} />
                    ))}
                  </div>
                </Block.Content>
              </Block>
            )}

            {selectedPriceType === PRICE_TYPES[1] && (
              <Block>
                <Block.Title className={style.title}>{t({ id: 'workforce.title', })}</Block.Title>

                {config.status === STATUS.LOADED && (
                  <Block.Content className={style.blockContent}>
                    <div className={join(style.items, style.workforces)}>
                      <span className={style.header}>{t({ id: 'workforce.itvcodeIdentifier', })}</span>
                      <span className={style.header}>{t({ id: 'workforce.itvcodeLabel', })}</span>
                      <span className={style.header}>{t({ id: 'workforce.duration', })}</span>
                      <span className={style.header}>{t({ id: 'workforce.priceWt', })}</span>
                      <span />
                      {/* TODO: Consider a loader line */}
                      {[FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status) && dataState.current.filter(_ => _.type === PRICE_TYPES[1]).map((item, i) => (
                        <Workforce
                          key={`workforce-${item.key || i}`}
                          item={item}
                          i={i}
                          onChange={e => dataDispatch({ type: 'updateItem', value: item, event: e, })}
                          onRemove={() => dataDispatch({ type: 'removeItem', value: item, })} />
                      ))}
                    </div>
                  </Block.Content>
                )}
                <Block.Actions>
                  <Button className={style.new} text
                    onClick={() => dataDispatch({ type: 'newItem', value: { ...DEFAULT_ITEM, duration: 0, type: PRICE_TYPES[1] }, })}>
                    {t({ id: 'workforce.new' })}
                  </Button>
                </Block.Actions>
              </Block>
            )}

            {selectedPriceType === PRICE_TYPES[2] && (
              <Block>
                <Block.Title>{t({ id: 'piece.title', })}</Block.Title>
                {config.status === STATUS.LOADED && (
                  <Block.Content className={style.blockContent}>
                    <div className={join(style.items, (config.status === STATUS.LOADED && config.payload.syncLock) ? style.syncLock : style.pieces)}>
                      <span className={style.header}>{t({ id: 'piece.itvcodeIdentifier' })}</span>
                      {config.status === STATUS.LOADED && config.payload.syncLock && <span className={style.header}>{t({ id: 'piece.chronocaisseRef' })}</span>}
                      <span className={style.header}>{t({ id: 'piece.itvcodeLabel' })}</span>
                      <span className={style.header}>{t({ id: 'piece.priceWt' })}</span>
                      <span />
                      {/* TODO: Consider a loader line */}
                      {[FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status) && dataState.current.filter(_ => _.type === PRICE_TYPES[2]).map((item, i) => (
                        <Piece
                          key={`piece-${item.key || i}`}
                          item={item}
                          i={i}
                          syncLock={config.status === STATUS.LOADED && config.payload.syncLock}
                          disabled={config.payload.syncLock}
                          onChange={e => dataDispatch({ type: 'updateItem', value: item, event: e, })}
                          onRemove={() => dataDispatch({ type: 'removeItem', value: item, })} />
                      ))}
                    </div>
                  </Block.Content>
                )}
                {config.status === STATUS.LOADED && !config.payload.syncLock && (
                  <Block.Actions>
                    <Button className={style.new} text
                      onClick={() => dataDispatch({ type: 'newItem', value: { ...DEFAULT_ITEM, type: PRICE_TYPES[2] }, })}>
                      {t({ id: 'piece.new' })}
                    </Button>
                  </Block.Actions>
                )}
              </Block>
            )}
          </Form.Content>

          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.status === FORM_STATUS.DIRTY && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.cancel' }), type: 'button', onClick: () => dataDispatch({ type: FORM_ACTION.RESET, }), outlined: true, disabled: postState.status === STATUS.LOADING, },
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING },
            ]} />}
        </Form>

        {config.status === STATUS.LOADED &&
         config.payload.syncLock &&
         selectedPriceType === PRICE_TYPES[2] && (
          <Block>
            <Block.Title>
              {/* TODO: translation */}
              {t({ id: 'sync.title' })}
            </Block.Title>
            <Block.Content>
              {syncState.status === STATUS.ERROR && (
                syncState.payload.code === 'itvcodesSyncInvalid'
                  ? syncState.payload.params.codes.map(error => (
                    <Alert key={`${error.id}-${error.label}`}>{formatError({
                      code: 'itvcodesSyncInvalid',
                      params: error
                    })}</Alert>
                  ))
                  : <Alert>{formatError(syncState.payload)}</Alert>
              )}
              <Button
                onClick={sync}
                disabled={syncState.status === STATUS.LOADING}>
                {/* TODO: translation */}
                {t({ id: 'sync.button' })}
              </Button>
            </Block.Content>
            {syncState.status === STATUS.LOADING && <Block.Loader />}
          </Block>
        )}

        {(
          selectedPriceType === PRICE_TYPES[1] ||
          (selectedPriceType === PRICE_TYPES[2] && config.status === STATUS.LOADED && !config.payload.syncLock)
        ) && (
          <Block>
            <Block.Title>
              {t({ id: 'import.title' })}
            </Block.Title>
            <Block.Content>
              <FileInput
                name="import"
                buttonLabel={t({ id: 'import' })}
                onFileChange={setSelectedImportFile}
                disabled={importState.status === STATUS.LOADING}
              />
              {/* Loading if importState.status === STATUS.LOADING */}
              <Button
                tag="a"
                href={(importState.status === STATUS.IDLE && importState.value.model) || undefined}
                disabled={importState.status !== STATUS.IDLE || importState.status === STATUS.LOADING || !importState.value || !importState.value.model}
                target="_blank"
                rel="noopener noreferrer">
                {t({ id: 'exportModel' })}
              </Button>
              <Button
                tag="a"
                href={(importState.status === STATUS.IDLE && importState.value.state) || undefined}
                disabled={importState.status !== STATUS.IDLE || importState.status === STATUS.LOADING || !importState.value || !importState.value.state}
                target="_blank"
                rel="noopener noreferrer">
                {t({ id: 'exportState' })}
              </Button>
            </Block.Content>
          </Block>
        )}

        <Modal isOpen={selectedImportFile} toggle={() => closeImportModal()}>
          <Block>
            <Block.Title className={style.title}>
              {t({ id: 'import.modal.title' })}
            </Block.Title>
            <Block.Content className={style.content}>
              <div>{t({ id: 'import.modal.disclaimer' })}</div>
            </Block.Content>
            <Block.Actions className={style.blockActions}>
              {postImportState.status === STATUS.ERROR && (
                <Alert>{formatError(postImportState.payload)}</Alert>
              )}
              <Button onClick={() => exportPrice('scratch')}
                disabed={postImportState.status === STATUS.LOADING}>
                {t({ id: 'import.modal.actions.scratch' })}
              </Button>
              <Button onClick={() => exportPrice('merge')}
                disabed={postImportState.status === STATUS.LOADING}>
                {t({ id: 'import.modal.actions.merge' })}
              </Button>
            </Block.Actions>
          </Block>
        </Modal>
      </Page.Content>
    </Page>
  )
}

function Marketplace ({ ...props }) {
  const t = useT()

  return (
    <Fragment>
      <label className={style.data} htmlFor={`${props.item.itvcodeId}-${props.i}-repairServices`}>
        <input id={`${props.item.itvcodeId}-${props.i}-repairServices`}
          value={t({ id: `marketplace.${props.item['repairServicesValue']}`})}
          name="repairServices"
          readOnly disabled />
      </label>
      <label className={style.data} htmlFor={`${props.item.itvcodeId}-${props.i}-repairServicesDetails`}>
        <input id={`${props.item.itvcodeId}-${props.i}-repairServicesDetails`}
          value={t({ id: `marketplace.${props.item['repairServicesValue']}.${props.item['repairServicesDetailsValue']}`})}
          name="repairServicesDetails"
          readOnly disabled />
      </label>
      <Field {...props} field="duration" />
      <Field {...props} type="number" field="priceWt" />
    </Fragment>
  )
}

function Workforce ({ onRemove, ...props }) {
  const t = useT()

  return (
    <Fragment>
      <Field {...props} field="itvcodeIdentifier" />
      <Field {...props} field="itvcodeLabel" />
      <Field {...props} field="duration" />
      <Field {...props} type="number" field="priceWt" />
      <div className={style.removeBtn}><ButtonIcon src={require('assets/icons/cross-red.svg').default} rounded danger onClick={onRemove} alt={t({ id: 'remove.alt' })} disabled={props.disabled} /></div>
    </Fragment>
  )
}

function Piece ({ onRemove, syncLock, ...props }) {
  const t = useT()

  return (
    <Fragment>
      <Field {...props} field="itvcodeIdentifier" />
      {syncLock && <Field {...props} field="chronocaisseRef" />}
      <Field {...props} field="itvcodeLabel" />
      <Field {...props} field="priceWt" type="number" />
      <div className={style.removeBtn}><ButtonIcon src={require('assets/icons/cross-red.svg').default} rounded danger onClick={onRemove} alt={t({ id: 'remove.alt' })} disabled={props.disabled} /></div>
    </Fragment>
  )
}

function Field ({ item, type, onChange, field, i, ...props }) {
  const t = useT()
  const parseNumber = useParseNumber()
  const intl = useIntl()

  const [displayedValue, setDisplayedValue] = useState(() =>
    (type === 'number' ? intl.formatNumber(item[field]) : item[field]) || 0
  )

  function handleChange (e) {
    switch (type) {
      case 'number': {
        const { target: { value, name } } = e
        const parsedValue = value ? parseNumber(value) : 0

        setDisplayedValue(oldValue => !isNaN(parsedValue) ? value : oldValue)

        return onChange({ target: { name, type: 'text', value: parsedValue } })
      }
      default:
        setDisplayedValue(e.target.value)
        return onChange(e)
    }
  }

  return (
    <label className={style.data} htmlFor={`${item.itvcodeId}-${i}-${field}`}>
      <input id={`${item.itvcodeId}-${i}-${field}`}
        value={displayedValue}
        name={field}
        placeholder={t({ id: `${field}.placeholder` })}
        onChange={handleChange}
        {...props} />
    </label>
  )
}
