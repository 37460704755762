import { NetworkList, } from './NetworkList'
import { NetworkNew, } from './NetworkNew'
import { NetworkAdmin, } from './NetworkAdmin'
import { NetworkAdminAsvs, } from './NetworkAdmin.Asvs'

export const Network = {
  List: NetworkList,
  New: NetworkNew,
  Admin: NetworkAdmin,
  Asvs: NetworkAdminAsvs,
}
