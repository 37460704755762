import { useEffect, useState, } from 'react'
import { useApi } from 'react-rest-api'
import { Link, } from 'react-router-dom'

import { useApp, } from '@velogik/component-app'
import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { useT, useFormatError } from '@velogik/component-intl'
import { Url, } from '@velogik/component-link'
import { Modal, } from '@velogik/component-modal'
import { wait, STATUS, } from '@velogik/helper-promise'

import { useHasProfile } from 'hooks/use-user'

import style from './PlanningModalItem.module.scss'

export function PlanningModalItem ({ item, isOpen, toggle }) {
  return (
    isOpen && (
      <Modal size="small" isOpen={isOpen} toggle={() => toggle()}>
        {((type) => {
          switch (type) {
            case 'interventions': return <Intervention item={item} />
            case 'sharedInterventions': return <SharedIntervention item={item} />
            case 'tasks': return <Task item={item} />
            default: return null
          }
        })(item && item.type)}
      </Modal>
    )
  )
}

function Intervention ({ item }) {
  const api = useApi()
  const t = useT('PlanningItem.Intervention', true)
  const tCommon = useT('common', true)
  const formatError = useFormatError()
  const { appState, } = useApp()

  const [dataState, setDataState] = useState({ status: STATUS.IDLE })

  const id = item.interventionId

  useEffect(() => {
    let isSubscribed = true

    if (id !== undefined) {
      wait(api.get('/interventions/details', undefined, { interventionId: id }))
        .then(payload => {
          const status = (appState.constants.interventionStatus.find(type => type.value === payload.informations.statusId) || {}).key
          const location = (appState.constants.interventionLocation.find(type => type.value === payload.informations.locationId) || {}).key
          const service = (appState.constants.interventionServices.find(type => type.value === payload.informations.servicesId) || {}).key
          const serviceCategory = service && (appState.constants.interventionServicesDetails.find(type => type.key === service) || {}).values
          const serviceDetails = serviceCategory && payload.informations.servicesDetailsId
            .map(servicesDetailsId => (serviceCategory.find(type => type.value === servicesDetailsId) || {}).key)
            .filter(_ => _)
          const dateBikeReceipt = payload.informations.isBikeReceivable && payload.informations.dateBikeReceipt
          const dateScheduledReturn = payload.informations.dateScheduledReturn ? new Date(payload.informations.dateScheduledReturn) : undefined

          isSubscribed && setDataState({ status: STATUS.LOADED, payload: {
            status,
            interventionId: payload.informations.interventionId,
            date: new Date(payload.informations.datePlanned),
            duration: payload.informations.duration,
            location,
            userName: payload.client.userName,
            phoneNumber: payload.client.phoneNumber,
            address: payload.informations.addressFull,
            equipmentIdentifier: payload.client.velocenterIdentifier,
            equipmentCustomName: payload.client.customName || null,
            service,
            serviceCategory,
            serviceDetails: serviceDetails || null,
            isBikeReceivable: payload.informations.isBikeReceivable ? 'true' : 'false',
            isBikeReceived: dateBikeReceipt ? 'true' : 'false',
            dateBikeReceipt: dateBikeReceipt && new Date(payload.informations.dateBikeReceipt),
            firstName: dateBikeReceipt && payload.informations.userBikeReceipt.firstName,
            lastName: dateBikeReceipt && payload.informations.userBikeReceipt.lastName,
            dateScheduledReturn: dateScheduledReturn,

            line: chunk => <div>{chunk}</div>,
            b: chunk => <b>{chunk}</b>,
            tel: chunk => <Url href={`tel:${payload.client.phoneNumber}`}>{chunk}</Url>
          } })
        })
        .catch(payload => isSubscribed && setDataState({ status: STATUS.ERROR, payload }))
    } else {
      isSubscribed && setDataState({ status: STATUS.IDLE })
    }

    return () => { isSubscribed = false }
  }, [appState, api, id])

  return (
    <Block>
      <Block.Title>{t({ id: 'title' })}</Block.Title>
      {dataState.status === STATUS.IDLE && <Block.Loader />}
      {dataState.status === STATUS.LOADED && (
        <Block.Content>
          <div>
            <div>{t({ id: 'interventionId.modalLabel' }, dataState.payload)} <b>{t({ id: 'interventionId.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'date.label' }, dataState.payload)} <b>{t({ id: 'date.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'duration.label' }, dataState.payload)} <b>{t({ id: 'duration.value' }, dataState.payload)}</b></div>
            {dataState.payload.userName && <div>{t({ id: 'userName.label' }, dataState.payload)} <b>{t({ id: 'userName.value' }, dataState.payload)}</b></div>}
            {dataState.payload.phoneNumber && <div>{t({ id: 'phoneNumber.label' }, dataState.payload)} <b>{t({ id: 'phoneNumber.value' }, {
              ...dataState.payload,
              tel: chunk => <Url href={`tel:${dataState.payload.phoneNumber}`}>{chunk}</Url>
            })}</b></div>}
            <div>{t({ id: 'location.label' }, dataState.payload)} <b>{t({ id: 'location.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'equipment.label' }, dataState.payload)} <b>
              {dataState.payload.equipmentCustomName || ''}
              {dataState.payload.equipmentCustomName && dataState.payload.equipmentIdentifier && ' - '}
              {dataState.payload.equipmentIdentifier}
            </b></div>
            <div>{t({ id: 'service.label' }, dataState.payload)} <b>{t({ id: 'service.value' }, {
              services: tCommon({ id: 'services' }, { value: dataState.payload.service }),
              servicesDetails: dataState.payload.serviceDetails ? dataState.payload.serviceDetails
                .map(_ => tCommon({ id: 'servicesDetails' }, { value: `${dataState.payload.service}_${_}`}))
                .join(' - ') : undefined
            })}</b></div>
            {dataState.payload.status !== 'express' && <>
              <div>{t({ id: 'equipmentReceivable.label' }, dataState.payload)} <b>{t({ id: 'equipmentReceivable.value' }, dataState.payload)}</b></div>
              {dataState.payload.dateScheduledReturn && (
                <div>{t({ id: 'dateSchedule.label' }, dataState.payload)} <b>{t({ id: 'dateSchedule.value' }, dataState.payload)}</b></div>
              )}
            </>}
          </div>
        </Block.Content>
      )}
      {dataState.status === STATUS.LOADED && (
        <Block.Actions className={style.actions}>
          <Button tag={Link} to={`/interventions/${id}/details`}>
            {t({ id: 'go' })}
          </Button>
        </Block.Actions>
      )}
      {dataState.status === STATUS.ERROR && (
        <Block.Content>
          <Alert>{formatError(dataState.payload)}</Alert>
        </Block.Content>
      )}
    </Block>
  )
}

function SharedIntervention ({ item }) {
  const api = useApi()
  const t = useT('PlanningItem.Intervention', true)
  const formatError = useFormatError()
  const { appState, } = useApp()

  const [dataState, setDataState] = useState({ status: STATUS.IDLE })

  const id = item.interventionId

  useEffect(() => {
    let isSubscribed = true

    if (id !== undefined) {
      wait(api.get('/velocare/sharedintervention', undefined, { interventionId: id }))
        .then(payload => {
          const status = (appState.constants.interventionStatus.find(type => type.value === payload.informations.statusId) || {}).key
          const location = (appState.constants.interventionLocation.find(type => type.value === payload.informations.locationId) || {}).key
          const dateScheduledReturn = payload.informations.dateScheduledReturn ? new Date(payload.informations.dateScheduledReturn) : undefined

          isSubscribed && setDataState({ status: STATUS.LOADED, payload: {
            status,
            interventionId: payload.informations.interventionId,
            date: new Date(payload.informations.datePlanned),
            duration: payload.informations.duration,
            location,
            userName: payload.client.userName,
            phoneNumber: payload.client.phoneNumber,
            address: payload.informations.addressFull,
            equipmentIdentifier: payload.client.velocenterIdentifier,
            equipmentCustomName: payload.client.customName || null,
            dateScheduledReturn: dateScheduledReturn,

            line: chunk => <div>{chunk}</div>,
            b: chunk => <b>{chunk}</b>,
            tel: chunk => <Url href={`tel:${payload.client.phoneNumber}`}>{chunk}</Url>
          } })
        })
        .catch(payload => isSubscribed && setDataState({ status: STATUS.ERROR, payload }))
    } else {
      isSubscribed && setDataState({ status: STATUS.IDLE })
    }

    return () => { isSubscribed = false }
  }, [appState, api, id])

  return (
    <Block>
      <Block.Title>{t({ id: 'title' })}</Block.Title>
      {dataState.status === STATUS.IDLE && <Block.Loader />}
      {dataState.status === STATUS.LOADED && (
        <Block.Content>
          <div>
            <div>{t({ id: 'interventionId.modalLabel' }, dataState.payload)} <b>{t({ id: 'interventionId.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'date.label' }, dataState.payload)} <b>{t({ id: 'date.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'duration.label' }, dataState.payload)} <b>{t({ id: 'duration.value' }, dataState.payload)}</b></div>
            {dataState.payload.userName && <div>{t({ id: 'userName.label' }, dataState.payload)} <b>{t({ id: 'userName.value' }, dataState.payload)}</b></div>}
            {dataState.payload.phoneNumber && <div>{t({ id: 'phoneNumber.label' }, dataState.payload)} <b>{t({ id: 'phoneNumber.value' }, {
              ...dataState.payload,
              tel: chunk => <Url href={`tel:${dataState.payload.phoneNumber}`}>{chunk}</Url>
            })}</b></div>}
            <div>{t({ id: 'location.label' }, dataState.payload)} <b>{t({ id: 'location.value' }, dataState.payload)}</b></div>
            {dataState.payload.equipmentCustomName && <div>{t({ id: 'equipment.label' }, dataState.payload)} <b>{dataState.payload.equipmentCustomName || ''}</b></div>}
          </div>
        </Block.Content>
      )}
      {dataState.status === STATUS.LOADED && (
        <Block.Actions className={style.actions}>
          <Button tag={Link} to={`/sharedInterventions/${id}/details`}>
            {t({ id: 'go' })}
          </Button>
        </Block.Actions>
      )}
      {dataState.status === STATUS.ERROR && (
        <Block.Content>
          <Alert>{formatError(dataState.payload)}</Alert>
        </Block.Content>
      )}
    </Block>
  )
}

function Task ({ item }) {
  const api = useApi()
  const t = useT('PlanningItem.Task', true)
  const formatError = useFormatError()
  const { appState, } = useApp()
  const isReader = useHasProfile('reader')

  const [dataState, setDataState] = useState({ status: STATUS.IDLE })

  const id = item.taskId

  useEffect(() => {
    let isSubscribed = true

    if (id !== undefined) {
      wait(api.get('/tasks/details', undefined, { taskId: id }))
        .then(payload => {
          isSubscribed && setDataState({ status: STATUS.LOADED, payload: {
            taskId: payload.taskId,
            firstName: payload.firstName,
            lastName: payload.lastName,
            date: new Date(payload.datePlanned),
            duration: payload.duration,
            description: payload.description,

            line: chunk => <div>{chunk}</div>,
            b: chunk => <b>{chunk}</b>,
          } })
        })
        .catch(payload => isSubscribed && setDataState({ status: STATUS.ERROR, payload }))
    } else {
      isSubscribed && setDataState({ status: STATUS.IDLE })
    }

    return () => { isSubscribed = false }
  }, [appState, api, id])

  return (
    <Block>
      <Block.Title>{t({ id: 'title' })}</Block.Title>
      {dataState.status === STATUS.IDLE && <Block.Loader />}
      {dataState.status === STATUS.LOADED && (
        <Block.Content>
          <div>
            <div>{t({ id: 'taskId.modalLabel' }, dataState.payload)} <b>{t({ id: 'taskId.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'date.label' }, dataState.payload)} <b>{t({ id: 'date.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'duration.label' }, dataState.payload)} <b>{t({ id: 'duration.value' }, dataState.payload)}</b></div>
            <div>{t({ id: 'description.label' }, dataState.payload)} <b>{t({ id: 'description.value' }, dataState.payload)}</b></div>
          </div>
        </Block.Content>
      )}
      {!isReader && dataState.status === STATUS.LOADED && (
        <Block.Actions className={style.actions}>
          <Button tag={Link} to={`/tasks/${id}/details`}>
            {t({ id: 'go' })}
          </Button>
        </Block.Actions>
      )}
      {dataState.status === STATUS.ERROR && (
        <Block.Content>
          <Alert>{formatError(dataState.payload)}</Alert>
        </Block.Content>
      )}
    </Block>
  )
}
