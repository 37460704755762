import { useEffect, useState, useReducer, useRef, useCallback, useMemo, } from 'react'
import { useApi } from 'react-rest-api'
import { Link as RRDLink, useLocation, useHistory, } from 'react-router-dom'

import { useApp, } from '@velogik/component-app'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { Link, } from '@velogik/component-link'
import { useNotify } from '@velogik/component-notifications'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { Spinner, } from '@velogik/component-spinner'
import { join } from '@velogik/helper-classnames'
import { formatDate, formatMinutes, } from '@velogik/helper-date'
import { wait, STATUS, } from '@velogik/helper-promise'
import { arraying, } from '@velogik/helper-utils'
import { useDebounceValue, } from '@velogik/hook-debounce'

import { DayInput, } from 'components/DayInput'
import { Legend, } from 'components/Legend'
import { PlanningRow, } from 'components/Planning'
import { ItemRender, } from 'components/Planning.helpers'
import { PlanningModalItem, } from 'components/PlanningModalItem'
import { PlanningTooltipItem, } from 'components/PlanningTooltipItem'
import { buildPlanning, buildPlanningRow, } from 'helpers/planning'
import { useHasProfile } from 'hooks/use-user'

import style from './Planning.All.module.scss'

function planningReducer (state, action) {
  switch (action.type) {
    case 'loading': return {
      ...state,
      status: STATUS.LOADING,
    }
    case 'init': return {
      ...state,
      status: STATUS.LOADED,
      initial: action.payload.result,

      payload: {
        ...state.payload,
        ...action.payload,
        rows: buildPlanning(
          action.payload.result,
          ['interventions', 'tasks', 'unavailabilities'],
          state.constants.planningSchedule.hourStart,
          state.constants.planningSchedule.hourEnd,
        )
      },
    }
    case 'filterUser': return state.status === STATUS.LOADED ? {
      ...state,
      payload: {
        ...state.payload,
        rows: buildPlanning(
          state.initial.filter(_ => !action.payload || _.userName.toUpperCase().includes(action.payload.toUpperCase())),
          ['interventions', 'tasks', 'unavailabilities'],
          state.constants.planningSchedule.hourStart,
          state.constants.planningSchedule.hourEnd,
        )
      },
    } : state
    default: return state
  }
}

function planningInitializer ({ constants, }) {
  return {
    status: STATUS.INITIALIZING,
    payload: {
      filters: {},
    },
    initial: [],
    constants,
  }
}

function RowRenderLoading (row, columns) {
  return <td colSpan={columns.length}><Spinner className={style.rowSpinner} /></td>
}

function RowRenderError (row, columns) {
  return <td colSpan={columns.length} className={style.rowRenderError}><div>{row.payload}</div></td>
}

export function PlanningAll () {
  const { appState, } = useApp()
  const location = useLocation()
  const history = useHistory()
  const t = useT()
  const isReader = useHasProfile('reader')

  const [planningState, planningDispatch] = useReducer(
    planningReducer,
    { constants: appState.constants, },
    planningInitializer
  )

  const [userFilter, setUserFilter] = useState('')
  const [confirmTransition, setConfirmTransition] = useState()
  const [tooltipOpen, setTooltipOpen] = useState(null)

  const query = new URLSearchParams(location.search)
  const queryDate = query.get('date')
  const daySelected = queryDate ? new Date(queryDate) : new Date()

  const columns = useMemo(() => arraying((appState.constants.planningSchedule.hourEnd - appState.constants.planningSchedule.hourStart) / 60).map((_, i) => ({
    key: i,
    label: formatMinutes(appState.constants.planningSchedule.hourStart + (60 * i)),
  })), [appState.constants])

  const userFilterDebounced = useDebounceValue(userFilter)

  useEffect(() => {
    planningDispatch({ type: 'filterUser', payload: userFilterDebounced })
  }, [userFilterDebounced])

  function handleSlotClick (e) {
    // console.log('handleSlotClick', e)
    return (e.type !== 'unavailbilities') ? setConfirmTransition(e) : e
  }

  function setDaySelected (value) {
    const query = new URLSearchParams(location.search)
    query.set('date', formatDate(value))
    history.push(`?${query}`)
  }

  function setUrlQueryParam (name, value) {
    const query = new URLSearchParams(location.search)
    query.set(name, value)
    history.push(`?${query}`)
  }

  return (
    <Page>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        {!isReader && (
          <Page.Header.Actions>
            <Button
              tag={RRDLink}
              to={{
                pathname: '/tasks/new',
                state: { defaultValues: {
                  shopId: planningState.payload.filters.shopId
                  && planningState.payload.filters.shopId.selected
                  && planningState.payload.filters.shopId.selected.value,
                }},
              }}
              outlined
            >
              {t({ id: 'tasks.new' })}
            </Button>
            <Button
              tag={RRDLink}
              to={{
                pathname: '/interventions/express',
                state: { defaultValues: {
                  shopId: planningState.payload.filters.shopId
                  && planningState.payload.filters.shopId.selected
                  && planningState.payload.filters.shopId.selected.value,
                }},
              }}
              outlined
            >
              {t({ id: 'interventions.express' })}
            </Button>
            <Button
              tag={RRDLink}
              to={{
                pathname: '/interventions/new',
                state: { defaultValues: {
                  shopId: planningState.payload.filters.shopId
                  && planningState.payload.filters.shopId.selected
                  && planningState.payload.filters.shopId.selected.value,
                }},
              }}
            >
              {t({ id: 'interventions.new' })}
            </Button>
          </Page.Header.Actions>
        )}
      </Page.Header>
      <Page.Content>
        <Block>
          <Block.Content className={style.filters}>
            {planningState.payload.filters.shopId && planningState.payload.filters.shopId.values.length > 0 &&
              <SelectInput
                label={t({ id: 'filter.shopId.label' })}
                all={t({ id: 'filter.shopId.all' })}
                placeholder={t({ id: 'filter.shopId.placeholder' })}
                disabled={planningState.status === STATUS.LOADING}
                value={planningState.payload.filters.shopId.selected && planningState.payload.filters.shopId.selected.value}
                options={planningState.payload.filters.shopId.values}
                onChange={({ target: { value } }) => setUrlQueryParam('shop', value)} />
            }
            <Input
              label={t({ id: 'filter.user.label' })}
              placeholder={t({ id: 'filter.user.placeholder' })}
              disabled={planningState.status === STATUS.LOADING}
              value={userFilter}
              onChange={({ target: { value } }) => setUserFilter(value)} />
            <DayInput className={style.scheduleDay} name="scheduleDay"
              label={t({ id: 'main.schedule.date.label' })}
              placeholder={''}
              disabled={planningState.status === STATUS.LOADING}
              value={daySelected}
              onChange={({ target: { value } }) => setDaySelected(value)} />
            <div className={style.showBlocked}>
              <Label>{t({ id: 'filter.showBlocked.label' })}</Label>
              <Input className={style.input} name="showBlocked"
                label={t({ id: 'filter.display' })}
                type="checkbox"
                disabled={planningState.status === STATUS.LOADING}
                value={Boolean(planningState.payload.filters.showBlocked)}
                onChange={() => setUrlQueryParam('showBlocked', !planningState.payload.filters.showBlocked)} />
            </div>
          </Block.Content>

          <StandardPlanning
            planningState={planningState} planningDispatch={planningDispatch}
            tooltipOpen={tooltipOpen} setTooltipOpen={setTooltipOpen}
            handleSlotClick={handleSlotClick}
            columns={columns}
          />
        </Block>

        <Block>
          <Block.Title>{t({ id: 'sharedPlanning.title' })}</Block.Title>
          <SharedPlannings
            tooltipOpen={tooltipOpen} setTooltipOpen={setTooltipOpen}
            handleSlotClick={handleSlotClick}
            columns={columns}
          />
        </Block>
      </Page.Content>

      <PlanningModalItem
        item={confirmTransition}
        isOpen={confirmTransition !== undefined} toggle={setConfirmTransition}
      />
    </Page>
  )
}

function StandardPlanning ({
  planningState, planningDispatch,
  tooltipOpen, setTooltipOpen,
  handleSlotClick,
  columns,
}) {
  const { appState, } = useApp()
  const api = useRef(useApi())
  const location = useLocation()
  const t = useT()
  const formatError = useFormatError()
  const { notify, } = useNotify()

  const query = new URLSearchParams(location.search)
  const queryDate = query.get('date')
  const shopId = query.get('shop')
  const showBlocked = query.get('showBlocked')

  useEffect(() => {
    planningDispatch({ type: 'loading' })
    wait(api.current.get('/planning/view', undefined, {
      shopId: shopId,
      dateStart: queryDate ? formatDate(new Date(queryDate)) : undefined,
      showBlocked: showBlocked,
    }))
      .then(payload => planningDispatch({ type: 'init', payload }))
      // TODO: display this error if happen
      .catch(payload => planningDispatch({ type: 'error', payload }))
  }, [queryDate, shopId, showBlocked, planningDispatch])

  const handleOnDropPlanningItem = useCallback((e, row) => {
    e.preventDefault()

    const daySelected = queryDate ? new Date(queryDate) : new Date()
    const item = JSON.parse(e.dataTransfer.getData('text/plain'))

    const rect = e.currentTarget.getBoundingClientRect()

    const cursorPosition = e.clientX - rect.left

    const timeStep = appState.constants.planningSchedule.timePickerStep
    const timeRange = appState.constants.planningSchedule.hourEnd - appState.constants.planningSchedule.hourStart
    const timeSteps = timeRange / timeStep

    const pixelRange = rect.width
    const pixelStep = pixelRange / timeSteps
    const pixelRatio = Math.round(cursorPosition / pixelStep)
    const timeValue = (pixelRatio * timeStep) + appState.constants.planningSchedule.hourStart

    const datePlanned = new Date(
      daySelected.getFullYear(),
      daySelected.getMonth(),
      daySelected.getDate(),
      0, timeValue, 0)

    if (item.type === 'interventions') {
      planningDispatch({ type: 'loading' })
      api.current.post('/planning/updateitv', { body: JSON.stringify({
        interventionId: item.interventionId,
        datePlanned: datePlanned,
        userIdPlanned: row.userId,
        dateStart: queryDate,
        shopId,
      }) })
        .then(payload => planningDispatch({ type: 'init', payload }))
        .catch(payload => notify(formatError(payload), { color: 'danger' }))
    }

    return e
  }, [appState.constants, shopId, queryDate, formatError, notify, planningDispatch])

  return (
    <Block.Content>
      <div className={join(style.planning, tooltipOpen && style.hideScroll)}>
        <PlanningRow
          disabled={planningState.status === STATUS.LOADING}
          onSlotClick={handleSlotClick}
          // onRowDoubleClick={handleRowDoubleClick}
          onDrop={handleOnDropPlanningItem}
          onTooltipOpen={setTooltipOpen}
          ColumnHeaderRender={({ column }) => column.label}
          ItemRender={ItemRender}
          TooltipRender={PlanningTooltipItem}
          RowHeaderRender={({ row }) => (
            <Link
              className={join(style.rowHeader, row.isLocked && style.locked)}
              disabled={row.isLocked}
              to={{
                pathname: `/planning/${row.userId}`,
                state: { day: queryDate ? new Date(queryDate) : new Date() }
              }}
            >
              {row.userName}
            </Link>)}
          columns={columns}
          columnExtractKey={(column) => column.key}
          rows={planningState.payload.rows}
          rowExtractKey={(row) => row.userId}
        />
      </div>
      <Legend.Block title={t({ id: 'legend.title' })} className={style.legend} titleClassName={style.title} contentClassName={style.content}>
        <Legend.Item label={t({ id: 'legend.planning-intervention-scheduled' })} className={join(style.interventions, style.scheduled)} />
        <Legend.Item label={t({ id: 'legend.planning-intervention-done' })} className={join(style.interventions, style.done)} />
        <Legend.Item label={t({ id: 'legend.planning-intervention-returned' })} className={join(style.interventions, style.returned)} />
        <Legend.Item label={t({ id: 'legend.planning-intervention-express' })} className={join(style.interventions, style.express)} />
        <Legend.Item label={t({ id: 'legend.planning-intervention-closed' })} className={join(style.interventions, style.closed)} />
        <Legend.Item label={t({ id: 'legend.planning-tasks' })} className={join(style.tasks)} />
        <Legend.Item label={t({ id: 'legend.planning-unavailabilities' })} className={join(style.unavailabilities)} />
      </Legend.Block>
    </Block.Content>
  )
}

function SharedPlannings ({
  tooltipOpen, setTooltipOpen,
  handleSlotClick,
  columns,
}) {
  const api = useApi()
  const location = useLocation()
  const { appState, } = useApp()
  const t = useT()
  const formatError = useFormatError()

  const query = new URLSearchParams(location.search)
  const queryDate = query.get('date')

  const [sharedPlannings, setSharedPlannings] = useState({ status: STATUS.INITIALIZING })

  useEffect(() => {
    let isSubscribed = true

    api.get('/sharedplannings/listlight')
      .then(payload => {
        const sharedPlannings = payload.map(sharedPlanning => ({
          status: STATUS.LOADING,
          groups: 1,
          items: [],
          rowRender: RowRenderLoading,
          ...sharedPlanning,
        }))

        isSubscribed && setSharedPlannings({ status: STATUS.LOADED, payload: sharedPlannings })

        isSubscribed && Promise.all(sharedPlannings.map(sharedPlanning =>
          api.get('/velocare/sharedPlanning', undefined, {
            sharedPlanningId: sharedPlanning.sharedPlanningId,
            dateStart: queryDate ? formatDate(new Date(queryDate)) : undefined,
          })
            .then(payload => isSubscribed && setSharedPlannings(sharedPlannings => ({
              status: sharedPlannings.status,
              payload: sharedPlannings.payload.map(_sharedPlanning => sharedPlanning === _sharedPlanning
                ? {
                  ..._sharedPlanning,
                  rowRender: undefined,
                  ...buildPlanningRow({
                    initialRow: { sharedInterventions: payload },
                    itemsPropertyName: ['sharedInterventions'],
                    canvasTimeStart: appState.constants.planningSchedule.hourStart,
                    canvasTimeEnd: appState.constants.planningSchedule.hourEnd,
                  })
                }
                : _sharedPlanning
              )
            })))
            .catch(payload => isSubscribed && setSharedPlannings(sharedPlannings => ({
              status: sharedPlannings.status,
              payload: sharedPlannings.payload.map(_sharedPlanning => sharedPlanning === _sharedPlanning
                ? {
                  ..._sharedPlanning,
                  rowRender: RowRenderError,
                  payload: formatError(payload),
                }
                : _sharedPlanning
              )
            }))
            )
        ))
          .then(() => setSharedPlannings(sharedPlannings => ({
            status: STATUS.LOADED, payload: sharedPlannings.payload
          })))
      })
      .catch(payload => isSubscribed && setSharedPlannings({ status: STATUS.ERROR, payload }))

    return () => { isSubscribed = false }
  }, [api, formatError, queryDate, appState.constants])

  return (
    <Block.Content>
      <div className={join(style.planning, tooltipOpen && style.hideScroll)}>
        <PlanningRow
          onSlotClick={handleSlotClick}
          onTooltipOpen={setTooltipOpen}
          ColumnHeaderRender={({ column }) => column.label}
          ItemRender={ItemRender}
          TooltipRender={PlanningTooltipItem}
          RowHeaderRender={({ row }) => row.name}
          columns={columns}
          columnExtractKey={(column) => column.key}
          rows={sharedPlannings.payload}
          rowExtractKey={row => row.sharedPlanningId}
        />
      </div>
      <Legend.Block title={t({ id: 'legend.title' })} className={style.legend} titleClassName={style.title} contentClassName={style.content}>
        <Legend.Item label={t({ id: 'legend.planning-intervention-scheduled' })} className={join(style.interventions, style.scheduled)} />
        <Legend.Item label={t({ id: 'legend.planning-intervention-done' })} className={join(style.interventions, style.done)} />
        <Legend.Item label={t({ id: 'legend.planning-intervention-returned' })} className={join(style.interventions, style.returned)} />
        <Legend.Item label={t({ id: 'legend.planning-intervention-closed' })} className={join(style.interventions, style.closed)} />
      </Legend.Block>
    </Block.Content>
  )
}
