function intersect (itm, arr) {
  return arr.find(_ => !(itm.timeStart + itm.duration <= _.timeStart || _.timeStart + _.duration <= itm.timeStart))
}

export function buildPlanningItem (item, canvasTimeStart, canvasTimeEnd, line) {
  const timeEnd = item.timeStart + item.duration
  const canvasDuration = canvasTimeEnd - canvasTimeStart

  const effectiveTimeStart = Math.max(item.timeStart, canvasTimeStart)
  const effectiveTimeEnd = Math.min(timeEnd, canvasTimeEnd)

  const timeStart = effectiveTimeStart - canvasTimeStart
  const timeStartPercent = timeStart * 100 / canvasDuration

  const itemDuration = effectiveTimeEnd - effectiveTimeStart
  const itemDurationPercent = itemDuration * 100 / canvasDuration

  return {
    ...item, line, timeEnd, timeStartPercent, durationPercent: itemDurationPercent
  }
}

export function buildPlanning (initialRows, itemsPropertyName, canvasTimeStart, canvasTimeEnd, dayFilter) {
  return initialRows.map(initialRow => buildPlanningRow({
    initialRow,
    itemsPropertyName,
    dayFilter,
    canvasTimeStart, canvasTimeEnd,
  }))
}

export function buildPlanningRow ({
  initialRow,
  itemsPropertyName,
  dayFilter,
  canvasTimeStart, canvasTimeEnd,
}) {
  const tzOffset = new Date().getTimezoneOffset()
  const elements = [].concat(itemsPropertyName).reduce((acc, item) =>
    acc.concat(initialRow[item]
      .map(_ => ({
        ..._,
        timeStart: _.timeStart - tzOffset,
        type: item,
      }))
    )
      .filter(_ => !dayFilter || _.datePlanned === dayFilter)
  , [])

  const row = {
    ...initialRow,
    groups: 0,
    items: []
  }

  while (elements.length !== 0) {
    const items = []
    row.groups++
    for (let i = elements.length - 1; i >= 0; i--) {
      const item = elements[i]
      if (item.timeStart > canvasTimeEnd || (item.timeStart + item.duration) < canvasTimeStart) {
        elements.splice(i, 1)
      } else if (!intersect(item, items)) {
        items.push(buildPlanningItem(item, canvasTimeStart, canvasTimeEnd, row.groups))
        elements.splice(i, 1)
      }
    }
    if (items.length > 0) {
      Array.prototype.push.apply(row.items, items)
    }
  }

  return row
}
