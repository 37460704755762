import { join, } from '@velogik/helper-classnames'

import style from './Planning.Item.module.scss'

export function Item ({ className, ...props }) {
  return <span className={join(style.Item, className)} {...props} />
}

Item.Text = function ({ className, ...props }) {
  return <span className={join(style.text, className)} {...props} />
}
