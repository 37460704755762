import { useState, useEffect, } from 'react'
import { useParams, useHistory, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Page, } from '@velogik/component-page'
import { Input, } from '@velogik/component-input'
import { SelectInput, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'

import { useHasProfile, } from 'hooks/use-user'

import { BackButton } from '../components/BackButton'

// TODO: Remind me why it's not in consts
const serviceOffers = ['basic', 'premium', 'custom', 'network']

const DEFAULT_VALUE = {}

export function ShopEdit() {
  const history = useHistory()
  const routeParams = useParams()

  const api = useApi()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const formatError = useFormatError()
  const isAdminVelocenter = useHasProfile('admin_velocenter')

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.INITIALIZING })

  const [networks, setNetworks] = useState({ status: STATUS.INITIALIZING })

  useEffect(() => {
    if (routeParams.shopId) {
      wait(api.get('/shops/details', undefined, { shopId: routeParams.shopId }))
        .then(payload => dataDispatch({ type: FORM_ACTION.INIT, value: payload }))
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
    } else {
      dataDispatch({ type: FORM_ACTION.INIT, value: DEFAULT_VALUE })
    }
  }, [api, dataDispatch, routeParams.shopId])

  useEffect(() => {
    wait(api.get('/networks/list'))
      .then(payload => setNetworks({ status: STATUS.LOADED, payload, }))
      .catch(payload => setNetworks({ status: STATUS.ERROR, payload, }))
  }, [api])

  function post () {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/shops/details', { body: JSON.stringify(dataState.current) }))
      .then(payload => {
        if (!routeParams.shopId) {
          history.replace(`/shops/${payload.shopId}/details`)
        } else {
          dataDispatch({ type: FORM_ACTION.INIT, value: payload })
          setPostState({ status: STATUS.LOADED, payload })
        }
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'partial' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Content>
                <Input name="shopName" type="text" label={t({ id: 'shopName.label' })} placeholder={t({ id: 'shopName.placeholder' })} />
                <Input name="shopEmail" type="text" label={t({ id: 'shopEmail.label' })} placeholder={t({ id: 'shopEmail.placeholder' })} />
                {isAdminVelocenter && networks.status === STATUS.INITIALIZING && <Block.Loader />}
                {isAdminVelocenter && networks.status === STATUS.LOADED && (
                  <SelectInput name="networkId"
                    label={t({ id: 'networkId.label' })}
                    placeholder={t({ id: 'networkId.placeholder', })}
                    options={networks.payload.map(option => ({
                      value: option.networkId,
                      label: option.networkName,
                    }))}
                  />
                )}
                {isAdminVelocenter && networks.status === STATUS.ERROR && <Alert>{formatError(networks.payload)}</Alert>}
                {isAdminVelocenter && <SelectInput name="serviceOffer"
                  label={t({ id: 'serviceOffer.label' })}
                  placeholder={t({ id: 'serviceOffer.placeholder' })}
                  options={serviceOffers.map(option => ({
                    value: option,
                    label: t({ id: 'serviceOffer.option' }, { option }),
                  }) )} />}
                <Input name="limitUserMax" type="text" label={t({ id: 'limitUserMax.label' })} placeholder={t({ id: 'limitUserMax.placeholder' })} />
                <Input name="chronocaisseIdentifier" type="text" label={t({ id: 'chronocaisseIdentifier.label' })} placeholder={t({ id: 'chronocaisseIdentifier.placeholder' })} />
                <Input name="chronocaisseApiKey" type="text" label={t({ id: 'chronocaisseApiKey.label' })} placeholder={t({ id: 'chronocaisseApiKey.placeholder' })} />
              </Block.Content>
            </Block>
          </Form.Content>

          {/* TODO: Success state */}
          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
