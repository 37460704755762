import { useState, useReducer, useEffect, } from 'react'
import { useHistory, useParams, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { AddressInput, } from '@velogik/component-address-input'
import { Banner, } from '@velogik/component-banner'
import { Button, } from '@velogik/component-button'
import { Block, } from '@velogik/component-block'
import { ButtonIcon, } from '@velogik/component-button-icon'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { ImageInput, } from '@velogik/component-image-input'
import { Input } from '@velogik/component-input'
import { useT, useIntl, useFormatError, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { Url, } from '@velogik/component-link'
import { Page, } from '@velogik/component-page'
import { PhoneInput, } from '@velogik/component-phone-input'
import { SelectInput, } from '@velogik/component-select-input'
import { join, } from '@velogik/helper-classnames'
import { wait, STATUS, } from '@velogik/helper-promise'
import { getFirstWeekDay, getLastWeekDay, formatMinutes, } from '@velogik/helper-date'
import { noop, arraying, } from '@velogik/helper-utils'

import { Accordion, } from 'components/Accordion'
import { Sheet, } from 'components/Sheet'
import { WeekScheduler, } from 'components/WeekScheduler'
import { BackButton } from 'components/BackButton'

import style from './ShopMarketplace.module.scss'

function calendarRulesReducer (state, action) {
  if (action.status === STATUS.ERROR) {
    return action
  }

  return {
    status: action.status || state.status,
    payload: {
      ...state.payload,
      ...(action.payload || {}),
      days: action.payload
        ? action.payload.days.map(day => ({
          ...day,
          slots: day.slots.map(slot => {
            const date = new Date(slot.date)
            return date.getHours() * 60 + date.getMinutes()
          })
        }))
        : state.payload ? state.payload.days : [],
      rows: action.payload
        ? arraying((action.payload.hourEnd - action.payload.hourStart) / action.payload.slotStep).map((_, i) => ({
          time: action.payload.hourStart + (action.payload.slotStep * i)
        }))
        : state.payload ? state.payload.rows : []
    }
  }
}

function buildWeek (date) {
  date.setHours(22)
  return {
    firstDay: getFirstWeekDay(date),
    lastDay: getLastWeekDay(date),
  }
}

export function ShopMarketplace () {
  const history = useHistory()
  const { shopId } = useParams()

  const api = useApi()
  const { appState, } = useApp()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const intl = useIntl()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })
  const [calendarRules, dispatchCalendarRules] = useReducer(calendarRulesReducer, { status: STATUS.INITIALIZING }) // TODO: deal with LOADING ~ INIT ~ ERROR STATUS

  const [currentWeek, setCurrentWeek] = useState(() => buildWeek(new Date()))

  useEffect(() => {
    wait(api.get('/shops/config', undefined, { shopId }))
      .then(value => dataDispatch({ type: FORM_ACTION.INIT, value: {
        ...value,
        daySlotDelay: appState.constants.shopDaySlotDelay.values.indexOf(value.daySlotDelay),
        dayHomeSlotDelay: appState.constants.shopDaySlotDelay.values.indexOf(value.dayHomeSlotDelay),
      } }))
      .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
  }, [api, dataDispatch, shopId, appState])

  useEffect(() => {
    wait(api.get('/shops/calendarrules', undefined, { shopId, dayStart: currentWeek.firstDay }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
  }, [api, shopId, currentWeek.firstDay])

  function post () {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/shops/config', { body: JSON.stringify({
      ...dataState.current,
      daySlotDelay: appState.constants.shopDaySlotDelay.values[dataState.current.daySlotDelay],
      dayHomeSlotDelay: appState.constants.shopDaySlotDelay.values[dataState.current.dayHomeSlotDelay],
    }) }))
      .then(payload => {
        dataDispatch({ type: FORM_ACTION.INIT, value: {
          ...payload,
          daySlotDelay: appState.constants.shopDaySlotDelay.values.indexOf(payload.daySlotDelay),
          dayHomeSlotDelay: appState.constants.shopDaySlotDelay.values.indexOf(payload.dayHomeSlotDelay),
        }, })
        setPostState({ status: STATUS.IDLE, payload })
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function picInputChange (file, initialValue) {
    return api.put('/shops/pic', { body: file }, { shopId, picType: initialValue.picType })
      .then(payload => ({ ...payload, picType: initialValue.picType }))
  }

  function changeWeek (direction) {
    const newDate = new Date(currentWeek.firstDay)
    newDate.setDate(direction === 'previous' ? newDate.getDate() - 7 : newDate.getDate() + 7)
    setCurrentWeek(buildWeek(newDate))
  }

  function toggleSlot (day, minutes) {
    if (!day.slots) {
      return
    }

    const slots = day.slots.indexOf(minutes) >= 0 ? day.slots.filter(_ => _ !== minutes) : day.slots.concat(minutes)

    dispatchCalendarRules({ status: STATUS.LOADING })
    // TODO: Posting rules can result to daychange on some days idk why
    return wait(api.post('/shops/calendarrules', { body: JSON.stringify({
      shopId,
      dayStart: currentWeek.firstDay,
      hourStart: '',
      hourEnd: '',
      days: [{ ...day, slots: slots.map(_ => formatMinutes(_)) }]
    }) }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
  }

  function changeSlotStep (slotStep) {
    // TODO: debounce ?
    dispatchCalendarRules({ status: STATUS.LOADING })
    wait(api.post('/shops/calendarslotstep', { body: JSON.stringify({
      shopId,
      dayStart: currentWeek.firstDay,
      hourStart: formatMinutes(calendarRules.payload.hourStart),
      hourEnd: formatMinutes(calendarRules.payload.hourEnd),
      slotStep: Number(slotStep),
    }) }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload}))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload}))
  }

  function changeHour (params) {
    dispatchCalendarRules({ status: STATUS.LOADING })
    return api.post('/shops/calendarslotstep', { body: JSON.stringify({
      shopId,
      dayStart: currentWeek.firstDay,
      hourStart: formatMinutes(params.hourStart || calendarRules.payload.hourStart),
      hourEnd: formatMinutes(params.hourEnd || calendarRules.payload.hourEnd),
      slotStep: Number(calendarRules.payload.slotStep),

    }) })
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
  }

  function copyWeek () {
    // TODO: loading
    wait(api.post('/shops/calendarweekrules', { body: JSON.stringify({
      shopId, day: currentWeek.firstDay,
    }) }))
    // TODO: then and catch ?
  }

  function emptyWeek () {
    dispatchCalendarRules({ status: STATUS.LOADING })
    // TODO: Posting rules can result to daychange on some days idk why
    return wait(api.post('/shops/calendarrules', { body: JSON.stringify({
      shopId,
      dayStart: currentWeek.firstDay,
      hourStart: '',
      hourEnd: '',
      days: calendarRules.payload.days.map(day => ({ ...day, slots: [], }))
    }) }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => dispatchCalendarRules({ status: STATUS.ERROR, payload }))
  }

  const shopRepairLocation = appState.constants.shopRepairLocation.reduce((obj, item) =>
    ({ ...obj, [item.key]: item.bit, })
  , {})

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        <Page.Header.Actions>
          {dataState.status === FORM_STATUS.INITIALIZED && (
            <Button
              tag={Url}
              href={`${dataState.current.frontDomain}/shops/${dataState.current.shopId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t({ id: 'actions.frontLink', })}
            </Button>
          )}
        </Page.Header.Actions>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'partial' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>

          <Form.Content className={style.formContent}>
            <Accordion id="main"
              className={style.main}
              title={<span>{t({ id: 'main.title' })}</span>}
              titleClass={style.sectionTitle}
              contentClass={style.sectionContent}
              forceDisplayOnDesktop={true}>
              <Block>
                <Block.Content>
                  <Input name="shopEmail" type="text" label={t({ id: 'shopEmail' })} />
                  <Input name="shopName" type="text" label={t({ id: 'shopName' })} />
                  <Input name="servicesDesc" type="textarea" label={t({ id: 'servicesDesc' })} />

                  <PhoneInput name="phoneNumber" type="phone"
                    label={t({ id: 'phoneNumber' })}
                    displayInitialValueAsLocalNumber={true}
                    defaultCountry={intl.locale.toUpperCase()} />

                  {/* TODO: address component */}
                  <AddressInput name="addressFull" label={t({ id: 'addressFull' })} />
                  <Input name="addressComplement" label={t({ id: 'addressComplement' })} />

                  <div>
                    <Label>{t({ id: 'repairLocation.label' })}</Label>
                    <Input name="repairLocation" type="radio" label={t({ id: 'repairLocation.twice' })} id="repairLocation-twice" value={shopRepairLocation.shop | shopRepairLocation.home} />
                    <Input name="repairLocation" type="radio" label={t({ id: 'repairLocation.shop' })} id="repairLocation-shop" value={shopRepairLocation.shop} />
                    <Input name="repairLocation" type="radio" label={t({ id: 'repairLocation.home' })} id="repairLocation-home" value={shopRepairLocation.home} />
                  </div>

                  <Input name="repairRange" type="range" label={t({ id: 'repairRange' })} id="repairRange" {...appState.constants.shopRepairRange} />
                  <Input name="travelFees" type="range" label={t({ id: 'travelFees' })} id="travelFees" {...appState.constants.shopTravelFees} />
                </Block.Content>
              </Block>
            </Accordion>

            <Accordion id="pictures"
              className={style.pictures}
              title={<span>{t({ id: 'pictures.title' })}</span>}
              titleClass={style.sectionTitle}
              contentClass={style.sectionContent}
              forceDisplayOnDesktop={true}>
              <Block>
                <Block.Content>
                  <ImageInput name="profilePicture"
                    label={t({ id: 'profilePicture.label' })}
                    buttonLabel={t({ id: 'profilePicture.button' })}
                    accept="image/x-png,image/jpeg"
                    onChange={noop}
                    onPictureChange={picInputChange} />
                  <ImageInput name="descPicture"
                    label={t({ id: 'descPicture.label' })}
                    buttonLabel={t({ id: 'descPicture.button' })}
                    accept="image/x-png,image/jpeg"
                    onChange={noop}
                    onPictureChange={picInputChange} />
                </Block.Content>
              </Block>
            </Accordion>

            <Accordion id="equipmentType"
              className={style.equipmentType}
              title={<span>{t({ id: 'equipmentType.title' })}</span>}
              titleClass={style.sectionTitle}
              contentClass={style.sectionContent}
              forceDisplayOnDesktop={true}>
              <Block>
                <Block.Content>
                  <div>
                    <Label>{t({ id: 'repairEquipments.label' })}</Label>
                    {appState.constants.shopRepairEquipments.map(repairEquipment =>
                      <Input name="repairEquipments"
                        id={`repairEquipments.${repairEquipment.key}`}
                        value={repairEquipment.bit}
                        type="bitmask"
                        key={`repairEquipments.${repairEquipment.key}`}
                        label={t({ id: `repairEquipments.${repairEquipment.key}` })} />
                    )}
                  </div>

                  <div>
                    <Label>{t({ id: 'repairSpecialization.label' })}</Label>
                    {appState.constants.shopRepairSpecialization.map(repairSpecialization =>
                      <Input name="repairSpecialization"
                        id={`repairSpecialization.${repairSpecialization.key}`}
                        value={repairSpecialization.bit}
                        type="bitmask"
                        key={`repairSpecialization.${repairSpecialization.key}`}
                        label={t({ id: `repairSpecialization.${repairSpecialization.key}` })} />
                    )}
                  </div>
                </Block.Content>
              </Block>
            </Accordion>

            <Accordion id="rdv"
              className={style.rdv}
              title={<span>{t({ id: 'rdv.title' })}</span>}
              titleClass={style.sectionTitle}
              contentClass={style.sectionContent}
              forceDisplayOnDesktop={true}>

              <Block>
                <Block.Content>
                  <div className={style.searchVisible}>
                    <Label>{t({ id: 'searchVisible.label' })}</Label>
                    <Input name="searchVisible" type="radio" label={t({ id: 'searchVisible.yes' })} id="searchVisible-yes" value={true} />
                    <Input name="searchVisible" type="radio" label={t({ id: 'searchVisible.no' })} id="searchVisible-no" value={false} />
                  </div>
                </Block.Content>
                {dataState.current.bookableLocation !== undefined && <Block.Content>
                  <div className={style.bookableLocation}>
                    <Label>{t({ id: 'bookableLocation.label' })}</Label>
                    {appState.constants.shopRepairLocation.map(repairLocation =>
                      <Input name="bookableLocation"
                        id={`bookableLocation.${repairLocation.key}`}
                        value={repairLocation.bit}
                        type="bitmask"
                        key={`bookableLocation.${repairLocation.key}`}
                        label={t({ id: 'bookableLocation.value' }, { type: repairLocation.key })} />
                    )}
                  </div>
                </Block.Content>}
              </Block>

              <Block>
                <Block.Content>
                  <div className={style.repairServices}>
                    <Label>{t({ id: 'repairServices.label' })}</Label>
                    {appState.constants.shopServices.map(shopService =>
                      <Input name="repairServices"
                        id={`repairServices.${shopService.key}`}
                        value={shopService.bit}
                        type="bitmask"
                        key={`repairServices.${shopService.key}`}
                        label={t({ id: `repairServices.${shopService.key}` })} />
                    )}
                  </div>
                </Block.Content>
              </Block>

              <Block>
                <Block.Content>
                  <div>
                    <Label>{t({ id: 'bookableServices.label' })}</Label>
                    {appState.constants.shopServices.map(shopService =>
                      <Input name="bookableServices"
                        id={`bookableServices.${shopService.key}`}
                        value={shopService.bit}
                        type="bitmask"
                        key={`bookableServices.${shopService.key}`}
                        label={t({ id: `bookableServices.${shopService.key}` })} />
                    )}
                  </div>

                  <div>
                    <Label>{t({ id: 'diagOnly.label' })}</Label>
                    <Input name="diagOnly" type="radio" label={t({ id: 'diagOnly.yes' })} id="diagOnly-yes" value={true} />
                    <Input name="diagOnly" type="radio" label={t({ id: 'diagOnly.no' })} id="diagOnly-no" value={false} />
                  </div>
                </Block.Content>
              </Block>
            </Accordion>

            <Accordion id="timeInfos"
              className={style.timeInfos}
              title={<span>{t({ id: 'agenda.title' })}</span>}
              titleClass={style.sectionTitle}
              contentClass={style.sectionContent}
              forceDisplayOnDesktop={true}>

              <Block>
                <Block.Content className={style.scheduler}>
                  <WeekScheduler
                    name="openingHours"
                    label={t({ id: 'openingHours.label' })}/>

                  <Input name="dailySlots" type="range" label={t({ id: 'dailySlots' })}
                    {...appState.constants.shopDailySlots} />
                  <Input name="dailyHomeSlots" type="range" label={t({ id: 'dailyHomeSlots' })}
                    {...appState.constants.shopDailySlots} />
                  <Input name="daySlotDelay" type="range" label={t({ id: 'daySlotDelay' })}
                    renderValue={value => t({ id: 'daySlotDelay.value' }, { value })}
                    min={0} max={appState.constants.shopDaySlotDelay.values.length - 1} step={1}>
                  </Input>
                  <Input name="dayHomeSlotDelay" type="range" label={t({ id: 'dayHomeSlotDelay' })}
                    renderValue={value => t({ id: 'daySlotDelay.value' }, { value })}
                    min={0} max={appState.constants.shopDaySlotDelay.values.length - 1} step={1}>
                  </Input>
                  <Input name="travelTime" type="range" label={t({ id: 'travelTime' })}
                    {...appState.constants.shopTravelTime  } />
                  <Input name="depositTerms" type="textarea" label={t({ id: 'depositTerms' })} />
                  <Input name="returnTerms" type="textarea" label={t({ id: 'returnTerms' })} />
                </Block.Content>
              </Block>

              <Block>
                {[STATUS.INITIALIZING].includes(calendarRules.status) && <Block.Loader />}
                {[STATUS.LOADED, STATUS.LOADING].includes(calendarRules.status) && (
                  <Block.Content className={style.calendar}>
                    <Label className={style.label}>{t({ id: 'calendar.label' })}</Label>
                    {/* <Input type="text" slots.hourEnd */}
                    <div className={style.parameters}>
                      <div className={style.currentWeek}>
                        <ButtonIcon src={require('assets/icons/chevron-open-black.svg').default} onClick={() => changeWeek('previous')} />
                        <span>{t({ id: 'currentWeek' }, currentWeek)}</span>
                        <ButtonIcon src={require('assets/icons/chevron-close-black.svg').default} onClick={() => changeWeek('after')} />
                      </div>

                      <div className={style.hours}>
                        <SelectInput name="slots.hourStart"
                          placeholder={t({ id: 'infos.shopId.placeholder' })}
                          value={calendarRules.payload.hourStart}
                          options={arraying(25)
                            .map((_, time) => time * 60)
                            .filter(hour => hour < calendarRules.payload.hourEnd)
                            .map(value => ({ label: formatMinutes(value), value, }))
                          }
                          onChange={({ target: { value } }) => changeHour({ hourStart: value })} />
                        <SelectInput name="slots.hourEnd"
                          placeholder={t({ id: 'calendar.shopId.placeholder' })}
                          value={calendarRules.payload.hourEnd}
                          options={arraying(25)
                            .map((_, time) => time * 60)
                            .filter(hour => hour > calendarRules.payload.hourStart)
                            .map(value => ({ label: formatMinutes(value), value, }))
                          }
                          onChange={({ target: { value } }) => changeHour({ hourEnd: value })} />
                      </div>

                      <div className={style.steps}>
                        <Input name="slotStep" className={style.step} type="radio"
                          disabled={calendarRules.status === STATUS.LOADING}
                          onChange={({ target: { value } }) => changeSlotStep(value)}
                          matchValue={calendarRules.payload.slotStep}
                          label={t({ id: 'slotStep.30' })}
                          labelClassName={style.label}
                          value={30} />
                        <Input name="slotStep" className={style.step} type="radio"
                          disabled={calendarRules.status === STATUS.LOADING}
                          onChange={({ target: { value } }) => changeSlotStep(value)}
                          matchValue={calendarRules.payload.slotStep}
                          label={t({ id: 'slotStep.60' })}
                          labelClassName={style.label}
                          value={60} />
                      </div>
                    </div>

                    <Sheet className={style.sheet}
                      columns={calendarRules.payload.days}
                      columnExtractKey={column => `head-${column.day}`}
                      columnHeaderRender={(column, columnIndex) => (
                        <div className={style.columnHeader}>
                          {t({ id: 'calendar.header' }, {
                            date: new Date(column.day),
                            title: chunk => <div>{chunk}</div>,
                            subTitle: chunk => <div>{chunk}</div>,
                          })}
                        </div>
                      )}
                      rows={calendarRules.payload.rows}
                      rowExtractKey={(_, i) => `line-${i}`}
                      headHeaderRender={() => (
                        <div className={style.rowHeader}>
                          {formatMinutes(calendarRules.payload.rows[0].time)}
                        </div>
                      )}
                      dataRender={(row, column) => (
                        <div className={join(style.data, column.slots.includes(row.time) && style.selected)}>
                          <span>
                            {formatMinutes(row.time)}
                          </span>
                        </div>
                      )}
                      onDataClick={(e, row, column) => toggleSlot(column, row.time)}
                      footer={() => (
                        <div className={style.legend}>
                          <div className={join(style.item, style.selected)}>{t({ id: 'calendar.legend.selected' })}</div>
                          <div className={join(style.item, style.free)}>{t({ id: 'calendar.legend.free' })}</div>
                        </div>
                      )} />
                  </Block.Content>
                )}
                <Block.Actions>
                  <Button onClick={() => copyWeek()} disabled={[STATUS.LOADING, STATUS.ERROR].includes(calendarRules.status)}>{t({ id: 'copyWeek' })}</Button>
                  <Button onClick={() => emptyWeek()} outlined disabled={[STATUS.LOADING, STATUS.ERROR].includes(calendarRules.status)}>{t({ id: 'emptyWeek' })}</Button>
                </Block.Actions>
              </Block>
            </Accordion>
          </Form.Content>

          {calendarRules.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(calendarRules.payload)}
            actions={[
              { label: t({ id: 'dirty.retry' }), outlined: true, onClick: () => history.go(0) }
            ]}
          />}
          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.cancel' }), type: 'button', onClick: () => dataDispatch({ type: FORM_ACTION.RESET, }), outlined: true, disabled: postState.status === STATUS.LOADING, },
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING, },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
