import { useApp, } from '@velogik/component-app'

export function useHasService(...props) {
  const { appState: { user } } = useApp()

  return user && (!props.length || props.includes(user.serviceOffer))
}

export function useHasProfile(...props) {
  const { appState: { user } } = useApp()

  return user && (!props.length || props.includes(user.profileName))
}
