import { useT, } from '@velogik/component-intl'

import { Breadcrumb, } from 'components/Breadcrumb'

export function InterventionBreadcrumb ({ status: interventionStatus, className, }) {
  const t = useT('/interventions/:interventionId/details', true)

  /**
   * Check if intervention is loaded then if current intervention is in one of status.
   * if `'any'` in present in status, only check if loaded
   * @param  {...String} status list of status to check
   * @returns true if loaded and matching whith one of status
   */
  function isStatus (...status) {
    return status && interventionStatus &&
      (status.includes('any') || status.includes(interventionStatus))
  }

  return (
    <Breadcrumb className={className}>
      {isStatus('any') && <Breadcrumb.Item
        active={isStatus('new', 'pending', 'scheduled', 'done', 'returned', 'closed', 'canceled', 'express')}
        color="blue"
        focus={isStatus('new')}>
        <Breadcrumb.Icon name="pencil" />
        <Breadcrumb.Label>{t({ id: 'breadcrumb.new.label' })}</Breadcrumb.Label>
      </Breadcrumb.Item>}
      {isStatus('canceled') && (
        <Breadcrumb.Item
          active
          color="red"
          focus={isStatus('canceled')}>
          <Breadcrumb.Icon name="forbidden" />
          <Breadcrumb.Label>{t({ id: 'breadcrumb.canceled.label' })}</Breadcrumb.Label>
        </Breadcrumb.Item>
      )}
      {isStatus('pending') && (
        <Breadcrumb.Item
          active
          color="blue"
          focus={isStatus('pending')}>
          <Breadcrumb.Icon name="clock" />
          <Breadcrumb.Label>{t({ id: 'breadcrumb.pending.label' })}</Breadcrumb.Label>
        </Breadcrumb.Item>
      )}
      {!isStatus('canceled', 'pending', 'express') && (
        <Breadcrumb.Item
          active={isStatus('scheduled', 'done', 'returned', 'closed')}
          color="blue"
          focus={isStatus('scheduled')}>
          <Breadcrumb.Icon name="clock" />
          <Breadcrumb.Label>{t({ id: 'breadcrumb.scheduled.label' })}</Breadcrumb.Label>
        </Breadcrumb.Item>
      )}
      {!isStatus('canceled', 'express') && (
        <Breadcrumb.Item
          active={isStatus('done', 'returned', 'closed')}
          color="green"
          focus={isStatus('done')}>
          <Breadcrumb.Icon name="done" />
          <Breadcrumb.Label>{t({ id: 'breadcrumb.done.label' })}</Breadcrumb.Label>
        </Breadcrumb.Item>
      )}
      {!isStatus('canceled', 'express') && (
        <Breadcrumb.Item
          active={isStatus('returned', 'closed')}
          color="blue"
          focus={isStatus('returned')}>
          <Breadcrumb.Icon name="money" />
          <Breadcrumb.Label>{t({ id: 'breadcrumb.returned.label' })}</Breadcrumb.Label>
        </Breadcrumb.Item>
      )}
      {isStatus('express') && (
        <Breadcrumb.Item
          active={isStatus('express', 'closed')}
          color="blue"
          focus={isStatus('express')}>
          <Breadcrumb.Icon name="money" />
          <Breadcrumb.Label>{t({ id: 'breadcrumb.express.label' })}</Breadcrumb.Label>
        </Breadcrumb.Item>
      )}
      {!isStatus('canceled') && (
        <Breadcrumb.Item
          active={isStatus('closed')}
          color="blue"
          focus={isStatus('closed')}>
          <Breadcrumb.Icon name="folder" />
          <Breadcrumb.Label>{t({ id: 'breadcrumb.closed.label' })}</Breadcrumb.Label>
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  )
}
