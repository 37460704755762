import { useEffect, useState, useMemo, useCallback, } from 'react'
import { Link, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Filters, } from '@velogik/component-filters'
import { useT, useFormatError, } from '@velogik/component-intl'
import { Loading, } from '@velogik/component-loading'
import { Modal, } from '@velogik/component-modal'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'

import { BackButton, } from '../components/BackButton'
import { Result, } from '../components/Result'

import style from './SelectEquipment.module.scss'

const filters = [
  { name: 'filter', type: 'text' },
]

export function SelectEquipment () {
  const api = useApi()
  const { appState, } = useApp()
  const t = useT()
  const formatError = useFormatError()
  const location = useLocation()

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })
  const [selectedEquipment, setSelectedEquipment] = useState(undefined)

  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get('userId') || ''
  const clientId = searchParams.get('clientId') || ''

  const columns = useMemo(() => [
    { name: 'velocenterIdentifier', component: ({ item, }) => <span className={style.velocenterIdentifier}>{ item.velocenterIdentifier }</span> },
    { name: 'identification', component: ({ item }) => t({ id: 'result.item.identification'}, {
      system: (appState.constants.identificationSystem.find(type => type.value === item.identificationSystem) || {}).key,
      code: item.identificationCode || null,
    }) },
    { name: 'brand', component: ({ item }) => item.brandName || item.brand || null },
    { name: 'customName', component: ({ item }) => item.customName || null },
    { name: 'clientName', component: ({ item }) => t({ id: 'result.item.clientName' }, item.currentClient)},
    { name: 'btn.select', component: ({ item, }) => clientId
      ? <Button
        tag={Link}
        to={location => {
          const searchParams = new URLSearchParams(location.search)
          return {
            pathname: '/interventions/new',
            search: new URLSearchParams({
              ...searchParams,
              userId: searchParams.get('userId') || '',
              clientId,
              equipmentId: item.equipmentId,
            }).toString(),
          }
        }}
      >
        {t({ id: 'result.item.btn.select' })}
      </Button>
      : <Button onClick={() => setSelectedEquipment(item)}>{t({ id: 'result.item.btn.select' })}</Button> },
  ], [appState.constants, t, clientId])

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    wait(api.get('/interventions/equipment', undefined, {
      filter: query.get('filter')
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location.search])

  const handleSelectClientLink = useCallback((location) => {
    const searchParams = new URLSearchParams(location.search)
    const clientId = searchParams.get('clientId') || ''

    if (clientId) {
      return {
        ...location,
        pathname: '/equipments/new',
      }
    } else {
      searchParams.set('equipmentId', 'unknown')
      return {
        ...location,
        pathname: '/interventions/new',
        search: searchParams.toString(),
      }
    }
  }, [])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        <Page.Header.Actions>
          <Button tag={Link} to={handleSelectClientLink}>{t({ id: 'notFound' })}</Button>
        </Page.Header.Actions>
      </Page.Header>

      <Page.Content>
        {dataState.status === STATUS.ERROR && <Alert>{formatError(dataState.payload)}</Alert>}
        {dataState.status === STATUS.INITIALIZING && <Loading>{t({ id: 'initializing' })}</Loading>}
        {dataState.status === STATUS.LOADED && <Filters
          data={dataState} filters={filters} />}
        {dataState.status === STATUS.LOADED && <Result
          keyExtractor={item => item.equipmentId}
          data={dataState} columns={columns}
          className={style.content} />}
      </Page.Content>

      {selectedEquipment && <Modal isOpen={Boolean(selectedEquipment)} toggle={() => setSelectedEquipment()}>
        <Block className={style.selectClient}>
          <Block.Title className={style.title}>{t({ id: 'selectClient.title' })}</Block.Title>
          <Block.Content className={style.content}>
            <div>{t({ id: 'selectClient.disclaimer' }, selectedEquipment.currentClient)}</div>
          </Block.Content>
          <Block.Actions className={style.blockActions}>
            <Button
              tag={Link}
              to={{
                pathname: '/interventions/new',
                search: new URLSearchParams({
                  userId,
                  equipmentId: selectedEquipment.equipmentId,
                }).toString(),
              }}
              outlined
            >
              {t({ id: 'selectClient.other' })}
            </Button>
            <Button
              tag={Link}
              to={{
                pathname: '/interventions/new',
                search: new URLSearchParams({
                  userId,
                  equipmentId: selectedEquipment.equipmentId,
                  clientId: selectedEquipment.currentClient.clientId,
                }).toString(),
              }}
            >
              {t({ id: 'selectClient.confirm' })}
            </Button>
          </Block.Actions>
        </Block>
      </Modal>}
    </Page>
  )
}
