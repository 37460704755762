import { ShopList, } from './ShopList'
import { ShopEdit, } from './ShopEdit'
import { ShopLegal, } from './ShopLegal'
import { ShopMarketplace, } from './ShopMarketplace'
import { ShopPieces, } from './ShopPieces'

export const Shop = {
  List: ShopList,
  Edit: ShopEdit,
  Legal: ShopLegal,
  Marketplace: ShopMarketplace,
  Pieces: ShopPieces,
}
