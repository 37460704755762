import { useState, useEffect, useReducer, } from 'react'
import { useParams, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { ButtonIcon, } from '@velogik/component-button-icon'
import { useT, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { join, } from '@velogik/helper-classnames'
import { getFirstWeekDay, getLastWeekDay, formatMinutes, } from '@velogik/helper-date'
import { wait, STATUS, } from '@velogik/helper-promise'
import { arraying, } from '@velogik/helper-utils'

import { Sheet, } from 'components/Sheet'

import style from './UserEdit.Unavailabilities.module.scss'

export function Unavailabilities ({ disabled, errorHandler }) {
  const api = useApi()
  const routeParams = useParams()
  const t = useT('unavailabilities')

  const [calendarRules, dispatchCalendarRules] = useReducer(calendarRulesReducer, { status: STATUS.INITIALIZING }) // TODO: deal with LOADING ~ INIT ~ ERROR STATUS
  const [currentWeek, setCurrentWeek] = useState(() => buildWeek(new Date()))

  useEffect(() => {
    wait(api.get('/users/calendarrules', undefined, { userId: routeParams.userId, dayStart: currentWeek.firstDay }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => errorHandler({ status: STATUS.ERROR, payload }))
  }, [api, routeParams.userId, currentWeek.firstDay, errorHandler])

  function changeWeek (direction) {
    const newDate = new Date(currentWeek.firstDay)
    newDate.setDate(direction === 'previous' ? newDate.getDate() - 7 : newDate.getDate() + 7)
    setCurrentWeek(buildWeek(newDate))
  }

  function toggleSlot (day, minutes) {
    if (!day.slots || !day.slots[minutes]) {
      return
    }

    const slot = day.slots[minutes]
    const slots = {
      ...day.slots,
      [minutes]: {
        ...slot,
        isUserAvailable: !slot.isUserAvailable
      }
    }

    dispatchCalendarRules({ status: STATUS.LOADING })
    // TODO: Posting rules can result to daychange on some days idk why
    return wait(api.post('/users/calendarrules', { body: JSON.stringify({
      userId: routeParams.userId,
      dayStart: currentWeek.firstDay,
      hourStart: '',
      hourEnd: '',
      days: [{ ...day, slots: Object.values(slots) }]
    }) }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => errorHandler({ status: STATUS.ERROR, payload }))
  }

  function copyWeek () {
    // TODO: loading
    wait(api.post('/users/calendarweekrules', { body: JSON.stringify({
      userId: routeParams.userId, day: currentWeek.firstDay,
    }) }))
      .catch(payload => errorHandler({ status: STATUS.ERROR, payload }))
  }

  function emptyWeek () {
    dispatchCalendarRules({ status: STATUS.LOADING })
    // TODO: Posting rules can result to daychange on some days idk why
    return wait(api.post('/users/calendarrules', { body: JSON.stringify({
      userId: routeParams.userId,
      dayStart: currentWeek.firstDay,
      hourStart: '',
      hourEnd: '',
      days: calendarRules.payload.days.map(day => ({ ...day, slots: [], }))
    }) }))
      .then(payload => dispatchCalendarRules({ status: STATUS.LOADED, payload }))
      .catch(payload => errorHandler({ status: STATUS.ERROR, payload }))
  }

  return (
    <Block className={style.calendar}>
      {[STATUS.INITIALIZING].includes(calendarRules.status) && <Block.Loader />}
      {[STATUS.LOADED, STATUS.LOADING].includes(calendarRules.status) && (
        <Block.Content className={style.content}>
          <Label className={style.label}>{t({ id: 'label' })}</Label>
          {/* <Input type="text" slots.hourEnd */}
          <div className={style.parameters}>
            <div className={style.currentWeek}>
              <ButtonIcon src={require('assets/icons/chevron-open-black.svg').default} onClick={() => changeWeek('previous')} />
              <span>{t({ id: 'currentWeek' }, currentWeek)}</span>
              <ButtonIcon src={require('assets/icons/chevron-close-black.svg').default} onClick={() => changeWeek('after')} />
            </div>
          </div>
          <Sheet className={style.sheet}
            columns={calendarRules.payload.days}
            columnExtractKey={column => `head-${column.day}`}
            columnHeaderRender={(column, columnIndex) => (
              <div className={style.columnHeader}>
                {t({ id: 'calendar.header' }, {
                  date: new Date(column.day),
                  title: chunk => <div>{chunk}</div>,
                  subTitle: chunk => <div>{chunk}</div>,
                })}
              </div>
            )}
            rows={calendarRules.payload.rows}
            rowExtractKey={(_, i) => `line-${i}`}
            headHeaderRender={() => (
              <div className={style.rowHeader}>
                {formatMinutes(calendarRules.payload.rows[0].time)}
              </div>
            )}
            dataRender={(row, column) => (
              <div className={join(
                style.data,
                column.slots[row.time] && column.slots[row.time].isUserAvailable && style.selected,
                !column.slots[row.time] && style.disabled
              )}>
                <span>
                  {formatMinutes(row.time)}
                </span>
              </div>
            )}
            onDataClick={(e, row, column) => toggleSlot(column, row.time)}
            footer={() => (
              <div className={style.legend}>
                <div className={join(style.item, style.selected)}>{t({ id: 'calendar.legend.selected' })}</div>
                <div className={join(style.item, style.free)}>{t({ id: 'calendar.legend.free' })}</div>
                <div className={join(style.item, style.closed)}>{t({ id: 'calendar.legend.closed' })}</div>
              </div>
            )} />
        </Block.Content>
      )}
      <Block.Actions>
        <Button onClick={() => copyWeek()} disabled={[STATUS.LOADING, STATUS.ERROR].includes(calendarRules.status)}>{t({ id: 'copyWeek' })}</Button>
        <Button onClick={() => emptyWeek()} outlined disabled={[STATUS.LOADING, STATUS.ERROR].includes(calendarRules.status)}>{t({ id: 'emptyWeek' })}</Button>
      </Block.Actions>
    </Block>
  )
}

function calendarRulesReducer (state, action) {
  if (action.status === STATUS.ERROR) {
    return action
  }

  return {
    status: action.status || state.status,
    payload: {
      ...state.payload,
      ...(action.payload || {}),
      days: action.payload
        ? action.payload.days.map(day => ({
          ...day,
          slots: day.slots.reduce((acc, slot) => {
            const date = new Date(slot.date)
            return { ...acc, [date.getHours() * 60 + date.getMinutes()]: slot }
          }, {})
        }))
        : state.payload ? state.payload.days : [],
      rows: action.payload
        ? arraying((action.payload.hourEnd - action.payload.hourStart) / action.payload.slotStep).map((_, i) => ({
          time: action.payload.hourStart + (action.payload.slotStep * i)
        }))
        : state.payload ? state.payload.rows : []
    }
  }
}

function buildWeek (date) {
  date.setHours(22)
  return {
    firstDay: getFirstWeekDay(date),
    lastDay: getLastWeekDay(date),
  }
}
