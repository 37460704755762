import { useState, } from 'react'
import { useHistory, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'

import { BackButton, } from '../components/BackButton'

const DEFAULT_VALUE = {
  networkName: ''
}

export function NetworkNew() {
  const history = useHistory()

  const api = useApi()
  const t = useT()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer(FORM_STATUS.INITIALIZED, DEFAULT_VALUE)
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  function post (additionalParams = {}) {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/networks/details', { body: JSON.stringify({
      ...dataState.current,
      ...additionalParams,
    }) }))
      .then(() => history.replace('/admin/networks'))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Title>{t({ id: 'title' })}</Block.Title>
              <Block.Content>
                <Input
                  name="networkName"
                  type="text"
                  label={t({ id: 'networkName.label' })}
                  placeholder={t({ id: 'networkName.placeholder' })}
                />
              </Block.Content>
            </Block>
          </Form.Content>

          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[postState.payload.code === 'preventUpdate'
              ? { label: t({ id: 'dirty.forceSave' }), onClick: () => post({ force: postState.payload.reason })}
              : { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && postState.status !== STATUS.LOADING && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              // TODO: reset button should cancel and back to ???
              { label: t({ id: 'dirty.cancel' }), type: 'button', onClick: () => dataDispatch({ type: FORM_ACTION.RESET, }), outlined: true, disabled: postState.status === STATUS.LOADING, },
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING, },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
