import { useEffect, useState, useReducer, useRef, useCallback, } from 'react'
import { Link as RRDLink, useLocation, useParams, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { useNotify, } from '@velogik/component-notifications'
import { Page, } from '@velogik/component-page'
import { join } from '@velogik/helper-classnames'
import { getFirstWeekDay, formatDate, formatMinutes, } from '@velogik/helper-date'
import { wait, STATUS, } from '@velogik/helper-promise'
import { arraying, } from '@velogik/helper-utils'
import { useT, useFormatError, } from '@velogik/component-intl'

import { DayInput, } from 'components/DayInput'
import { Legend, } from 'components/Legend'
import { PlanningCol, } from 'components/Planning'
import { ItemRender, } from 'components/Planning.helpers'
import { PlanningModalItem, } from 'components/PlanningModalItem'
import { PlanningTooltipItem, } from 'components/PlanningTooltipItem'
import { buildPlanning, } from 'helpers/planning'
import { useHasProfile } from 'hooks/use-user'

import style from './Planning.User.module.scss'

function planningReducer (state, action) {
  switch (action.type) {
    case 'init': {
      const dayStart = action.dayStart
      const dateStart = new Date(dayStart).getDate()

      const filteredResult = action.payload.result.find(row => Number(row.userId) === Number(state.userId))

      return {
        ...state,
        status: STATUS.LOADED,
        initial: action.payload.result,

        payload: {
          ...state.payload,
          user: filteredResult,
          columns: arraying(7).map((_, i) => {
            const currentDate = formatDate(new Date(dayStart).setDate(dateStart + i))
            const { interventions, tasks, ...planning } = buildPlanning(
              [filteredResult],
              ['interventions', 'tasks', 'unavailabilities'],
              state.constants.planningSchedule.hourStart,
              state.constants.planningSchedule.hourEnd,
              currentDate,
            )[0]

            return {
              key: `column.${i}`,
              label: currentDate,
              ...planning
            }
          }),
        },
      }
    }
    default: return state
  }
}

function planningInitializer ({ userId, constants, }) {
  const nbRows = (constants.planningSchedule.hourEnd - constants.planningSchedule.hourStart) / 60
  return {
    status: STATUS.INITIALIZING,
    userId,
    payload: {
      user: {
        userName: undefined
      },
      rows: arraying(nbRows).map((_, i) => ({
        key: `row.${i}`,
        label: formatMinutes(constants.planningSchedule.hourStart + (60 * i)),
      })),
      columnExtractKey: column => column.key,
      rowExtractKey: row => row.key,
    },
    initial: [],
    constants,
  }
}

export function PlanningUser () {
  const { appState, } = useApp()
  const api = useRef(useApi())
  const t = useT()
  const formatError = useFormatError()
  const { notify, } = useNotify()
  const location = useLocation()
  const { userId } = useParams()
  const isReader = useHasProfile('reader')

  const [daySelected, setDaySelected] = useState(location.state && location.state.day ? new Date(location.state.day) : new Date())
  const [confirmTransition, setConfirmTransition] = useState()
  const [tooltipOpen, setTooltipOpen] = useState(null)

  const [planningState, planningDispatch] = useReducer(
    planningReducer,
    { constants: appState.constants, userId, },
    planningInitializer,
  )

  function handleSlotClick (e) {
    return (e.type !== 'unavailbilities') ? setConfirmTransition(e) : e
  }

  useEffect(() => {
    const weekSelected = getFirstWeekDay(daySelected)
    wait(api.current.get('/planning/view', undefined, {
      userId,
      dateStart: formatDate(weekSelected),
      dateEnd: formatDate(new Date(weekSelected).setDate(weekSelected.getDate() + 7)),
    }))
      .then(payload => planningDispatch({ type: 'init', payload, dayStart: weekSelected }))
      // TODO: display this error if happen
      .catch(payload => planningDispatch({ type: 'error', payload }))
  }, [userId, daySelected])

  const handleOnDropPlanningItem = useCallback((e, column) => {
    e.preventDefault()

    const item = JSON.parse(e.dataTransfer.getData('text/plain'))
    const weekSelected = getFirstWeekDay(daySelected)

    const dayTarget = new Date(column.label)

    const rect = e.currentTarget.getBoundingClientRect()

    const cursorPosition = e.clientY - rect.top

    const timeStep = appState.constants.planningSchedule.timePickerStep
    const timeRange = appState.constants.planningSchedule.hourEnd - appState.constants.planningSchedule.hourStart
    const timeSteps = timeRange / timeStep

    const pixelRange = rect.height
    const pixelStep = pixelRange / timeSteps
    const pixelRatio = Math.round(cursorPosition / pixelStep)
    const timeValue = (pixelRatio * timeStep) + appState.constants.planningSchedule.hourStart

    const datePlanned = new Date(
      dayTarget.getFullYear(),
      dayTarget.getMonth(),
      dayTarget.getDate(),
      0, timeValue, 0)

    if (item.type === 'interventions') {
      planningDispatch({ type: 'loading' })
      api.current.post('/planning/updateitv', { body: JSON.stringify({
        interventionId: item.interventionId,
        datePlanned: datePlanned,
        userIdPlanned: userId,
        dateStart: formatDate(weekSelected),
        dateEnd: formatDate(new Date(weekSelected).setDate(weekSelected.getDate() + 7)),
        userId,
      }) })
        .then(payload => planningDispatch({ type: 'init', payload, dayStart: weekSelected }))
        .catch(payload => notify(formatError(payload), { color: 'danger' }))
    }

    return e
  }, [appState.constants, userId, daySelected, formatError, notify, planningDispatch])

  return (
    <Page>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' }, planningState.payload.user)}</Page.Header.Title>
        {!isReader && (<Page.Header.Actions>
          {/* TODO: send userId + selectedShop */}
          <Button
            tag={RRDLink}
            to={{
              pathname: '/tasks/new',
              state: { userId },
            }}
            outlined
          >
            {t({ id: 'tasks.new' })}
          </Button>
          <Button
            tag={RRDLink}
            to={{
              pathname: '/interventions/express',
              state: { userId },
            }}
            outlined
          >
            {t({ id: 'interventions.express' })}
          </Button>
          <Button
            tag={RRDLink}
            to={{
              pathname: '/interventions/new',
              search: new URLSearchParams({ userId, }).toString(),
            }}
          >
            {t({ id: 'interventions.new' })}
          </Button>
        </Page.Header.Actions>)}
      </Page.Header>
      <Page.Content>
        <Block>
          <Block.Content className={style.filters}>
            <DayInput className={style.scheduleDay} name="scheduleDay"
              label={t({ id: 'main.schedule.date.label' })}
              value={daySelected}
              placeholder={''}
              onChange={({ target: { value } }) => setDaySelected(value)} />
          </Block.Content>
          <Block.Content>
            <div className={join(style.planning, tooltipOpen && style.hideScroll)}>
              <PlanningCol
                disabled={planningState.status === STATUS.LOADING}
                onSlotClick={handleSlotClick}
                onDrop={handleOnDropPlanningItem}
                onTooltipOpen={setTooltipOpen}
                ColumnHeaderRender={({ column }) =>
                  t({ id: 'main.schedule.column.header', }, { date: new Date(column.label) })}
                ItemRender={ItemRender}
                TooltipRender={PlanningTooltipItem}
                RowHeaderRender={({ row }) => row.label}
                {...planningState.payload} />
            </div>
          </Block.Content>
        </Block>
        <Legend.Block title={t({ id: 'legend.title' })} className={style.legend}>
          <Legend.Item label={t({ id: 'legend.planning-intervention-scheduled' })} className={join(style.interventions, style.scheduled)} />
          <Legend.Item label={t({ id: 'legend.planning-intervention-done' })} className={join(style.interventions, style.done)} />
          <Legend.Item label={t({ id: 'legend.planning-intervention-returned' })} className={join(style.interventions, style.returned)} />
          <Legend.Item label={t({ id: 'legend.planning-intervention-express' })} className={join(style.interventions, style.express)} />
          <Legend.Item label={t({ id: 'legend.planning-intervention-closed' })} className={join(style.interventions, style.closed)} />
          <Legend.Item label={t({ id: 'legend.planning-tasks' })} className={join(style.tasks)} />
          <Legend.Item label={t({ id: 'legend.planning-unavailabilities' })} className={join(style.unavailabilities)} />
        </Legend.Block>
      </Page.Content>

      <PlanningModalItem
        item={confirmTransition}
        isOpen={confirmTransition !== undefined} toggle={setConfirmTransition}
        tPrefix={'confirmTransition'}
      />
    </Page>
  )
}
