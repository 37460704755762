import { useEffect, useState, useMemo, } from 'react'
import { Link, useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Button, } from '@velogik/component-button'
import { Filters, } from '@velogik/component-filters'
import { Page, } from '@velogik/component-page'
import { join } from '@velogik/helper-classnames'
import { wait, STATUS } from '@velogik/helper-promise'
import { useT, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { Result, } from 'components/Result'

import style from './SharedPlanningList.module.scss'

const filters = [
  { name: 'networkId', queryName: 'networkId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'shopId', queryName: 'shopId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'filter', queryName: 'q', type: 'text' },
]

export function SharedPlanningList() {
  const api = useApi()
  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const t = useT()

  const location = useLocation()

  const columns = useMemo(() => [
    { name: 'name', component: ({ item }) => (
      <div className={join(style.result_row, style.item)}>
        <span className={style.label}>{t({ id: 'result.item.name' }, item)}</span>
      </div>
    ) },
    { name: 'link', },
    { name: 'shopName', component: ({ item }) => (
      <div className={join(style.result_row, style.item)}>
        {item.networkName && <span className={style.networkName}>{t({ id: 'result.item.networkName' }, item)}</span>}
        <span className={style.label}>{t({ id: 'result.item.shopName' }, item)}</span>
      </div>
    ) },
    { name: 'btn.view', component: ({ item }) => <Button tag={Link} to={`/sharedPlannings/${item.sharedPlanningId}`}>{t({ id: 'result.item.btn.view' })}</Button> },
  ], [t,])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/sharedplannings/list', undefined, {
      p: query.get('p'),
      ipp: query.get('ipp'),
      ...filters.reduce((acc, item) => ({ ...acc, [item.name]: query.get(item.queryName) }), {}),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload: {
        ...payload,
        result: payload.result.map(user => ({
          ...user,
          networkName: payload.filters.networkId ? (payload.filters.networkId.values.find(network => network.value === user.networkId) || {}).label : null,
        }))
      }, }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload, }))
  }, [api, location.search])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        <Page.Header.Actions>
          <Button tag={Link} to={'/sharedPlannings/new'} outlined>{t({ id: 'new' })}</Button>
        </Page.Header.Actions>
      </Page.Header>

      <Page.Content>
        <Filters data={dataState} filters={filters} />
        <Result
          keyExtractor={item => item.sharedPlanningId}
          data={dataState} columns={columns} />
      </Page.Content>
    </Page>
  )
}
