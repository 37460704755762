import { useEffect, useState, useReducer, } from 'react'
import { Link as RRDLink, useHistory, useParams, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Link, Url, } from '@velogik/component-link'
import { Modal, } from '@velogik/component-modal'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'

import { BackButton, } from 'components/BackButton'
import { useHasProfile } from 'hooks/use-user'

import style from './EquipmentView.module.scss'

function confirmNewInterventionReducer (state, action) {
  switch (action.type) {
    case 'open': return true
    case 'toggle': return !state
    case 'close': return false
    default: return state
  }
}

export function EquipmentView() {
  const api = useApi()
  const history = useHistory()
  const { equipmentId } = useParams()
  const t = useT()
  const formatError = useFormatError()

  const isReader = useHasProfile('reader')
  const isAdmin = useHasProfile('admin_network', 'admin_velocenter')

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING })
  const [confirmNewInterventionState, confirmNewInterventionDispatch] = useReducer(confirmNewInterventionReducer, false)

  useEffect(() => {
    wait(api.get('/equipments/details', undefined, { equipmentId }))
      .then(payload => setDataState({
        status: STATUS.LOADED,
        payload: {
          ...payload,
          currentClient: payload.clients.find(client => client.isCurrent) || {}
        },
      }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, equipmentId,])

  function toggleVisibility () {
    setDataState({ status: STATUS.INITIALIZING })

    api.post('/equipments/details', { body: JSON.stringify({
      ...dataState.payload.informations,
      equipmentId: dataState.payload.informations.equipmentId,
      hidden: !dataState.payload.informations.hidden,
    }) })
      .then(payload => setDataState({
        status: STATUS.LOADED,
        payload: {
          ...dataState.payload,
          informations: payload
        }
      }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        {!isReader && (
          <Page.Header.Actions>
            <Button onClick={() => confirmNewInterventionDispatch({ type: 'open' })}
              disabled={dataState.status !== STATUS.LOADED}>
              {t({ id: 'interventions.actions.new', })}
            </Button>
          </Page.Header.Actions>
        )}
      </Page.Header>

      <Page.Content className={style.pageContent}>
        <Block>
          <Block.Title>{t({ id: 'informations.title' })}</Block.Title>
          {dataState.status === STATUS.INITIALIZING && <Block.Loader />}
          {dataState.status === STATUS.LOADED && (
            <Block.Content striped>
              <Block.Item
                label={t({ id: 'informations.item.equipmentType.label' })}
                value={t({ id: 'informations.item.equipmentType.value' }, {
                  value: dataState.payload.informations.equipmentTypeValue,
                  business: dataState.payload.informations.business,
                })} />
              <Block.Item
                label={t({ id: 'informations.item.identificationSystem.label' })}
                value={t({ id: 'informations.item.identificationSystem.value'}, dataState.payload.informations )} />
              <Block.Item
                label={t({ id: 'informations.item.velocenterIdentifier.label' })}
                value={dataState.payload.informations.velocenterIdentifier} />
              <Block.Item
                label={t({ id: 'informations.item.brand.label' })}
                value={dataState.payload.informations.brandName || dataState.payload.informations.brand || t({ id: 'informations.item.brand.empty' })} />
              <Block.Item
                label={t({ id: 'informations.item.customName.label' })}
                value={dataState.payload.informations.customName || t({ id: 'informations.item.customName.empty' })} />
              <Block.Item
                label={t({ id: 'informations.item.model.label' })}
                value={dataState.payload.informations.model || t({ id: 'informations.item.model.empty' })} />
              <Block.Item
                label={t({ id: 'informations.item.purchaseYear.label' })}
                value={t({ id: 'informations.item.purchaseYear.value' }, { value: dataState.payload.informations.purchaseYear || undefined })} />
              <Block.Item
                label={t({ id: 'informations.item.frontSprocket.label' })}
                value={t({ id: 'informations.item.frontSprocket.value' }, { value: dataState.payload.informations.frontSprocket || undefined })} />
              <Block.Item
                label={t({ id: 'informations.item.rearSprocket.label' })}
                value={t({ id: 'informations.item.rearSprocket.value' }, { value: dataState.payload.informations.rearSprocket || undefined })} />
              <Block.Item
                label={t({ id: 'informations.item.wheelSize.label' })}
                value={t({ id: 'informations.item.wheelSize.value' }, { value: dataState.payload.informations.wheelSize || undefined })} />
            </Block.Content>
          )}

          {!isReader && dataState.status === STATUS.LOADED && dataState.payload.informations.isEditable && (
            <Block.Actions>
              <Button tag={RRDLink} to={`/equipments/${equipmentId}/edit`}
                disabled={dataState.status !== STATUS.LOADED}>
                {t({ id: 'informations.actions.edit', })}
              </Button>
            </Block.Actions>
          )}
        </Block>

        <Block>
          <Block.Title className={style.heading}>{t({ id: 'interventions.title' })}</Block.Title>
          {dataState.status === STATUS.INITIALIZING && <Block.Loader />}
          {dataState.status === STATUS.LOADED && (
            <Block.Content striped>
              {dataState.payload.interventions.length === 0 && <Alert type="warning">{t({ id: 'interventions.noInterventions' })}</Alert>}
              {dataState.payload.interventions.length > 0 && dataState.payload.interventions.map(intervention => (
                <Block.Item key={intervention.interventionId}
                  className={style.interventionItem}
                  label={<>
                    {intervention.hasAccess
                      ? <Link to={`/interventions/${intervention.interventionId}/details`}>{t({ id: 'interventions.name' }, intervention)}</Link>
                      : t({ id: 'interventions.name' }, intervention)}
                    <div>{t({ id: 'interventions.status' }, {
                      status: intervention.statusValue,
                      date: intervention.dateDone ? new Date(intervention.dateDone) : undefined })
                    }</div>
                  </>}
                  value={t({ id: 'interventions.billing' }, {
                    billing: intervention.billing,
                    name: intervention.billing && intervention.billing.name,
                    url: chunk => <Url href={intervention.billing.url} target="_blank" rel="noopener noreferrer">{chunk}</Url>,
                  })} />
              ))}
            </Block.Content>
          )}

          {!isReader && (
            <Block.Actions>
              <Button onClick={() => confirmNewInterventionDispatch({ type: 'open' })}
                disabled={dataState.status !== STATUS.LOADED}>
                {t({ id: 'interventions.actions.new', })}
              </Button>
            </Block.Actions>
          )}
        </Block>

        <Block>
          <Block.Title>{t({ id: 'clients.title' })}</Block.Title>
          {dataState.status === STATUS.INITIALIZING && <Block.Loader />}
          {dataState.status === STATUS.LOADED && (
            <Block.Content striped>
              {dataState.payload.clients.length === 0 && <Alert type="warning">{t({ id: 'client.noClients' })}</Alert>}
              {dataState.payload.clients.length > 0 && dataState.payload.clients.map(client => (
                <Block.Item key={client.dateSince} className={style.clientItem}
                  label={t({ id: 'clients.item.label' }, client)}
                  value={<div className={style.value}>
                    {t({ id: 'clients.item.value' }, {
                      dateSince: client.dateSince ? new Date(client.dateSince) : null,
                      dateTo: client.dateTo ? new Date(client.dateTo) : null,
                    })}
                    <Button tag={RRDLink} className={style.button} to={`/clients/${client.clientId}`} outlined>{t({ id: 'clients.item.details' })}</Button>
                  </div>} />
              ))}
            </Block.Content>
          )}

          {!isReader && (
            <Block.Actions>
              <Button tag={RRDLink} to={`/clients/new?equipmentId=${equipmentId}`}
                disabled={dataState.status !== STATUS.LOADED}>
                {t({ id: 'clients.actions.new', })}
              </Button>
            </Block.Actions>
          )}
        </Block>

        {!isReader && !isAdmin && dataState.status === STATUS.LOADED && dataState.payload.informations.isEditable && (
          <Block className={style.visibilityBlock}>
            <Block.Title>{t({ id: 'visibility.title' })}</Block.Title>
            <Block.Content className={style.visibilityContent}>
              <Button onClick={toggleVisibility}
                outlined
                color="danger"
                className={style.visibilityButton}
                disabled={dataState.status !== STATUS.LOADED}>
                {t({ id: `visibility.${dataState.payload.informations.hidden ? 'show' : 'hide'}.action` })}
              </Button>
              {t({ id: `visibility.${dataState.payload.informations.hidden ? 'show' : 'hide'}.description` })}
            </Block.Content>
          </Block>
        )}
      </Page.Content>

      {dataState.status === STATUS.ERROR && <Banner type="danger" sticky
        description={formatError(dataState.payload)}
        actions={[
          { label: t({ id: 'dirty.retry' }), outlined: true, onClick: () => history.go(0) }
        ]}
      />}

      {dataState.status === STATUS.LOADED && <Modal isOpen={confirmNewInterventionState} toggle={() => confirmNewInterventionDispatch({ type: 'toggle' })}>
        <Block className={style.selectClient}>
          <Block.Title className={style.title}>{t({ id: 'selectClient.title' })}</Block.Title>
          <Block.Content className={style.content}>
            <div>{t({ id: 'selectClient.disclaimer' }, dataState.payload.currentClient)}</div>
          </Block.Content>
          <Block.Actions className={style.blockActions}>
            <Button tag={RRDLink} to={`/interventions/new?equipmentId=${equipmentId}`} outlined>
              {t({ id: 'selectClient.other' })}
            </Button>
            <Button tag={RRDLink} to={`/interventions/new?equipmentId=${equipmentId}&clientId=${dataState.payload.currentClient.clientId}`}>
              {t({ id: 'selectClient.confirm' })}
            </Button>
          </Block.Actions>
        </Block>
      </Modal>}
    </Page>
  )
}
