import { useMemo, } from 'react'

import { Input, } from '@velogik/component-input'
import { Label, } from '@velogik/component-label'
import { SelectInput, } from '@velogik/component-select-input'
import { useForm, } from '@velogik/component-form'
import { formatMinutes, } from '@velogik/helper-date'
import { useIntl, } from '@velogik/component-intl'

import style from './WeekScheduler.module.scss'

const HOUR = 60
const STEP = 60
const STEP_RATIO = HOUR / (STEP / HOUR)
const STARTDAY = HOUR * 6 // 6:00
const MIDDAY = HOUR * 12 // 12:00
const ENDDAY = HOUR * 22 // 22:00

const AM_DURATIONS = Array.from(new Array((((MIDDAY + HOUR * 2) - STARTDAY) / STEP_RATIO) + 1)).map((_, i) => ({
  label: formatMinutes(STARTDAY + i * 60),
  value: STARTDAY + i * 60,
}))

const PM_DURATIONS = Array.from(new Array(((ENDDAY - MIDDAY) / STEP_RATIO) + 1)).map((_, i) => ({
  label: formatMinutes(MIDDAY + i * 60),
  value: MIDDAY + i * 60,
}))

export function WeekScheduler ({ name, ...props }) {
  const form = useForm()

  const value = props.value !== undefined ? props.value : form.state.current ? form.state.current[name] : undefined
  const onChange = props.onChange || (form && form.dispatcher)

  const disabled = props.disabled || form.disabled

  function handleChange (day, dayValue) {
    return onChange({ target: { type: 'day', name, value: {
      ...value,
      [day]: dayValue,
    }, }, })
  }

  return (
    <div className={style.root}>
      {props.label && <Label className={style.label}>{props.label}</Label>}
      {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
        <Day key={`${day}`} day={day} value={value[day]} disabled={disabled}
          onChange={(value) => handleChange(day, value)} />
      ))}
    </div>
  )
}

function Day ({ day, value, onChange, disabled, }) {
  const { formatMessage } = useIntl()

  const valueAmOpen = value && value.am && value.am.open
  const valueAmClose = value && value.am && value.am.close
  const valuePmOpen = value && value.pm && value.pm.open
  const valuePmClose = value && value.pm && value.pm.close

  const durations = { am: { open: [], close: [] }, pm: { open: [], close: [] }, }

  durations.am.open = useMemo(() =>
    AM_DURATIONS
      .slice(0, -1)
      .filter(duration => !valuePmOpen || (duration.value < valuePmOpen))
      .filter(duration => !valueAmClose || (duration.value < valueAmClose))
  , [valuePmOpen, valueAmClose])
  durations.am.close = useMemo(() =>
    AM_DURATIONS
      .slice(1)
      .filter(duration => !valuePmOpen || (duration.value <= valuePmOpen))
      .filter(duration => !valueAmOpen || (duration.value > valueAmOpen))
  , [valuePmOpen, valueAmOpen])
  durations.pm.open = useMemo(() =>
    PM_DURATIONS
      .slice(0, -1)
      .filter(duration => !valueAmClose || (duration.value >= valueAmClose))
      .filter(duration => !valuePmClose || (duration.value < valuePmClose))
  , [valueAmClose, valuePmClose])
  durations.pm.close = useMemo(() =>
    PM_DURATIONS
      .slice(1)
      .filter(duration => !valueAmClose || (duration.value > valueAmClose))
      .filter(duration => !valuePmOpen || (duration.value > valuePmOpen))
  , [valueAmClose, valuePmOpen])

  function handleOpeningHoursToggleDay ({ target: { checked, } }) {
    return onChange(checked
      ? { am: { open: null, close: null }, pm: { open: null, close: null } }
      : null,)
  }

  function handleOpeningHoursTimeChange (inputValue, timeRange, timeBound) {
    return onChange({
      ...value,
      [timeRange]: {
        ...value[timeRange],
        [timeBound]: inputValue && Number(inputValue)
          ? Number(inputValue)
          : null
      },
    },)
  }

  return (
    <div className={style.day}>
      <Input name={day} type="checkbox"
        inputClassName={style.dayInput}
        label={formatMessage({ id: `WeekScheduler.${day}`})}
        labelClassName={style.dayLabel}
        value={Boolean(value)}
        disabled={disabled}
        onChange={handleOpeningHoursToggleDay} />
      {value && ['am', 'pm'].map(dayQuarter => ['open', 'close'].map(part => (
        <SelectInput
          key={`${day}.${dayQuarter}.${part}`}
          name={`${day}.${dayQuarter}.${part}`}
          className={style.durationSelect}
          label={formatMessage({ id: `WeekScheduler.${dayQuarter}.${part}.label` })}
          placeholder={formatMessage({ id: `WeekScheduler.${dayQuarter}.${part}.placeholder` })}
          disabled={disabled}
          value={value[dayQuarter][part]}
          onChange={({ target: { value } }) => handleOpeningHoursTimeChange(value, dayQuarter, part)}
          options={durations[dayQuarter][part]} />
      )))}
    </div>
  )
}
