import { useEffect, useState, useMemo, } from 'react'
import { Link as RRDLink, useLocation, useHistory } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Filters, } from '@velogik/component-filters'
import { Button, } from '@velogik/component-button'
import { Input, } from '@velogik/component-input'
import { Label, } from '@velogik/component-label'
import { Link, Url, } from '@velogik/component-link'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { join, } from '@velogik/helper-classnames'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useIntl, } from '@velogik/component-intl'
import { formatDate, } from '@velogik/helper-date'

import { useHasProfile, } from 'hooks/use-user'

import { BackButton, } from 'components/BackButton'
import { DayInput, } from 'components/DayInput'
import { Result, } from 'components/Result'

import { formatPhoneNumber } from 'react-phone-number-input'

import style from './InterventionList.module.scss'

const filters = [
  { name: 'shopId', queryName: 'shopId', type: 'select', hasPlaceholder: true, multiple: false, canSelectAll: true },
  { name: 'statusId', queryName: 'statusId', type: 'status', },
  { name: 'dateSince', type: 'date' },
  { name: 'dateTo', type: 'date' },
  { name: 'dateScheduledReturn', type: 'date', canClear: true },
  { name: 'filter', type: 'text' },
  { name: 'location', type: 'location' },
  { name: 'clientId', },
  { name: 'originId', type: 'origin', },
  { name: 'id', type: 'id', },
]

export function InterventionList() {
  const api = useApi()
  const isReader = useHasProfile('reader')

  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING, })

  const t = useT()
  const { formatMessage } = useIntl()

  const location = useLocation()

  const columns = useMemo(() => [
    { name: 'interventionId', headerClassName: style.littleCol, orderKey: 'interventionId' },
    { name: 'address', },
    { name: 'date', useItem: item => ({ date: item.date != null ? new Date(item.date) : null }), orderKey: 'datePlanned' },
    { name: 'dateScheduledReturn', useItem: item => ({ date: item.dateScheduledReturn != null ? new Date(item.dateScheduledReturn) : null }), orderKey: 'dateScheduledReturn' },
    { name: 'user', },
    { name: 'equipmentDescription', component: EquipmentDescriptionResultRender },
    { name: 'originId', component: ({ item }) => formatMessage({ id: 'constants.origin' }, { value: item.originValue }) },
    { name: 'userName', component: ({ item }) => <>
      <Link to={`/clients/${item.clientId}`} className={style.userName}>
        {t({ id: 'result.item.userName' }, item)}
      </Link>
      <Url href={`tel:${item.phoneNumber}`} className={style.phoneNumber}>
        {t(
          { id: 'result.item.phoneNumber' },
          { phoneNumber: formatPhoneNumber(item.phoneNumber) },
        )}
      </Url>
    </> },
    { name: 'duration', orderKey: 'duration' },
    { name: 'statusId', component: StatusResultRender },
    { name: 'btn.view', component: ({ item }) => <Button tag={RRDLink} to={`/interventions/${item.interventionId}/details`}>{t({ id: 'result.item.btn.view' })}</Button> },
  ], [t, formatMessage])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    wait(api.get('/interventions/list', undefined, {
      p: query.get('p'),
      ipp: query.get('ipp'),
      ...filters.reduce((acc, item) => ({ ...acc, [item.name]: query.get(item.queryName || item.name) }), {}),
      'order[column]': query.get('order[column]'),
      'order[dir]': query.get('order[dir]'),
    }))
      .then(payload => setDataState({ status: STATUS.LOADED, payload: { ...payload, filters: { ...payload.filters, id: undefined } } }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api, location])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        {!isReader && (
          <Page.Header.Actions>
            <Button tag={RRDLink} to={'/interventions/express'} outlined>
              {t({ id: 'express' })}
            </Button>
            <Button tag={RRDLink} to={'/interventions/new'}>
              {t({ id: 'new' })}
            </Button>
          </Page.Header.Actions>
        )}
      </Page.Header>

      <Page.Content>
        <Filters data={dataState} filters={filters}
          statusRender={StatusRender}
          dateRender={DateFilterRender}
          locationRender={LocationFilterRender}
          originRender={OriginFilterRender}
          idRender={IdFilterRender} />
        <Result
          keyExtractor={item => item.interventionId}
          data={dataState} columns={columns} />
      </Page.Content>
    </Page>
  )
}

function EquipmentDescriptionResultRender ({ item }) {
  return item.equipmentId
    ? <Link to={`/equipments/${item.equipmentId}`}>
      <div className={style.equipmentDescription}>
        {item.velocenterIdentifier && <span className={style.velocenterIdentifier}>{item.velocenterIdentifier}</span>}
        <span className={style.customName}>{item.customName}</span>
      </div>
    </Link>
    : <div className={style.equipmentDescription}>{item.customName}</div>
}

function StatusResultRender ({ item }) {
  const { formatMessage } = useIntl()

  return (
    <div className={join(style.status, style[item.statusValue], item.dateBikeReceipt && style.received)}>
      {formatMessage({ id: 'constants.interventionStatus' }, { value: item.statusValue })}
    </div>
  )
}

function StatusRender ({ filter, applyFilter, payload }) {
  const t = useT()

  const tValue = payload.filters[filter.name].selected
    && payload.filters[filter.name].selected.value
    && t({ id: `filters.${filter.name}.value` }, { value: payload.filters[filter.name].selected.key })

  return (
    <SelectInput
      key={filter.name}
      name={filter.name}
      label={t({ id: `filters.${filter.name}.label`, })}
      value={tValue || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value, })}
      all={t({ id: `filters.${filter.name}.all` })}
      options={payload.filters[filter.name].values}
      optionRender={option => t({ id: `filters.${filter.name}.value` }, { value: option.label })}
    />
  )
}

function DateFilterRender ({ filter, applyFilter, payload }) {
  const t = useT()

  return (
    <DayInput name={filter.name} canClear={payload.filters[filter.name]}
      label={t({ id: `filters.${filter.name}.label`, })}
      placeholder={t({ id: `filters.${filter.name}.placeholder`, })}
      value={payload.filters[filter.name] || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value ? formatDate(value) : '', })} />
  )
}

function LocationFilterRender ({ filter, applyFilter, payload }) {
  const t = useT()

  const tValue = payload.filters[filter.name].selected
    && payload.filters[filter.name].selected.value
    && t({ id: `filters.${filter.name}.option` }, { option: payload.filters[filter.name].selected.key })

  return (
    <SelectInput
      key={filter.name}
      name={filter.name}
      label={t({ id: `filters.${filter.name}.label`, })}
      value={tValue || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value, })}
      all={t({ id: `filters.${filter.name}.all` })}
      options={payload.filters[filter.name].values}
      optionRender={option => t({ id: `filters.${filter.name}.option` }, { option: option.label })}
    />
  )
}

function OriginFilterRender ({ filter, applyFilter, payload }) {
  const t = useT()

  const tValue = payload.filters[filter.name].selected
    && payload.filters[filter.name].selected.value
    && t({ id: `filters.${filter.name}.option` }, { option: payload.filters[filter.name].selected.key })

  return (
    <SelectInput
      key={filter.name}
      name={filter.name}
      label={t({ id: `filters.${filter.name}.label`, })}
      value={tValue || ''}
      onChange={({ target: { value } }) => applyFilter({ [filter.queryName || filter.name]: value, })}
      all={t({ id: `filters.${filter.name}.all` })}
      options={payload.filters[filter.name].values}
      optionRender={option => t({ id: `filters.${filter.name}.option` }, { option: option.label })}
    />
  )
}

function IdFilterRender () {
  const history = useHistory()
  const t = useT()

  const [id, setId] = useState('')

  function handleSubmit (e) {
    e.preventDefault()

    if (id && Number.isInteger(Number(id))) {
      history.push(`/interventions/${id}/details`)
    }

    return false
  }

  return (
    <div className={style.idFilter}>
      <Label className={style.label}>{t({ id: 'filters.id.label', })}</Label>
      <form className={style.form} onSubmit={handleSubmit}>
        <Input className={style.input} key={'idFilter'}
          value={id}
          onChange={({ target: { value } }) => setId(value)}
          placeholder={t({ id: 'filters.id.placeholder', })} />
        <Button type="submit"
          disabled={!id || !Number.isInteger(Number(id))} className={style.button}>{t({ id: 'filters.id.button', })}</Button>
      </form>
    </div>
  )
}
