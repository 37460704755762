import { createElement, } from 'react'
import { join } from '@velogik/helper-classnames'

import { Item, } from 'components/Planning.Item'

import style from './Planning.helpers.module.scss'

export function ItemRender ({ item, className, ...props }) {
  return createElement(
    ((type) => {
      switch (type) {
        case 'interventions': return InterventionItemRender
        case 'sharedInterventions': return InterventionItemRender
        case 'tasks': return TaskItemRender
        default: return Item
      }
    })(item.type),
    {item, className: join(style.item, style[item.type], className), ...props}
  )
}

export function InterventionItemRender ({ item, className, ...props }) {
  return (
    <Item
      title={`# ${item.interventionId}`}
      className={join(className, style[item.status], props.disabled && style.disabled)}
      {...props}
    >
      <Item.Text>{`# ${item.interventionId}`}</Item.Text>
    </Item>
  )
}

export function TaskItemRender ({ item, ...props }) {
  return (
    <Item title={`# ${item.taskId}`} {...props}>
      <Item.Text>{`# ${item.taskId}`}</Item.Text>
    </Item>
  )
}
