import { useEffect, useState, useCallback, useMemo, useReducer, createElement, } from 'react'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { AddressInput, } from '@velogik/component-address-input'
import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, FormProvider, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useIntl, useFormatError, } from '@velogik/component-intl'
import { ImageInput, } from '@velogik/component-image-input'
import { Label, } from '@velogik/component-label'
import { Url, } from '@velogik/component-link'
import { Loading, } from '@velogik/component-loading'
import { Modal, } from '@velogik/component-modal'
import { Page } from '@velogik/component-page'
import { SelectInput, SelectFilter, } from '@velogik/component-select-input'
import { join, } from '@velogik/helper-classnames'
import { formatMinutes, formatDate, compareWithoutTime, } from '@velogik/helper-date'
import { extractDiff, } from '@velogik/helper-object'
import { wait, STATUS, } from '@velogik/helper-promise'
import { noop, } from '@velogik/helper-utils'

import { buildPlanning } from 'helpers/planning'
import { useHasProfile, useHasService, } from 'hooks/use-user'

import { Itvcode, } from 'components/Itvcode'
import { BackButton, } from 'components/BackButton'
import { ButtonModal, } from 'components/ButtonModal'
import { PlanningRow, } from 'components/Planning'
import { Item as PlanningItem } from 'components/Planning.Item'
import { DayInput, } from 'components/DayInput'

import { InterventionBreadcrumb, } from 'pages/InterventionBreadcrumb'
import { CreateItvcode, } from 'pages/InterventionDetails.CreateItvcode.Modal'

import { formatPhoneNumber } from 'react-phone-number-input'

import style from './InterventionDetails.module.scss'

const DEFAULT_ITVCODE_VALUE = {
  quantity: 1,
  comments: '',
  dontCharge: false,
  isSav: false,
}

const DEFAULT_ITV_INFORMATIONS = {
  remindClient: false,
}

// TODO: Avoid this ugly override in favor of context in SelectInput
function openReducer (state, action) {
  switch(action.type) {
    case 'onInputClick':
      return !state
    case 'onItemSelected':
      return action.autoclose ? false : state
    default:
      return state
  }
}

export function InterventionDetails () {
  const api = useApi()

  const routeParams = useParams()
  const history = useHistory()
  const location = useLocation()
  const t = useT('/interventions/:interventionId/details', true)
  const tCommon = useT('common', true)
  const tMandatory = useT('Mandatory', true)
  const tCreateItvcodeModal = useT('createItvcodeModal')
  const { formatMessage } = useIntl()
  const formatError = useFormatError()
  const { appState, } = useApp()
  const isBasic = useHasService('basic')
  const isReader = useHasProfile('reader')

  const [dataState, dataDispatch] = useFormReducer()
  const [scheduleState, scheduleDispatch] = useFormReducer()
  const [returnState, returnDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const [planningState, setPlanningState] = useState({ status: STATUS.INITIALIZING })
  const [usersState, setUsersState] = useState({ status: STATUS.INITIALIZING })
  const [clientState, setClientState] = useState({ status: STATUS.INITIALIZING })
  const [billingState, setBillingState] = useState({ status: STATUS.INITIALIZING })
  const [userPics, setUserPics] = useState({ status: STATUS.INITIALIZING })
  const [returnedState, setReturnedState] = useState({ status: STATUS.INITIALIZING })
  const [historyState, setHistoryState] = useState({ status: STATUS.INITIALIZING })
  const [itvcodesState, setItvcodesState] = useState({ status: STATUS.INITIALIZING })
  const [itvcodesEstimate, setItvcodesEstimate] = useState({ status: STATUS.INITIALIZING })

  const [currentService, setCurrentService] = useState()
  const [daySelected, setDaySelected] = useState()
  const [scheduleTime, setScheduleTime] = useState('')
  const [filterUser, setFilterUser] = useState('')

  const [scheduledReturnDay, setScheduledReturnDay] = useState()
  const [scheduledReturnTime, setScheduledReturnTime] = useState()
  const [scheduledReturnTimeFilter, setScheduledReturnTimeFilter] = useState('')
  const [scheduledReturnTimeOpenState, scheduledReturnTimeOpenDispatch] = useReducer(openReducer, false)

  const [confirmCancel, setConfirmCancel] = useState(false)
  const [confirmHold, setConfirmHold] = useState(false)
  const [cancelationReason, setCancelationReason] = useState('')
  const [askReturnConditions, setAskReturnConditions] = useState(false)
  const [returnConditions, setReturnConditions] = useState('')
  const [imageOpen, setImageOpen] = useState()

  const [timeFilter, setTimeFilter] = useState('')
  const [timeOpenState, timeOpenDispatch] = useReducer(openReducer, false)

  const [itvFilter, setItvFilter] = useState('')
  const [itvOpenState, itvOpenDispatch] = useReducer(openReducer, false)

  const isDataLoaded = [FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status)
  const isScheduleLoaded = [FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(scheduleState.status)

  const dataItvcodes = dataState.current && dataState.current.itvcodes

  const pathSlug = location.pathname && location.pathname.split('/')[1]

  const scheduleSheetConfig = useMemo(() => ({
    columns: Array.from(Array((appState.constants.planningSchedule.hourEnd - appState.constants.planningSchedule.hourStart) / 60)).map((_, i) => ({
      key: i,
      label: formatMinutes(appState.constants.planningSchedule.hourStart + (60 * i)),
    })),
    columnExtractKey: column => column.key,
    rowExtractKey: row => row.userId,
  }), [appState.constants,])

  const scheduleTimeOptions = useMemo((() =>
    Array.from(Array((appState.constants.planningSchedule.hourEnd - appState.constants.planningSchedule.hourStart) / appState.constants.planningSchedule.timePickerStep)).map((_, i) => {
      const minutes = appState.constants.planningSchedule.hourStart + (appState.constants.planningSchedule.timePickerStep * i)
      return {
        label: formatMinutes(minutes),
        value: String(minutes),
      }
    })
  ), [appState.constants])

  const scheduleTimeOptionsFiltered = useMemo(() => {
    const filterToNumber = String(Number(timeFilter.replace(/[^\d]+/g, '')))

    return filterToNumber === '0' ? scheduleTimeOptions : scheduleTimeOptions.filter(time =>
      String(Number(time.label.replace(/[^\d]+/g, ''))).startsWith(filterToNumber))
  }, [timeFilter, scheduleTimeOptions])

  const scheduledReturnTimeOptionsFiltered = useMemo(() => {
    const filterToNumber = String(Number(scheduledReturnTimeFilter.replace(/[^\d]+/g, '')))

    return filterToNumber === '0' ? scheduleTimeOptions : scheduleTimeOptions.filter(time =>
      String(Number(time.label.replace(/[^\d]+/g, ''))).startsWith(filterToNumber))
  }, [scheduledReturnTimeFilter, scheduleTimeOptions])

  /**
   * Filtrered list of users with the Sheet user filter
   */
  const planningData = useMemo(() => {
    if ( isScheduleLoaded
      && planningState.status === STATUS.LOADED
    ) {
      const sameDay = compareWithoutTime(planningState.daySelected, scheduleState.current.datePlanned) === 0

      // TODO: display this error if happen
      return buildPlanning(
        planningState.payload
          .filter(_ => _.userName.toUpperCase().includes(filterUser.toUpperCase()))
          .map(_ => {
            if (sameDay && _.userId === scheduleState.current.userIdPlanned) {
              const valueDate = new Date(scheduleState.current.datePlanned)
              // const tzOffset = valueDate.getTimezoneOffset()
              const tzOffset = new Date().getTimezoneOffset()
              const timeStart = (valueDate.getHours() * 60 + valueDate.getMinutes()) + tzOffset
              const duration = scheduleState.current.duration

              return {
                ..._,
                interventions: _.interventions.concat({ timeStart, duration }),
              }
            } else {
              return _
            }
          }),
        ['interventions', 'tasks', 'unavailabilities'],
        appState.constants.planningSchedule.hourStart,
        appState.constants.planningSchedule.hourEnd
      )
    }

    return []
  }, [planningState, scheduleState, filterUser, isScheduleLoaded, appState.constants])

  /**
   * Set the current intervention status when dataState loaded
   */
  const interventionStatus = useMemo(() =>
    isDataLoaded ? dataState.current.statusValue : undefined
  , [isDataLoaded, dataState])

  const itvcodeList = useMemo(() =>
    isDataLoaded && itvcodesState.status === STATUS.LOADED
      ? itvcodesState.payload.itvcodes
        .map(itvcode => {
          const added = dataItvcodes.find(_ => _.itvcodeId === itvcode.itvcodeId)
          return {
            value: itvcode.itvcodeId,
            label: [itvcode.itvcodeIdentifier, itvcode.chronocaisseRef, itvcode.itvcodeLabel].filter(_ => _).join(' - '),
            clickable: !added,
            className: added && style.itvcodeAdded,
          }
        })
        .filter(itvcode => itvcode.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(itvFilter.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
      : []
  , [isDataLoaded, itvcodesState, dataItvcodes, itvFilter])

  // TODO: consider put it all on the same reducer
  const refreshStates = useCallback((payload) => {
    const searchParams = new URLSearchParams(location.search)

    const informations = { ...DEFAULT_ITV_INFORMATIONS, ...payload.informations }

    if (Number(searchParams.get('userId'))) {
      informations.userIdPlanned = Number(searchParams.get('userId'))
    }

    scheduleDispatch({ type: FORM_ACTION.INIT, value: informations })
    returnDispatch({ type: FORM_ACTION.INIT, value: informations })
    setClientState({ status: STATUS.LOADED, payload: payload.client })
    setBillingState({ status: STATUS.LOADED, payload: payload.billing })
    setReturnedState({ status: STATUS.LOADED, payload: payload.returned })
    if (informations.datePlanned) {
      const valueDate = new Date(informations.datePlanned)
      setDaySelected(valueDate)
      setScheduleTime(`${valueDate.getHours() * 60 + valueDate.getMinutes()}`)
    }
    else {
      setDaySelected(new Date())
      setScheduleTime('')
    }
    Promise.all(payload.userPics.map(picture => api.get(picture, undefined, undefined, {
      resolveHook: _ => _
    })
      .then(res => res.blob())
      .then(res => URL.createObjectURL(res))
    ))
      .then(userPics => setUserPics({ status: STATUS.LOADED, payload: userPics }))
    setReturnConditions(payload.shop.returnTerms)

    dataDispatch({ type: FORM_ACTION.INIT, value: informations, })

    const serviceDetails = appState.constants.interventionServicesDetails.find(_ => _.value === informations.servicesId)
    setCurrentService(serviceDetails)
  }, [api, dataDispatch, scheduleDispatch, returnDispatch, appState.constants, location.search])

  useEffect(() => {
    if (daySelected) {
      setPlanningState({ status: STATUS.INITIALIZING })
      wait(api.get('/planning/view', undefined, { dateStart: formatDate(daySelected) }))
        .then(payload => {
          setUsersState({ status: STATUS.LOADED, payload: payload.result.map(user => ({
            label: user.userName,
            value: user.userId
          })) })
          setPlanningState({
            status: STATUS.LOADED,
            payload: payload.result,
            daySelected
          })
        })
      // TODO: display this error if happen
        .catch(payload => setPlanningState({ status: STATUS.ERROR, payload }))
    }
  }, [api, appState.constants, daySelected,])

  useEffect(() => {
    (() => {
      switch (pathSlug) {
        case 'interventions': return wait(api.get('/interventions/details', undefined, { interventionId: routeParams.interventionId }))
          .then((interventionPayload) =>
            api.get('/shops/itvcodes', undefined, {
              equipmentId: interventionPayload.informations.equipmentId,
              equipmentType: interventionPayload.client.equipmentType,
              shopId: interventionPayload.informations.shopId,
            })
              .then(itvcodesPayload => setItvcodesState({ status: STATUS.LOADED, payload: itvcodesPayload }))
              .then(() => refreshStates(interventionPayload))
          )
        case 'sharedInterventions': return wait(api.get('/velocare/sharedintervention', undefined, { interventionId: routeParams.interventionId }))
          .then((interventionPayload) =>
            api.get('/shops/itvcodes', undefined, {
              equipmentId: interventionPayload.informations.equipmentId,
              businessId: interventionPayload.informations.businessId,
              categoryId: interventionPayload.informations.categoryId,
              familyId: interventionPayload.informations.familyId,
            })
              .then(itvcodesPayload => setItvcodesState({ status: STATUS.LOADED, payload: itvcodesPayload }))
              .then(() => refreshStates(interventionPayload))
          )
        default: return Promise.reject()
      }
    })()
      .catch(value => dataDispatch({ type: FORM_ACTION.ERROR, value }))
  }, [api, pathSlug, routeParams, dataDispatch, refreshStates])

  useEffect(() => window.scrollTo(0, 0), [interventionStatus])

  useEffect(() => {
    if (interventionStatus && pathSlug === 'interventions') {
      wait(api.get('/interventions/history', undefined, { interventionId: routeParams.interventionId }))
        .then((historyPayload) => setHistoryState({ status: STATUS.LOADED, payload: historyPayload, }))
        .catch(payload => setHistoryState({ status: STATUS.ERROR, payload }))
    }
  }, [api, pathSlug, routeParams, interventionStatus])

  useEffect(() => {
    if (dataItvcodes && pathSlug === 'interventions') {
      setItvcodesEstimate({ status: STATUS.LOADING })
      api.post('/shops/itvcodesEstimate', { body: JSON.stringify({
        itvcodes: dataItvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          dontCharge: itvcode.dontCharge,
          priceWt: itvcode.priceWt,
          isSav: itvcode.isSav,
        }))
      }) })
        .then(payload => setItvcodesEstimate({
          status: STATUS.LOADED,
          payload: payload.billing
        }))
        .catch(payload => setItvcodesEstimate({
          status: STATUS.ERROR,
          payload
        }))
    }
  }, [api, pathSlug, dataItvcodes, dataDispatch])

  /**
   * Check if intervention is loaded then if current intervention is in one of status.
   * if `'any'` in present in status, only check if loaded
   * @param  {...String} status list of status to check
   * @returns true if loaded and matching whith one of status
   */
  function isStatus (...status) {
    return status && isDataLoaded &&
      (status.includes('any') || status.includes(dataState.current.statusValue))
  }

  function submitData (state, additionalParams = {}) {
    setAskReturnConditions(false)
    setPostState({ status: STATUS.LOADING })
    return (() => {
      switch (pathSlug) {
        case 'interventions': return wait(api.post('/interventions/details', { body: JSON.stringify({
          addressComplement: '',
          ...additionalParams,
          ...state.current,
          // Override current by modified dataState values (only if state is not dataState)
          ...(state === dataState ? {} : extractDiff(dataState.initial, dataState.current)),
        }) }))
        case 'sharedInterventions': return wait(api.post('/velocare/updateitv', { body: JSON.stringify({
          addressComplement: '',
          nextStep: false,
          ...additionalParams,
          ...state.current,
          // Override current by modified dataState values (only if state is not dataState)
          ...(state === dataState ? {} : extractDiff(dataState.initial, dataState.current)),
        }) }))
        default: return
      }
    })()
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: state.current }))
  }

  function handleServicesIdChange ({ target: { value } }) {
    const service = appState.constants.interventionServicesDetails.find(_ => _.value === Number(value))

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesId',
        value: value
      }
    })

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesDetailsId',
        value: (service && service.values.length === 1) ? [service.values[0]] : []
      }
    })
    setCurrentService(service)
  }

  function handleServicesDetailsChange ({ value }) {
    const servicesDetailsId = (dataState.current.servicesDetailsId && currentService.isMultiple)
      ? dataState.current.servicesDetailsId.indexOf(value) >= 0
        ? dataState.current.servicesDetailsId.filter(_ => _ !== value)
        : dataState.current.servicesDetailsId.concat(value)
      : [value]

    dataDispatch({
      target: {
        type: 'text',
        name: 'servicesDetailsId',
        value: servicesDetailsId
      }
    })
  }

  function createItvcodeCallback (itvcode) {
    setItvcodesState({ status: STATUS.INITIALIZING })

    dataDispatch({
      type: 'addItem',
      name: 'itvcodes',
      target: { type: 'list' },
      value: {
        ...DEFAULT_ITVCODE_VALUE,
        ...itvcode,
      },
    })

    api.get('/shops/itvcodes', undefined, {
      equipmentId: clientState.payload.equipmentId,
      equipmentType: clientState.payload.equipmentType,
      shopId: dataState.current.shopId
    })
      .then(itvcodesPayload => setItvcodesState({ status: STATUS.LOADED, payload: itvcodesPayload }))
      .catch(value => dataDispatch({ type: FORM_ACTION.ERROR, value }))
  }

  function handleItvcodeAdd (itvcodeId) {
    const itvcode = itvcodesState.payload.itvcodes.find(_ => String(_.itvcodeId) === String(itvcodeId)) || {}
    dataDispatch({ type: 'addItem', value: {
      ...DEFAULT_ITVCODE_VALUE,
      ...itvcode
    }, name: 'itvcodes', target: { type: 'list' }, })
  }

  function handleItvcodeRemove (item) {
    dataDispatch({ type: 'removeItem', value: item, name: 'itvcodes', target: { type: 'list' }, })
  }

  function handleItvcodeChange (item, { target: {name, value} },) {
    dataDispatch({ type: 'updateItem', value: item, name: 'itvcodes', target: { name, value }, })
  }

  function handleScheduleDayChange ({ target: { value } }) {
    setDaySelected(value)
    if (isScheduleLoaded
     && scheduleState.current.datePlanned
     && compareWithoutTime(scheduleState.current.datePlanned, value) === 0)
    {
      const valueDate = new Date(scheduleState.current.datePlanned)
      setScheduleTime(`${valueDate.getHours() * 60 + valueDate.getMinutes()}`)
    } else {
      setScheduleTime('')
    }
  }

  function handlePlanningDoubleClick (e, row) {
    const rect = e.target.getBoundingClientRect()
    const cursorPosition = e.clientX - rect.left

    const timeStep = appState.constants.planningSchedule.timePickerStep
    const timeRange = appState.constants.planningSchedule.hourEnd - appState.constants.planningSchedule.hourStart
    const timeSteps = timeRange / timeStep

    const pixelRange = rect.width
    const pixelStep = pixelRange / timeSteps
    const pixelRatio = Math.round(cursorPosition / pixelStep)
    const timeValue = (pixelRatio * timeStep) + appState.constants.planningSchedule.hourStart

    setScheduleTime(timeValue)

    const datePlanned = new Date(
      daySelected.getFullYear(),
      daySelected.getMonth(),
      daySelected.getDate(),
      0, timeValue, 0)
    scheduleDispatch({ target: { type: 'time', name: 'datePlanned', value: datePlanned }})
    scheduleDispatch({ target: { type: 'select', name: 'userIdPlanned', value: row.userId }})
  }

  function handleScheduleTimeChange ({ target: { value } }) {
    setScheduleTime(value)

    const datePlanned = new Date(
      daySelected.getFullYear(),
      daySelected.getMonth(),
      daySelected.getDate(),
      0, value, 0)

    scheduleDispatch({ target: { type: 'time', name: 'datePlanned', value: datePlanned }})
  }

  function handleScheduledReturnDayChange ({ target: { value } }) {
    setScheduledReturnDay(value)
    if (isScheduleLoaded
     && scheduleState.current.dateScheduledReturn
     && compareWithoutTime(scheduleState.current.dateScheduledReturn, value) === 0)
    {
      const valueDate = new Date(scheduleState.current.dateScheduledReturn)
      setScheduledReturnTime(`${valueDate.getHours() * 60 + valueDate.getMinutes()}`)
    } else {
      setScheduledReturnTime('')
    }
  }

  function handleScheduledReturnTimeChange ({ target: { value: _value } }) {
    setScheduledReturnTime(_value)

    const value = new Date(
      scheduledReturnDay.getFullYear(),
      scheduledReturnDay.getMonth(),
      scheduledReturnDay.getDate(),
      0, _value, 0)

    scheduleDispatch({ target: { type: 'time', name: 'dateScheduledReturn', value }})
  }

  function done () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/interventions/details', { body: JSON.stringify({
      interventionId: routeParams.interventionId,
      nextStep: true,
    }) }))
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function cancel () {
    setConfirmCancel()
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/interventions/details', { body: JSON.stringify({
      interventionId: routeParams.interventionId,
      cancel: true,
      cancelationReason,
    }) }))
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function hold () {
    setConfirmHold()
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/interventions/details', { body: JSON.stringify({
      ...dataState.current,
      pending: true,
      nextStep: true,
    }) }))
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function handleBikeReceived () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/interventions/details', { body: JSON.stringify({
      ...dataState.current,
      bikeReceipt: true,
    }) }))
      .then(refreshStates)
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function onReturningPictureChange (file, picType) {
    return api.put('/interventions/pic', { body: file }, { interventionId: routeParams.interventionId, picType })
    // TODO: handle error
  }

  function onTransformToEstimateClick () {
    setPostState({ status: STATUS.LOADING })
    return wait(api.post('/estimates/createfromitv', { body: JSON.stringify({
      interventionId: routeParams.interventionId,
    }) }))
      .then(payload => history.push(`/estimates/${payload.informations.estimateId}/details`))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function onGenerateReceiptOrderClick () {
    setPostState({ status: STATUS.LOADING })

    // TODO: consider adding and use an api.createUrl (createApiUrl)
    return fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/interventions/details`, {
      ...api.config,
      method: 'POST',
      body: JSON.stringify({
        ...dataState.current,
        generateRepairOrder: true,
      }),
    })
      .then(response => {
        switch (response.headers.get('content-type')) {
          case 'application/pdf': return response.blob()
          default: return (response.ok ? response.json() : response.json().then(res => Promise.reject(res)))
            .then(response => response && response.code ? Promise.reject(response) : Promise.resolve(response))
        }
      })
      .then(blob => URL.createObjectURL(blob))
      .then(url => window.open(url))
      .then(() => setPostState({ status: STATUS.IDLE }))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function isDirty () {
    [dataState, scheduleState].find(state => state.status === FORM_STATUS.DIRTY)
  }

  const erroredState = [dataState, scheduleState, returnState].find(_ => _.status === FORM_STATUS.ERROR)

  const cannotEdit = isReader || postState.status === STATUS.LOADING
  const isSummaryInterventionDisabled = cannotEdit || isStatus('returned', 'closed', 'canceled', 'express', isBasic ? 'done' : undefined) || pathSlug !== 'interventions'

  return <Page>
    <BackButton />
    <Page.Header className={style.header}>
      <Page.Header.Title className={style.heading}>{t({ id: 'pageTitle' }, { id: routeParams.interventionId })}</Page.Header.Title>
      <Page.Header.Content>
        <InterventionBreadcrumb status={dataState.current.statusValue} />
      </Page.Header.Content>
      {!isReader && isStatus('returned',) && pathSlug === 'interventions' && (
        <Page.Header.Actions>
          <Button onClick={done} disabled={cannotEdit}>
            {t({ id: 'actions.close' })}
          </Button>
        </Page.Header.Actions>
      )}
      {!isReader && isStatus('canceled') && (
        <Page.Header.Actions>
          <Button
            tag={Link}
            to={{ pathname: '/interventions/new/', search: new URLSearchParams({
              clientId: dataState.current.clientId,
              equipmentId: dataState.current.equipmentId
            }).toString()}}
            disabled={cannotEdit}
          >
            {t({ id: 'actions.newItv' })}
          </Button>
        </Page.Header.Actions>
      )}
    </Page.Header>

    {isStatus('new', 'pending', 'scheduled', 'done') && (
      <Page.Content>
        {tMandatory({ id: 'partial' })}
      </Page.Content>
    )}
    <Page.Content>
      <Form state={dataState} dispatcher={dataDispatch}
        disabled={cannotEdit}
        preventTransition={isDirty() && postState.status !== STATUS.LOADING}>
        <Form.Content className={style.formContent}>
          {isStatus('scheduled', 'done', 'returned', 'closed') && (
            <Block className={style.summarySchedule}>
              <Block.Title>{t({ id: 'summary.schedule.title' })}</Block.Title>
              <Block.Content striped>
                <Block.Item
                  label={t({ id: 'summary.schedule.duration.label' })}
                  value={formatMinutes(dataState.current.duration)} />
                <Block.Item
                  label={t({ id: 'summary.schedule.datePlanned.label' })}
                  value={t(
                    { id: 'summary.schedule.datePlanned.value' },
                    { value: new Date(dataState.current.datePlanned)}
                  )} />
                <Block.Item
                  label={t({ id: 'summary.schedule.userIdPlanned.label' })}
                  value={usersState.status === STATUS.LOADED && (usersState.payload.find(_ => _.value === dataState.current.userIdPlanned) || {}).label} />
                {dataState.current.dateScheduledReturn && <Block.Item
                  label={t({ id: 'summary.schedule.dateScheduledReturn.label' })}
                  value={t(
                    { id: 'summary.schedule.dateScheduledReturn.value' },
                    { value: new Date(dataState.current.dateScheduledReturn)}
                  )} />}
              </Block.Content>
              {!isReader && isStatus('scheduled') && pathSlug === 'interventions' && <Block.Actions>
                <Button outlined onClick={() => submitData(dataState, { prevStep: true })}>
                  {t({ id: 'summary.schedule.actions.reschedule' })}
                </Button>
              </Block.Actions>}
            </Block>
          )}

          <Block className={style.summaryIntervention}>
            <Block.Title>{t({ id: 'summary.details.title' })}</Block.Title>
            <Block.Content>
              <SelectInput
                name="locationId"
                label={t({ id: 'summary.details.locationId.label' })}
                disabled={isSummaryInterventionDisabled}
                options={appState.constants.interventionLocation.map(_ => ({
                  value: _.value,
                  label: t({ id: 'summary.details.locationId.value' }, { value: _.key }),
                }))} />

              <SelectInput
                name="servicesId"
                label={t({ id: 'summary.details.servicesId.label' })}
                placeholder={t({ id: 'summary.details.servicesId.placeholder' })}
                disabled={isSummaryInterventionDisabled}
                all={t({ id: 'summary.details.servicesId.all' })}
                options={appState.constants.interventionServicesDetails.map(_ => ({
                  value: _.value,
                  label: tCommon({ id: 'services' }, { value: _.key }),
                }))}
                value={currentService ? currentService.value : ''}
                onChange={handleServicesIdChange} />

              {currentService && currentService.values.map(servicesDetails =>
                <Input key={`servicesDetails-${servicesDetails.key}`}
                  id={`servicesDetails-${servicesDetails.key}`}
                  name={`servicesDetails-${servicesDetails.key}`}
                  label={tCommon({ id: 'servicesDetails' }, { value: `${currentService.key}_${servicesDetails.key}` })}
                  disabled={isSummaryInterventionDisabled}
                  type="checkbox"
                  value={dataState.current.servicesDetailsId && dataState.current.servicesDetailsId.indexOf(servicesDetails.value) >= 0}
                  onChange={() => handleServicesDetailsChange(servicesDetails)}
                />
              )}

              <div>
                <Label>{t({ id: 'summary.details.additional.label' })}</Label>
                <Input name="additional" type="checkbox"
                  label={t({ id: 'summary.details.additional.tick' })}
                  disabled={isSummaryInterventionDisabled} />
              </div>

              <AddressInput name="addressFull"
                label={t({ id: 'summary.details.address.label' })}
                disabled={isSummaryInterventionDisabled} />
              <Input name="addressComplement" type="text"
                label={t({ id: 'summary.details.addressComplement.label' })}
                placeholder={t({ id: 'summary.details.addressComplement.placeholder' })}
                disabled={isSummaryInterventionDisabled} />

              <Input name="description" type="textarea"
                label={t({ id: 'summary.details.description.label' })}
                placeholder={t({ id: 'summary.details.description.placeholder' })}
                disabled={isSummaryInterventionDisabled} />

              {userPics.status === STATUS.LOADED && userPics.payload.length > 0 && <div>
                <Label>{t({ id: 'summary.details.pictures.label' })}</Label>
                <div className={style.clientPictures}>
                  {userPics.status === STATUS.LOADED && userPics.payload.map((picture, i) =>
                    <img
                      key={`userPic-${i}`}
                      className={style.clientPicture}
                      src={picture}
                      onClick={() => setImageOpen(picture)}
                      alt="" />
                  )}
                </div>
              </div>}
            </Block.Content>
          </Block>

          <Block className={style.summaryClient}>
            <Block.Title>{t({ id: 'summary.client.title' })}</Block.Title>
            {isStatus('any') && <Block.Content striped>
              <Block.Item
                label={t({ id: 'summary.client.velocenterIdentifier' })}
                value={clientState.payload.velocenterIdentifier || '-'} />
              <Block.Item
                label={t({ id: 'summary.client.customName' })}
                value={clientState.payload.customName} />
              <Block.Item
                label={t({ id: 'summary.client.userName' })}
                value={clientState.payload.userName || '-'} />
              <Block.Item
                label={t({ id: 'summary.client.phoneNumber.label' })}
                value={clientState.payload.phoneNumber
                  ? <Url href={`tel:${clientState.payload.phoneNumber}`}>
                    {t(
                      { id: 'summary.client.phoneNumber.value' },
                      { phoneNumber: formatPhoneNumber(clientState.payload.phoneNumber) },
                    )}</Url>
                  : '-' } />
              <Block.Item
                label={t({ id: 'summary.client.email' })}
                value={clientState.payload.email
                  ? <Url href={`mailto:${clientState.payload.email}`}>{clientState.payload.email}</Url>
                  : '-' } />
            </Block.Content>}
          </Block>

          {isDataLoaded && dataState.current.isBikeReceivable && (
            <Block className={style.bikeReceipt}>
              <Block.Title>{t({ id: 'main.bikeReceipt.title' })}</Block.Title>
              <Block.Content>
                {dataState.current.dateBikeReceipt
                  ? <Alert type="success">{t(
                    { id: 'main.bikeReceipt.content.received' },
                    {
                      dateBikeReceipt: new Date(dataState.current.dateBikeReceipt),
                      firstName: dataState.current.userBikeReceipt.firstName,
                      lastName: dataState.current.userBikeReceipt.lastName,
                    }
                  )}</Alert>
                  : t({ id: 'main.bikeReceipt.content.toReceive' })}
              </Block.Content>
              {!isReader && !dataState.current.dateBikeReceipt && (
                <Block.Actions>
                  <Button
                    className={style.receive}
                    onClick={() => handleBikeReceived()}
                  >
                    {t({ id: 'main.bikeReceipt.actions.received' })}
                  </Button>
                </Block.Actions>
              )}
            </Block>
          )}

          {isStatus('new', 'pending') && (
            <Block className={style.schedule}>
              <Block.Title>{t({ id: 'main.schedule.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <FormProvider
                  state={scheduleState} dispatcher={scheduleDispatch}
                  disabled={cannotEdit}
                >
                  <Input className={style.duration} name="duration"
                    type="range"
                    label={t({ id: 'main.schedule.duration.label' })}
                    renderValue={formatMinutes}
                    {...appState.constants.interventionDuration} />
                  <DayInput className={style.scheduleDay} name="scheduleDay"
                    label={t({ id: 'main.schedule.date.label' })}
                    placeholder=""
                    value={daySelected}
                    onChange={handleScheduleDayChange} />
                  <div className={style.sheetWrapper}>
                    <PlanningRow
                      className={style.sheet}
                      rows={planningData}
                      onRowDoubleClick={handlePlanningDoubleClick}
                      ItemRender={ItemRender}
                      HeadHeaderRender={() => <Input
                        key="filterUser"
                        className={style.filterUser}
                        placeholder={t({ id: 'main.schedule.filterUser.placeholder' })}
                        value={filterUser}
                        onChange={({ target: { value }}) => setFilterUser(value)} />}
                      ColumnHeaderRender={({ column }) => column.label}
                      RowHeaderRender={({ row }) => row.userName}
                      {...scheduleSheetConfig}
                    />
                  </div>
                  <SelectInput className={style.userIdPlanned} name="userIdPlanned"
                    label={t({ id: 'main.schedule.userIdPlanned.label' })}
                    placeholder={t({ id: 'main.schedule.userIdPlanned.placeholder' })}
                    options={usersState.status === STATUS.LOADED ? usersState.payload : []} />
                  <SelectInput className={style.scheduleTime} name="scheduleTime"
                    label={t({ id: 'main.schedule.time.label' })}
                    placeholder={t({ id: 'main.schedule.time.placeholder' })}
                    autoclose={true}
                    value={scheduleTime}
                    valueRender={value => ({ value, label: formatMinutes(value)})}
                    options={scheduleTimeOptionsFiltered}
                    onChange={handleScheduleTimeChange}
                    openState={timeOpenState} openDispatch={timeOpenDispatch}
                    customOption={SelectFilter}
                    customOptionProps={{
                      placeholder: t({ id: 'main.schedule.time.filter.placeholder' }),
                      openState: timeOpenState,
                      value: timeFilter,
                      onChange: ({ target: { value } }) => setTimeFilter(value)
                    }}
                  />
                  <Input className={style.datePlanned} name="datePlanned"
                    type="datePlanned"
                    disabled
                    label={t({ id: 'main.schedule.datePlanned.label' })}
                    value={isScheduleLoaded
                      ? t(
                        { id: 'main.schedule.datePlanned.value' },
                        { datePlanned: scheduleState.current.datePlanned ? new Date(scheduleState.current.datePlanned) : null }
                      )
                      : ''}
                  />

                  <DayInput className={style.dateScheduledReturnDay} name="dateScheduledReturnDay"
                    label={t({ id: 'main.schedule.scheduledReturnDay.label' })}
                    placeholder={t({ id: 'main.schedule.scheduledReturnDay.placeholder' })}
                    value={scheduledReturnDay}
                    onChange={handleScheduledReturnDayChange} />

                  <SelectInput className={style.dateScheduledReturnTime} name="dateScheduledReturnTime"
                    label={t({ id: 'main.schedule.scheduledReturnTime.label' })}
                    placeholder={t({ id: 'main.schedule.scheduledReturnTime.placeholder' })}
                    autoclose={true}
                    value={scheduledReturnTime}
                    valueRender={value => ({ value, label: formatMinutes(value)})}
                    options={scheduledReturnTimeOptionsFiltered}
                    onChange={handleScheduledReturnTimeChange}
                    openState={scheduledReturnTimeOpenState} openDispatch={scheduledReturnTimeOpenDispatch}
                    customOption={SelectFilter}
                    customOptionProps={{
                      placeholder: t({ id: 'main.schedule.scheduledReturnTime.filter.placeholder' }),
                      openState: scheduledReturnTimeOpenState,
                      value: scheduledReturnTimeFilter,
                      onChange: ({ target: { value } }) => setScheduledReturnTimeFilter(value)
                    }}
                  />

                  <div className={style.remindClient}>
                    <Label>{t({ id: 'main.schedule.remindClient.label' })}</Label>
                    <Input name="remindClient" id="remindClient.true"
                      type="radio"
                      label={t({ id: 'main.schedule.remindClient.true' })}
                      value={true} />
                    <Input name="remindClient" id="remindClient-false"
                      type="radio"
                      label={t({ id: 'main.schedule.remindClient.false' })}
                      value={false} />
                  </div>
                </FormProvider>
              </Block.Content>
              {!isReader && (
                <Block.Actions className={join(style.blockActions, style[dataState.current.statusValue])}>
                  <Button onClick={() => submitData(scheduleState, { nextStep: true, ...dataState.current })}>
                    {t(
                      { id: 'main.schedule.actions.plan' },
                      { pending: isStatus('pending') ? 'true' : 'false' },
                    )}
                  </Button>
                  <Button outlined className={style.transformToEstimate} onClick={onTransformToEstimateClick}>{t({ id: 'main.coreContent.actions.transformToEstimate' })}</Button>
                  <Button outlined onClick={() => setConfirmCancel(true)}>{t({ id: 'main.schedule.actions.cancel' })}</Button>
                  {!isStatus('pending') && <Button outlined onClick={() => setConfirmHold(true)}>{t({ id: 'main.schedule.actions.hold' })}</Button>}
                </Block.Actions>
              )}
            </Block>
          )}

          <Block className={style.coreContent}>
            {(isBasic ? isStatus('new', 'scheduled', 'pending') : isStatus('new', 'scheduled', 'pending', 'done')) && (<>
              <Block.Title>{t({ id: `main.coreContent.${interventionStatus}.title` })}</Block.Title>
              <Block.Content className={style.content}>
                <div className={style.newItvcode}>
                  <Label className={style.label}>{t({ id: 'main.coreContent.itvcodes.add.label' })}</Label>
                  {!isReader && (
                    <SelectInput
                      name="itvcodes"
                      value={t({ id: 'main.coreContent.itvcodes.add.placeholder' })}
                      autoclose={false}
                      onChange={({ target: { value } }) => handleItvcodeAdd(value)}
                      options={itvcodeList}
                      openState={itvOpenState} openDispatch={itvOpenDispatch}
                      customOption={SelectFilter}
                      customOptionProps={{
                        placeholder: t({ id: 'main.coreContent.itvcodes.filter.placeholder' }),
                        openState: itvOpenState,
                        value: itvFilter,
                        onChange: ({ target: { value } }) => setItvFilter(value)
                      }}
                    />
                  )}

                  {!isReader && pathSlug === 'interventions' && (
                    <ButtonModal
                      ModalComponent={CreateItvcode}
                      modalProps={{
                        t: tCreateItvcodeModal,
                        shopId: dataState.current.shopId,
                        interventionId: dataState.current.interventionId,
                        equipmentType: clientState.payload.equipmentType,
                        canCreateWorkforce: itvcodesState.status === STATUS.LOADED && itvcodesState.payload.canCreateWorkforce,
                        canCreatePiece: itvcodesState.status === STATUS.LOADED && itvcodesState.payload.canCreatePiece,
                        closeCallback: createItvcodeCallback,
                      }}
                      buttonProps={{
                        outlined: true,
                        className: style.createItvcode,
                        disabled: itvcodesState.status !== STATUS.LOADED,
                      }}
                    >
                      {t({ id: 'main.coreContent.itvcodes.new' })}
                    </ButtonModal>
                  )}
                </div>

                {dataItvcodes.map(itvcode => (
                  <Itvcode key={itvcode.itvcodeId}
                    data={itvcode}
                    postState={postState}
                    disabled={pathSlug !== 'interventions'}
                    onRemove={() => handleItvcodeRemove(itvcode)}
                    onChange={e => handleItvcodeChange(itvcode, e)} />
                ))}
                {dataItvcodes.length === 0 && <Alert type="warning">{t({ id: 'noItvcodes' })}</Alert>}

                <div className={style.comments}>
                  <Input type="textarea" name="commentsForClient"
                    label={t({ id: 'main.coreContent.commentsForClient.label' })}
                    placeholder={t({ id: 'main.coreContent.commentsForClient.placeholder' })} />
                  <Input type="textarea" name="commentsForUser"
                    label={t({ id: 'main.coreContent.commentsForUser.label' })}
                    placeholder={t({ id: 'main.coreContent.commentsForUser.placeholder' })} />
                </div>
              </Block.Content>
              {!isReader && isStatus('scheduled') && (
                <Block.Actions className={join(style.blockActions, style.scheduled)}>
                  <Button className={style.askReturnCondition} onClick={() => setAskReturnConditions(true)}>{t({ id: 'main.coreContent.actions.askReturnCondition' })}</Button>
                  {pathSlug === 'interventions' && <Button outlined className={style.cancel} onClick={() => setConfirmCancel(true)}>{t({ id: 'main.coreContent.actions.cancel' })}</Button>}
                  {pathSlug === 'interventions' && <Button outlined className={style.generateReceiptOrder} onClick={onGenerateReceiptOrderClick}>{t({ id: 'main.coreContent.actions.generateReceiptOrder' })}</Button>}
                  {pathSlug === 'interventions' && <Button outlined className={style.hold} onClick={() => setConfirmHold(true)}>{t({ id: 'main.coreContent.actions.hold' })}</Button>}
                  {pathSlug === 'interventions' && <Button outlined className={style.transformToEstimate} onClick={onTransformToEstimateClick}>{t({ id: 'main.coreContent.actions.transformToEstimate' })}</Button>}
                </Block.Actions>
              )}
            </>)}

            {(isBasic ? isStatus('new', 'scheduled') : isStatus('new', 'scheduled', 'done')) && pathSlug === 'interventions' && (
              <Block.Content className={style.content}>
                {itvcodesEstimate.status === STATUS.ERROR && <Alert>{formatError(itvcodesEstimate.payload)}</Alert>}
                {[STATUS.INITIALIZING, STATUS.LOADING].includes(itvcodesEstimate.status) && <Loading />}
                {itvcodesEstimate.status === STATUS.LOADED && (
                  <div className={style.billing}>
                    <div className={style.details}>
                      <span>{t({ id: 'billing.total_Nt.label' })}</span>
                      <span>{t({ id: 'billing.total_Nt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Taxes.label' })}</span>
                      <span>{t({ id: 'billing.total_Taxes.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Wt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Mo_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Mo_Wt.value' }, itvcodesEstimate.payload)}</span>
                      <span>{t({ id: 'billing.total_Pc_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Pc_Wt.value' }, itvcodesEstimate.payload)}</span>
                    </div>
                    <div className={style.result}>
                      <span>{t({ id: 'billing.result.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.result.total_Wt.value' }, itvcodesEstimate.payload)}</span>
                    </div>
                    {isStatus('scheduled', 'done') && <>
                      <div className={style.details}>
                        <span>{t({ id: 'billing.total_Sav_Nt.label' })}</span>
                        <span>{t({ id: 'billing.total_Sav_Nt.value' }, itvcodesEstimate.payload)}</span>
                        <span>{t({ id: 'billing.total_Sav_Vat.label' })}</span>
                        <span>{t({ id: 'billing.total_Sav_Vat.value' }, itvcodesEstimate.payload)}</span>
                      </div>
                      <div className={style.result}>
                        <span>{t({ id: 'billing.result.total_Sav_Wt.label' })}</span>
                        <span>{t({ id: 'billing.result.total_Sav_Wt.value' }, itvcodesEstimate.payload)}</span>
                      </div>
                    </>}
                  </div>
                )}
              </Block.Content>
            )}

            {isStatus('done') && !isBasic && billingState.payload && (<>
              <Block.Title>{t({ id: 'main.coreContent.returning.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <FormProvider
                  state={returnState} dispatcher={returnDispatch}
                  disabled={cannotEdit}
                >
                  <div className={style.returning}>
                    <ImageInput className={style.picture} name="picture"
                      buttonLabel={t({ id: 'main.coreContent.returning.picture.button' })}
                      onChange={noop}
                      onPictureChange={onReturningPictureChange}
                      value={returnedState.payload}
                      accept="image/x-png,image/jpeg" />
                    <div className={style.validations}>
                      <div>
                        <Label>{t({ id: 'main.coreContent.returning.clientValidation.label' })}</Label>
                        <Input name="clientValidation" type="checkbox"
                          id={'returning.clientValidation'}
                          label={t({ id: 'main.coreContent.returning.clientValidation.value' })} />
                      </div>
                      <Input name="clientReservations" type="textarea"
                        label={t({ id: 'main.coreContent.returning.clientReservations.label' })}
                        placeholder={t({ id: 'main.coreContent.returning.clientReservations.placeholder' })} />
                      <div>
                        <Label>{t({ id: 'main.coreContent.returning.returnValidation.label' })}</Label>
                        <Input name="returnValidation" type="checkbox"
                          id={'returning.returnValidation'}
                          label={t({ id: 'main.coreContent.returning.returnValidation.value' })} />
                      </div>
                      <Input name="techReservations" type="textarea"
                        label={t({ id: 'main.coreContent.returning.techReservations.label' })}
                        placeholder={t({ id: 'main.coreContent.returning.techReservations.placeholder' })} />
                      <div>
                        <AddressInput name="billingAddress"
                          label={t({ id: 'main.coreContent.returning.addressFull.label' })}
                          prefix="billing"
                          placeholder={t({ id: 'main.coreContent.returning.addressFull.placeholder' })} />
                      </div>
                      <div>
                        <Input name="billingAddressComplement" type="text"
                          label={t({ id: 'main.coreContent.returning.addressComplement.label' })}
                          placeholder={t({ id: 'main.coreContent.returning.addressComplement.placeholder' })} />
                      </div>
                    </div>
                  </div>
                </FormProvider>
              </Block.Content>
              <Block.Actions className={style.blockActions}>
                <Button onClick={() => submitData(returnState, { nextStep: true, ...dataState.current })}>
                  {t({ id: 'main.coreContent.returning.nexStep' })}
                </Button>
              </Block.Actions>
            </>)}
            {isStatus('returned', 'closed') && (
              <Block.Title>{t({ id: 'main.coreContent.billingSummary.title' })}</Block.Title>
            )}
            {isStatus('canceled') && (
              <Block.Title>{t({ id: 'main.coreContent.canceled.title' })}</Block.Title>
            )}
            {(isBasic ? isStatus('done') : isStatus('returned', 'express', 'closed', 'canceled')) && (
              <Block.Content className={join(style.content, style.billingSummary)} striped>
                <Block.Item className={style.itvcodes}>
                  {dataItvcodes.map(itvcode => (
                    <div key={itvcode.itvcodeId} className={style.itvcode}>
                      <div className={join(style.item, style.identifier, style[style.type])}>
                        <Label className={style.label}>{itvcode.itvcodeIdentifier} - {itvcode.itvcodeLabel}</Label>
                        <span className={style.value}>{t({ id: 'main.coreContent.billingSummary.itvcode.priceWt' }, {
                          ...itvcode,
                          billingType: itvcode.isSav ? 'sav' : itvcode.dontCharge ? 'dontCharge' : 'billed'
                        })}</span>
                      </div>
                      <div className={style.item}>
                        <Label className={style.label}>{t({ id: 'main.coreContent.billingSummary.itvcode.quantity.label' })}</Label>
                        <span className={style.value}>{itvcode.quantity}</span>
                      </div>
                      <div className={style.item}>
                        <Label className={style.label}>{t({ id: 'main.coreContent.billingSummary.itvcode.comments.label' })}</Label>
                        <span className={style.value}>{t({ id: 'main.coreContent.billingSummary.itvcode.comments.value' }, { comments: itvcode.comments || null})}</span>
                      </div>
                    </div>
                  ))}
                </Block.Item>
                {(isBasic ? isStatus('done') : isStatus('returned', 'express', 'closed')) && pathSlug === 'interventions' && (<>
                  <Block.Item
                    className={style.item}
                    label={t({ id: 'main.coreContent.billingSummary.commentsForClient.label' })}
                    value={t({ id: 'main.coreContent.billingSummary.commentsForClient.value' }, { commentsForClient: dataState.current.commentsForClient || null })} />
                  {isBasic
                    ? <Block.Item label={t({ id: 'main.coreContent.commentsForUser.label' })}>
                      <Input type="textarea" name="commentsForUser" placeholder={t({ id: 'main.coreContent.commentsForUser.placeholder' })} />
                    </Block.Item>
                    : <Block.Item
                      className={style.item}
                      label={t({ id: 'main.coreContent.billingSummary.commentsForUser.label' })}
                      value={t({ id: 'main.coreContent.billingSummary.commentsForUser.value' }, { commentsForUser: dataState.current.commentsForUser || null })}/>
                  }
                  <Block.Item className={style.billing}
                    label={t({ id: 'main.coreContent.billingSummary.billing.title' })}>
                    <span>
                      <span>{t({ id: 'billing.total_Nt.label' })}</span>
                      <span>{t({ id: 'billing.total_Nt.value' }, billingState.payload)}</span>
                    </span>
                    <span>
                      <span>{t({ id: 'billing.total_Taxes.label' })}</span>
                      <span>{t({ id: 'billing.total_Taxes.value' }, billingState.payload)}</span>
                    </span>
                    <span>
                      <span>{t({ id: 'billing.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Wt.value' }, billingState.payload)}</span>
                    </span>
                    <span>
                      <span>{t({ id: 'billing.total_Mo_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Mo_Wt.value' }, billingState.payload)}</span>
                    </span>
                    <span>
                      <span>{t({ id: 'billing.total_Pc_Wt.label' })}</span>
                      <span>{t({ id: 'billing.total_Pc_Wt.value' }, billingState.payload)}</span>
                    </span>
                    <span>
                      <span>{t({ id: 'billing.result.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.result.total_Wt.value' }, billingState.payload)}</span>
                    </span>
                  </Block.Item>
                  {isStatus('returned', 'closed') && <Block.Item className={style.billing}
                    label={t({ id: 'main.coreContent.billingSummary.billingSav.title' })}>
                    <span>
                      <span>{t({ id: 'billing.total_Nt.label' })}</span>
                      <span>{t({ id: 'billing.total_Sav_Nt.value' }, billingState.payload)}</span>
                    </span>
                    <span>
                      <span>{t({ id: 'billing.total_Taxes.label' })}</span>
                      <span>{t({ id: 'billing.total_Sav_Vat.value' }, billingState.payload)}</span>
                    </span>
                    <span>
                      <span>{t({ id: 'billing.result.total_Wt.label' })}</span>
                      <span>{t({ id: 'billing.result.total_Sav_Wt.value' }, billingState.payload)}</span>
                    </span>
                  </Block.Item>}
                </>)}
              </Block.Content>
            )}
          </Block>

          {isStatus('returned', 'express', 'closed') && pathSlug === 'interventions' && (
            <Block className={style.returned}>
              <Block.Title>{t({ id: 'main.returned.title' })}</Block.Title>
              <Block.Content className={style.content}>
                {returnedState.payload.picUrl && <div className={style.picture}>
                  <img name="picture" alt="" src={returnedState.payload.picUrl} />
                </div>}
                <div className={style.datas}>
                  <div>
                    <Label>{t({ id: 'main.returned.clientValidation.label' })}</Label>
                    <span>{t({ id: 'main.returned.clientValidation.value' })}</span>
                  </div>
                  <div className={style.item}>
                    <Label>{t({ id: 'main.returned.clientReservations.label' })}</Label>
                    <span>{t({ id: 'main.returned.clientReservations.value' }, {
                      clientReservations: returnedState.payload.clientReservations || null
                    })}</span>
                  </div>
                  <div>
                    <Label>{t({ id: 'main.returned.returnValidation.label' })}</Label>
                    <span>{t({ id: 'main.returned.returnValidation.value' }, {
                      returnValidation: isStatus('express') ? null : (String(dataState.current.returnValidation || false))
                    })}</span>
                  </div>
                  <div className={style.item}>
                    <Label>{t({ id: 'main.returned.techReservations.label' })}</Label>
                    <span>{t({ id: 'main.returned.techReservations.value' }, {
                      techReservations: returnedState.payload.techReservations || null
                    })}</span>
                  </div>
                </div>
              </Block.Content>
            </Block>
          )}

          {isStatus('any') && pathSlug === 'interventions' && (
            <Block className={style.history}>
              <Block.Title>{t({ id: 'history.title' })}</Block.Title>
              <Block.Content className={style.content}>
                {historyState.status === STATUS.LOADED && historyState.payload.map(history => (
                  <div key={`${history.status}.${history.dateCreated}`} className={join(style.item, history.status.startsWith('status_') && style.status, style[history.status])}>
                    <span className={style.dateCreated}>{t({ id: 'history.dateCreated' }, { dateCreated: history.dateCreated ? new Date(history.dateCreated) : null })}</span>
                    <span className={style.userName}>{t({ id: 'history.userName' }, history)}</span>
                    <span className={style.status}>{formatMessage({ id: 'constants.interventionHistoryStatus' }, {
                      ...history,
                      toEstimate: chunk => <Link to={`/estimates/${history.value}/details`}>{chunk}</Link>,
                    })}</span>
                  </div>
                ))}
                {historyState.status === STATUS.ERROR && <Alert>{formatError(historyState.payload)}</Alert>}
              </Block.Content>
            </Block>
          )}
        </Form.Content>

        {erroredState && <Banner type="danger" sticky
          description={formatError(erroredState.payload)}
          actions={[
            { label: t({ id: 'dirty.retry' }), outlined: true, onClick: () => history.go(0) }
          ]}
        />}
        {[dataState.current, scheduleState.current, returnState.current].includes(postState.failedDataState) && postState.status === STATUS.ERROR && <Banner sticky
          type="danger"
          description={formatError(postState.payload)}
          actions={[postState.payload.code === 'preventUpdate'
            ? { label: t({ id: 'dirty.forceSave' }), onClick: () => submitData(dataState, { force: postState.payload.reason })}
            : { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
          ]}
        />}
        {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && postState.status !== STATUS.LOADING && <Banner sticky
          description={t({ id: 'dirty.disclaimer' })}
          actions={[
          // TODO: reset button should cancel and back to ???
            { label: t({ id: 'dirty.cancel' }), onClick: () => dataDispatch({ type: FORM_ACTION.RESET, }), outlined: true, disabled: postState.status === STATUS.LOADING, },
            { label: t({ id: 'dirty.save' }), onClick: () => submitData(dataState), disabled: postState.status === STATUS.LOADING, },
          ]} />}

        <Modal isOpen={askReturnConditions}>
          <Block className={style.returnConditions}>
            <Block.Title className={style.title}>{t({ id: 'returnConditions.title' })}</Block.Title>
            <Block.Content className={style.content}>
              <div>{t({ id: 'returnConditions.disclaimer' })}</div>
              <Input name="returnConditions" type="textarea"
                label={t({ id: 'returnConditions.label' })}
                placeholder={t({ id: 'returnConditions.placeholder' })}
                value={returnConditions}
                onChange={({ target: { value } }) => setReturnConditions(value)} />
            </Block.Content>
            <Block.Actions className={style.blockActions}>
              <Button onClick={() => setAskReturnConditions()} outlined>{t({ id: 'returnConditions.cancel' })}</Button>
              <Button onClick={() => submitData(dataState, { nextStep: true, returnConditions })}>
                {t({ id: 'returnConditions.submit' })}
              </Button>
            </Block.Actions>
          </Block>
        </Modal>

        <Modal isOpen={confirmCancel} toggle={() => setConfirmCancel(_ => !_)}>
          <Block className={style.confirmCancel}>
            <Block.Title className={style.title}>{t({ id: 'confirmCancel.title' })}</Block.Title>
            <Block.Content className={style.content}>
              <div>{t({ id: 'confirmCancel.disclaimer' })}</div>
              <Input name="cancelationReason" type="textarea"
                label={t({ id: 'confirmCancel.cancelationReason.label' })}
                placeholder={t({ id: 'confirmCancel.cancelationReason.placeholder' })}
                value={cancelationReason}
                onChange={({ target: { value } }) => setCancelationReason(value)} />
            </Block.Content>
            <Block.Actions className={style.blockActions}>
              <Button onClick={() => setConfirmCancel()} outlined>{t({ id: 'confirmCancel.actions.cancel' })}</Button>
              <Button onClick={cancel}>{t({ id: 'confirmCancel.actions.submit' })}</Button>
            </Block.Actions>
          </Block>
        </Modal>

        <Modal isOpen={confirmHold} toggle={() => setConfirmHold(_ => !_)}>
          <Block className={style.confirmHold}>
            <Block.Title className={style.title}>{t({ id: 'confirmHold.title' })}</Block.Title>
            <Block.Content className={style.content}>
              {t({ id: 'confirmHold.disclaimer' })}
            </Block.Content>
            <Block.Actions className={style.blockActions}>
              <Button onClick={() => setConfirmHold()} outlined>{t({ id: 'confirmHold.actions.cancel' })}</Button>
              <Button onClick={() => hold()}>{t({ id: 'confirmHold.actions.submit' })}</Button>
            </Block.Actions>
          </Block>
        </Modal>

        <Modal isOpen={imageOpen} toggle={() => setImageOpen(_ => !_)}>
          <Block className={style.imageOpen}>
            <Block.Title className={style.title}>{t({ id: 'imageOpen.title' })}</Block.Title>
            <Block.Content className={style.content}>
              <img className={style.picture} src={imageOpen} alt="" />
            </Block.Content>
            <Block.Actions className={style.blockActions}>
              <Button
                className={style.action}
                tag={Url}
                href={`${imageOpen}`}
                target="_blank"
                rel="noopener noreferrer"
                outlined>
                {t({ id: 'imageOpen.actions.open' })}
              </Button>
              <Button
                className={style.action}
                onClick={() => setImageOpen()}
                outlined>
                {t({ id: 'imageOpen.actions.close' })}
              </Button>
            </Block.Actions>
          </Block>
        </Modal>
      </Form>
    </Page.Content>
  </Page>
}

function ItemRender ({ item, className, ...props }) {
  return createElement(
    ((type) => {
      switch (type) {
        case 'interventions': return InterventionItemRender
        case 'tasks': return TaskItemRender
        default: return PlanningItem
      }
    })(item.type),
    {item, className: join(style.item, style[item.type], className), ...props}
  )
}

function InterventionItemRender ({ item, className, ...props }) {
  const t = useT()
  const routeParams = useParams()

  return (
    <PlanningItem
      title={t({ id: 'main.schedule.planning.item.desc' }, {
        interventionId: item.interventionId || undefined,
        current: routeParams.interventionId || undefined,
      })}
      className={join(className, style[item.status], !item.interventionId && style.current)}
      {...props}>
      <PlanningItem.Text>
        {t({ id: 'main.schedule.planning.item.title' }, {
          interventionId: item.interventionId || undefined,
          current: routeParams.interventionId || undefined,
        })}
      </PlanningItem.Text>
    </PlanningItem>
  )
}

function TaskItemRender ({ item, ...props }) {
  return (
    <PlanningItem title={`# ${item.taskId}`} {...props}>
      <PlanningItem.Text>{`# ${item.taskId}`}</PlanningItem.Text>
    </PlanningItem>
  )
}
