import { useState, useEffect, } from 'react'
import { Link as RRDLink, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { useT, useIntl, } from '@velogik/component-intl'
import { Link, } from '@velogik/component-link'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { join, } from '@velogik/helper-classnames'
import { formatDate, } from '@velogik/helper-date'
import { STATUS, } from '@velogik/helper-promise'

import { useHasProfile, useHasService, } from 'hooks/use-user'

import style from './Dashboard.module.scss'

function LinChart({ data }) {
  const col = 100 / data.length
  const halfCol = col / 2
  const dataNumber = data.map(_ => Number(_))
  const minHeight = Math.min(...dataNumber)
  const maxHeight = Math.max(...dataNumber)
  const themeColor = getComputedStyle(document.body).getPropertyValue('--theme-color')
  const strokeWidth = 1

  const path = data.reduce((acc, item, index) => {
    if (!acc) {
      return `M0,${item}`
    }
    const colIndex = col * index
    return `${acc}S${colIndex - halfCol},${item} ${colIndex},${item}`
  }, '')

  return (
    <svg width="100%" height="100%"
      viewBox={`0 ${minHeight - strokeWidth} 100 ${maxHeight + strokeWidth * 2}`}
      preserveAspectRatio="none">
      <path d={path}
        stroke={themeColor}
        transform={`translate(0,${maxHeight}) scale(1, -1)`}
        strokeWidth="1" fill="none">
      </path>
    </svg>
  )
}

export function Dashboard() {
  const api = useApi()
  const t = useT()
  const intl = useIntl()

  const isNotBasic = useHasService('admin', 'network', 'premium', 'custom')
  const isReader = useHasProfile('reader')

  const [dashboard, setDashboard] = useState({ status: STATUS.INITIALIZING, payload: {} })

  useEffect(() => {
    api.get('/dashboard/blocks')
      .then(payload => setDashboard({
        status: STATUS.LOADED,
        blocks: payload.blocks,
        filters: payload.filters,
      }))
      .catch(payload => setDashboard({ status: STATUS.ERROR, payload }))
  }, [api])

  function shopChanged(shopId) {
    setDashboard(old => ({ ...old, status: STATUS.LOADING, }))
    api.get('/dashboard/blocks', undefined, { shopId })
      .then(payload => setDashboard({
        status: STATUS.LOADED,
        blocks: payload.blocks,
        filters: payload.filters,
      }))
      .catch(payload => setDashboard({ status: STATUS.ERROR, payload }))
  }

  const year = new Date().getFullYear()
  const month = new Date().getMonth()

  const firstDayOfYear = formatDate(new Date(year, 0, 1))
  const lastDayOfYear = formatDate(new Date(year + 1, 0, 0))

  const firstDayOfMonth = formatDate(new Date(year, month, 1))
  const lastDayOfMonth = formatDate(new Date(year, month + 1, 0))
  const firstDayOfNextMonth = formatDate(new Date(year, month + 1, 1))
  const lastDayOfNextMonth = formatDate(new Date(year, month + 2, 0))

  return (
    <Page>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>
      {(
        [STATUS.INITIALIZING, STATUS.LOADING].includes(dashboard.status) ||
        (dashboard.status === STATUS.LOADED && dashboard.filters.shopId.values.length > 1)
      ) && (
        <Page.Content>
          <Block className={style.filters}>
            <Block.Content>
              <SelectInput
                label={t({ id: 'filters.shopId' })}
                disabled={dashboard.status === STATUS.LOADING}
                value={dashboard.filters ? Number(dashboard.filters.shopId.selected) : ''}
                onChange={({ target: { value } }) => shopChanged(Number(value))}
                options={dashboard.filters ? dashboard.filters.shopId.values : []} />
            </Block.Content>
          </Block>
        </Page.Content>
      )}
      {dashboard.status === STATUS.LOADED && (
        <Page.Content className={style.blocks}>
          {dashboard.blocks.selfInterventions && (
            <Block className={join(style.block, style.selfInterventions)}>
              <Block.Title>{t({ id: 'blocks.selfInterventions.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}`}>
                  <div className={style.value}>{t({ id: 'blocks.selfInterventions.content.value' }, dashboard.blocks.selfInterventions )}</div>
                  <div className={style.label}>{t({ id: 'blocks.selfInterventions.content.label' }, )}</div>
                </Link>
              </Block.Content>
              {dashboard.blocks.selfInterventions.metrics && (
                <Block.Content className={style.metrics}>
                  <div className={style.wrapper}>
                    <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.selfInterventions.archived.value' }, dashboard.blocks.selfInterventions.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.selfInterventions.archived.label' })}</div>
                    </Link>
                    <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}&statusId=2&dateSince=${formatDate(new Date())}&dateTo=${formatDate(new Date())}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.selfInterventions.today.value' }, dashboard.blocks.selfInterventions.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.selfInterventions.today.label' })}</div>
                    </Link>
                    <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}&statusId=2`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.selfInterventions.planned.value' }, dashboard.blocks.selfInterventions.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.selfInterventions.planned.label' })}</div>
                    </Link>
                  </div>
                </Block.Content>
              )}
              <Block.Actions>
                <Button tag={RRDLink} to={`/interventions?shopId=${dashboard.filters.shopId.selected}`}>{t({ id: 'blocks.selfInterventions.linkName' })}</Button>
              </Block.Actions>
            </Block>
          )}
          {dashboard.blocks.globalInterventions && (
            <Block className={join(style.block, style.globalInterventions)}>
              <Block.Title>{t({ id: 'blocks.globalInterventions.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}`}>
                  <div className={style.value}>{t({ id: 'blocks.globalInterventions.content.value' }, dashboard.blocks.globalInterventions )}</div>
                  <div className={style.label}>{t({ id: 'blocks.globalInterventions.content.label' }, )}</div>
                </Link>
              </Block.Content>
              {dashboard.blocks.globalInterventions.metrics && (
                <Block.Content className={style.metrics}>
                  <div className={style.wrapper}>
                    <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.globalInterventions.archived.value' }, dashboard.blocks.globalInterventions.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.globalInterventions.archived.label' })}</div>
                    </Link>
                    <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}&statusId=2&dateSince=${formatDate(new Date())}&dateTo=${formatDate(new Date())}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.globalInterventions.today.value' }, dashboard.blocks.globalInterventions.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.globalInterventions.today.label' })}</div>
                    </Link>
                    <Link to={`/interventions?shopId=${dashboard.filters.shopId.selected}&statusId=2`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.globalInterventions.planned.value' }, dashboard.blocks.globalInterventions.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.globalInterventions.planned.label' })}</div>
                    </Link>
                  </div>
                </Block.Content>
              )}
              <Block.Actions>
                <Button tag={RRDLink} to={`/interventions?shopId=${dashboard.filters.shopId.selected}`}>{t({ id: 'blocks.globalInterventions.linkName' })}</Button>
              </Block.Actions>
            </Block>
          )}
          {dashboard.blocks.billings && (
            <Block className={join(style.block, style.billings)}>
              <Block.Title>{t({ id: 'blocks.billings.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <Link to={`/invoices?shopId=${dashboard.filters.shopId.selected}`}>
                  <div className={style.value}>{t({ id: 'blocks.billings.content.value' }, dashboard.blocks.billings )}</div>
                  <div className={style.label}>{t({ id: 'blocks.billings.content.label' }, )}</div>
                </Link>
              </Block.Content>
              {dashboard.blocks.billings.metrics && (
                <Block.Content className={style.metrics}>
                  <div className={style.wrapper}>
                    <Link to={`/invoices?shopId=${dashboard.filters.shopId.selected}&dateStart=${firstDayOfYear}&dateEnd=${lastDayOfYear}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.billings.year.value' }, dashboard.blocks.billings.metrics, )}</div>
                      <div className={style.label}>{intl.formatDate(new Date(), { year: 'numeric' })}</div>
                    </Link>
                    <Link to={`/invoices?shopId=${dashboard.filters.shopId.selected}&dateStart=${firstDayOfMonth}&dateEnd=${lastDayOfMonth}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.billings.currentMonth.value' }, dashboard.blocks.billings.metrics, )}</div>
                      <div className={style.label}>{intl.formatDate(new Date(), { month: 'long' })}</div>
                    </Link>
                    <Link to={`/invoices?shopId=${dashboard.filters.shopId.selected}&dateStart=${firstDayOfNextMonth}&dateEnd=${lastDayOfNextMonth}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.billings.lastMonth.value' }, dashboard.blocks.billings.metrics, )}</div>
                      <div className={style.label}>{intl.formatDate(new Date().setMonth(new Date().getMonth() - 1), { month: 'long' })}</div>
                    </Link>
                  </div>
                </Block.Content>
              )}
              {dashboard.blocks.billings.graph && (
                <Block.Content className={style.graph}>
                  <LinChart data={dashboard.blocks.billings.graph} />
                </Block.Content>
              )}
              <Block.Actions>
                <Button tag={RRDLink} to={`/invoices?shopId=${dashboard.filters.shopId.selected}`}>{t({ id: 'blocks.billings.linkName' })}</Button>
              </Block.Actions>
            </Block>
          )}
          {dashboard.blocks.estimates && (
            <Block className={join(style.block, style.estimates)}>
              <Block.Title>{t({ id: 'blocks.estimates.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <Link to={`/estimates?shopId=${dashboard.filters.shopId.selected}`}>
                  <div className={style.value}>{t({ id: 'blocks.estimates.content.value' }, dashboard.blocks.estimates )}</div>
                  <div className={style.label}>{t({ id: 'blocks.estimates.content.label' }, )}</div>
                </Link>
              </Block.Content>
              {dashboard.blocks.estimates.metrics && (
                <Block.Content className={style.metrics}>
                  <div className={style.wrapper}>
                    <Link to={`/invoices?shopId=${dashboard.filters.shopId.selected}&dateStart=${firstDayOfYear}&dateEnd=${lastDayOfYear}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.estimates.year.value' }, dashboard.blocks.estimates.metrics, )}</div>
                      <div className={style.label}>{intl.formatDate(new Date(), { year: 'numeric' })}</div>
                    </Link>
                    <Link to={`/invoices?shopId=${dashboard.filters.shopId.selected}&dateStart=${firstDayOfMonth}&dateEnd=${lastDayOfMonth}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.estimates.currentMonth.value' }, dashboard.blocks.estimates.metrics, )}</div>
                      <div className={style.label}>{intl.formatDate(new Date(), { month: 'long' })}</div>
                    </Link>
                    <Link to={`/invoices?shopId=${dashboard.filters.shopId.selected}&dateStart=${firstDayOfNextMonth}&dateEnd=${lastDayOfNextMonth}`} className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.estimates.lastMonth.value' }, dashboard.blocks.estimates.metrics, )}</div>
                      <div className={style.label}>{intl.formatDate(new Date().setMonth(new Date().getMonth() - 1), { month: 'long' })}</div>
                    </Link>
                  </div>
                </Block.Content>
              )}
              <Block.Actions>
                <Button tag={RRDLink} to={`/estimates?shopId=${dashboard.filters.shopId.selected}`}>{t({ id: 'blocks.estimates.linkName' })}</Button>
              </Block.Actions>
            </Block>
          )}
          {dashboard.blocks.clients && (
            <Block className={join(style.block, style.clients)}>
              <Block.Title>{t({ id: 'blocks.clients.title' })}</Block.Title>
              <Block.Content className={style.content}>
                <Link to={`/clients?shopId=${dashboard.filters.shopId.selected}`}>
                  <div className={style.value}>{t({ id: 'blocks.clients.content.value' }, dashboard.blocks.clients )}</div>
                  <div className={style.label}>{t({ id: 'blocks.clients.content.label' }, )}</div>
                </Link>
              </Block.Content>
              {dashboard.blocks.clients.metrics && (
                <Block.Content className={style.metrics}>
                  <div className={style.wrapper}>
                    <div className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.clients.archived.value' }, dashboard.blocks.clients.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.clients.archived.label' })}</div>
                    </div>
                    <div className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.clients.today.value' }, dashboard.blocks.clients.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.clients.today.label' })}</div>
                    </div>
                    <div className={style.metric}>
                      <div className={style.value}>{t({ id: 'blocks.clients.planned.value' }, dashboard.blocks.clients.metrics, )}</div>
                      <div className={style.label}>{t({ id: 'blocks.clients.planned.label' })}</div>
                    </div>
                  </div>
                </Block.Content>
              )}
              <Block.Actions>
                <Button tag={RRDLink} to={`/clients?shopId=${dashboard.filters.shopId.selected}`}>{t({ id: 'blocks.clients.linkName' })}</Button>
              </Block.Actions>
            </Block>
          )}
          <Block className={join(style.block, style.shortcuts)}>
            <Block.Title>{t({ id: 'blocks.shortcuts.title' })}</Block.Title>
            <Block.Content className={style.buttons}>
              {!isReader && isNotBasic && <Button tag={RRDLink} to="/interventions/new">{t({ id: 'blocks.shortcuts.newIntervention' })}</Button>}
              {!isReader && isNotBasic && <Button tag={RRDLink} outlined to="/interventions/express">{t({ id: 'blocks.shortcuts.expressIntervention' })}</Button>}
              <Button tag={RRDLink} outlined to="/planning">{t({ id: 'blocks.shortcuts.planning' })}</Button>
              {!isReader && isNotBasic && <Button tag={RRDLink} outlined to="/clients/new">{t({ id: 'blocks.shortcuts.clients' })}</Button>}
              {isNotBasic && <Button tag={RRDLink} outlined to="/equipments">{t({ id: 'blocks.shortcuts.equipments' })}</Button>}
            </Block.Content>
          </Block>
        </Page.Content>
      )}
    </Page>
  )
}
