import { useEffect, useState, } from 'react'
import { useApi } from 'react-rest-api'

import { AddressInput, } from '@velogik/component-address-input'
import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { ButtonIcon, } from '@velogik/component-button-icon'
import { Form, useFormReducer, FORM_ACTION, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useIntl, useFormatError } from '@velogik/component-intl'
import { Modal, } from '@velogik/component-modal'
import { PhoneInput, } from '@velogik/component-phone-input'
import { wait, STATUS, } from '@velogik/helper-promise'
import { noop, } from '@velogik/helper-utils'

import style from './EditClientModal.module.scss'

export function EditClientModal ({
  clientState,
  isOpen, close,
  onSave = noop,
  tPrefix = 'editClientModal',
}) {
  const api = useApi()
  const t = useT(tPrefix)
  const intl = useIntl()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  function handleSave (data) {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/clients/details', { body: JSON.stringify(dataState.current) }))
      .then(payload => onSave(payload))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload }))
  }

  useEffect(() => {
    if (isOpen && clientState) {
      dataDispatch({ type: FORM_ACTION.INIT, value: clientState.payload })
    }
  },[isOpen, clientState, dataDispatch])

  useEffect(() => {
    if (!isOpen) {
      setPostState({ status: STATUS.IDLE })
    }
  }, [isOpen])

  return isOpen && (
    <Modal
      isOpen={isOpen}
      toggle={() => close()}
      className={style.modal}
      innerClassName={style.innerModal}>
      <Form onSubmit={handleSave} state={dataState} dispatcher={dataDispatch}
        disabled={postState.status === STATUS.LOADING}>
        <Block>
          <Block.Title className={style.title}>
            <div>{t({ id: 'title' })}</div>
            <ButtonIcon
              src={require('assets/icons/cross-black.svg').default}
              className={style.cross}
              onClick={close} />
          </Block.Title>
          <Block.Content>
            <Input name="firstName" type="text" label={t({ id: 'firstName.label' })} placeholder={t({ id: 'firstName.placeholder' })} />
            <Input name="lastName" type="text" label={t({ id: 'lastName.label' })} placeholder={t({ id: 'lastName.placeholder' })} />
            <Input name="email" type="email" label={t({ id: 'email.label' })} placeholder={t({ id: 'email.placeholder' })} />
            <PhoneInput name="phoneNumber" type="phone" label={t({ id: 'phoneNumber.label' })} placeholder={t({ id: 'phoneNumber.placeholder' })} displayInitialValueAsLocalNumber={true} defaultCountry={intl.locale.toUpperCase()} />
            <AddressInput name="addressFull"
              label={t({ id: 'addressFull.label' })}
              placeholder={t({ id: 'addressFull.placeholder' })} />
            <Input name="addressComplement" type="text"
              label={t({ id: 'addressComplement.label' })}
              placeholder={t({ id: 'addressComplement.placeholder' })} />
          </Block.Content>
          <Block.Actions>
            {postState.status === STATUS.ERROR && (
              <Alert>{formatError(postState.payload)}</Alert>
            )}
            <Button onClick={() => handleSave()}>
              {t({ id: 'save' })}
            </Button>
          </Block.Actions>
        </Block>
      </Form>
    </Modal>
  )
}
