import { InterventionList, } from 'pages/InterventionList'
import { InterventionNew, } from 'pages/InterventionNew'
import { InterventionExpress, } from 'pages/InterventionNew.Express'
import { InterventionDetails, } from 'pages/InterventionDetails'

export const Intervention = {
  List: InterventionList,
  New: InterventionNew,
  Details: InterventionDetails,
  Express: InterventionExpress,
}
