import { useEffect, useState, } from 'react'
import { Link as RRDLink, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Link, } from '@velogik/component-link'
import { Loading, } from '@velogik/component-loading'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'

import { useHasProfile, useHasService, } from 'hooks/use-user'

import { BackButton, } from '../components/BackButton'

import usersLogo from 'assets/admin.svg'

import style from './ShopList.module.scss'

export function ShopList() {
  const api = useApi()
  const [dataState, setDataState] = useState({ status: STATUS.INITIALIZING })

  const canAdminNetwork = useHasProfile('admin_velocenter', 'admin_network')
  const canCreateShop = useHasProfile('admin_velocenter', 'admin_network')
  const isNotBasic = useHasService('admin', 'network', 'premium', 'custom')
  const canAccessSharedPlannings = useHasProfile('admin_velocenter', 'admin_network')

  const t = useT()
  const formatError = useFormatError()

  useEffect(() => {
    wait(api.get('/shops/list'))
      .then(payload => setDataState({ status: STATUS.LOADED, payload }))
      .catch(payload => setDataState({ status: STATUS.ERROR, payload }))
  }, [api])

  function ShopItem({ shop }) {
    return (
      <div className={style.ShopItem}>
        <span className={style.blockTitle}>{t({ id: 'workshop.infos.name' }, shop)}</span>
        {shop.networkName && <span className={style.networkName}>{t({ id: 'workshop.infos.network' }, shop)}</span>}
        <Link to={`/users?shopId=${shop.shopId}`} className={style.userCount}>{t({ id: 'workshop.infos.userCount' }, shop)}</Link>
        {canAccessSharedPlannings && <Link to={`/sharedPlannings?shopId=${shop.shopId}`} className={style.userCount}>{t({ id: 'workshop.infos.sharedPlanningsCount' }, shop)}</Link>}
        <span className={style.updateTitle}>{t({ id: 'workshop.update.title' })}</span>
        {canCreateShop && <Button tag={RRDLink} to={`/shops/${shop.shopId}/details`} className={style.updateDetails}>{t({ id: 'workshop.update.details' })}</Button>}
        {isNotBasic && <Button tag={RRDLink} to={`/shops/${shop.shopId}/legal`} className={style.updateLegal}>{t({ id: 'workshop.update.legal' })}</Button>}
        <Button tag={RRDLink} to={`/shops/${shop.shopId}/marketplace`} className={style.updateMarketplace}>{t({ id: 'workshop.update.marketplace' })}</Button>
        <Button tag={RRDLink} to={`/shops/${shop.shopId}/pieces`} className={style.updatePieces}>{t({ id: 'workshop.update.pieces' })}</Button>
      </div>
    )
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
        <Page.Header.Actions>
          {canAdminNetwork && (
            <Button tag={RRDLink} to={'/networks/admin'}>{t({ id: 'actions.adminNetwork' })}</Button>
          )}
          {canCreateShop && (
            <Button tag={RRDLink} to={'/shops/new'}>{t({ id: 'actions.new' })}</Button>
          )}
        </Page.Header.Actions>
      </Page.Header>

      {dataState.status === STATUS.INITIALIZING && <Loading>{t({ id: 'initializing' })}</Loading>}
      {dataState.status === STATUS.ERROR && <Alert>{formatError(dataState.payload)}</Alert>}
      {dataState.status === STATUS.LOADED && (
        <Page.Content className={style.pageContent}>
          <section className={style.side}>
            <header className={style.sectionTitle}>{t({ id: 'users.title' })}</header>
            <Block className={style.block}>
              <Block.Content className={style.content}>
                <span className={style.title}>{t({ id: 'users.count' }, { count: dataState.payload.usersCount })}</span>
                <img src={usersLogo} className={style.logo} alt={t({ id: 'users.logoAlt' })} />
                <Button tag={RRDLink} to="/users" className={style.list}>{t({ id: 'users.list' })}</Button>
                <Button tag={RRDLink} to="/users/new" className={style.new} outlined>{t({ id: 'users.new' })}</Button>
              </Block.Content>
            </Block>

            {canAccessSharedPlannings && <header className={style.sectionTitle}>{t({ id: 'sharedPlannings.title' })}</header>}
            {canAccessSharedPlannings && <Block className={style.block}>
              <Block.Content className={style.content}>
                <span className={style.title}>{t({ id: 'sharedPlannings.count' }, { count: dataState.payload.sharedPlanningsCount })}</span>
                <Button tag={RRDLink} to="/sharedPlannings" className={style.list}>{t({ id: 'sharedPlannings.list' })}</Button>
                <Button tag={RRDLink} to="/sharedPlannings/new" className={style.new} outlined>{t({ id: 'sharedPlannings.new' })}</Button>
              </Block.Content>
            </Block>}
          </section>
          <section className={style.workshops}>
            <header className={style.sectionTitle}>{t({ id: 'workshops.title' })}</header>
            <div className={style.sectionContent}>
              {dataState.payload.shops.length > 0 && dataState.payload.shops.map(shop => <ShopItem key={shop.shopId} shop={shop} />)}
              {dataState.payload.shops.length === 0 && <Alert type="danger">{t({ id: 'workshops.noShops' })}</Alert>}
            </div>
          </section>
        </Page.Content>
      )}
    </Page>
  )
}
