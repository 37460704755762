import { useEffect, useRef, useState, useCallback, } from 'react'
import { Sheet, } from './Sheet'
import { Item, } from './Planning.Item'

import { join, } from '@velogik/helper-classnames'
import { arraying, noop, } from '@velogik/helper-utils'

import style from './Planning.module.scss'

export function PlanningRow ({
  className,
  HeadHeaderRender, RowHeaderRender, ColumnHeaderRender,
  onSlotClick = noop,
  ItemRender = Item,
  onRowClick = noop, onRowDoubleClick = noop,
  TooltipRender = null,
  onTooltipOpen = noop,
  onDrop = noop,
  disabled = false,
  ...props
}) {
  const [showTooltip, setShowTooltip] = useState(null)
  const tooltipRef = useRef()

  const setupTooltipPosition = useCallback(() => {
    const tooltip = tooltipRef.current
    if (showTooltip && tooltip) {
      const itemClientRect = showTooltip.itemRef.getBoundingClientRect()

      const itemPos = {
        left: document.documentElement.scrollLeft + itemClientRect.left + (itemClientRect.width / 2),
        top: document.documentElement.scrollTop + itemClientRect.top + (itemClientRect.height / 2),
      }

      const windowSize = {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      }

      const tooltipClientRect = tooltip.getBoundingClientRect()

      if (itemPos.left + tooltipClientRect.width > windowSize.width) {
        tooltip.style.right = '0px'
      } else {
        tooltip.style.left = `${itemPos.left}px`
      }

      tooltip.style.top = `${itemPos.top}px`
    }
  }, [showTooltip])

  useEffect(() => onTooltipOpen(showTooltip), [onTooltipOpen, showTooltip])

  useEffect(() => {
    setupTooltipPosition()
  }, [showTooltip, setupTooltipPosition])

  return (
    <>
      <Sheet className={join(style.planning, style.planningRow, className)}
        headHeaderRender={HeadHeaderRender || null}
        columnHeaderRender={(column) => <div className={style.columnHead}><ColumnHeaderRender column={column} /></div>}
        rowHeaderRender={(row) => <div className={style.rowHead}><RowHeaderRender row={row} /></div>}
        rowRender={(row, columns, i) => row.rowRender ? row.rowRender(row, columns, i) : (
          <td colSpan={columns.length}>
            <div className={style.row}
              onClick={e => onRowClick(e, row)}
              onDragOver={e => {
                if (onDrop && !disabled) {
                  e.preventDefault()
                  e.dataTransfer.dropEffect = 'move'
                }
              }}
              onDrop={e => !disabled && onDrop(e, row)}
              onDoubleClick={e => !disabled && onRowDoubleClick(e, row)}
            >
              <div className={style.splitters}>
                {arraying(columns.length - 1).map((_, j) => (
                  <div key={j} className={style.splitter} />
                ))}
              </div>
              {arraying(row.groups).map((_, j) => (
                <div key={`line.${row.userId}.${j}`} className={style.shadowLine}>
                  {row.items.filter(_ => _.line === j + 1).map((item, k) => (
                    <ItemRender key={`${i}.${j}.${k}`}
                      className={join(style.item)}
                      item={item}
                      draggable={Boolean(item.updatable)}
                      disabled={disabled}

                      onMouseEnter={e => {
                        if (TooltipRender) {
                          setShowTooltip({item, itemRef: e.currentTarget})
                        }
                      }}
                      onMouseLeave={e => {
                        if (TooltipRender) {
                          if (tooltipRef.current && !tooltipRef.current.contains(e.relatedTarget)) {
                            setShowTooltip(null)
                          }
                        }
                      }}

                      onClick={(e) => {
                        e.stopPropagation()
                        onSlotClick(item)
                      }}
                      onDoubleClick={e => {
                        e.stopPropagation()
                      }}
                      onDragStart={e => {
                        if (item.updatable && !disabled) {
                          setShowTooltip(null)
                          e.dataTransfer.setData('text/plain', JSON.stringify(item))
                        }
                      }}

                      style={{
                        left: `${item.timeStartPercent}%`,
                        width: `${item.durationPercent}%`,
                      }} />
                  ))}
                </div>
              ))}
            </div>
          </td>
        )}
        {...props} />
      {showTooltip && TooltipRender && (
        <TooltipRender
          ref={tooltipRef}
          className={style.tooltip}
          item={showTooltip.item}
          onDataLoaded={() => setupTooltipPosition()}
          onMouseLeave={e => {
            if (showTooltip.itemRef && !showTooltip.itemRef.contains(tooltipRef.current)) {
              setShowTooltip(null)
            }
          }} />
      )}
    </>
  )
}

export function PlanningCol ({
  className,
  HeadHeaderRender, RowHeaderRender, ColumnHeaderRender,
  onSlotClick = noop,
  ItemRender = Item,
  TooltipRender = null,
  onTooltipOpen = noop,
  onDrop = noop,
  disabled = false,
  ...props
}) {
  const [showTooltip, setShowTooltip] = useState(null)
  const tooltipRef = useRef()

  const setupTooltipPosition = useCallback(() => {
    const tooltip = tooltipRef.current
    if (showTooltip && tooltip) {
      const itemClientRect = showTooltip.itemRef.getBoundingClientRect()

      const itemPos = {
        left: document.documentElement.scrollLeft + itemClientRect.left + (itemClientRect.width / 2),
        top: document.documentElement.scrollTop + itemClientRect.top + (itemClientRect.height / 2),
      }

      const windowSize = {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      }

      const tooltipClientRect = tooltip.getBoundingClientRect()

      if (itemPos.left + tooltipClientRect.width > windowSize.width) {
        tooltip.style.right = '0px'
      } else {
        tooltip.style.left = `${itemPos.left}px`
      }

      tooltip.style.top = `${itemPos.top}px`
    }
  }, [showTooltip])

  useEffect(() => onTooltipOpen(showTooltip), [onTooltipOpen, showTooltip])

  useEffect(() => {
    setupTooltipPosition()
  }, [showTooltip, setupTooltipPosition])

  return (
    <>
      <Sheet className={join(style.planning, style.planningColumn, className)}
        headHeaderRender={HeadHeaderRender || null}
        columnHeaderRender={(column) => <div className={style.columnHead}><ColumnHeaderRender column={column} /></div>}
        rowHeaderRender={(row) => <div className={style.rowHead}><RowHeaderRender row={row} /></div>}
        rowRender={(row, columns, i, { rows }) => i === 0 ? (
          columns.map((column, j) => <td key={`${i}-${j}`} rowSpan={rows.length}>
            <div className={style.wrapper}>
              <div className={style.splitters}>
                {arraying(rows.length).map((_, j) => (
                  <div key={j} className={style.splitter} />
                ))}
              </div>
              {arraying(column.groups).map((_, j) => (
                <div key={`line.${row.userId}.${j}`} className={style.shadowLine}
                  onDragOver={e => {
                    if (onDrop && !disabled) {
                      e.preventDefault()
                      e.dataTransfer.dropEffect = 'move'
                    }
                  }}
                  onDrop={e => !disabled && onDrop(e, column)}
                  style={{
                    width: `calc(100% / ${column.groups})`,
                    left: `calc(100% / ${column.groups} * ${j})`,
                  }}>
                  {column.items.filter(_ => _.line === j + 1).map((item, k) => (
                    <ItemRender key={`${i}.${j}.${k}`}
                      className={join(style.item)}
                      item={item}
                      draggable={Boolean(item.updatable)}
                      disabled={disabled}

                      onClick={() => onSlotClick(item)}

                      onMouseEnter={e => {
                        if (TooltipRender) {
                          setShowTooltip({item, itemRef: e.currentTarget})
                        }
                      }}
                      onMouseLeave={e => {
                        if (TooltipRender) {
                          if (tooltipRef.current && !tooltipRef.current.contains(e.relatedTarget)) {
                            setShowTooltip(null)
                          }
                        }
                      }}
                      onDragStart={e => {
                        if (item.updatable && !disabled) {
                          setShowTooltip(null)
                          e.dataTransfer.setData('text/plain', JSON.stringify(item))
                        }
                      }}

                      style={{
                        top: `${item.timeStartPercent}%`,
                        height: `${item.durationPercent}%`,
                      }} />
                  ))}
                </div>
              ))}
            </div>
          </td>)
        ) : null}
        {...props}/>
      {showTooltip && TooltipRender && (
        <TooltipRender
          ref={tooltipRef}
          className={style.tooltip}
          item={showTooltip.item}
          onDataLoaded={setupTooltipPosition}
          onMouseLeave={e => {
            if (showTooltip.itemRef && !showTooltip.itemRef.contains(tooltipRef.current)) {
              setShowTooltip(null)
            }
          }} />
      )}
    </>
  )
}
