import { useState, } from 'react'
import { useApi, } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'

export function ForgotPassword() {
  const api = useApi()
  const t = useT()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer(FORM_STATUS.INITIALIZED)
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  function newPassword () {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/auth/reinit', { body: JSON.stringify(dataState.current) }))
      .then(payload => {
        // TODO:
        dataDispatch({ type: FORM_ACTION.INIT, value: payload, })
        setPostState({ status: STATUS.LOADED, payload })
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  return (
    <Page>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        <Form onSubmit={newPassword}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING && !dataState.current}>
          <Form.Content>
            <Block>
              <Block.Content>
                <Input name="email" type="email"
                  label={t({ id: 'email.label' })}
                  placeholder={t({ id: 'email.placeholder' })} />

                {postState.status === STATUS.ERROR && <Alert>{formatError(postState.payload)}</Alert>}
                {postState.status === STATUS.LOADED && <Alert type="success">{t({ id: 'success' })}</Alert>}
              </Block.Content>

              <Block.Actions>
                <Button type="submit">{t({ id: 'valid' })}</Button>
              </Block.Actions>
            </Block>
          </Form.Content>
        </Form>
      </Page.Content>
    </Page>
  )
}
