import { useMemo, } from 'react'

import { join, } from '@velogik/helper-classnames'

import style from './Accordion.module.scss'

import chevron from '../assets/icons/chevron-top-black.svg'

export function Accordion ({
  id, title,
  children,
  className: _className,
  titleClass: _titleClass,
  contentClass: _contentClass,
  forceDisplayOnDesktop,
  ...props
}) {
  const className = useMemo(() => join(style.root, forceDisplayOnDesktop && style.forceDisplayOnDesktop, _className), [_className, forceDisplayOnDesktop])
  const titleClass = useMemo(() => join(style.title, forceDisplayOnDesktop && style.forceDisplayOnDesktop, _titleClass), [_titleClass, forceDisplayOnDesktop])
  const contentClass = useMemo(() => join(style.content, forceDisplayOnDesktop && style.forceDisplayOnDesktop, _contentClass), [_contentClass, forceDisplayOnDesktop])

  return (
    <div className={className} {...props}>
      <input id={`accordion-${id}`} type="checkbox" className={style.titleCheckbox} />
      <label htmlFor={`accordion-${id}`} className={titleClass}>
        {title}
        <img className={style.chevron} src={chevron} alt="" />
      </label>
      <div className={contentClass}>
        {children}
      </div>
    </div>
  )
}
