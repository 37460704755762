import { useState, } from 'react'
import { useApi, } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_STATUS, FORM_ACTION, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, } from '@velogik/component-intl'
import { useNotify, } from '@velogik/component-notifications'
import { Page, } from '@velogik/component-page'
import { wait, STATUS, } from '@velogik/helper-promise'

export function Account () {
  const api = useApi()
  const t = useT()
  const { appState, appDispatch, } = useApp()
  const { notify, } = useNotify()

  const [dataState, dataDispatch] = useFormReducer(FORM_STATUS.INITIALIZED, appState.user)
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  function submit () {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/users/me', { body: JSON.stringify(dataState.current) }))
      .then(payload => {
        appDispatch({ type: 'refreshUser', payload })
        notify(t({ id: 'saved' }))
        dataDispatch({ type: FORM_ACTION.PRISTINE, })
        setPostState({ status: STATUS.IDLE })
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  return (
    <Page>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>
      <Page.Content>
        <Form onSubmit={() => submit()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Content>
                <Input name="firstName" label={t({ id: 'firstName.label' })} />
                <Input name="lastName" label={t({ id: 'lastName.label' })} />
                <Input name="email" label={t({ id: 'email.label' })} disabled />
              </Block.Content>
              <Block.Actions>
                <Button type="submit">{t({ id: 'submit' })}</Button>
              </Block.Actions>
            </Block>
          </Form.Content>
        </Form>
      </Page.Content>
    </Page>
  )
}
