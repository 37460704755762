import { Alert, } from '@velogik/component-alert'
import { ButtonIcon, } from '@velogik/component-button-icon'
import { Input, } from '@velogik/component-input'
import { Label, } from '@velogik/component-label'
import { useIntl, useFormatError } from '@velogik/component-intl'

import { join, } from '@velogik/helper-classnames'
import { STATUS, } from '@velogik/helper-promise'
// import { useDebounceFunction, } from '@velogik/hook-debounce'

import style from './Itvcode.module.scss'

export function Itvcode ({ data, postState, hidePrice, onRemove, onChange, }) {
  const { formatMessage, } = useIntl()
  const formatError = useFormatError()

  // TODO: debounce for priceChange
  // const onChangeDebounce = useDebounceFunction(onChange, 250)

  const matchingErr = postState
    && postState.status === STATUS.ERROR
    && postState.payload.code === 'itvcodesInvalid' // TODO: define real error code
    && postState.payload.params.codes.find(code => code.id === data.itvcodeId)

  function handleQuantityChange (newQuantity) {
    onChange({ target: {
      type: 'text',
      name: 'quantity',
      value: newQuantity < 1 ? 1 : newQuantity >= Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : newQuantity
    }})
  }

  return (
    <div className={style.root}>
      <div className={join(style.header, style[data.type])}>
        <span className={style.label}>
          {data.itvcodeIdentifier} - {data.itvcodeLabel}
          <Input name="priceWt"
            className={style.priceWt}
            type="number"
            min="0"
            step="any"
            value={data.priceWt}
            onChange={onChange} />
        </span>
        <ButtonIcon src={require('assets/icons/cross-red.svg').default} rounded={true} danger
          onClick={onRemove} />
      </div>
      <div className={style.checks}>
        {data.isDontChargeAvailable && <Input type="checkbox" name="dontCharge"
          className={style.dontCharge}
          id={`itvcode.dontCharge.${data.itvcodeId}`}
          value={data.dontCharge}
          label={formatMessage({ id: 'Itvcode.dontCharge.label' })}
          onChange={({ target: { name, checked } }) => {
            onChange({ target: { name, value: checked } })
          }} />}
        {data.isSavAvailable && <Input type="checkbox" name="isSav"
          className={style.asv}
          id={`itvcode.asv.${data.itvcodeId}`}
          value={data.isSav}
          label={formatMessage({ id: 'Itvcode.asv.label' })}
          onChange={({ target: { name, checked } }) => {
            onChange({ target: { name, value: checked } })
          }} />}
      </div>
      <Label className={style.label}>{formatMessage({ id: 'Itvcode.quantity.label' })}</Label>
      <div className={style.quantity}>
        <ButtonIcon src={require('assets/icons/minus-blue.svg').default} onClick={() => handleQuantityChange(data.quantity - 1)} />
        <span className={style.total}>{data.quantity}</span>
        <ButtonIcon src={require('assets/icons/plus-blue.svg').default} onClick={() => handleQuantityChange(data.quantity + 1)} />
      </div>
      <Label className={style.label} htmlFor="comments">{formatMessage({ id: 'Itvcode.comments.label' })}</Label>
      <Input type="textarea" name="comments"
        className={style.comments}
        value={data.comments}
        placeholder={formatMessage({ id: 'Itvcode.comments.placeholder' })}
        onChange={onChange} />
      {matchingErr && <Alert type="warning" className={style.error}>{formatError({ code: matchingErr.reason })}</Alert>}
    </div>
  )
}
