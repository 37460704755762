import { useEffect, useState, } from 'react'
import { useHistory, useLocation, Link as RRDLink, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_STATUS, FORM_ACTION, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { ImageInput, } from '@velogik/component-image-input'
import { Link, } from '@velogik/component-link'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'
import { useT, useFormatError, } from '@velogik/component-intl'
import { noop, } from '@velogik/helper-utils'

import { BackButton, } from 'components/BackButton'
import { useHasProfile, } from 'hooks/use-user'

function adjustColor(color, amount) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
}

const editableStyles = [
  { 
    cssSelector: '--theme-color',
    defaultValue: '#f2bf00',
    onChange: value => ({
      '--theme-color': value,
      '--theme-light-color': adjustColor(value, 20),
      '--theme-dark-color': adjustColor(value, -20),
    }),
  },
  {
    cssSelector: '--theme-contrast-color',
    defaultValue: '#222222',
    onChange: value => ({
      '--theme-contrast-color': value,
      '--theme-contrast-dark-color': adjustColor(value, -20),
    }),
  },
  {
    cssSelector: '--app-bckg-color',
    defaultValue: '#fffdf8',
    onChange: value => ({
      '--app-bckg-color': value,
    }),
  },
]

export function NetworkAdmin () {
  const history = useHistory()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const api = useApi()
  const t = useT()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })
  const [networks, setNetworks] = useState({ status: STATUS.INITIALIZING })

  const isAdminVelocenter = useHasProfile('admin_velocenter')

  const selectedNetwork = parseInt(query.get('networkId'))

  useEffect(() => {
    wait(api.get('/networks/list'))
      .then(payload => setNetworks({ status: STATUS.LOADED, payload, }))
      .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
  }, [api, dataDispatch])

  useEffect(function () {
    const query = new URLSearchParams(location.search)
    const networkId = query.get('networkId')

    if (!networkId && networks.status === STATUS.LOADED) {
      if (networks.payload.length > 0) {
        const query = new URLSearchParams({ 'networkId': networks.payload[0].networkId })
        history.push(`?${query}`)
      } else {
        dataDispatch({ type: FORM_ACTION.ERROR, value: { code: 'noNetworks', prefix: 'front' }, })
      }
    }
  }, [history, dataDispatch, networks, location.search])

  useEffect(() => {
    if (selectedNetwork) {
      dataDispatch({ type: FORM_ACTION.INIT, value: {} })
      setPostState({ status: STATUS.LOADING })
      wait(api.get('/networks/whitelabel', undefined, { networkId: selectedNetwork }))
        .then(payload => {
          dataDispatch({ type: FORM_ACTION.INIT, value: {...payload, logo: { picUrl: '', picType: 'header' }}, })
          setPostState({ status: STATUS.IDLE, })
        })
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
    }
  }, [api, dataDispatch, selectedNetwork,])

  function post () {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/networks/whitelabel', { body: JSON.stringify(dataState.current) }))
      .then(payload => {
        dataDispatch({ type: FORM_ACTION.INIT, value: payload, })
        setPostState({ status: STATUS.LOADED, payload, })
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function picInputChange (file, initialValue) {
    return api.put('/networks/logo', { body: file }, { networkId: selectedNetwork, picType: initialValue.picType })
      .then(payload => ({ ...payload, picType: initialValue.picType }))
  }

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}
        >
          <Form.Content>

            {networks.status === STATUS.LOADED && networks.payload.length > 1 && (
              <Block className={'fill-grid'}>
                <Block.Title>{t({ id: 'networks.title' })}</Block.Title>
                <Block.Content>
                  <SelectInput name="networkId"
                    value={selectedNetwork}
                    onChange={({ target: { value } }) => {
                      const query = new URLSearchParams({ 'networkId': value })
                      history.push(`?${query}`)
                    }}
                    disabled={false}
                    options={networks.payload.map(option => ({
                      value: option.networkId,
                      label: option.networkName,
                    }))}
                  />
                  {!selectedNetwork && <Alert type="warning">{t({ id: 'missingNetwork' })}</Alert>}
                </Block.Content>
              </Block>
            )}

            <Block>
              <Block.Title>{t({ id: 'header.title' })}</Block.Title>
              {dataState.current && dataState.current.headers && (
                <Block.Content>
                  <ImageInput name="logo"
                    label={t({ id: 'header.logo.label' })}
                    buttonLabel={t({ id: 'header.logo.button' })}
                    accept="image/svg+xml"
                    onChange={noop}
                    onPictureChange={picInputChange} />
                  <Input
                    id="link1"
                    type="checkbox"
                    label={t({ id: 'header.link1.checkbox' })}
                    value={Boolean(dataState.current.headers[0])}
                    onChange={({ target }) => {
                      const headers = [...dataState.current.headers]
                      headers[0] = target.checked ? { order: 1, label: '', url: '' } : null
                      dataDispatch({ target: { type: 'text', name: 'headers', value: headers } })
                    }} />
                  {dataState.current.headers[0] && <Input
                    type="text" name="label"
                    label={t({ id: 'header.link1.label' })}
                    value={dataState.current.headers[0].label}
                    onChange={({ target }) => dataDispatch({
                      type: 'updateItem',
                      name: 'headers',
                      value: dataState.current.headers[0],
                      target
                    })} />}
                  {dataState.current.headers[0] && <Input
                    type="text" name="url"
                    label={t({ id: 'header.link1.url' })}
                    value={dataState.current.headers[0].url}
                    onChange={({ target }) => dataDispatch({
                      type: 'updateItem',
                      name: 'headers',
                      value: dataState.current.headers[0],
                      target
                    })} />}
                  <Input
                    id="link2"
                    type="checkbox"
                    label={t({ id: 'header.link2.checkbox' })}
                    value={Boolean(dataState.current.headers[1])}
                    onChange={({ target }) => {
                      const headers = [...dataState.current.headers]
                      headers[1] = target.checked ? { order: 2, label: '', url: '' } : null
                      dataDispatch({ target: { type: 'text', name: 'headers', value: headers } })
                    }} />
                  {dataState.current.headers[1] && <Input
                    type="text" name="label"
                    label={t({ id: 'header.link2.label' })}
                    value={dataState.current.headers[1].label}
                    onChange={({ target }) => dataDispatch({
                      type: 'updateItem',
                      name: 'headers',
                      value: dataState.current.headers[1],
                      target
                    })} />}
                  {dataState.current.headers[1] && <Input
                    type="text" name="url"
                    label={t({ id: 'header.link2.url' })}
                    value={dataState.current.headers[1].url}
                    onChange={({ target }) => dataDispatch({
                      type: 'updateItem',
                      name: 'headers',
                      value: dataState.current.headers[1],
                      target
                    })} />}
                </Block.Content>
              )}
            </Block>
            <Block>
              <Block.Title>{t({ id: 'style.title' })}</Block.Title>
              <Block.Content>
                {dataState.current && dataState.current.style && editableStyles.map(({ cssSelector, defaultValue, onChange }) => (
                  <Input
                    key={cssSelector}
                    name={cssSelector}
                    value={dataState.current.style[cssSelector] || defaultValue}
                    label={cssSelector}
                    type="color"
                    onChange={({ target: { value } }) => {
                      const result = onChange(value)
                      dataDispatch({
                        target: {
                          type: 'text',
                          name: 'style',
                          value: Object.assign({}, dataState.current.style, result)
                        }
                      })
                    }}
                  />
                ))}
              </Block.Content>

              <Block.Title>{t({ id: 'style.preview.title' })}</Block.Title>
              {dataState.current && dataState.current.style && (
                <Block.Content style={{
                  background: 'var(--app-bckg-color)',
                  ...dataState.current.style
                }}>
                  <Button>{t({ id: 'style.preview.button' })}</Button>
                  <Button outlined>{t({ id: 'style.preview.button' })}</Button>
                  <Link tag="span" onClick={noop}>{t({ id: 'style.preview.link' })}</Link>
                </Block.Content>
              )}
            </Block>
            <Block>
              <Block.Title>{t({ id: 'config.title' })}</Block.Title>
              <Block.Content>
                <Input
                  label={t({ id: 'config.GTMId.label' })}
                  placeholder={t({ id: 'config.GTMId.placeholder' })}
                  name="GTMId" />
                {isAdminVelocenter && <Input
                  label={t({ id: 'config.domainUrl.label' })}
                  placeholder={t({ id: 'config.domainUrl.placeholder' })}
                  name="domainUrl" />}
                <Button  tag={RRDLink} to={{ pathname: 'asvs', search: location.search }}>
                  {t({ id: 'config.asvs' })}
                </Button>
              </Block.Content>
            </Block>
          </Form.Content>

          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
