import { useState, } from 'react'

import { Button, } from '@velogik/component-button'
import { Modal, } from '@velogik/component-modal'

export function ButtonModal ({
  ModalComponent,
  modalProps = {},
  buttonProps = {},
  children,
}) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)} {...buttonProps}>
        {children}
      </Button>
      <Modal isOpen={open} toggle={() => setOpen(!open)}>
        <ModalComponent close={() => setOpen(false)} props={modalProps} />
      </Modal>
    </>
  )
}
