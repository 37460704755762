import { useState, useEffect, useMemo, } from 'react'
import { useApi } from 'react-rest-api'
import { Link, useHistory, useLocation, useParams, } from 'react-router-dom'

import { useApp, } from '@velogik/component-app'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_STATUS, FORM_ACTION } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, useIntl, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { Modal, } from '@velogik/component-modal'
import { useNotify, } from '@velogik/component-notifications'
import { Page, } from '@velogik/component-page'
import { SelectInput, SelectFilter, } from '@velogik/component-select-input'
import { join } from '@velogik/helper-classnames'
import { formatDate, formatMinutes, } from '@velogik/helper-date'
import { wait, STATUS, } from '@velogik/helper-promise'

import { BackButton, } from '../components/BackButton'
import { DayInput, } from 'components/DayInput'

import style from './TaskEdit.module.scss'

// TODO: remove once it's in consts
const defaultDuration = 60

export function TaskEdit () {
  const history = useHistory()
  const location = useLocation()
  const routeParams = useParams()

  const api = useApi()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const formatError = useFormatError()
  const intl = useIntl()
  const { appState, } = useApp()
  const { notify } = useNotify()

  const [dataState, dataDispatch] = useFormReducer(undefined, {
    duration: appState.constants.taskDuration.defaultValue || defaultDuration,
  })
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const [shopsState, setShopsState] = useState({ status: STATUS.INITIALIZING })
  const [usersState, setUsersState] = useState({ status: STATUS.INITIALIZING })

  const [userFilter, setUserFilter] = useState('')

  const [daySelected, setDaySelected] = useState(new Date())
  const [scheduleTime, setScheduleTime] = useState('')
  const [timeFilter, setTimeFilter] = useState('')
  const [confirmDelete, setConfirmDelete] = useState()

  const hoursDuration = Math.floor(dataState.current.duration / 60)
  const minutesDuration = Math.floor(dataState.current.duration % 60)

  const hoursDurationOptions = useMemo(() => {
    const result = []
    for (let i = Math.floor(appState.constants.taskDuration.min / 60); i <= Math.floor(appState.constants.taskDuration.max / 60); i++) {
      result.push({
        value: parseInt(i),
        label: intl.formatMessage({ id: 'duration.hours.value', defaultMessage: '{ hours }H' }, { hours: i })
      })
    }
    return result
  }, [appState.constants, intl])

  const minutesDurationOptions = useMemo(() => {
    const result = []
    const isHourMin = Math.floor(appState.constants.taskDuration.min / 60) === hoursDuration
    const isHourMax = Math.floor(appState.constants.taskDuration.max / 60) === hoursDuration
    const startAt = isHourMin ? appState.constants.taskDuration.min % 60 : 0
    const endAt = isHourMax ? appState.constants.taskDuration.max % 60 : 60

    let i = startAt
    do {
      result.push({
        value: parseInt(i),
        label: intl.formatMessage({ id: 'duration.minutes.value', defaultMessage: '{ minutes } min' }, { minutes: i })
      })
      i = i + appState.constants.taskDuration.step
    } while (isHourMax ? (i <= endAt) : (i < endAt))
    return result
  }, [appState.constants, intl, hoursDuration])

  const initialized = [FORM_STATUS.INITIALIZED, FORM_STATUS.DIRTY].includes(dataState.status)
  const shopId = initialized ? dataState.current.shopId : null

  useEffect(() => {
    wait(Promise.all([
      routeParams.taskId ? api.get('/tasks/details', undefined, { taskId: routeParams.taskId }) : null,
      api.get('/shops/listlight'),
    ]))
      .then(([taskPayload, shopsPayload]) => {
        const overridedParams = {
          shopId: shopsPayload[0].shopId,
          // TODO: handle values from location.state
          // ...(location.state.defaultValues || {}),
        }

        if (taskPayload) {
          const datePlanned = new Date(taskPayload.datePlanned)
          setDaySelected(datePlanned)
          setScheduleTime(datePlanned.getHours() * 60 + datePlanned.getMinutes())
        }

        setShopsState({ status: STATUS.LOADED, payload: shopsPayload })
        dataDispatch({ type: FORM_ACTION.INIT, value: {
          ...{ duration: appState.constants.taskDuration.defaultValue || defaultDuration },
          ...overridedParams,
          ...taskPayload,
        }})
      })
      .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload }))
  }, [api, appState.constants, dataDispatch, location.state, routeParams.taskId])

  useEffect(() => {
    if (initialized) {
      setUsersState({ status: STATUS.INITIALIZING })
      api.get('/users/listlight', undefined, { shopId, type: 'taskNew', })
        .then(payload => setUsersState({ status: STATUS.LOADED, payload }))
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload }))
    }
  }, [api, dataDispatch, initialized, shopId])

  const users = useMemo(() => {
    if (usersState.status === STATUS.LOADED) {
      return usersState.payload
        .filter(user => `${user.firstName} ${user.lastName}`.toLowerCase().includes(userFilter.toLowerCase()))
        .map(user => ({
          label: t({ id: 'userId.value' }, user),
          value: user.userId,
        }))
    }
  },[t, usersState, userFilter])

  const scheduleTimeOptions = useMemo((() =>
    Array.from(Array((appState.constants.planningSchedule.hourEnd - appState.constants.planningSchedule.hourStart) / appState.constants.planningSchedule.timePickerStep)).map((_, i) => {
      const minutes = appState.constants.planningSchedule.hourStart + (appState.constants.planningSchedule.timePickerStep * i)
      return {
        label: formatMinutes(minutes),
        value: String(minutes),
      }
    })
  ), [appState.constants])

  const scheduleTimeOptionsFiltered = useMemo(() => {
    const filterToNumber = String(Number(timeFilter.replace(/[^\d]+/g, '')))

    return filterToNumber === '0' ? scheduleTimeOptions : scheduleTimeOptions.filter(time =>
      String(Number(time.label.replace(/[^\d]+/g, ''))).startsWith(filterToNumber))
  }, [timeFilter, scheduleTimeOptions])

  function handleShopIdChange (e) {
    dataDispatch(e)
    dataDispatch({ target: { type: 'text', name: 'userId', value: undefined } })
  }

  function handleDaySelectedChange ({ target: { value } }) {
    setScheduleTime('')
    setDaySelected(value)
    dataDispatch({ target: {
      type: 'date',
      name: 'datePlanned',
      value: null
    }})
  }

  function handleScheduleTimeChange ({ target: { value } }) {
    setScheduleTime(value)
    dataDispatch({ target: {
      type: 'date',
      name: 'datePlanned',
      value: value ? new Date(
        daySelected.getFullYear(),
        daySelected.getMonth(),
        daySelected.getDate(),
        0, value, 0) : null
    }})
  }

  function handleDurationChange(duration) {
    dataDispatch({ target: {
      name: 'duration',
      type: 'range',
      value: Math.min(Math.max(duration, appState.constants.taskDuration.min), appState.constants.taskDuration.max)
    } })
  }

  function handleHoursDurationChange ({ target: { value }}) {
    handleDurationChange((value * 60) + dataState.current.duration % 60)
  }

  function handleMinutesDurationChange ({ target: { value }}) {
    handleDurationChange((Math.floor(dataState.current.duration / 60) * 60) + value)
  }

  function post () {
    setPostState({ status: STATUS.LOADING })

    wait(api.post('/tasks/details', { body: JSON.stringify(dataState.current) }))
      .then(payload => {
        if (!routeParams.taskId) {
          const query = new URLSearchParams()
          query.set('date', formatDate(payload.datePlanned))
          history.push(`/planning?${query}`)
        } else {
          notify(t({ id: 'saved' }))
          dataDispatch({ type: FORM_ACTION.INIT, value: payload })
          setPostState({ status: STATUS.LOADED, payload })
        }
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  function remove () {
    setPostState({ status: STATUS.LOADING })

    wait(api.del('/tasks/details', null, { taskId: routeParams.taskId }))
      .then(() => history.replace('/planning'))
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  return (
    <Page className={style.root}>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'full' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={post}
          loadingClassName={style.formLoading}
          state={dataState} dispatcher={dataDispatch}
          disabled={!initialized || postState.status === STATUS.LOADING}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content className={style.formContent}>
            <Block>
              <Block.Title className={style.title}>{t({ id: 'who.title' })}</Block.Title>
              <Block.Content className={style.content}>
                {shopsState.status === STATUS.LOADED && shopsState.payload.length > 1 && (
                  <div className={style.inputs}>
                    <SelectInput
                      name="shopId"
                      label={t({ id: 'shopId.label' })}
                      placeholder={t({ id: 'shopId.placeholder' })}
                      options={shopsState.payload.map(shop => ({
                        value: shop.shopId,
                        label: shop.shopName,
                      }))}
                      onChange={handleShopIdChange}
                    />
                  </div>
                )}

                <SelectInput
                  name="userId"
                  label={t({ id: 'userId.label' })}
                  placeholder={t({ id: 'userId.placeholder' })}
                  disabled={usersState.status !== STATUS.LOADED}
                  options={users}
                  customOption={SelectFilter}
                  customOptionProps={{
                    placeholder: t({ id: 'userId.filter.placeholder' }),
                    value: userFilter,
                    onChange: ({ target: { value } }) => setUserFilter(value)
                  }}
                />
              </Block.Content>
            </Block>

            <Block>
              <Block.Title className={style.title}>{t({ id: 'when.title' })}</Block.Title>
              <Block.Content>
                <DayInput className={style.scheduleDay} name="scheduleDay"
                  label={t({ id: 'date.label' })}
                  placeholder=""
                  value={daySelected}
                  onChange={handleDaySelectedChange} />

                <SelectInput className={style.scheduleTime} name="scheduleTime"
                  label={t({ id: 'time.label' })}
                  placeholder={t({ id: 'time.placeholder' })}
                  autoclose={true}
                  value={scheduleTime}
                  valueRender={value => ({ value, label: formatMinutes(value)})}
                  options={scheduleTimeOptionsFiltered}
                  onChange={handleScheduleTimeChange}
                  customOption={SelectFilter}
                  customOptionProps={{
                    placeholder: t({ id: 'time.filter.placeholder' }),
                    value: timeFilter,
                    onChange: ({ target: { value } }) => setTimeFilter(value)
                  }}
                />
                <div>
                  <Label>{t({ id: 'duration.label' })}</Label>
                  <div className={style.duration}>
                    <SelectInput
                      name="duration.hours"
                      className={join(style.durationInput, style.durationHours)}
                      placeholder={t({ id: 'duration.hours.placeholder' })}
                      value={hoursDuration}
                      onChange={handleHoursDurationChange}
                      options={hoursDurationOptions} />
                    <SelectInput
                      name="duration.minutes"
                      className={join(style.durationInput, style.durationMinutes)}
                      placeholder={t({ id: 'duration.minutes.placeholder' })}
                      value={minutesDuration}
                      onChange={handleMinutesDurationChange}
                      options={minutesDurationOptions} />
                  </div>
                </div>
              </Block.Content>
            </Block>

            <Block>
              <Block.Title className={style.title}>{t({ id: 'what.title' })}</Block.Title>
              <Block.Content>
                <Input className={style.duration} name="description"
                  type="textarea"
                  label={t({ id: 'description.label' })}
                  placeholder={t({ id: 'description.placeholder' })} />
              </Block.Content>
              <Block.Actions className={style.actions}>
                <Button type="submit">{t({ id: 'actions.submit' })}</Button>
                <Button tag={Link} outlined to="/planning">{t({ id: 'actions.cancel' })}</Button>
                {routeParams.taskId && <Button color="danger" onClick={() => setConfirmDelete(true)}>{t({ id: 'actions.remove' })}</Button>}
              </Block.Actions>
            </Block>
          </Form.Content>
        </Form>

        {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
          className={style.stickyBanner}
          type="danger"
          description={formatError(postState.payload)}
          actions={[postState.payload.code === 'preventUpdate'
            ? { label: t({ id: 'dirty.forceSave' }), onClick: () => post({ force: postState.payload.reason })}
            : { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
          ]}
        />}
      </Page.Content>

      {confirmDelete && <Modal size="small" isOpen>
        <Block>
          <Block.Title>{t({ id: 'confirm.title' })}</Block.Title>
          <Block.Actions className={style.actions}>
            <Button onClick={() => setConfirmDelete(false)}>
              {t({ id: 'confirm.dismiss' })}
            </Button>
            <Button color="danger" onClick={remove} disabled={postState.status === STATUS.LOADING}>
              {t({ id: 'confirm.action' })}
            </Button>
          </Block.Actions>
        </Block>
      </Modal>}
    </Page>
  )
}
