import { useState, useEffect, useCallback, } from 'react'
import { Link, useParams, useHistory, } from 'react-router-dom'
import { useApi } from 'react-rest-api'

import { Alert, } from '@velogik/component-alert'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Button, } from '@velogik/component-button'
import { Form, useFormReducer, FORM_ACTION, FORM_STATUS, } from '@velogik/component-form'
import { Input, } from '@velogik/component-input'
import { useT, useFormatError, } from '@velogik/component-intl'
import { Label, } from '@velogik/component-label'
import { Page, } from '@velogik/component-page'
import { SelectInput, } from '@velogik/component-select-input'
import { wait, STATUS, } from '@velogik/helper-promise'

import { BackButton, } from 'components/BackButton'
import { useHasProfile, } from 'hooks/use-user'

const DEFAULT_VALUE = {}

export function SharedPlanningEdit() {
  const history = useHistory()

  const api = useApi()
  const routeParams = useParams()
  const t = useT()
  const tMandatory = useT('Mandatory', true)
  const formatError = useFormatError()

  const canCreateShop = useHasProfile('admin_velocenter', 'admin_network')

  const [dataState, dataDispatch] = useFormReducer()
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const [shops, setShops] = useState({ status: STATUS.INITIALIZING })
  const [vlcrBusiness, setVlcrBusiness] = useState({ status: STATUS.INITIALIZING })
  const [vlcrUsers, setVlcrUsers] = useState({ status: STATUS.IDLE })

  const selectedBusiness = dataState.current && dataState.current.businessId && vlcrBusiness.status === STATUS.LOADED &&
    vlcrBusiness.payload.find(business => String(business.value) === dataState.current.businessId)

  const isLoading = dataState.status === FORM_STATUS.INITIALIZING || [shops, vlcrBusiness].find(state => state.status === STATUS.INITIALIZING)
  const erroredState = [shops, vlcrBusiness].find(state => state.status === STATUS.ERROR)

  useEffect(() => {
    if (routeParams.sharedPlanningId) {
      wait(api.get('/sharedplannings/details', undefined, { sharedPlanningId: routeParams.sharedPlanningId }))
        .then(payload => dataDispatch({ type: FORM_ACTION.INIT, value: {
          ...payload,
          businessId: `${payload.subsidiaryId}-${payload.businessId}`,
        }, }))
        .catch(payload => dataDispatch({ type: FORM_ACTION.ERROR, value: payload, }))
    } else {
      dataDispatch({ type: FORM_ACTION.INIT, value: DEFAULT_VALUE })
    }
  }, [api, dataDispatch, routeParams.sharedPlanningId,])

  useEffect(() => {
    wait(api.get('/shops/listlight'))
      .then(payload => setShops({ status: STATUS.LOADED, payload }))
      .catch(payload => setShops({ status: STATUS.ERROR, payload }))

    wait(api.get('/velocare/business'))
      .then(payload => setVlcrBusiness({
        status: STATUS.LOADED,
        payload: payload.map(business => {
          const [subsidiaryId, businessId] = business.id.split('-')
          return {
            value: business.id,
            label: business.name,
            subsidiaryId, businessId,
          }
        }),
      }))
      .catch(payload => setVlcrBusiness({ status: STATUS.ERROR, payload }))
  }, [api,])

  useEffect(() => {
    setVlcrUsers({ status: STATUS.IDLE })
    if (selectedBusiness) {
      setVlcrUsers({ status: STATUS.INITIALIZING })

      api.get('/velocare/users', undefined, selectedBusiness)
        .then(payload => setVlcrUsers({
          status: STATUS.LOADED,
          payload: payload.map(user => {
            return {
              value: user.userId,
              label: user.name,
            }
          }),
        }))
        .catch(payload => setVlcrUsers({ status: STATUS.ERROR, payload }))
    }
  }, [api, selectedBusiness, ])

  function post() {
    setPostState({ status: STATUS.LOADING })
    wait(api.post('/sharedplannings/details', { body: JSON.stringify({
      ...dataState.current,
      subsidiaryId: selectedBusiness ? selectedBusiness.subsidiaryId : undefined,
      businessId: selectedBusiness ? selectedBusiness.businessId : undefined,
    }) }))
      .then(payload => {
        if (!routeParams.sharedPlanningId) {
          history.replace(`/sharedPlannings/${payload.sharedPlanningId}`)
        } else {
          dataDispatch({ type: FORM_ACTION.INIT, value: {
            ...payload,
            businessId: `${payload.subsidiaryId}-${payload.businessId}`,
          }, })
          setPostState({ status: STATUS.IDLE, payload })
        }
      })
      .catch(payload => setPostState({ status: STATUS.ERROR, payload, failedDataState: dataState.current }))
  }

  const handleBusinessChange = useCallback(payload => {
    dataDispatch({ target: { name: 'userId', type: 'text', value: '' } })
    dataDispatch(payload)
  }, [dataDispatch])

  const handleBusinessValueRender = useCallback(value => {
    return vlcrBusiness.status === STATUS.LOADED
      ? vlcrBusiness.payload.find(business => business.value === value)
      : { value: '', label: t({ id: 'infos.loading' }) }
  }, [vlcrBusiness, t, ])

  const handleUserValueRender = useCallback(value => {
    return vlcrUsers.status === STATUS.LOADED
      ? vlcrUsers.payload.find(user => user.value === value)
      : vlcrUsers.status === STATUS.IDLE
        ? { value: '', label: t({ id: 'infos.userId.placeholder' }) }
        : { value: '', label: t({ id: 'infos.loading' }) }
  }, [vlcrUsers, t, ])

  return (
    <Page>
      <BackButton />
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>

      <Page.Content>
        {tMandatory({ id: 'full' })}
      </Page.Content>
      <Page.Content>
        <Form onSubmit={() => post()}
          state={dataState} dispatcher={dataDispatch}
          disabled={isLoading || erroredState}
          preventTransition={dataState.status === FORM_STATUS.DIRTY && postState.status !== STATUS.LOADING}>
          <Form.Content>
            <Block>
              <Block.Title>{t({ id: 'workshops.title' })}</Block.Title>
              {shops.status === STATUS.INITIALIZING && <Block.Loader />}
              {shops.status === STATUS.ERROR && <Block.Content>
                <Alert>{formatError(shops.payload)}</Alert>
              </Block.Content>}
              {shops.status === STATUS.LOADED && <Block.Content>
                <div>
                  <Label>{t({ id: 'workshops.label' })}</Label>
                  {shops.payload.map(workshop => <Input key={`shop-${workshop.shopId}`}
                    type="radio"
                    name="shopId"
                    id={`shopId-${workshop.shopId}`}
                    value={workshop.shopId}
                    label={workshop.shopName} />)}
                </div>
              </Block.Content>}
              {canCreateShop && (
                <Block.Actions>
                  <Button tag={Link} to='/shops/new' type="button" outlined>{t({ id: 'workshops.new' })}</Button>
                </Block.Actions>
              )}
            </Block>

            <Block>
              <Block.Title>{t({ id: 'infos.title' })}</Block.Title>
              <Block.Content>
                <Input name="name" label={t({ id: 'infos.name.label' })} />
                <SelectInput
                  name="businessId"
                  label={t({ id: 'infos.businessId.label' })}
                  placeholder={t({ id: vlcrBusiness.status === STATUS.INITIALIZING ? 'infos.loading' : 'infos.businessId.placeholder' })}
                  disabled={vlcrBusiness.status !== STATUS.LOADED || postState.status === STATUS.INITIALIZING}
                  options={vlcrBusiness.status === STATUS.LOADED ? vlcrBusiness.payload : []}
                  onChange={handleBusinessChange}
                  valueRender={handleBusinessValueRender}
                />
                <SelectInput
                  name="userId"
                  label={t({ id: 'infos.userId.label' })}
                  placeholder={t({ id: vlcrUsers.status === STATUS.INITIALIZING ? 'infos.loading' : 'infos.userId.placeholder' })}
                  disabled={vlcrUsers.status !== STATUS.LOADED || !selectedBusiness || postState.status === STATUS.INITIALIZING}
                  options={vlcrUsers.status === STATUS.LOADED ? vlcrUsers.payload : []}
                  valueRender={handleUserValueRender}
                />
              </Block.Content>
            </Block>
          </Form.Content>

          {dataState.status === STATUS.ERROR && <Banner sticky type="danger" description={formatError(dataState.payload)} />}
              
          {/* TODO: Success state */}
          {postState.failedDataState === dataState.current && postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true, onClick: () => setPostState({ status: STATUS.IDLE, failedDataState: undefined }) }
            ]}
          />}
          {postState.failedDataState !== dataState.current && dataState.initial !== dataState.current && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.save' }), type: 'submit', disabled: postState.status === STATUS.LOADING },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
