const messages = {
  datePattern: '^(?<day>0[1-9]|[12][0-9]|3[01])(?<sep>[^\\w\\s])(?<month>1[0-2]|0[1-9])\\k<sep>(?<year>\\d{4})$',

  // `window.title` and `meta.description` uses match.path internal `path` for routes transformed to be valid as select key
  // So /services become services
  // /intervention/2/details -> is /intervention/:interventionId/details become `interventions_interventionId_details`
  'window.title': `{ path, select,
    other {Velocenter - Votre back office dédié pour le métier du cycle}
  }`,
  'meta.description': `{ path, select,
    other {Gérez du bout des doigts votre activité grâce à Velocenter ! Interventions, clients, vélos : Plus rien ne vous échappe !}
  }`,
  'loading': 'Plus que quelques etapes avant la reparation de votre Vélo 💪 ....',
  
  'guard.premium': 'Cette fonctionnalitée est disponible à partir du "pack complet"',

  'Form.dataLoading': 'Chargement...', // TODO
  'Form.retry': 'Je réessaye',

  'header.title': 'VELOCENTER',
  'header.burger': 'Bouton du menu',
  'header.home': 'Accueil',
  'header.dashboard': 'Mon tableau de bord',
  'header.clients': 'Mes clients',
  'header.shops': 'Mon administration',
  'header.planning': 'Le planning',
  'header.equipments': 'Les vélos',
  'header.interventions': 'Mes interventions',
  'header.signout': 'Je me déconnecte',
  'header.account': 'Mon compte',

  'Mandatory.full': 'Tous les champs sont obligatoires',
  'Mandatory.partial': 'Les champs marqués d\'un * sont obligatoires',

  'Footer.legal.title': 'Les informations légales',
  'Footer.legal.terms': 'Conditions Générales d\'Utilisation',
  'Footer.legal.privacy': 'Politique de confidentialité',
  'Footer.legal.mentions': 'Mentions légales',
  'Footer.commercial.title': 'Centre d\'aide',
  'Footer.commercial.contact': 'Contactez-nous',
  'Footer.commercial.services': 'Offres de services',
  'Footer.commercial.faq': 'Foire Aux Questions',
  'Footer.social.title': '',
  'Footer.social.whateveridk': '',
  'Footer.version': `{ version, select,
    SNAPSHOT {{ version }}
    DEV {Dev}
    other {v{ version }}
  }`,
  'Footer.admin.title': 'Admin',
  'Footer.admin.networks': 'Réseaux',

  'constants.origin': `{ value, select,
    bo {BO}
    other {{value}}
  }`,

  'constants.interventionStatus': `{ value, select,
    new {Nouveau}
    scheduled {Programmée}
    pending {En attente}
    done {Terminée}
    returned {Facturée}
    express {Facturée en expresse}
    canceled {Annulée}
    closed {Clôturée}
    other {Inconnue}
  }`,

  'constants.interventionHistoryStatus': `{ status, select,
    status_new {Nouvelle}
    status_scheduled {Programmée}
    status_pending {Mis en attente}
    status_renew {Modifiée pour nouvelle planification}
    status_done {Terminée}
    status_returned {Facturée}
    status_express {Facturée en express}
    status_closed {Clôturée}
    status_canceled {Annulée : { value }}
    bike_receipt {Vélo reçu}
    sms_itv_scheduled {SMS de notification de date de rendez-vous envoyé}
    sms_itv_done {SMS de restitution envoyé}
    informations_update {Informations mises à jour}
    itvcodes_update {Codes intervention mis à jour}
    canceledForEstimate {Annulation de l'intervention suite à la duplication vers <toEstimate>devis #{ value }</toEstimate>}
    velocare_itv {Intervention créée dans Velocare : {value}}
    other {{status}}
  }`,

  'constants.estimateHistoryStatus': `{ status, select,
    status_new {Nouveau}
    status_sent {Envoyé}
    status_done {Validé}
    status_canceled {Annulé}
    informations_update {Informations mises à jour}
    itvcodes_update {Codes intervention mis à jour}
    sms_estimate_sent {SMS de devis envoyé}
    duplicateFromEstimate {Dupliqué depuis le <toEstimate>devis #{ value }</toEstimate>}
    transformFromItv {Dupliqué depuis l''<toIntervention>intervention #{ value }</toIntervention>}
    canceledForEstimate {Annulation du devis suite à la duplication vers <toEstimate>devis #{ value }</toEstimate>}
    other {{status}}
  }`,

  'common.services': `{value, select,
    breakdown {Panne}
    overhaul {Révision et mise en service}
    spare {Montage de pièce détachée}
    other {Autre}
  }`,

  'common.servicesDetails': `{value, select,
    breakdown_speed {Problème de vitesse}
    breakdown_crankset {Pédalier défectueux}
    breakdown_flatTire {Roue crevée}
    breakdown_brokenWheel {Roue cassée}
    breakdown_brakeAdjustment {Réglage des freins}
    breakdown_brokenBrakes {Freins cassés}
    breakdown_battery {Problème de batterie}
    breakdown_diag {Diagnostic}
    breakdown_otherBreakdown {Autre}
    overhaul_overhaul {Révision}
    overhaul_setup {Mise en service}
    spare_spareMine {Montage pièce détachée en possession du client}
    spare_spareBuy {Montage pièce détachée avec achat}
    spare_kit {Montage kit électique}
    spare_accessory {Montage petit accessoire}
    spare_idSetup {Installation identification}
    spare_lockDisassembly {Démontage antivol}
    spare_spareDisassembly {Démontage pièce détachée}
    other_other {Autre}
    other {Autre}
  }`,

  '/.loading': 'Chargement ...',

  '/not-found.title': 'Page introuvable',
  '/not-found.content': 'Oups ! Nous sommes vraiment désolés, impossible de trouver la page que vous cherchez. Elle a peut-être été déplacée, supprimée ou… elle n’a jamais existé !',
  '/not-found.button': 'Je reviens au tableau de bord',

  // PAGES

  // /login
  '/login.pageTitle': 'Bienvenue !',
  '/login.email.label': 'Votre email',
  '/login.email.placeholder': 'votre@email.com',
  '/login.password.label': 'Un mot de passe',
  '/login.password.placeholder': ' ',
  '/login.forgotPassword': 'Oups, j’ai oublié mon mot de passe !',
  '/login.submit': 'Je me connecte',

  '/forgot-password.pageTitle': 'Mot de passe oublié',
  '/forgot-password.email.label': 'Mon email',
  '/forgot-password.email.placeholder': 'votre@email.com',
  '/forgot-password.valid': 'Réinitialiser',
  '/forgot-password.success': 'Un nouveau mot de passe vous a été envoyé',

  // /dashboard
  '/dashboard.pageTitle': 'Mon activité en un clin d\'oeil',
  '/dashboard.filters.shopId': 'Sélectionnez un atelier',
  '/dashboard.blocks.selfInterventions.title': 'Mes interventions',
  '/dashboard.blocks.selfInterventions.content.value': '{ value }',
  '/dashboard.blocks.selfInterventions.content.label': 'Au total',
  '/dashboard.blocks.selfInterventions.archived.value': '{ archived }',
  '/dashboard.blocks.selfInterventions.archived.label': 'Effectuée(s)',
  '/dashboard.blocks.selfInterventions.today.value': '{ today }',
  '/dashboard.blocks.selfInterventions.today.label': 'Aujourd\'hui',
  '/dashboard.blocks.selfInterventions.planned.value': '{ planned }',
  '/dashboard.blocks.selfInterventions.planned.label': 'Planifiée(s)',
  '/dashboard.blocks.selfInterventions.linkName': 'Liste des interventions',

  '/dashboard.blocks.globalInterventions.title': 'Interventions atelier',
  '/dashboard.blocks.globalInterventions.content.value': '{ value }',
  '/dashboard.blocks.globalInterventions.content.label': 'Au total',
  '/dashboard.blocks.globalInterventions.archived.value': '{ archived }',
  '/dashboard.blocks.globalInterventions.archived.label': 'Effectuée(s)',
  '/dashboard.blocks.globalInterventions.today.value': '{ today }',
  '/dashboard.blocks.globalInterventions.today.label': 'Aujourd\'hui',
  '/dashboard.blocks.globalInterventions.planned.value': '{ planned }',
  '/dashboard.blocks.globalInterventions.planned.label': 'Planifiée(s)',
  '/dashboard.blocks.globalInterventions.linkName': 'Liste des interventions',

  '/dashboard.blocks.billings.title': 'Mes Factures',
  '/dashboard.blocks.billings.content.value': '{ value }',
  '/dashboard.blocks.billings.content.label': 'Au total',
  '/dashboard.blocks.billings.year.value': '{ year }',
  '/dashboard.blocks.billings.year.label': 'Y',
  '/dashboard.blocks.billings.currentMonth.value': '{ currentMonth }',
  '/dashboard.blocks.billings.currentMonth.label': 'M',
  '/dashboard.blocks.billings.lastMonth.value': '{ lastMonth }',
  '/dashboard.blocks.billings.lastMonth.label': 'M-1',
  '/dashboard.blocks.billings.linkName': 'Liste des factures',

  '/dashboard.blocks.clients.title': 'Mes clients',
  '/dashboard.blocks.clients.content.value': '{ value }',
  '/dashboard.blocks.clients.content.label': 'Rattachés à l\'atelier',
  '/dashboard.blocks.clients.archived.value': '{ archived }',
  '/dashboard.blocks.clients.archived.label': 'Archivé',
  '/dashboard.blocks.clients.today.value': '{ today }',
  '/dashboard.blocks.clients.today.label': 'Aujourd\'hui',
  '/dashboard.blocks.clients.planned.value': '{ planned }',
  '/dashboard.blocks.clients.planned.label': 'Plannifié',
  '/dashboard.blocks.clients.linkName': 'Liste des clients',

  '/dashboard.blocks.estimates.title': 'Mes Devis',
  '/dashboard.blocks.estimates.content.value': '{ value }',
  '/dashboard.blocks.estimates.content.label': 'Au total',
  '/dashboard.blocks.estimates.year.value': '{ year }',
  '/dashboard.blocks.estimates.year.label': 'Y',
  '/dashboard.blocks.estimates.currentMonth.value': '{ currentMonth }',
  '/dashboard.blocks.estimates.currentMonth.label': 'M',
  '/dashboard.blocks.estimates.lastMonth.value': '{ lastMonth }',
  '/dashboard.blocks.estimates.lastMonth.label': 'M-1',
  '/dashboard.blocks.estimates.linkName': 'Liste des devis',

  '/dashboard.blocks.shortcuts.title': 'Mes raccourcis',
  '/dashboard.blocks.shortcuts.newIntervention': 'Nouvelle intervention',
  '/dashboard.blocks.shortcuts.expressIntervention': 'Nouvelle intervention expresse',
  '/dashboard.blocks.shortcuts.planning': 'Mon planning',
  '/dashboard.blocks.shortcuts.clients': 'Nouveau client',
  '/dashboard.blocks.shortcuts.equipments': 'Les vélos',

  // /new-password
  '/new-password.pageTitle': 'Nouveau mot de passe',
  '/new-password.username.label': 'Nom d\'utilisateur',
  '/new-password.password.label': 'Nouveau mot de passe',
  '/new-password.password.placeholder': ' ',
  '/new-password.confirmPassword.label': 'Confirmation du mot de passe',
  '/new-password.confirmPassword.placeholder': ' ',
  '/new-password.valid': 'Valider mon nouveau mot de passe',
  '/new-password.saved': 'Mot de passe enregistré avec succès !',

  // /clients
  '/clients.pageTitle': 'Mes clients',
  '/clients.initializing': 'Chargement des données en cours...',
  '/clients.new': 'Je créé un nouveau client',
  '/clients.filters.nameFilter.label': 'Filtrer par nom',
  '/clients.filters.nameFilter.placeholder': 'Saisir...',
  '/clients.filters.emailFilter.label': 'Filtrer par email',
  '/clients.filters.emailFilter.placeholder': 'email@...',
  '/clients.filters.shopId.label': 'Filtrer par atelier',
  '/clients.filters.shopId.all': 'Tous les ateliers',
  '/clients.filters.shopId.placeholder': '...',
  '/clients.ipp': 'Résultats par page',
  '/clients.result.header.clientId': 'ID',
  '/clients.result.header.name': 'Nom',
  '/clients.result.header.email': 'Adresse email',
  '/clients.result.header.btn.interventions': 'Les interventions',
  '/clients.result.header.btn.details': 'Infos client',
  '/clients.result.item.clientId': '{clientId}',
  '/clients.result.item.name': '{firstName} {lastName}',
  '/clients.result.item.email': '{email}',
  '/clients.result.item.btn.interventions': 'Interventions',
  '/clients.result.item.btn.details': 'Détails',
  '/clients.noresult': 'Pas (encore) de client',

  // /clients/new
  '/clients/new.pageTitle': 'Nouveau client',
  '/clients/new.infos.title': 'Informations',
  '/clients/new.infos.firstName.label': 'Prénom *',
  '/clients/new.infos.firstName.placeholder': 'Jean',
  '/clients/new.infos.lastName.label': 'Nom *',
  '/clients/new.infos.lastName.placeholder': 'Dupond',
  '/clients/new.infos.email.label': 'Email *',
  '/clients/new.infos.email.placeholder': 'mon@email.com',
  '/clients/new.infos.newsletter.label': 'Le client accepte de recevoir l\'actualité de son réparateur',
  '/clients/new.infos.shopId.label': 'Atelier *',
  '/clients/new.infos.shopId.placeholder': 'Choisir un atelier',
  '/clients/new.infos.phoneNumber.label': 'Numéro de téléphone',
  '/clients/new.infos.phoneNumber.placeholder': '06 02 03 04 05',
  '/clients/new.infos.phoneNumber.validate': 'Accepter la réception de SMS',
  '/clients/new.infos.addressFull.label': 'Adresse',
  '/clients/new.infos.addressFull.placeholder': '25 Rue de Sèze, 69006 Lyon, France',
  '/clients/new.infos.addressComplement.label': 'Complément d’adresse',
  '/clients/new.infos.addressComplement.placeholder': '...',
  '/clients/new.dirty.disclaimer': 'Des informations ont été ajoutées',
  '/clients/new.dirty.cancel': 'Réinitialiser',
  '/clients/new.dirty.save': 'Je créé le client',
  '/clients/new.dirty.forceSave': 'Valider',
  '/clients/new.dirty.dismiss': 'J’ai compris',

  // /clients/:clientId
  '/clients/:clientId.pageTitle': 'Fiche du client',

  '/clients/:clientId.informations.title': 'Informations du client',
  '/clients/:clientId.informations.item.firstName.label': 'Prénom *',
  '/clients/:clientId.informations.item.lastName.label': 'Nom *',
  '/clients/:clientId.informations.item.email.label': 'Email *',
  '/clients/:clientId.informations.item.phoneNumber.label': 'Numéro de téléphone',
  '/clients/:clientId.informations.item.addressFull.label': 'Adresse',
  '/clients/:clientId.informations.actions.edit': 'Éditer les informations',
  '/clients/:clientId.informations.actions.newItv': 'Nouvelle intervention',

  '/clients/:clientId.equipment.title': 'Vélo',
  '/clients/:clientId.equipment.item.equipmentType.label': 'Type de vélo',
  '/clients/:clientId.equipment.item.equipmentType.value': `{ equipmentType, select,
    vae {Vélo à Assistance Électrique}
    standard {Vélo standard}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    velocare {Vélo { business }}
    other {Autre}
  }`,
  '/clients/:clientId.equipment.item.velocenterIdentifier.label': 'Identification Velocenter',
  '/clients/:clientId.equipment.item.brand.label': 'Marque',
  '/clients/:clientId.equipment.item.customName.label': 'Nom personnalisé',
  '/clients/:clientId.equipment.item.model.label': 'Modèle',
  '/clients/:clientId.equipment.item.equipmentStatusId.label': 'Statut',
  '/clients/:clientId.equipmentStatus.active': 'En sa possession',
  '/clients/:clientId.equipmentStatus.sold': 'Vendu',
  '/clients/:clientId.equipmentStatus.destroyed': 'Détruit',
  '/clients/:clientId.equipmentStatus.stolen': 'Volé',
  '/clients/:clientId.equipment.actions.details': 'Plus d’infos',
  '/clients/:clientId.equipment.actions.newItv': 'Nouvelle intervention',
  '/clients/:clientId.actions.newEquipment': 'Ajouter un vélo',
  '/clients/:clientId.actions.newIntervention': 'Nouvelle intervention',

  '/clients/:clientId.selectEquipment.title': 'Choisir un vélo',
  '/clients/:clientId.selectEquipment.disclaimer': 'Pour créer une nouvelle intervention, vous devez au préalable choisir un vélo de votre client :',
  '/clients/:clientId.selectEquipment.equipment.label': `
    <title>{velocenterIdentifier}</title>
    <content>{customName}</content>
  `,
  '/clients/:clientId.selectEquipment.other.label': 'Autre vélo',
  '/clients/:clientId.selectEquipment.cancel': 'Annuler',
  '/clients/:clientId.selectEquipment.go': 'Suivant',

  '/clients/:clientId.archive.title': 'Archiver le client',
  '/clients/:clientId.archive.archive.description': 'Masquer l’équipement pour tout le monde dans toute l’application',
  '/clients/:clientId.archive.unarchive.description': 'Rétablir la visibilité du client',
  '/clients/:clientId.archive.archive.action': 'Archiver',
  '/clients/:clientId.archive.unarchive.action': 'Dé-archiver',

  // /clients/:clientId/edit
  '/clients/:clientId/edit.pageTitle': 'Edition du client',
  '/clients/:clientId/edit.infos.title': 'Informations du client',
  '/clients/:clientId/edit.infos.firstName.label': 'Prénom',
  '/clients/:clientId/edit.infos.firstName.placeholder': 'Jean',
  '/clients/:clientId/edit.infos.lastName.label': 'Nom',
  '/clients/:clientId/edit.infos.lastName.placeholder': 'Dupond',
  '/clients/:clientId/edit.infos.email.label': 'Email',
  '/clients/:clientId/edit.infos.email.placeholder': 'email@domaine.com',
  '/clients/:clientId/edit.infos.newsletter.label': 'Le client accepte de recevoir l\'actualité de son réparateur',
  '/clients/:clientId/edit.infos.shopId.label': 'Atelier de préférence',
  '/clients/:clientId/edit.infos.shopId.placeholder': 'Choisir un atelier',
  '/clients/:clientId/edit.infos.phoneNumber.label': 'Numéro de téléphone',
  '/clients/:clientId/edit.infos.phoneNumber.placeholder': '+33 1 02 03 04 05',
  '/clients/:clientId/edit.infos.phoneNumber.validate': 'Accepter la réception de SMS',
  '/clients/:clientId/edit.infos.addressFull.label': 'Adresse',
  '/clients/:clientId/edit.infos.addressFull.placeholder': '25 Rue de Sèze, 69006 Lyon, France',
  '/clients/:clientId/edit.infos.addressComplement.label': 'Complément d’adresse (facultatif)',
  '/clients/:clientId/edit.infos.addressComplement.placeholder': '...',
  '/clients/:clientId/edit.dirty.disclaimer': 'Des informations sur la fiche ont été modifiées',
  '/clients/:clientId/edit.dirty.cancel': 'Réinitialiser',
  '/clients/:clientId/edit.dirty.save': 'Je modifie les informations du client',
  '/clients/:clientId/edit.dirty.dismiss': 'J’ai compris',

  // /users
  '/users.pageTitle': 'Mes utilisateurs',
  '/users.initializing': 'Chargement ...',

  '/users.filters.networkId.label': 'Filtrer par réseau',
  '/users.filters.networkId.all': 'Tous les réseaux',
  '/users.filters.shopId.label': 'Filtrer par atelier',
  '/users.filters.shopId.all': 'Tous les ateliers',
  '/users.filters.profileId.label': 'Profil de droit',
  '/users.filters.profileId.all': 'Tous les profils',
  '/users.filters.profileId.value': `{ profileName, select,
    admin_network {Administrateur Réseau}
    admin_velocenter {Administrateur Vélogik}
    admin_shop {Chef d'atelier}
    user {Technicien}
    reader {Lecteur}
    other {Inconnu: {profileName}}
  }`,
  '/users.filters.filter.label': 'Recherche',
  '/users.filters.filter.placeholder': ' ',
  '/users.result.header.name': 'Nom',
  '/users.result.header.email': 'Email',
  '/users.result.header.shopName': 'Atelier',
  '/users.result.header.btn.view': ' ',
  '/users.result.item.name': '{ firstName } { lastName }',
  '/users.result.item.isLocked': '{ isLocked, select, true {Bloqué} false {Actif} other {Indéfini: {isLocked}} }',
  '/users.result.item.email': '{ email }',
  '/users.result.item.networkName': '{ networkName }',
  '/users.result.item.shopName': '{ shopName }',
  '/users.result.item.btn.view': 'Modifier',
  '/users.noresult': 'Aucun utilisateur dans la liste',
  '/users.new': 'Créer un nouvel utilisateur',

  // /users/:userId
  '/users/:userId.pageTitle': 'Edition d\'un utilisateur',
  '/users/:userId.workshops.title': 'Choix d\'un atelier',
  '/users/:userId.workshops.label': 'Atelier lié',
  '/users/:userId.workshops.new': 'Créer un nouvel atelier',
  '/users/:userId.infos.title': 'Informations',
  '/users/:userId.infos.firstName.label': 'Prénom',
  '/users/:userId.infos.firstName.placeholder': ' ',
  '/users/:userId.infos.lastName.label': 'Nom',
  '/users/:userId.infos.lastName.placeholder': ' ',
  '/users/:userId.infos.email.label': 'Email',
  '/users/:userId.infos.email.placeholder': 'email@domaine.com',
  '/users/:userId.infos.isLocked.label': 'Utilisateur bloqué',
  '/users/:userId.profile.title': 'Profil de droit',
  '/users/:userId.profile.loading': 'profile.loading',
  '/users/:userId.networkId.placeholder': 'Choisir un réseau',
  '/users/:userId.unavailabilities.label': 'Indisponibilitées',
  '/users/:userId.unavailabilities.currentWeek': '{ firstDay, date, ::dL } - { lastDay, date, ::dL } { firstDay, date, ::yyyy }',
  '/users/:userId.unavailabilities.calendar.header': '<title>{ date, date, ::eeee }</title><subTitle>{ date, date, ::dMMM }</subTitle>',
  '/users/:userId.unavailabilities.calendar.legend.selected': 'Disponible',
  '/users/:userId.unavailabilities.calendar.legend.free': 'Indisponible',
  '/users/:userId.unavailabilities.calendar.legend.closed': 'Fermé',
  '/users/:userId.unavailabilities.copyWeek': 'Appliquer aux semaines suivantes',
  '/users/:userId.unavailabilities.emptyWeek': 'Indisponible cette semaine',
  '/users/:userId.submit': 'submit',
  '/users/:userId.dirty.disclaimer': 'Des informations sur l\'utilisateur ont été modifiées',
  '/users/:userId.dirty.save': 'Je modifie l\'utilisateur',
  '/users/:userId.dirty.reset': 'Réinitialiser',
  '/users/:userId.dirty.dismiss': 'J\'ai compris',
  '/users/:userId.profile.profileSlug.value': `{ profileSlug, select,
    admin_network {Administrateur Réseau}
    admin_velocenter {Administrateur Vélogik}
    admin_shop {Chef d'atelier}
    user {Technicien}
    reader {Lecteur}
    other {{profileSlug}}
  }`,

  // /users/new
  '/users/new.pageTitle': 'Nouvel utilisateur',
  '/users/new.workshops.title': 'Choix d\'un atelier',
  '/users/new.workshops.label': 'Atelier lié',
  '/users/new.workshops.new': 'Créer un nouvel atelier',
  '/users/new.infos.title': 'Informations',
  '/users/new.infos.firstName.label': 'Prénom',
  '/users/new.infos.firstName.placeholder': ' ',
  '/users/new.infos.lastName.label': 'Nom',
  '/users/new.infos.lastName.placeholder': ' ',
  '/users/new.infos.email.label': 'Email',
  '/users/new.infos.email.placeholder': 'email@domaine.com',
  '/users/new.profile.title': 'Profil de droit',
  '/users/new.profile.loading': 'profile.loading',
  '/users/new.submit': 'submit',
  '/users/new.dirty.disclaimer': 'Des infos ont été ajoutées sur l\'utilisateur',
  '/users/new.dirty.save': 'Je créé l\'utilisateur',
  '/users/new.dirty.reset': 'Réinitialiser',
  '/users/new.dirty.dismiss': 'J\'ai compris',
  '/users/new.networkId.placeholder': 'Sélectionner un réseau',
  '/users/new.profile.profileSlug.value': `{ profileSlug, select,
    admin_network {Administrateur Réseau}
    admin_velocenter {Administrateur Vélogik}
    admin_shop {Chef d'atelier}
    user {Technicien}
    reader {Lecteur}
    other {{profileSlug}}
  }`,

  // /equipments
  '/equipments.pageTitle': 'Les vélos',
  '/equipments.initializing': 'Chargement des données en cours...',
  '/equipments.filters.shopId.label': 'Filtrer par atelier',
  '/equipments.filters.shopId.all': 'Tous les ateliers',
  '/equipments.filters.equipmentType.label': 'Filtrer par type',
  '/equipments.filters.equipmentType.all': 'Tous les types',
  '/equipments.filters.equipmentType.option': `{ option, select,
    vae {Vélo à Assistance Electrique}
    cargoae {Vélo cargo électrique}
    standard {Vélo standard}
    cargo {Vélo cargo}
    other {{value}}
  }`,
  '/equipments.filters.filter.label': 'Filtrer',
  '/equipments.filters.filter.placeholder': 'Nom d\'usage / Marque ...',
  '/equipments.filters.showHidden.inputLabel': 'Equipements masqués',
  '/equipments.filters.showHidden.label': 'Afficher',
  '/equipments.ipp': 'Résultats par page',
  '/equipments.noresult': 'Aucun vélo à afficher',
  '/equipments.result.header.velocenterIdentifier': 'ID Vélo',
  '/equipments.result.header.equipmentType': 'Type de vélo',
  '/equipments.result.header.customName': 'Nom personnalisé',
  '/equipments.result.header.brand': 'Marque',
  '/equipments.result.header.interventionsCount': 'Interventions',
  '/equipments.result.header.btn.details': 'Détails',
  '/equipments.result.item.equipmentType': `{ equipmentTypeValue, select,
    standard {Vélo standard}
    vae {Vélo à Assistance Électrique}
    cargo {Vélo cargo}
    cargoae {Vélo cargo Électrique}
    null {N/A}
    velocare {Vélo { business }}
    other {Autre}
  }`,
  '/equipments.result.item.customName': '{ customName, select, null {-} other {{ customName }} }',
  '/equipments.result.item.interventionsCount': '{ interventionsCount, plural, =0 {-} one {#} other {#} }',
  '/equipments.result.item.btn.details': 'Détails',

  // /equipments/new
  '/equipments/new.pageTitle': 'Nouveau vélo',

  '/equipments/new.typeSelector.standard': 'Vélo particulier',
  '/equipments/new.typeSelector.velocare': 'Vélo professionel (Velocare)',

  '/equipments/new.infos.equipmentType.label': 'Type de vélo *',
  '/equipments/new.infos.equipmentType.placeholder': 'Choisir...',
  '/equipments/new.infos.equipmentType.value': `{value, select,
    vae {Vélo à Assistance Électrique}
    standard {Vélo standard}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    other {Autre}
  }`,
  '/equipments/new.infos.identificationSystem.label': 'Type d\'identification *',
  '/equipments/new.infos.identificationSystem.value': `{value, select,
    none {Pas d'identification}
    bicycode {Bicycode}
    paravol {Paravol}
    recobike {Recobike}
    avrysec {Avrysec}
    velocenter {Velocenter ID}
    other {{ value }}
  }`,
  '/equipments/new.infos.identificationCode.label': 'ID d\'identification *',
  '/equipments/new.infos.brandId.label': 'Marque *',
  '/equipments/new.infos.brandId.placeholder': 'Selectionner ...',
  '/equipments/new.infos.brandId.filter': 'Chercher ...',
  '/equipments/new.infos.brandId.option': `{value, select,
    otherBrand {Autre ...}
    loading {Chargement ...}
    other {{ value }}
  }`,
  '/equipments/new.infos.customName.label': 'Nom personnalisé *',
  '/equipments/new.infos.model.label': 'Modèle',
  '/equipments/new.infos.model.empty': '-',
  '/equipments/new.infos.purchaseYear.label': 'Année d\'achat',
  '/equipments/new.infos.frontSprocket.label': 'Pignons à l\'avant',
  '/equipments/new.infos.rearSprocket.label': 'Pignons à l\'arrière',
  '/equipments/new.infos.wheelSize.label': 'Taille des roues',
  '/equipments/new.infos.identificationSystem.placeholder': 'Choisir',
  '/equipments/new.infos.identificationCode.placeholder': ' ',
  '/equipments/new.infos.brand.placeholder': 'Marque du vélo',

  '/equipments/new.infos.customName.placeholder': ' ',
  '/equipments/new.infos.model.placeholder': ' ',
  '/equipments/new.infos.purchaseYear.placeholder': ' ',
  '/equipments/new.infos.frontSprocket.placeholder': ' ',
  '/equipments/new.infos.rearSprocket.placeholder': ' ',
  '/equipments/new.infos.wheelSize.placeholder': ' ',
  '/equipments/new.dirty.disclaimer': 'Des informations sur le vélo ont été ajoutées',
  '/equipments/new.dirty.cancel': 'Réinitialiser',
  '/equipments/new.dirty.save': 'J\'ajoute un nouveau vélo',
  '/equipments/new.dirty.dismiss': 'J\'ai compris',

  '/equipments/new.velocare.business.title': 'Lier le vélo Velocare',
  '/equipments/new.velocare.business.label': 'Affaire',
  '/equipments/new.velocare.equipmentIdentifier.label': 'Identifiant du vélo',
  '/equipments/new.velocare.equipmentIdentifier.placeholder': '0123456',

  // /equipments/:equipmentId
  '/equipments/:equipmentId.pageTitle': 'Informations sur le vélo',

  '/equipments/:equipmentId.interventions.title': 'Interventions',
  '/equipments/:equipmentId.interventions.actions.new': 'Nouvelle intervention',
  '/equipments/:equipmentId.interventions.noInterventions': 'Aucune intervention',
  '/equipments/:equipmentId.interventions.name': 'Intervention { interventionId }',
  '/equipments/:equipmentId.interventions.status': `{ status, select,
    new {Nouveau}
    scheduled {Programmée}
    pending {En attente}
    done {Terminée}
    returned {Facturée}
    express {Facturée en expresse}
    closed {Clôturée}
    canceled {Annulée}
    other {Error}
  }{ date, select,
    undefined {}
    other { le {date, date}}
  }`,
  '/equipments/:equipmentId.interventions.billing': `{ billing, select,
    null {}
    other {<url>{ name }</url>}
  }`,

  '/equipments/:equipmentId.clients.item.label': '{ firstName } { lastName }',
  '/equipments/:equipmentId.clients.item.value': `{ dateTo, select,
    null {depuis le { dateSince, date }}
    other {du { dateSince, date } au { dateTo, date }}
  }`,

  '/equipments/:equipmentId.informations.item.equipmentType.label': 'Type de vélo',
  '/equipments/:equipmentId.informations.item.equipmentType.value': `{ value, select,
    vae {Vélo à Assistance Électrique}
    standard {Vélo standard}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    velocare {Vélo { business }}
    other {Autre}
  }`,
  '/equipments/:equipmentId.informations.item.identificationSystem.label': 'Type d\'identification',
  '/equipments/:equipmentId.informations.item.identificationSystem.value': `{ equipmentTypeValue, select,
    velocare {{ velocareIdentifier }}
    other {{identificationSystemValue, select,
      none {Aucun}
      bicycode {Bicycode ({ identificationCode })}
      paravol {Paravol ({ identificationCode })}
      recobike {Recobike ({ identificationCode })}
      avrysec {Avrysec ({ identificationCode })}
      velocenter {Velocenter}
      other {Erreur "identificationSystemValue" : { identificationSystemValue }}
    }}
  }`,
  '/equipments/:equipmentId.informations.item.velocenterIdentifier.label': 'Identification Velocenter',
  '/equipments/:equipmentId.informations.item.brand.label': 'Marque',
  '/equipments/:equipmentId.informations.item.brand.empty': 'N/A',
  '/equipments/:equipmentId.informations.item.customName.label': 'Nom personnalisé',
  '/equipments/:equipmentId.informations.item.customName.empty': 'N/A',
  '/equipments/:equipmentId.informations.item.model.label': 'Modèle',
  '/equipments/:equipmentId.informations.item.model.empty': '-',
  '/equipments/:equipmentId.informations.item.purchaseYear.label': 'Année d\'achat',
  '/equipments/:equipmentId.informations.item.purchaseYear.value': '{ value, select, undefined {-} other {{ value }} }',
  '/equipments/:equipmentId.informations.item.frontSprocket.label': 'Pignons à l\'avant',
  '/equipments/:equipmentId.informations.item.frontSprocket.value': '{ value, select, undefined {-} other {{ value }} }',
  '/equipments/:equipmentId.informations.item.rearSprocket.label': 'Pignons à l\'arrière',
  '/equipments/:equipmentId.informations.item.rearSprocket.value': '{ value, select, undefined {-} other {{ value }} }',
  '/equipments/:equipmentId.informations.item.wheelSize.label': 'Taille des roues',
  '/equipments/:equipmentId.informations.item.wheelSize.value': '{ value, select, undefined {-} other {{ value }} }',

  '/equipments/:equipmentId.informations.actions.edit': 'Modifier le vélo',
  '/equipments/:equipmentId.clients.item.details': 'Détail',
  '/equipments/:equipmentId.clients.actions.new': 'Nouveau client',

  '/equipments/:equipmentId.informations.title': 'Informations',
  '/equipments/:equipmentId.clients.title': 'Clients propriétaires',

  '/equipments/:equipmentId.selectClient.title': 'Nouvelle intervention',
  '/equipments/:equipmentId.selectClient.client.label': '{firstName} {lastName}',
  '/equipments/:equipmentId.selectClient.other.label': 'Autre',
  '/equipments/:equipmentId.selectClient.disclaimer': 'Vous allez créer une nouvelle intervention sur le client actif sur ce vélo.',
  '/equipments/:equipmentId.selectClient.other': 'Changer de client',
  '/equipments/:equipmentId.selectClient.confirm': 'Commencer l\'intervention',

  '/equipments/:equipmentId.visibility.title': 'Visibilité du vélo pour votre atelier',
  '/equipments/:equipmentId.visibility.hide.description': 'Masquer l\'équipement pour tout le monde dans toute l\'application',
  '/equipments/:equipmentId.visibility.hide.action': 'Cacher',
  '/equipments/:equipmentId.visibility.show.description': 'Rétablir la visibilité de l\'équipement',
  '/equipments/:equipmentId.visibility.show.action': 'Afficher',

  '/equipments/:equipmentId.dirty.retry': 'Je réessaye',

  '/equipments/:equipmentId/edit.pageTitle': 'Modifier un vélo',
  '/equipments/:equipmentId/edit.infos.equipmentType.label': 'Type de vélo *',
  '/equipments/:equipmentId/edit.infos.equipmentType.placeholder': 'Choisir...',
  '/equipments/:equipmentId/edit.infos.equipmentType.value': `{value, select,
    vae {Vélo à Assistance Électrique}
    standard {Vélo standard}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    other {Autre}
  }`,
  '/equipments/:equipmentId/edit.infos.identificationSystem.label': 'Type d\'identification *',
  '/equipments/:equipmentId/edit.infos.identificationSystem.value': `{value, select,
    none {Pas d'identification}
    bicycode {Bicycode}
    paravol {Paravol}
    recobike {Recobike}
    avrysec {Avrysec}
    velocenter {Velocenter ID}
    other {{ value }}
  }`,
  '/equipments/:equipmentId/edit.infos.velocenterIdentifier.label': 'Identification Velocenter',
  '/equipments/:equipmentId/edit.infos.identificationCode.label': 'ID d\'identification *',
  '/equipments/:equipmentId/edit.infos.brandId.label': 'Marque *',
  '/equipments/:equipmentId/edit.infos.brandId.placeholder': 'Selectionner ...',
  '/equipments/:equipmentId/edit.infos.brandId.filter': 'Chercher ...',
  '/equipments/:equipmentId/edit.infos.brandId.option': `{value, select,
    otherBrand {Autre ...}
    loading {Chargement ...}
    other {{ value }}
  }`,
  '/equipments/:equipmentId/edit.infos.customName.label': 'Nom personnalisé *',
  '/equipments/:equipmentId/edit.infos.model.label': 'Modèle',
  '/equipments/:equipmentId/edit.infos.model.empty': '-',
  '/equipments/:equipmentId/edit.infos.purchaseYear.label': 'Année d\'achat',
  '/equipments/:equipmentId/edit.infos.frontSprocket.label': 'Pignons à l\'avant',
  '/equipments/:equipmentId/edit.infos.rearSprocket.label': 'Pignons à l\'arrière',
  '/equipments/:equipmentId/edit.infos.wheelSize.label': 'Taille des roues',
  '/equipments/:equipmentId/edit.infos.identificationSystem.placeholder': 'Choisir',
  '/equipments/:equipmentId/edit.infos.identificationCode.placeholder': ' ',
  '/equipments/:equipmentId/edit.infos.brand.placeholder': 'Marque du vélo',
  '/equipments/:equipmentId/edit.infos.customName.placeholder': ' ',
  '/equipments/:equipmentId/edit.infos.model.placeholder': ' ',
  '/equipments/:equipmentId/edit.infos.purchaseYear.placeholder': ' ',
  '/equipments/:equipmentId/edit.infos.frontSprocket.placeholder': ' ',
  '/equipments/:equipmentId/edit.infos.rearSprocket.placeholder': ' ',
  '/equipments/:equipmentId/edit.infos.wheelSize.placeholder': ' ',

  '/equipments/:equipmentId/edit.dirty.disclaimer': 'Certaines informations du vélo ont été modifiées',
  '/equipments/:equipmentId/edit.dirty.cancel': 'Annuler les modifications',
  '/equipments/:equipmentId/edit.dirty.save': 'J\'enregistre les modifications',
  '/equipments/:equipmentId/edit.dirty.dismiss': 'J\'ai compris',

  // /networks
  '/networks/new.pageTitle': 'Ajouter un réseau',
  '/networks/new.title': 'Informations',
  '/networks/new.networkName.label': 'Nom',
  '/networks/new.networkName.placeholder': 'ACME Corporation...',
  '/networks/new.dirty.disclaimer': 'Des informations ont été mises à jour',
  '/networks/new.dirty.save': 'Créer',
  '/networks/new.dirty.cancel': 'Annuler',
  '/networks/new.dirty.dismiss': 'J\'ai compris',

  '/networks/admin.pageTitle': 'Administration du réseau',
  '/networks/admin.networks.title': 'Choix du réseau',
  '/networks/admin.header.title': 'En-tête de page',
  '/networks/admin.header.logo.label': 'Logo',
  '/networks/admin.header.logo.button': 'Choisir ...',
  '/networks/admin.header.link1.checkbox': 'Lien 1',
  '/networks/admin.header.link1.label': 'Nom du lien',
  '/networks/admin.header.link1.url': 'Url',
  '/networks/admin.header.link2.checkbox': 'Lien 2',
  '/networks/admin.header.link2.label': 'Nom du lien',
  '/networks/admin.header.link2.url': 'Url',

  '/networks/admin.style.title': 'Thème',
  '/networks/admin.style.preview.title': 'Prévisualisation',
  '/networks/admin.style.preview.button': 'Bouton',
  '/networks/admin.style.preview.link': 'Lien',

  '/networks/admin.config.title': 'Configuration',
  '/networks/admin.config.GTMId.label': 'Clé GTM',
  '/networks/admin.config.GTMId.placeholder': 'GTM-',
  '/networks/admin.config.domainUrl.label': 'Domain',
  '/networks/admin.config.domainUrl.placeholder': 'https://',
  '/networks/admin.config.asvs': 'Gestion SAV',

  '/networks/admin.dirty.disclaimer': 'Des informations ont été mises à jour',
  '/networks/admin.dirty.save': 'Sauvegarder',
  '/networks/admin.dirty.dismiss': 'J\'ai compris',

  '/networks/asvs.pageTitle': 'Gestion SAV',
  '/networks/asvs.networks.title': 'Choix du réseau',
  '/networks/asvs.dirty.dismiss': 'J\'ai compris',
  '/networks/asvs.list.businessId.label': 'Affaire',
  '/networks/asvs.list.businessId.placeholder': 'Choisir ...',
  '/networks/asvs.list.businessId.all': 'Aucune',
  '/networks/asvs.list.businessId.': '',
  '/networks/asvs.list.familyId.label': 'Famille',
  '/networks/asvs.list.familyId.placeholder': 'Choisir ...',
  '/networks/asvs.list.familyId.all': 'Aucune',
  '/networks/asvs.list.familyId.': '',
  '/networks/asvs.list.actions.edit': 'Editer',
  '/networks/asvs.list.actions.remove': 'Supprimer ...',
  '/networks/asvs.list.actions.cancel': 'Annuler',
  '/networks/asvs.list.actions.save': 'Sauvegarder',
  '/networks/asvs.list.actions.confirmRemove': 'Confirmer la suppression',
  '/networks/asvs.list.actions.abortRemove': 'Annuler',
  '/networks/asvs.list.loading': 'Chargement ...',
  '/networks/asvs.new.title': 'Nouveau',
  '/networks/asvs.new.loading': 'Chargement ...',
  '/networks/asvs.new.brandId.label': 'Marque',
  '/networks/asvs.new.brandId.placeholder': 'Choisir ...',
  '/networks/asvs.new.brandId.filter': 'Chercher ...',
  '/networks/asvs.new.businessId.label': 'Affaire',
  '/networks/asvs.new.businessId.placeholder': 'Choisir ...',
  '/networks/asvs.new.businessId.all': 'Aucune',
  '/networks/asvs.new.familyId.label': 'Famille',
  '/networks/asvs.new.familyId.placeholder': 'Choisir ...',
  '/networks/asvs.new.familyId.all': 'Aucune',
  '/networks/asvs.new.actions.create': 'Créer',

  // /shops
  '/shops.pageTitle': 'Mon administration',
  '/shops.initializing': 'Chargement des données en cours...',
  '/shops.users.title': 'Mes utilisateurs',
  '/shops.users.logoAlt': 'LOGO',
  '/shops.users.count': '{ count, plural, =0 {Pas d\'utilisateur} one {# utilisateur} other {# utilisateurs} }',
  '/shops.users.list': 'Voir ma liste',
  '/shops.users.new': 'J\'ajoute un utilisateur',
  '/shops.sharedPlannings.title': 'Mes plannings partagés',
  '/shops.sharedPlannings.count': '{ count, plural, =0 {Pas de planning partagé} one {# planning partagé} other {# plannings partagés} }',
  '/shops.sharedPlannings.list': 'Voir ma liste',
  '/shops.sharedPlannings.new': 'J\'ajoute un planning partagé',
  '/shops.workshops.title': 'Mes ateliers',
  '/shops.workshops.noShops': 'Pas encore d\'ateliers',
  '/shops.workshop.infos.name': '{ shopName }',
  '/shops.workshop.infos.network': '{ networkName }',
  '/shops.workshop.infos.userCount': '{ usersCount, plural, =0 {Pas d\'utilisateur} one {# utilisateur} other {# utilisateurs} }',
  '/shops.workshop.infos.sharedPlanningsCount': '{ sharedPlanningsCount, plural, =0 {Pas de planning partagé} one {# planning partagé} other {# plannings partagés} }',
  '/shops.workshop.update.title': 'Je modifie les infos sur...',
  '/shops.workshop.update.details': 'Admin',
  '/shops.workshop.update.legal': 'L\'atelier',
  '/shops.workshop.update.marketplace': 'La page de présentation',
  '/shops.workshop.update.pieces': 'Les tarifs',
  '/shops.actions.adminNetwork': 'Administration Réseau',
  '/shops.actions.new': 'Créer un nouvel atelier',

  // /shops/new
  '/shops/new.pageTitle': 'Créer un atelier',
  '/shops/new.shopName.label': 'Nom de l\'atelier *',
  '/shops/new.shopName.placeholder': ' ',
  '/shops/new.shopEmail.label': 'Email de contact *',
  '/shops/new.shopEmail.placeholder': ' ',
  '/shops/new.networkId.label': 'Réseau lié',
  '/shops/new.networkId.placeholder': ' ',
  '/shops/new.serviceOffer.label': 'Offre de service *',
  '/shops/new.serviceOffer.placeholder': ' ',
  '/shops/new.serviceOffer.option': `{ option, select,
    basic {Pack Lancement (basic)}
    premium {Pack Complet (premium)}
    custom {Pack Sur-mesure (custom)}
    network {Pack Réseau (network)}
    other {{value}}
  }`,
  '/shops/new.limitUserMax.label': 'Limite max. d\'utilisateurs *',
  '/shops/new.limitUserMax.placeholder': ' ',
  '/shops/new.chronocaisseIdentifier.label': 'Identifiant Chrono-Caisse (workspace) - se trouve dans l\'URL : chrono-caisse.com/ws/xxxxxxxxxxxxx',
  '/shops/new.chronocaisseIdentifier.placeholder': 'xxxxxxxxxxxxx',
  '/shops/new.chronocaisseApiKey.label': 'Clé API Chrono-Caisse - À créer avec clé "velocenter" depuis admin chronocaisse',
  '/shops/new.chronocaisseApiKey.placeholder': ' ',
  '/shops/new.submit': 'Valider',
  '/shops/new.dirty.disclaimer': 'Des informations ont été mises à jour...',
  '/shops/new.dirty.save': 'Créer l\'atelier',
  '/shops/new.dirty.reset': 'Reinitialiser',
  '/shops/new.dirty.dismiss': 'J\'ai compris',

  // /shops/:shopId/details
  '/shops/:shopId/details.pageTitle': 'Edition de l\'atelier',
  '/shops/:shopId/details.title': 'Modifier/Visualiser un Shop',
  '/shops/:shopId/details.shopName.label': 'Nom de l\'atelier *',
  '/shops/:shopId/details.shopEmail.label': 'Email de l\'atelier *',
  '/shops/:shopId/details.networkId.label': 'ID du réseau (si existant)',
  '/shops/:shopId/details.networkId.placeholder': 'Sélectionner un réseau',
  '/shops/:shopId/details.serviceOffer.label': 'Offre de service *',
  '/shops/:shopId/details.serviceOffer.placeholder': ' ',
  '/shops/:shopId/details.serviceOffer.option': `{ option, select,
    basic {Pack Lancement (basic)}
    premium {Pack Complet (premium)}
    custom {Pack Sur-mesure (custom)}
    network {Pack Réseau (network)}
    other {{value}}
  }`,
  '/shops/:shopId/details.limitUserMax.label': 'Nombre d\'utilisateur maximum *',
  '/shops/:shopId/details.limitUserMax.placeholder': ' ',
  '/shops/:shopId/details.chronocaisseIdentifier.label': 'Identifiant Chrono-Caisse (workspace) - se trouve dans l\'URL : chrono-caisse.com/ws/xxxxxxxxxxxxx',
  '/shops/:shopId/details.chronocaisseIdentifier.placeholder': 'xxxxxxxxxxxxx',
  '/shops/:shopId/details.chronocaisseApiKey.label': 'Clé API Chrono-Caisse - À créer avec clé "velocenter" depuis admin chronocaisse',
  '/shops/:shopId/details.chronocaisseApiKey.placeholder': ' ',
  '/shops/:shopId/details.submit': 'Soumettre',
  '/shops/:shopId/details.dirty.disclaimer': 'Des informations ont été mises à jour...',
  '/shops/:shopId/details.dirty.save': 'Sauvegarder',
  '/shops/:shopId/details.dirty.reset': 'Reinitialiser',
  '/shops/:shopId/details.dirty.dismiss': 'J\'ai compris',

  // /shops/:shopId/legal
  '/shops/:shopId/legal.pageTitle': 'Atelier - Informations de facturation',
  '/shops/:shopId/legal.shopLegalName.label': 'Nom ou raison sociale *',
  '/shops/:shopId/legal.shopLegalName.placeholder': ' ',
  '/shops/:shopId/legal.shopVatNumber.label': 'Numéro de TVA intra',
  '/shops/:shopId/legal.shopVatNumber.placeholder': 'FR32123123123',
  '/shops/:shopId/legal.shopCompanyNumber.label': 'Numéro SIRET *',
  '/shops/:shopId/legal.shopCompanyNumber.placeholder': '12312312300099',
  '/shops/:shopId/legal.addressFull.label': 'Adresse *',
  '/shops/:shopId/legal.addressFull.placeholder': ' ',
  '/shops/:shopId/legal.addressComplement.label': 'Complément d\'adresse (facultatif)',
  '/shops/:shopId/legal.addressComplement.placeholder': ' ',
  '/shops/:shopId/legal.legalNotice.label': 'Mentions légales à faire apparaitre',
  '/shops/:shopId/legal.legalNotice.placeholder': ' ',
  '/shops/:shopId/legal.vatRateMo.label': 'Taux de TVA Main d\'oeuvre *',
  '/shops/:shopId/legal.vatRateMo.placeholder': '0/10/20',
  '/shops/:shopId/legal.vatRatePc.label': 'Taux de TVA Pièce *',
  '/shops/:shopId/legal.vatRatePc.placeholder': '0/10/20',
  '/shops/:shopId/legal.dirty.disclaimer': 'Des informations ont été mises à jour...',
  '/shops/:shopId/legal.dirty.save': 'Sauvegarder',
  '/shops/:shopId/legal.dirty.reset': 'Reinitialiser',
  '/shops/:shopId/legal.dirty.dismiss': 'J\'ai compris',

  // /shops/:shopId/marketplace
  '/shops/:shopId/marketplace.pageTitle': 'Atelier - Page de présentation',
  '/shops/:shopId/marketplace.actions.frontLink': 'Voir la page de l\'atelier sur Velocenter',
  '/shops/:shopId/marketplace.main.title': 'Profil de l\'atelier en détails',
  '/shops/:shopId/marketplace.shopEmail': 'Email de contact *',
  '/shops/:shopId/marketplace.shopName': 'Nom visible sur la page et la recherche *',
  '/shops/:shopId/marketplace.servicesDesc': 'Description des services proposés *',
  '/shops/:shopId/marketplace.phoneNumber': 'Numéro de téléphone *',
  '/shops/:shopId/marketplace.addressFull': 'Adresse *',
  '/shops/:shopId/marketplace.addressComplement': 'Complément d\'adresse (facultatif)',
  '/shops/:shopId/marketplace.repairLocation.label': 'Types de réparations proposées',
  '/shops/:shopId/marketplace.repairLocation.twice': 'En atelier et à domicile',
  '/shops/:shopId/marketplace.repairLocation.shop': 'Uniquement en atelier',
  '/shops/:shopId/marketplace.repairLocation.home': 'Uniquement à domicile',
  '/shops/:shopId/marketplace.repairRange': 'Rayon de déplacement (en km)',
  '/shops/:shopId/marketplace.travelFees': 'Frais de déplacement fixe à appliquer (en €)',

  '/shops/:shopId/marketplace.pictures.title': 'Photos',
  '/shops/:shopId/marketplace.profilePicture.label': 'Photo de profil',
  '/shops/:shopId/marketplace.profilePicture.button': 'Je choisis une photo',
  '/shops/:shopId/marketplace.descPicture.label': 'Photo de description',
  '/shops/:shopId/marketplace.descPicture.button': 'Je choisis une photo',

  '/shops/:shopId/marketplace.equipmentType.title': 'Détails des services',
  '/shops/:shopId/marketplace.repairEquipments.label': 'L\'atelier prend en charge',
  '/shops/:shopId/marketplace.repairEquipments.vae': 'Vélo à Assistance Électrique',
  '/shops/:shopId/marketplace.repairEquipments.cargo': 'Vélo cargo',
  '/shops/:shopId/marketplace.repairEquipments.cargoae': 'Vélo cargo électrique',
  '/shops/:shopId/marketplace.repairEquipments.standard': 'Vélo standard',
  '/shops/:shopId/marketplace.repairSpecialization.label': 'Spécialités de l\'atelier',
  '/shops/:shopId/marketplace.repairSpecialization.cowboy': 'Vélo à transmission auto',
  '/shops/:shopId/marketplace.repairSpecialization.road': 'Vélo de route',
  '/shops/:shopId/marketplace.repairSpecialization.vtt': 'VTT',
  '/shops/:shopId/marketplace.repairSpecialization.dutch': 'Vélo hollandais',
  '/shops/:shopId/marketplace.repairSpecialization.lyingDown': 'Vélo position couchée',
  '/shops/:shopId/marketplace.repairSpecialization.folding': 'Vélo pliant',

  '/shops/:shopId/marketplace.rdv.title': 'Paramètres de prise de rendez-vous',
  '/shops/:shopId/marketplace.searchVisible.label': 'Profil visible dans les résultats de recherche',
  '/shops/:shopId/marketplace.searchVisible.yes': 'Oui',
  '/shops/:shopId/marketplace.searchVisible.no': 'Non',
  '/shops/:shopId/marketplace.bookableLocation.label': 'Type d\'intervention que le client peut réserver en ligne',
  '/shops/:shopId/marketplace.bookableLocation.value': `{ type, select,
    home {A domicile}
    shop {En atelier}
    other {{ type }}
  }`, // TODO: tr
  '/shops/:shopId/marketplace.repairServices.label': 'Les prestations de service proposées',
  '/shops/:shopId/marketplace.repairServices.breakdown': 'Panne',
  '/shops/:shopId/marketplace.repairServices.overhaul': 'Révision ou mise en service',
  '/shops/:shopId/marketplace.repairServices.spare': 'Montage pièce détachée',
  '/shops/:shopId/marketplace.repairServices.other': 'Autre',
  '/shops/:shopId/marketplace.bookableServices.label': 'Le client peut réserver un créneau',
  '/shops/:shopId/marketplace.bookableServices.breakdown': 'Panne',
  '/shops/:shopId/marketplace.bookableServices.overhaul': 'Révision ou mise en service',
  '/shops/:shopId/marketplace.bookableServices.spare': 'Montage pièce détachée',
  '/shops/:shopId/marketplace.bookableServices.other': 'Autre',
  '/shops/:shopId/marketplace.diagOnly.label': 'Forcer la prise de rendez-vous diagnostic pour toutes les pannes',
  '/shops/:shopId/marketplace.diagOnly.yes': 'Oui',
  '/shops/:shopId/marketplace.diagOnly.no': 'Non',

  '/shops/:shopId/marketplace.agenda.title': 'Paramètres du calendrier',
  '/shops/:shopId/marketplace.openingHours.label': 'Horaires d\'ouverture',
  '/shops/:shopId/marketplace.openingHours.monday': 'Lundi',
  '/shops/:shopId/marketplace.openingHours.tuesday': 'Mardi',
  '/shops/:shopId/marketplace.openingHours.wednesday': 'Mercredi',
  '/shops/:shopId/marketplace.openingHours.thursday': 'Jeudi',
  '/shops/:shopId/marketplace.openingHours.friday': 'Vendredi',
  '/shops/:shopId/marketplace.openingHours.saturday': 'Samedi',
  '/shops/:shopId/marketplace.openingHours.sunday': 'Dimanche',
  '/shops/:shopId/marketplace.dailySlots': 'Nombre de créneaux réservables par jour en atelier',
  '/shops/:shopId/marketplace.dailyHomeSlots': 'Nombre de créneaux réservables par jour à domicile',
  '/shops/:shopId/marketplace.daySlotDelay': 'Délai minimum de réservation en atelier',
  '/shops/:shopId/marketplace.dayHomeSlotDelay': 'Délai minimum de réservation à domicile',
  '/shops/:shopId/marketplace.daySlotDelay.value': `{ value, select,
    0 {Non}
    1 {1 Jour}
    2 {2 Jours}
    3 {3 Jours}
    4 {1 semaine}
    5 {2 semaines}
    other {Petit malin}
  }`,
  '/shops/:shopId/marketplace.travelTime': 'Temps de déplacement',
  '/shops/:shopId/marketplace.depositTerms': 'Modalités de dépôt du vélo',
  '/shops/:shopId/marketplace.returnTerms': 'Modalités de restitution du vélo',
  '/shops/:shopId/marketplace.calendar.label': 'Calendrier des disponibilités',
  '/shops/:shopId/marketplace.currentWeek': '{ firstDay, date, ::dL } - { lastDay, date, ::dL } { firstDay, date, ::yyyy }', // See https://formatjs.io/docs/intl-messageformat#datetime-skeleton for doc
  '/shops/:shopId/marketplace.slotStep.60': '1h',
  '/shops/:shopId/marketplace.slotStep.30': '30min',
  '/shops/:shopId/marketplace.calendar.header': '<title>{ date, date, ::eeee }</title><subTitle>{ date, date, ::dMMM }</subTitle>',
  '/shops/:shopId/marketplace.calendar.legend.selected': 'Disponible',
  '/shops/:shopId/marketplace.calendar.legend.free': 'Indisponible',
  '/shops/:shopId/marketplace.copyWeek': 'Appliquer aux semaines suivantes',
  '/shops/:shopId/marketplace.emptyWeek':'Indisponible cette semaine',

  '/shops/:shopId/marketplace.dirty.disclaimer': 'Des informations ont été modifiées...',
  '/shops/:shopId/marketplace.dirty.cancel': 'Réinitialiser',
  '/shops/:shopId/marketplace.dirty.save': 'Sauvegarder',
  '/shops/:shopId/marketplace.dirty.dismiss': 'J\'ai compris',

  // /shops/:shopId/pieces
  '/shops/:shopId/pieces.pageTitle': 'Les tarifs',
  '/shops/:shopId/pieces.repairEquipments.label': 'Type de vélo',
  '/shops/:shopId/pieces.repairEquipments.value': `{ value, select,
    vae {Vélo à Assistance Electrique}
    cargoae {Vélo cargo électrique}
    standard {Vélo standard}
    cargo {Vélo cargo}
    other {{ value }}
  }`,
  '/shops/:shopId/pieces.priceType.label': 'Grille des tarifs',
  '/shops/:shopId/pieces.priceType.value': `{ value, select,
    marketplace {Prestations affichés sur Velocenter.com}
    workforce {Mains-d'oeuvre}
    piece {Pièces}
    other {{ value }}
  }`,
  '/shops/:shopId/pieces.marketplace.title': 'Prestations affichées sur Velocenter.com',
  '/shops/:shopId/pieces.marketplace.repairService': 'Catégorie de service',
  '/shops/:shopId/pieces.marketplace.repairServicesDetails': 'Type de service',
  '/shops/:shopId/pieces.marketplace.duration': 'Durée (min)',
  '/shops/:shopId/pieces.marketplace.priceWt': 'Prix (€ TTC)',
  '/shops/:shopId/pieces.marketplace.breakdown': 'Panne',
  '/shops/:shopId/pieces.marketplace.breakdown.speed': 'Problème de vitesse',
  '/shops/:shopId/pieces.marketplace.breakdown.crankset': 'Pédalier défectueux',
  '/shops/:shopId/pieces.marketplace.breakdown.flatTire': 'Roue crevée',
  '/shops/:shopId/pieces.marketplace.breakdown.brokenWheel': 'Roue cassée',
  '/shops/:shopId/pieces.marketplace.breakdown.brakeAdjustment': 'Réglage des freins',
  '/shops/:shopId/pieces.marketplace.breakdown.brokenBrakes': 'Freins cassés',
  '/shops/:shopId/pieces.marketplace.breakdown.battery': 'Problème de batterie',
  '/shops/:shopId/pieces.marketplace.breakdown.diag': 'Diagnostic',
  '/shops/:shopId/pieces.marketplace.breakdown.otherBreakdown': 'Autre',
  '/shops/:shopId/pieces.marketplace.overhaul': 'Révision et mise en service',
  '/shops/:shopId/pieces.marketplace.overhaul.overhaul': 'Révision',
  '/shops/:shopId/pieces.marketplace.overhaul.setup': 'Mise en service',
  '/shops/:shopId/pieces.marketplace.spare': 'Montage de pièce détachée',
  '/shops/:shopId/pieces.marketplace.spare.spareMine': 'Montage pièce détachée en possession du client',
  '/shops/:shopId/pieces.marketplace.spare.spareBuy': 'Montage pièce détachée avec achat',
  '/shops/:shopId/pieces.marketplace.spare.kit': 'Montage kit électique',
  '/shops/:shopId/pieces.marketplace.spare.accessory': 'Montage petit accessoire',
  '/shops/:shopId/pieces.marketplace.spare.idSetup': 'Installation identification',
  '/shops/:shopId/pieces.marketplace.spare.lockDisassembly': 'Démontage antivol',
  '/shops/:shopId/pieces.marketplace.spare.spareDisassembly': 'Démontage pièce détachée',
  '/shops/:shopId/pieces.marketplace.other': 'Autre',
  '/shops/:shopId/pieces.marketplace.other.none': 'Autre',
  '/shops/:shopId/pieces.marketplace.other.other': 'Autre',
  '/shops/:shopId/pieces.workforce.title': 'Tarifs des mains d\'oeuvre',
  '/shops/:shopId/pieces.workforce.itvcodeIdentifier': 'Code unique',
  '/shops/:shopId/pieces.workforce.itvcodeLabel': 'Libellé',
  '/shops/:shopId/pieces.workforce.duration': 'Durée (min)',
  '/shops/:shopId/pieces.workforce.priceWt': 'Prix (€ TTC)',
  '/shops/:shopId/pieces.workforce.new': 'Ajouter une MO',
  '/shops/:shopId/pieces.piece.title': 'Tarifs des pièces de rechange',
  '/shops/:shopId/pieces.piece.itvcodeIdentifier': 'Code unique',
  '/shops/:shopId/pieces.piece.chronocaisseRef': 'Réf pièce',
  '/shops/:shopId/pieces.piece.itvcodeLabel': 'Libellé',
  '/shops/:shopId/pieces.piece.priceWt': 'Prix (€ TTC)',
  '/shops/:shopId/pieces.piece.new': 'Ajouter une pièce',
  '/shops/:shopId/pieces.itvcodeIdentifier.placeholder': 'Code unique ...',
  '/shops/:shopId/pieces.chronocaisseRef.placeholder': 'Réf pièce ...',
  '/shops/:shopId/pieces.itvcodeLabel.placeholder': 'Libellé ...',
  '/shops/:shopId/pieces.duration.placeholder': 'Durée ...',
  '/shops/:shopId/pieces.priceWt.placeholder': 'Prix € TTC ...',
  '/shops/:shopId/pieces.dirty.disclaimer': 'Des informations ont été modifiées ...',
  '/shops/:shopId/pieces.dirty.cancel': 'Réinitialiser',
  '/shops/:shopId/pieces.dirty.dismiss': 'J\'ai compris',
  '/shops/:shopId/pieces.dirty.save': 'Sauvegarder',
  '/shops/:shopId/pieces.sync.title': 'Actions avec le logiciel de caisse',
  '/shops/:shopId/pieces.sync.button': 'Synchroniser les prix',
  '/shops/:shopId/pieces.synced': 'Synchronisé',
  '/shops/:shopId/pieces.import.title': 'Importer / Télécharger les tarifs',
  '/shops/:shopId/pieces.import': 'Importer des tarifs',
  '/shops/:shopId/pieces.exportModel': 'Télécharger le modele de données',
  '/shops/:shopId/pieces.exportState': 'Télécharger tous les tarifs',
  '/shops/:shopId/pieces.import.modal.title': 'Importer des tarifs',
  '/shops/:shopId/pieces.import.modal.disclaimer': `
Souhaitez-vous écraser et remplacer les données existantes ou fusionner vos tarifs avec les données existantes ?`,
  '/shops/:shopId/pieces.import.modal.actions.scratch': 'Ecraser les données existantes',
  '/shops/:shopId/pieces.import.modal.actions.merge': 'Fusionner avec les données existantes',

  '/sharedPlannings.pageTitle': 'Plannings partagés',
  '/sharedPlannings.new': 'Créer',
  '/sharedPlannings.initializing': 'Chargement des données en cours...',
  '/sharedPlannings.filters.networkId.label': 'Filtrer par réseau',
  '/sharedPlannings.filters.networkId.all': 'Tous les réseaux',
  '/sharedPlannings.filters.networkId.placeholder': ' ',
  '/sharedPlannings.filters.shopId.label': 'Filtrer par atelier',
  '/sharedPlannings.filters.shopId.all': 'Tous les ateliers',
  '/sharedPlannings.filters.shopId.placeholder': ' ',
  '/sharedPlannings.filters.filter.label': 'Recherche',
  '/sharedPlannings.filters.filter.placeholder': ' ',
  '/sharedPlannings.result.header.name': 'Nom',
  '/sharedPlannings.result.header.link': 'Lien',
  '/sharedPlannings.result.header.shopName': 'Atelier',
  '/sharedPlannings.result.header.btn.view': ' ',
  '/sharedPlannings.result.item.name': '{ name }',
  '/sharedPlannings.result.item.link': '{ userName } - { businessName }',
  '/sharedPlannings.result.item.networkName': '{ networkName }',
  '/sharedPlannings.result.item.shopName': '{ shopName }',
  '/sharedPlannings.result.item.btn.view': 'Editer',
  '/sharedPlannings.noresult': 'Aucun plannings partagés dans la liste',

  '/sharedPlannings/new.pageTitle': 'Edition d\'un planning partagé',
  '/sharedPlannings/new.workshops.title': 'Choix d\'un atelier',
  '/sharedPlannings/new.workshops.label': 'Atelier lié',
  '/sharedPlannings/new.workshops.new': 'Créer un nouvel atelier',
  '/sharedPlannings/new.infos.title': 'Informations',
  '/sharedPlannings/new.infos.loading': 'Chargement ...',
  '/sharedPlannings/new.infos.name.label': 'Nom',
  '/sharedPlannings/new.infos.businessId.label': 'Affaire',
  '/sharedPlannings/new.infos.businessId.placeholder': 'Choisir ...',
  '/sharedPlannings/new.infos.userId.label': 'Utilisateur',
  '/sharedPlannings/new.infos.userId.placeholder': 'Choisir ...',

  '/sharedPlannings/new.dirty.dismiss': 'Fermer',
  '/sharedPlannings/new.dirty.retry': 'Je réessaye',
  '/sharedPlannings/new.dirty.disclaimer': 'Des modifications ont été effectuées sur le planning partagé',
  '/sharedPlannings/new.dirty.save': 'J\'enregistre les modifications',

  '/sharedPlannings/:sharedPlanningId.pageTitle': 'Edition d\'un planning partagé',
  '/sharedPlannings/:sharedPlanningId.workshops.title': 'Choix d\'un atelier',
  '/sharedPlannings/:sharedPlanningId.workshops.label': 'Atelier lié',
  '/sharedPlannings/:sharedPlanningId.workshops.new': 'Créer un nouvel atelier',
  '/sharedPlannings/:sharedPlanningId.infos.title': 'Informations',
  '/sharedPlannings/:sharedPlanningId.infos.loading': 'Chargement ...',
  '/sharedPlannings/:sharedPlanningId.infos.name.label': 'Nom',
  '/sharedPlannings/:sharedPlanningId.infos.businessId.label': 'Affaire',
  '/sharedPlannings/:sharedPlanningId.infos.businessId.placeholder': 'Choisir ...',
  '/sharedPlannings/:sharedPlanningId.infos.userId.label': 'Utilisateur',
  '/sharedPlannings/:sharedPlanningId.infos.userId.placeholder': 'Choisir ...',

  '/sharedPlannings/:sharedPlanningId.dirty.dismiss': 'Fermer',
  '/sharedPlannings/:sharedPlanningId.dirty.retry': 'Je réessaye',
  '/sharedPlannings/:sharedPlanningId.dirty.disclaimer': 'Des modifications ont été effectuées sur le planning partagé',
  '/sharedPlannings/:sharedPlanningId.dirty.save': 'J\'enregistre les modifications',

  '/interventions.pageTitle': 'Mes interventions',
  '/interventions.initializing': 'Chargement des données en cours...',
  '/interventions.ipp': 'Résultats par page',
  '/interventions.express': 'Nouvelle intervention expresse',
  '/interventions.new': 'Nouvelle intervention',
  '/interventions.filters.shopId.label': 'Filtrer par atelier',
  '/interventions.filters.shopId.all': 'Tous les ateliers',
  '/interventions.filters.filter.label': 'Filtrer',
  '/interventions.filters.filter.placeholder': 'Intervenant / Client ...',
  '/interventions.filters.statusId.label': 'Filtrer par statut',
  '/interventions.filters.statusId.all': 'Tous les statuts',
  '/interventions.filters.statusId.value': `{ value, select,
    new {Nouveau}
    scheduled {Programmée}
    pending {En attente}
    done {Terminée}
    returned {Facturée}
    express {Facturée en expresse}
    closed {Clôturée}
    canceled {Annulée}
    other {{value}}
  }`,
  '/interventions.filters.dateSince.label': 'Depuis le',
  '/interventions.filters.dateSince.placeholder': '01/01/1970',
  '/interventions.filters.dateTo.label': 'Jusqu\'au',
  '/interventions.filters.dateTo.placeholder': '01/01/2200',
  '/interventions.filters.dateScheduledReturn.label': 'Date de restitution',
  '/interventions.filters.dateScheduledReturn.placeholder': '01/01/2200',
  '/interventions.filters.location.label': 'Type d\'intervention',
  '/interventions.filters.location.all': 'Tous',
  '/interventions.filters.location.option': `{ option, select,
    shop {En atelier}
    home {A domicile}
    other {{ option }}
  }`,
  '/interventions.filters.originId.label': 'Origine',
  '/interventions.filters.originId.all': 'Tous',
  '/interventions.filters.originId.option': `{ option, select,
    bo {BO}
    marketplace {Marketplace}
    other {{ option }}
  }`,
  '/interventions.filters.id.label': 'Numéro d\'intervention',
  '/interventions.filters.id.placeholder': '1234',
  '/interventions.filters.id.button': 'Voir',
  '/interventions.result.header.interventionId': 'ID',
  '/interventions.result.header.address': 'Adresse de l\'intervention',
  '/interventions.result.header.date': 'Date planifiée',
  '/interventions.result.header.dateScheduledReturn': 'Date de restitution',
  '/interventions.result.header.user': 'Intervenant', // TODO: new #760
  '/interventions.result.header.equipmentDescription': 'Vélo',
  '/interventions.result.header.originId': 'Origine',
  '/interventions.result.header.userName': 'Client',
  '/interventions.result.header.duration': 'Durée',
  '/interventions.result.header.statusId': 'Statut',
  '/interventions.result.header.btn.view': 'Voir',
  '/interventions.result.item.interventionId': '{ interventionId }',
  '/interventions.result.item.address': '{ addressFull }',
  '/interventions.result.item.date': `{ date, select,
    null {}
    other {{date, date, ::yyyyMMddHm}}
  }`,
  '/interventions.result.item.dateScheduledReturn': `{ date, select,
    null {}
    other {{date, date, ::yyyyMMddHm}}
  }`,
  '/interventions.result.item.user': '{ techFirstName } { techLastName }', // TODO: new #760
  '/interventions.result.item.userName': '{ firstName } { lastName }',
  '/interventions.result.item.phoneNumber': '{ phoneNumber }',
  '/interventions.result.item.duration': `{ duration, select,
    null {N/A}
    other {{duration} min}
  }`,
  '/interventions.result.item.btn.view': 'Voir',
  '/interventions.noresult': 'Aucune intervention à afficher ...',

  // /interventions/new
  '/interventions/new.pageTitle': 'Intervention sur le vélo { equipmentId } - Création de l’intervention',

  '/interventions/new.noItvcodes': 'Vous devez choisir au moins un code MO ou pièce pour continuer',
  '/interventions/new.dirty.save': 'Sauvegarder',
  '/interventions/new.dirty.dismiss': 'Fermer',
  '/interventions/new.dirty.forceSave': 'Valider',

  '/interventions/new.form.title': 'Détail de l’intervention',
  '/interventions/new.form.shopId.label': 'Atelier *',
  '/interventions/new.form.shopId.placeholder': 'Choisir ...',
  '/interventions/new.form.locationId.label': 'Type d’intervention *',
  '/interventions/new.form.locationId.value': `{value, select,
    shop {A l'atelier}
    home {A domicile}
    other {{ other }}
  }`,
  '/interventions/new.form.locationId.placeholder': 'Choisir ...',
  '/interventions/new.form.address.label': 'Adresse d’intervention *',
  '/interventions/new.form.address.placeholder': ' ',
  '/interventions/new.form.addressComplement.label': 'Complément d\'adresse (facultatif)',
  '/interventions/new.form.addressComplement.placeholder': ' ',
  '/interventions/new.form.servicesId.label': 'Prestation de service',
  '/interventions/new.form.servicesId.placeholder': 'Choisir ...',
  '/interventions/new.form.servicesId.all': 'Aucun',
  '/interventions/new.form.description.label': 'Description de l\'intervention',
  '/interventions/new.form.description.placeholder': ' ',
  '/interventions/new.form.additional.label': 'Révision complémentaire',
  '/interventions/new.form.additional.true': 'Oui',
  '/interventions/new.form.additional.false': 'Non',
  '/interventions/new.form.isNonQuality.label': 'Non qualité',
  '/interventions/new.form.itvcodes.add.label': 'Mains d\'oeuvres et pièces changées *',
  '/interventions/new.form.itvcodes.add.placeholder': 'Choisir ...',
  '/interventions/new.form.itvcodes.filter.placeholder': 'Rechercher ...',
  '/interventions/new.form.itvcodes.new': 'Créer un code itv',
  '/interventions/new.form.commentsForClient.label': 'Commentaire pour l’usager',
  '/interventions/new.form.commentsForClient.placeholder': ' ',
  '/interventions/new.form.commentsForUser.label': 'Commentaire pour le technicien',
  '/interventions/new.form.commentsForUser.placeholder': ' ',
  '/interventions/new.form.actions.submit': 'Créer et planifier l’intervention',
  '/interventions/new.form.actions.expressIntervention': 'Facturation expresse',
  '/interventions/new.form.actions.estimate': 'Créer un devis',
  '/interventions/new.form.actions.cancel': 'Annuler la création',

  '/interventions/new.summary.client.title': 'Informations client',
  '/interventions/new.summary.client.content.clientId.label': 'ID Client',
  '/interventions/new.summary.client.content.firstName.label': 'Prénom',
  '/interventions/new.summary.client.content.lastName.label': 'Nom',
  '/interventions/new.summary.client.content.email.label': 'Adresse email',
  '/interventions/new.summary.client.content.phoneNumber.label': 'Numéro de téléphone',
  '/interventions/new.summary.client.content.addressFull.label': 'Adresse de facturation',
  '/interventions/new.summary.client.actions.edit': 'Modifier le client',

  '/interventions/new.editClientModal.title': 'Modifier le client', // TODO: tr
  '/interventions/new.editClientModal.firstName.label': 'Prénom', // TODO: tr
  '/interventions/new.editClientModal.firstName.placeholder': '...', // TODO: tr
  '/interventions/new.editClientModal.lastName.label': 'Nom', // TODO: tr
  '/interventions/new.editClientModal.lastName.placeholder': '...', // TODO: tr
  '/interventions/new.editClientModal.email.label': 'Adresse email', // TODO: tr
  '/interventions/new.editClientModal.email.plcaholder': '...', // TODO: tr
  '/interventions/new.editClientModal.phoneNumber.label': 'Numéro de téléphone', // TODO: tr
  '/interventions/new.editClientModal.phoneNumber.placeholder': '...', // TODO: tr
  '/interventions/new.editClientModal.addressFull.label': 'Adresse de facturation', // TODO: tr
  '/interventions/new.editClientModal.addressFull.placeholder': '...', // TODO: tr
  '/interventions/new.editClientModal.addressComplement.label': 'Complément d\'adresse (facultatif)', // TODO: tr
  '/interventions/new.editClientModal.addressComplement.placeholder': '...', // TODO: tr
  '/interventions/new.editClientModal.save': 'Sauvegarder', // TODO: tr

  '/interventions/new.summary.equipment.title': 'Vélo du client',
  '/interventions/new.summary.equipment.content.velocenterIdentifier.label': 'Identification Vélocenter',
  '/interventions/new.summary.equipment.content.equipmentType.label': 'Type de vélo',
  '/interventions/new.summary.equipment.content.equipmentType.value': `{ equipmentTypeValue, select,
    standard {Vélo standard}
    vae {Vélo à Assistance Électrique}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    velocare {Vélo { business }}
    other {Autre}
  }`,
  '/interventions/new.summary.equipment.content.customName.label': 'Nom personnalisé',
  '/interventions/new.summary.equipment.content.customName.value': '{ customName , select, null {-} other {{ customName }} }',
  '/interventions/new.summary.equipment.content.brand.label': 'Marque',
  '/interventions/new.summary.equipment.content.brand.value': '{ brand , select, null {-} other {{ brand }} }',
  '/interventions/new.summary.equipment.content.equipmentStatus.label': 'Statut',
  '/interventions/new.summary.equipment.content.equipmentStatus.value': `{ equipmentStatus, select,
    active {En sa possession}
    sold {Vendu}
    destroyed {Détruit}
    stolen {Volé}
    other {Erreur avec equipmentStatus : { equipmentStatus }}
  }`,
  '/interventions/new.summary.equipment.actions.edit': 'Modifier le vélo',

  '/interventions/new.billing.total_Nt.label': 'Total HT',
  '/interventions/new.billing.total_Nt.value': '{total_Nt, number, ::currency/EUR}',
  '/interventions/new.billing.total_Taxes.label': 'Total TVA',
  '/interventions/new.billing.total_Taxes.value': '{total_Taxes, number, ::currency/EUR}',
  '/interventions/new.billing.total_Wt.label': 'Total TTC',
  '/interventions/new.billing.total_Wt.value': '{total_Wt, number, ::currency/EUR}',
  '/interventions/new.billing.total_Pc_Wt.label': 'Total Pièces TTC',
  '/interventions/new.billing.total_Pc_Wt.value': '{total_Pc_Wt, number, ::currency/EUR}',
  '/interventions/new.billing.total_Mo_Wt.label': 'Total Main d\'Oeuvre TTC',
  '/interventions/new.billing.total_Mo_Wt.value': '{total_Mo_Wt, number, ::currency/EUR}',
  '/interventions/new.billing.result.total_Wt.label': 'Total TTC',
  '/interventions/new.billing.result.total_Wt.value': '{total_Wt, number, ::currency/EUR}',
  
  '/interventions/new.confirmExpress.title': 'Êtes vous sûrs',
  '/interventions/new.confirmExpress.content': 'Vous etes sur le point de creer l\'intervention directement en l\'état facturée',
  '/interventions/new.confirmExpress.actions.cancel': 'Annuler',
  '/interventions/new.confirmExpress.actions.submit': 'Valider',

  '/interventions/new.createItvcodeModal.title': 'Ajouter un code d\'intervention',
  '/interventions/new.createItvcodeModal.mandatory': 'Tous les champs sont obligatoires',
  '/interventions/new.createItvcodeModal.type.workforce': 'Main d\'œuvre (MO)',
  '/interventions/new.createItvcodeModal.type.piece': 'Pièce de rechange (PR)',
  '/interventions/new.createItvcodeModal.itvcodeIdentifier.label': 'Code unique',
  '/interventions/new.createItvcodeModal.itvcodeIdentifier.placeholder': 'Champ requis',
  '/interventions/new.createItvcodeModal.itvcodeLabel.label': 'Description',
  '/interventions/new.createItvcodeModal.itvcodeLabel.placeholder': 'Champ requis',
  '/interventions/new.createItvcodeModal.price.label': 'Prix',
  '/interventions/new.createItvcodeModal.duration.label': 'Durée',
  '/interventions/new.createItvcodeModal.persist': 'Enregistrer ce code pour les prochaines fois',
  '/interventions/new.createItvcodeModal.actions.create': 'Ajouter à l\'intervention',
  '/interventions/new.createItvcodeModal.actions.error.dismiss': 'J\'ai compris',

  '/interventions/express.pageTitle': 'Intervention expresse',
  '/interventions/express.form.title': 'Détail de l’intervention',
  '/interventions/express.form.shopId.label': 'Atelier *',
  '/interventions/express.form.shopId.placeholder': 'Sélectionner ...',
  '/interventions/express.form.equipmentType.label': 'Type de vélo *',
  '/interventions/express.form.equipmentType.placeholder': 'Choisir ...',
  '/interventions/express.form.equipmentType.value': `{ value, select,
    standard {Vélo standard}
    vae {Vélo à Assistance Électrique}
    cargo {Vélo cargo}
    cargoae {Vélo cargo électrique}
    velocare {Vélo { business }}
    other {Autre}
  }`,
  '/interventions/express.form.equipmentName.label': 'Nom du vélo *',
  '/interventions/express.form.locationId.label': 'Type d’intervention *',
  '/interventions/express.form.locationId.value': `{value, select,
    shop {A l'atelier}
    home {A domicile}
    other {{ other }}
  }`,
  '/interventions/express.form.locationId.placeholder': 'Choisir ...',
  '/interventions/express.form.servicesId.label': 'Prestation de service',
  '/interventions/express.form.servicesId.placeholder': 'Choisir ...',
  '/interventions/express.form.servicesId.all': 'Aucun',
  '/interventions/express.form.address.label': 'Adresse d’intervention *',
  '/interventions/express.form.addressComplement.label': 'Complément d\'adresse (facultatif)',
  '/interventions/express.form.description.label': 'Description de l\'intervention',
  '/interventions/express.form.additional.label': 'Révision complémentaire',
  '/interventions/express.form.additional.true': 'Oui',
  '/interventions/express.form.additional.false': 'Non',
  '/interventions/express.form.isNonQuality.label': 'Non qualité',
  '/interventions/express.form.itvcodes.add.label': 'Mains d\'oeuvres et pièces changées *',
  '/interventions/express.form.itvcodes.add.placeholder': 'Choisir ...',
  '/interventions/express.form.itvcodes.filter.placeholder': 'Rechercher ...',
  '/interventions/express.form.itvcodes.new': 'Créer un code itv',
  '/interventions/express.noItvcodes': 'Vous devez choisir au moins un code MO ou pièce pour continuer',
  '/interventions/express.form.commentsForClient.label': 'Commentaire pour l’usager',
  '/interventions/express.form.commentsForUser.label': 'Commentaire pour le technicien',
  
  '/interventions/express.billing.total_Nt.label': 'Total HT',
  '/interventions/express.billing.total_Nt.value': '{total_Nt, number, ::currency/EUR}',
  '/interventions/express.billing.total_Taxes.label': 'Total TVA',
  '/interventions/express.billing.total_Taxes.value': '{total_Taxes, number, ::currency/EUR}',
  '/interventions/express.billing.total_Wt.label': 'Total TTC',
  '/interventions/express.billing.total_Wt.value': '{total_Wt, number, ::currency/EUR}',
  '/interventions/express.billing.total_Pc_Wt.label': 'Total Pièces TTC',
  '/interventions/express.billing.total_Pc_Wt.value': '{total_Pc_Wt, number, ::currency/EUR}',
  '/interventions/express.billing.total_Mo_Wt.label': 'Total Main d\'Oeuvre TTC',
  '/interventions/express.billing.total_Mo_Wt.value': '{total_Mo_Wt, number, ::currency/EUR}',
  '/interventions/express.billing.result.total_Wt.label': 'Total TTC',
  '/interventions/express.billing.result.total_Wt.value': '{total_Wt, number, ::currency/EUR}',

  '/interventions/express.actions.transformToItv': 'Convertir en Intervention',
  '/interventions/express.actions.submit': 'Facturation expresse',
  '/interventions/express.actions.cancel': 'Annuler la création',
  '/interventions/express.dirty.dismiss': 'J\'ai compris',

  '/interventions/express.createItvcodeModal.title': 'Ajouter un code d\'intervention',
  '/interventions/express.createItvcodeModal.mandatory': 'Tous les champs sont obligatoires',
  '/interventions/express.createItvcodeModal.type.workforce': 'Main d\'œuvre (MO)',
  '/interventions/express.createItvcodeModal.type.piece': 'Pièce de rechange (PR)',
  '/interventions/express.createItvcodeModal.itvcodeIdentifier.label': 'Code unique',
  '/interventions/express.createItvcodeModal.itvcodeIdentifier.placeholder': 'Champ requis',
  '/interventions/express.createItvcodeModal.itvcodeLabel.label': 'Description',
  '/interventions/express.createItvcodeModal.itvcodeLabel.placeholder': 'Champ requis',
  '/interventions/express.createItvcodeModal.price.label': 'Prix',
  '/interventions/express.createItvcodeModal.duration.label': 'Durée',
  '/interventions/express.createItvcodeModal.persist': 'Enregistrer ce code pour les prochaines fois',
  '/interventions/express.createItvcodeModal.actions.create': 'Ajouter à l\'intervention',
  '/interventions/express.createItvcodeModal.actions.error.dismiss': 'J\'ai compris',

  '/interventions/express.confirmPost.title': 'Confirmer',
  '/interventions/express.confirmPost.content': 'Vous allez creer une intervention directement a l\'etat de facturation',
  '/interventions/express.confirmPost.actions.submit': 'Valider',
  '/interventions/express.confirmPost.actions.cancel': 'Annuler',

  '/interventions/new/select-client.notFound': 'Créer un nouveau client',
  '/interventions/new/select-client.pageTitle': 'Recherchez un client pour cette intervention',
  '/interventions/new/select-client.filters.filter.label': 'Recherche',
  '/interventions/new/select-client.filters.filter.placeholder': 'John Doe / email@dot.com ...',
  '/interventions/new/select-client.filters.clientPhone.label': 'Téléphone du client',
  '/interventions/new/select-client.filters.clientPhone.placeholder': '06 01 02 03 04',
  '/interventions/new/select-client.result.header.name': 'Nom du client',
  '/interventions/new/select-client.result.header.phoneNumber': 'Téléphone',
  '/interventions/new/select-client.result.header.email': 'Email',
  '/interventions/new/select-client.result.header.btn.select': 'Actions',
  '/interventions/new/select-client.result.item.name': '{ firstName } { lastName }',
  '/interventions/new/select-client.result.item.phoneNumber': '{ phoneNumber, select, null {N/A} other {{ phoneNumber }} }',
  '/interventions/new/select-client.result.item.email': '{ email }',
  '/interventions/new/select-client.result.item.btn.select': 'Je sélectionne ce client',
  '/interventions/new/select-client.noresult': 'Aucun client trouvé avec cet email',
  '/interventions/new/select-client.SelectedClientModal.title': 'Sélection du vélo',
  '/interventions/new/select-client.SelectedClientModal.disclaimer': 'Vous devez choisir un vélo de votre client :',
  '/interventions/new/select-client.SelectedClientModal.list.equipment': `
    <title>{velocenterIdentifier}</title>
    <content>{customName}</content>
  `,
  '/interventions/new/select-client.SelectedClientModal.list.other': 'Créer un nouveau vélo',
  '/interventions/new/select-client.SelectedClientModal.actions.cancel': 'Annuler',
  '/interventions/new/select-client.SelectedClientModal.actions.confirm': 'Valider',
  '/interventions/new/select-client.initializing': 'Chargement ...',

  '/interventions/new/select-equipment.selectClient.title': 'Nouvelle intervention',
  '/interventions/new/select-equipment.selectClient.disclaimer': 'Vous allez créer une nouvelle intervention sur le client en possession de vélo : { firstName } { lastName }',
  '/interventions/new/select-equipment.selectClient.other': 'Changer de client',
  '/interventions/new/select-equipment.selectClient.confirm': 'Commencer l\'intervention',
  '/interventions/new/select-equipment.noresult': 'Aucun vélo trouvé avec cette identification',

  '/interventions/new/select-equipment.notFound': 'Rechercher par client',
  '/interventions/new/select-equipment.pageTitle': 'Recherchez un vélo pour cette intervention',
  '/interventions/new/select-equipment.filters.filter.label': 'Recherche',
  '/interventions/new/select-equipment.filters.filter.placeholder': 'Identifiant / Client / Marque ...',
  '/interventions/new/select-equipment.result.header.velocenterIdentifier': 'Identifiant Vélocenter',
  '/interventions/new/select-equipment.result.header.identification': 'Identification',
  '/interventions/new/select-equipment.result.header.brand': 'Marque',
  '/interventions/new/select-equipment.result.header.customName': 'Nom personnalisé',
  '/interventions/new/select-equipment.result.header.clientName': 'Client',
  '/interventions/new/select-equipment.result.header.btn.select': 'Actions',
  '/interventions/new/select-equipment.result.item.identification': `{ system, select,
    none {Vélocenter}
    bicycode {Bicycode}
    paravol {Paravol}
    recobike {Recobike}
    avrysec {Avrysec}
    velocare {Velocare}
    other { Inconnu }
  }{ code, select,
    null {}
    other { - { code }}
  }`,
  '/interventions/new/select-equipment.result.item.clientName': '{ firstName } { lastName }',
  '/interventions/new/select-equipment.result.item.btn.select': 'Je sélectionne ce vélo',
  '/interventions/new/select-equipment.initializing': 'Chargement ...',

  // /interventions/:interventionId/details
  '/interventions/:interventionId/details.pageTitle': 'Intervention { id }',
  '/interventions/:interventionId/details.actions.newItv': 'Créer une nouvelle intervention ?',

  '/interventions/:interventionId/details.noItvcodes': 'Vous devez choisir au moins un code MO ou pièce pour continuer',

  '/interventions/:interventionId/details.breadcrumb.new.label': 'Nouveau',
  '/interventions/:interventionId/details.breadcrumb.pending.label': 'En attente',
  '/interventions/:interventionId/details.breadcrumb.scheduled.label': 'Planifiée',
  '/interventions/:interventionId/details.breadcrumb.done.label': 'Terminée',
  '/interventions/:interventionId/details.breadcrumb.returned.label': 'Facturée',
  '/interventions/:interventionId/details.breadcrumb.express.label': 'Facturée en express',
  '/interventions/:interventionId/details.breadcrumb.closed.label': 'Clôturée',
  '/interventions/:interventionId/details.breadcrumb.canceled.label': 'Annulée',

  '/interventions/:interventionId/details.summary.schedule.title': 'Planification',
  '/interventions/:interventionId/details.summary.schedule.duration.label': 'Durée',
  '/interventions/:interventionId/details.summary.schedule.datePlanned.label': 'Date de réparation',
  '/interventions/:interventionId/details.summary.schedule.datePlanned.value': '{ value, date, ::yyyyMMddHm }',
  '/interventions/:interventionId/details.summary.schedule.userIdPlanned.label': 'Technicien assigné',
  '/interventions/:interventionId/details.summary.schedule.dateScheduledReturn.label': 'Date de restitution',
  '/interventions/:interventionId/details.summary.schedule.dateScheduledReturn.value': '{ value, date, ::yyyyMMddHm }',
  '/interventions/:interventionId/details.summary.schedule.actions.reschedule': 'Replanifier',

  '/interventions/:interventionId/details.summary.client.title': 'Informations client et vélo',
  '/interventions/:interventionId/details.summary.client.velocenterIdentifier': 'Identifiant Vélocenter',
  '/interventions/:interventionId/details.summary.client.customName': 'Nom personnalisé',
  '/interventions/:interventionId/details.summary.client.userName': 'Nom',
  '/interventions/:interventionId/details.summary.client.phoneNumber.label': 'Téléphone',
  '/interventions/:interventionId/details.summary.client.phoneNumber.value': '{ phoneNumber, select, null {N/A} other {{ phoneNumber }}}',
  '/interventions/:interventionId/details.summary.client.email': 'Email',

  '/interventions/:interventionId/details.summary.details.title': 'Détail de l’intervention',
  '/interventions/:interventionId/details.summary.details.locationId.label': 'Type de prestation',
  '/interventions/:interventionId/details.summary.details.locationId.value': `{value, select,
    shop {A l'atelier}
    home {A domicile}
    other {{ other }}
  }`,
  '/interventions/:interventionId/details.summary.details.address.label': 'Adresse d’intervention',
  '/interventions/:interventionId/details.summary.details.addressComplement.label': 'Complément d\'adresse (facultatif)',
  '/interventions/:interventionId/details.summary.details.addressComplement.placeholder': ' ',
  '/interventions/:interventionId/details.summary.details.servicesId.label': 'Prestation de service',
  '/interventions/:interventionId/details.summary.details.servicesId.placeholder': 'Choisir ...',
  '/interventions/:interventionId/details.summary.details.servicesId.all': 'Aucun',
  '/interventions/:interventionId/details.summary.details.additional.label': 'Prestation complémentaire',
  '/interventions/:interventionId/details.summary.details.additional.tick': 'Révision complémentaire',
  '/interventions/:interventionId/details.summary.details.description.label': 'Description de l\'intervention',
  '/interventions/:interventionId/details.summary.details.description.placeholder': ' ',
  '/interventions/:interventionId/details.summary.details.pictures.label': 'Photos du client',

  '/interventions/:interventionId/details.main.schedule.title': 'Planification',
  '/interventions/:interventionId/details.main.schedule.duration.label': 'Durée de l\'intervention',
  '/interventions/:interventionId/details.main.schedule.date.label': 'Date',
  '/interventions/:interventionId/details.main.schedule.filterUser.placeholder': 'Filtrer sur un utilisateur',
  '/interventions/:interventionId/details.main.schedule.userIdPlanned.label': 'Technicien *',
  '/interventions/:interventionId/details.main.schedule.userIdPlanned.placeholder': 'Choix du technicien',
  '/interventions/:interventionId/details.main.schedule.time.label': 'Heure *',
  '/interventions/:interventionId/details.main.schedule.time.placeholder': 'Heure de l\'intervention',
  '/interventions/:interventionId/details.main.schedule.time.filter.placeholder': 'Rechercher ...',
  '/interventions/:interventionId/details.main.schedule.planning.item.title': `{ interventionId, select,
    undefined {#{ current }}
    other {#{ interventionId }}
  }`,
  '/interventions/:interventionId/details.main.schedule.planning.item.desc': `{ interventionId, select,
    undefined {#{ current }}
    other {#{ interventionId }}
  }`,
  '/interventions/:interventionId/details.main.schedule.datePlanned.label': 'Actuellement planifiée pour',
  '/interventions/:interventionId/details.main.schedule.datePlanned.value': `{ datePlanned, select,
    null {Pas encore planifié}
    other {{datePlanned, date, ::yyyyMMddHm}}
  }`,
  '/interventions/:interventionId/details.main.schedule.remindClient.label': 'Prévenir le client de la date et l\'heure de l’intervention ? *',
  '/interventions/:interventionId/details.main.schedule.remindClient.true': 'Oui',
  '/interventions/:interventionId/details.main.schedule.remindClient.false': 'Non',

  '/interventions/:interventionId/details.main.schedule.scheduledReturnDay.label': 'Date de restitution',
  '/interventions/:interventionId/details.main.schedule.scheduledReturnDay.placeholder': 'Date de restitution ...',
  '/interventions/:interventionId/details.main.schedule.scheduledReturnTime.label': 'Heure de restitution',
  '/interventions/:interventionId/details.main.schedule.scheduledReturnTime.placeholder': 'Heure de restitution ...',
  '/interventions/:interventionId/details.main.schedule.scheduledReturnTime.filter.placeholder': 'Rechercher ...',
  '/interventions/:interventionId/details.main.schedule.actions.plan': `{pending, select,
    true {Programmer l'intervention}
    other {Je planifie l’intervention}
  }`,
  '/interventions/:interventionId/details.main.schedule.actions.hold': 'Mettre en attente',
  '/interventions/:interventionId/details.main.schedule.actions.cancel': 'Annuler l\'intervention',

  '/interventions/:interventionId/details.main.bikeReceipt.title': 'Réception du vélo en atelier',
  '/interventions/:interventionId/details.main.bikeReceipt.content.toReceive': 'Le vélo n\'est pas encore réceptionné dans atelier',
  '/interventions/:interventionId/details.main.bikeReceipt.content.received': 'Le vélo a été réceptionné le { dateBikeReceipt, date, ::ccccc } { dateBikeReceipt, date, ::dMMM } à {dateBikeReceipt, date, ::Hm} par { firstName } { lastName }',
  '/interventions/:interventionId/details.main.bikeReceipt.actions.received': 'Indiquer comme réceptionné',

  '/interventions/:interventionId/details.main.coreContent.new.title': 'Codes d\'interventions',
  '/interventions/:interventionId/details.main.coreContent.pending.title': 'Codes d\'interventions',
  '/interventions/:interventionId/details.main.coreContent.scheduled.title': 'Intervention',
  '/interventions/:interventionId/details.main.coreContent.done.title': 'Facturation',
  '/interventions/:interventionId/details.main.coreContent.canceled.title': 'Intervention annulée',

  '/interventions/:interventionId/details.main.coreContent.itvcodes.add.label': 'Mains d\'oeuvres et pièces changées *',
  '/interventions/:interventionId/details.main.coreContent.itvcodes.add.placeholder': 'Choisir ...',
  '/interventions/:interventionId/details.main.coreContent.itvcodes.filter.placeholder': 'Rechercher ...',
  '/interventions/:interventionId/details.main.coreContent.itvcodes.new': 'Créer un code itv',
  '/interventions/:interventionId/details.main.coreContent.commentsForClient.label': 'Commentaire pour l’usager',
  '/interventions/:interventionId/details.main.coreContent.commentsForClient.placeholder': ' ',
  '/interventions/:interventionId/details.main.coreContent.commentsForUser.label': 'Commentaire pour le technicien',
  '/interventions/:interventionId/details.main.coreContent.commentsForUser.placeholder': ' ',

  '/interventions/:interventionId/details.main.coreContent.actions.generateReceiptOrder': 'Générer OR',
  '/interventions/:interventionId/details.main.coreContent.actions.transformToEstimate': 'Transformer en devis',
  '/interventions/:interventionId/details.main.coreContent.actions.askReturnCondition': 'Je termine l’intervention',
  '/interventions/:interventionId/details.main.coreContent.actions.hold': 'Mettre en attente',
  '/interventions/:interventionId/details.main.coreContent.actions.cancel': 'Annuler l’intervention',

  '/interventions/:interventionId/details.confirmCancel.title': 'Annuler l’intervention',
  '/interventions/:interventionId/details.confirmCancel.disclaimer': 'Indiquez à l’utilisateur les raisons de l’annulation de cette intervention. Il sera informé par email dans les plus brefs délais.',
  '/interventions/:interventionId/details.confirmCancel.cancelationReason.label': 'Raisons d’annulation',
  '/interventions/:interventionId/details.confirmCancel.cancelationReason.placeholder': ' ',
  '/interventions/:interventionId/details.confirmCancel.actions.cancel': 'Annuler',
  '/interventions/:interventionId/details.confirmCancel.actions.submit': 'Je confirme l’annulation',

  '/interventions/:interventionId/details.confirmHold.title': 'Mettre en attente',
  '/interventions/:interventionId/details.confirmHold.disclaimer': 'L’intervention va être mise en attente',
  '/interventions/:interventionId/details.confirmHold.actions.cancel': 'Annuler',
  '/interventions/:interventionId/details.confirmHold.actions.submit': 'Valider',

  '/interventions/:interventionId/details.imageOpen.title': 'Photo client',
  '/interventions/:interventionId/details.imageOpen.actions.open': 'Ouvrir',
  '/interventions/:interventionId/details.imageOpen.actions.close': 'Fermer',

  '/interventions/:interventionId/details.disclaimer': 'Des informations ont été modifiées...',
  '/interventions/:interventionId/details.cancel': 'Réinitialiser',
  '/interventions/:interventionId/details.save': 'Sauvegarder',

  '/interventions/:interventionId/details.main.coreContent.returning.title': 'Restitution du vélo (checkout)',
  '/interventions/:interventionId/details.main.coreContent.returning.picture.button': 'Prise de photo',
  '/interventions/:interventionId/details.main.coreContent.returning.clientValidation.label': 'Validation du client *',
  '/interventions/:interventionId/details.main.coreContent.returning.clientValidation.value': 'Le client a donné son accord pour facturation',
  '/interventions/:interventionId/details.main.coreContent.returning.clientReservations.label': 'Réserves du client',
  '/interventions/:interventionId/details.main.coreContent.returning.clientReservations.placeholder': ' ',
  '/interventions/:interventionId/details.main.coreContent.returning.returnValidation.label': 'Validation de la restitution',
  '/interventions/:interventionId/details.main.coreContent.returning.returnValidation.value': 'En tant que technicien, je confirme que le vélo est restitué en bon état et qu\'il n\'existe pas de vice identifié.',
  '/interventions/:interventionId/details.main.coreContent.returning.techReservations.label': 'Réserves du technicien',
  '/interventions/:interventionId/details.main.coreContent.returning.techReservations.placeholder': ' ',
  '/interventions/:interventionId/details.main.coreContent.returning.addressFull.label': 'Adresse de facturation *',
  '/interventions/:interventionId/details.main.coreContent.returning.addressFull.placeholder': ' ',
  '/interventions/:interventionId/details.main.coreContent.returning.addressComplement.label': 'Complément d\'adresse (facultatif)',
  '/interventions/:interventionId/details.main.coreContent.returning.addressComplement.placeholder': '...',
  '/interventions/:interventionId/details.main.coreContent.returning.nexStep': 'Je restitue le vélo',

  '/interventions/:interventionId/details.main.coreContent.billingSummary.title': 'Facturation',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.itvcode.priceWt': `{ billingType, select,
    dontCharge {Non facturé}
    sav {Prise en charge SAV • { priceWt, number, ::currency/EUR }}
    other {Facturé • { priceWt, number, ::currency/EUR }}
  }`,
  '/interventions/:interventionId/details.main.coreContent.billingSummary.itvcode.quantity.label': 'Quantité',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.itvcode.comments.label': 'Commentaire',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.itvcode.comments.value': '{ comments, select, null {N/A} other {{ comments }} }',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.commentsForClient.label': 'Commentaire pour le client',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.commentsForClient.value': '{ commentsForClient, select, null {N/A} other {{ commentsForClient }} }',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.commentsForUser.label': 'Commentaire pour le technicien',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.commentsForUser.value': '{ commentsForUser, select, null {N/A} other {{ commentsForUser }} }',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.billing.title': 'Facturation',
  '/interventions/:interventionId/details.main.coreContent.billingSummary.billingSav.title': 'Service Après-Vente',

  '/interventions/:interventionId/details.main.returned.title': 'Restitution',
  '/interventions/:interventionId/details.main.returned.clientValidation.label': 'Validation du client',
  '/interventions/:interventionId/details.main.returned.clientValidation.value': 'Le client est ok pour facturation',
  '/interventions/:interventionId/details.main.returned.clientReservations.label': 'Réserves du client',
  '/interventions/:interventionId/details.main.returned.clientReservations.value': '{ clientReservations, select, null {N/A} other {{ clientReservations }} }',
  '/interventions/:interventionId/details.main.returned.returnValidation.label': 'Validation de la restitution',
  '/interventions/:interventionId/details.main.returned.returnValidation.value': `{ returnValidation, select,
    true {Vélo est en état / il est restitué et que l’atelier est responsable de ses actes}
    null {N/A}
    other {Vélo restitué en l'état, en connaissance de un ou plusieurs défauts que le client refuse de régler}
  }`,
  '/interventions/:interventionId/details.main.returned.techReservations.label': 'Réserves du technicien',
  '/interventions/:interventionId/details.main.returned.techReservations.value': '{ techReservations, select, null {N/A} other {{ techReservations }} }',

  '/interventions/:interventionId/details.billing.total_Nt.label': 'Total HT',
  '/interventions/:interventionId/details.billing.total_Nt.value': '{total_Nt, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.total_Taxes.label': 'Total TVA',
  '/interventions/:interventionId/details.billing.total_Taxes.value': '{total_Taxes, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.total_Wt.label': 'Total TTC',
  '/interventions/:interventionId/details.billing.total_Wt.value': '{total_Wt, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.total_Pc_Wt.label': 'Total Pièces TTC',
  '/interventions/:interventionId/details.billing.total_Pc_Wt.value': '{total_Pc_Wt, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.total_Mo_Wt.label': 'Total Main d\'Oeuvre TTC',
  '/interventions/:interventionId/details.billing.total_Mo_Wt.value': '{total_Mo_Wt, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.result.total_Wt.label': 'Total TTC',
  '/interventions/:interventionId/details.billing.result.total_Wt.value': '{total_Wt, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.total_Sav_Nt.label': 'Total SAV HT',
  '/interventions/:interventionId/details.billing.total_Sav_Nt.value': '{total_Sav_Nt, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.total_Sav_Vat.label': 'Total SAV TVA',
  '/interventions/:interventionId/details.billing.total_Sav_Vat.value': '{total_Sav_Vat, number, ::currency/EUR}',
  '/interventions/:interventionId/details.billing.result.total_Sav_Wt.label': 'Total SAV TTC',
  '/interventions/:interventionId/details.billing.result.total_Sav_Wt.value': '{total_Sav_Wt, number, ::currency/EUR}',

  '/interventions/:interventionId/details.history.title': 'Historique',
  '/interventions/:interventionId/details.history.dateCreated': '{ dateCreated, date, ::yyyMMddHm }',
  '/interventions/:interventionId/details.history.userName': '@{ userName }',

  '/interventions/:interventionId/details.returnConditions.title': 'Restitution du vélo',
  '/interventions/:interventionId/details.returnConditions.disclaimer': 'Indiquez à l\'utilisateur vos modalités de restitutions pour qu\'il puisse récupérer son vélo dans les meilleures conditions.',
  '/interventions/:interventionId/details.returnConditions.label': 'Conditions de restitution',
  '/interventions/:interventionId/details.returnConditions.placeholder': ' ',
  '/interventions/:interventionId/details.returnConditions.cancel': 'Annuler',
  '/interventions/:interventionId/details.returnConditions.submit': 'Je confirme la restitution',

  '/interventions/:interventionId/details.actions.close': 'Je clôture l\'intervention',
  '/interventions/:interventionId/details.dirty.dismiss': 'Fermer',
  '/interventions/:interventionId/details.dirty.retry': 'Je réessaye',

  '/interventions/:interventionId/details.dirty.disclaimer': 'Des modifications ont été effectuées sur l\'intervention',
  '/interventions/:interventionId/details.dirty.cancel': 'Annuler les modifications',
  '/interventions/:interventionId/details.dirty.save': 'J\'enregistre les modifications',

  '/interventions/:interventionId/details.createItvcodeModal.title': 'Ajouter un code d\'intervention',
  '/interventions/:interventionId/details.createItvcodeModal.mandatory': 'Tous les champs sont obligatoires',
  '/interventions/:interventionId/details.createItvcodeModal.type.workforce': 'Main d\'œuvre (MO)',
  '/interventions/:interventionId/details.createItvcodeModal.type.piece': 'Pièce de rechange (PR)',
  '/interventions/:interventionId/details.createItvcodeModal.itvcodeIdentifier.label': 'Code unique',
  '/interventions/:interventionId/details.createItvcodeModal.itvcodeIdentifier.placeholder': 'Champ requis',
  '/interventions/:interventionId/details.createItvcodeModal.itvcodeLabel.label': 'Description',
  '/interventions/:interventionId/details.createItvcodeModal.itvcodeLabel.placeholder': 'Champ requis',
  '/interventions/:interventionId/details.createItvcodeModal.price.label': 'Prix',
  '/interventions/:interventionId/details.createItvcodeModal.duration.label': 'Durée',
  '/interventions/:interventionId/details.createItvcodeModal.persist': 'Enregistrer ce code pour les prochaines fois',
  '/interventions/:interventionId/details.createItvcodeModal.actions.create': 'Ajouter à l\'intervention',
  '/interventions/:interventionId/details.createItvcodeModal.actions.error.dismiss': 'J\'ai compris',

  '/estimates.pageTitle': 'Mes devis',
  '/estimates.actions.new': 'Nouveau devis',

  '/estimates.statusValue': `{ statusValue, select,
    new {Nouveau}
    sent {Envoyé}
    done {Validé}
    canceled {Annulé}
    other {{ statusValue }}
  }`,

  '/estimates.filters.shopId.label': 'Atelier',
  '/estimates.filters.shopId.all': 'Tous',
  // '/estimates.filters.shopId.placeholder': '',
  '/estimates.filters.filter.label': 'Filtre',
  '/estimates.filters.filter.placeholder': 'Nom / Identifiant ...',
  '/estimates.filters.dateSince.label': 'Depuis le',
  '/estimates.filters.dateSince.placeholder': '01/01/1970',
  '/estimates.filters.dateTo.label': 'Jusqu’au',
  '/estimates.filters.dateTo.placeholder': '01/01/2222',
  '/estimates.filters.statusId.label': 'Statut',
  '/estimates.filters.statusId.all': 'Tous',

  '/estimates.noresult': 'Pas de devis à afficher',
  '/estimates.initializing': 'Chargement ...',
  '/estimates.result.header.date': 'Date',
  '/estimates.result.header.estimateId': 'Id',
  '/estimates.result.header.velocenterIdentifier': 'Identification Vélocenter',
  '/estimates.result.header.userName': 'Client',
  '/estimates.result.header.status': 'Statut',
  '/estimates.result.header.totalNt': 'HT',
  '/estimates.result.header.totalWt': 'TTC',
  '/estimates.result.header.view': ' ',
  '/estimates.result.item.date': '{ date, date }',
  '/estimates.result.item.estimateId': '{ estimateId }',
  '/estimates.result.item.velocenterIdentifier': '{ velocenterIdentifier }',
  '/estimates.result.item.userName': '{ userName }',
  '/estimates.result.item.totalNt': '{ total_Nt }',
  '/estimates.result.item.totalWt': '{ total_Wt }',
  '/estimates.result.item.view': 'Accéder',

  '/estimates/:estimateId/details.interventionLocation': '{ key, select, home {A domicile} shop {En atelier} other {{ key }} }',

  '/estimates/:estimateId/details.pageTitle': 'Devis {id}',
  '/estimates/:estimateId/details.actions.goToItv': 'Accéder à l’intervention',
  '/estimates/:estimateId/details.actions.newItv': 'Créer un nouveau devis',
  '/estimates/:estimateId/details.actions.duplicate': 'Annuler et dupliquer le devis',
  '/estimates/:estimateId/details.actions.confirmCancel': 'Annuler le devis',
  '/estimates/:estimateId/details.actions.showPDF': 'Télécharger le devis',
  '/estimates/:estimateId/details.actions.send': 'Envoyer par email',
  '/estimates/:estimateId/details.actions.resend': 'Renvoyer le devis par email au client',
  '/estimates/:estimateId/details.actions.createIntervention': 'Valider et planifier l’intervention',

  '/estimates/:estimateId/details.confirmCancel.title': 'Annuler le devis',
  '/estimates/:estimateId/details.confirmCancel.disclaimer': 'Voulez-vous vraiment annuler la création du devis ?',
  '/estimates/:estimateId/details.confirmCancel.actions.cancel': 'Annuler',
  '/estimates/:estimateId/details.confirmCancel.actions.submit': 'Je confirme l’annulation',

  '/estimates/:estimateId/details.breadcrumb.new.label': 'Nouveau',
  '/estimates/:estimateId/details.breadcrumb.sent.label': 'Envoyé',
  '/estimates/:estimateId/details.breadcrumb.done.label': 'Validé',
  '/estimates/:estimateId/details.breadcrumb.canceled.label': 'Annulé',

  '/estimates/:estimateId/details.summary.client.title': 'Informations',
  '/estimates/:estimateId/details.summary.client.velocenterIdentifier': 'Identifiant Vélocenter',
  '/estimates/:estimateId/details.summary.client.customName': 'Nom personnalisé',
  '/estimates/:estimateId/details.summary.client.userName': 'Nom du client',
  '/estimates/:estimateId/details.summary.client.phoneNumber.label': 'Téléphone',
  '/estimates/:estimateId/details.summary.client.phoneNumber.value': '{ phoneNumber, select, null {N/A} other {{ phoneNumber }}}',
  '/estimates/:estimateId/details.summary.client.email': 'Email',

  '/estimates/:estimateId/details.coreContent.new.title': 'Détail du devis',
  '/estimates/:estimateId/details.coreContent.sent.title': 'Envoyé',
  '/estimates/:estimateId/details.coreContent.done.title': 'Validé',
  '/estimates/:estimateId/details.coreContent.canceled.title': 'Annulé',

  '/estimates/:estimateId/details.coreContent.locationId.label': 'Type d’intervention *',
  '/estimates/:estimateId/details.coreContent.locationId.placeholder': 'Choisir ...',
  '/estimates/:estimateId/details.coreContent.address.label': 'Adresse de l’intervention',
  '/estimates/:estimateId/details.coreContent.address.placeholder': ' ',
  '/estimates/:estimateId/details.coreContent.addressComplement.label': 'Complément d’adresse (facultatif)',
  '/estimates/:estimateId/details.coreContent.addressComplement.placeholder': ' ',
  '/estimates/:estimateId/details.coreContent.servicesDetailsId.label': 'Prestation de service',
  '/estimates/:estimateId/details.coreContent.servicesDetailsId.placeholder': 'Choisir ...',
  '/estimates/:estimateId/details.coreContent.description.label': 'Description de l’intervention',
  '/estimates/:estimateId/details.coreContent.description.placeholder': ' ',
  '/estimates/:estimateId/details.coreContent.additional.label': 'Révision complémentaire',
  '/estimates/:estimateId/details.coreContent.additional.true': 'Oui',
  '/estimates/:estimateId/details.coreContent.additional.false': 'Non',

  '/estimates/:estimateId/details.coreContent.itvcodes.add.label': 'Mains d’oeuvres et pièces à changées *',
  '/estimates/:estimateId/details.coreContent.itvcodes.add.placeholder': 'Choisir ...',
  '/estimates/:estimateId/details.coreContent.itvcodes.filter.placeholder': 'Rechercher ...',
  '/estimates/:estimateId/details.coreContent.commentsForClient.label': 'Commentaire pour l’usager',
  '/estimates/:estimateId/details.coreContent.commentsForClient.placeholder': ' ',
  '/estimates/:estimateId/details.coreContent.commentsForUser.label': 'Commentaire pour le technicien',
  '/estimates/:estimateId/details.coreContent.commentsForUser.placeholder': ' ',
  '/estimates/:estimateId/details.noItvcodes': 'Au moins un code d’intervention est nécessaire.',
  '/estimates/:estimateId/details.dirty.dismiss': 'J’ai compris',

  '/estimates/:estimateId/details.coreContent.itvcode.priceWt': `{ dontCharge, select,
    true {Non facturé}
    false {Facturé • { priceWt, number, ::currency/EUR }}
    other {Erreur}
  }`,
  '/estimates/:estimateId/details.coreContent.itvcode.quantity.label': 'Quantité',
  '/estimates/:estimateId/details.coreContent.itvcode.comments.label': 'Commentaire',
  '/estimates/:estimateId/details.coreContent.itvcode.comments.value': '{ comments, select, null {N/A} other {{ comments }} }',

  '/estimates/:estimateId/details.billing.total_Nt.label': 'Total HT',
  '/estimates/:estimateId/details.billing.total_Nt.value': '{total_Nt, number, ::currency/EUR}',
  '/estimates/:estimateId/details.billing.total_Taxes.label': 'Total TVA',
  '/estimates/:estimateId/details.billing.total_Taxes.value': '{total_Taxes, number, ::currency/EUR}',
  '/estimates/:estimateId/details.billing.total_Wt.label': 'Total TTC',
  '/estimates/:estimateId/details.billing.total_Wt.value': '{total_Wt, number, ::currency/EUR}',
  '/estimates/:estimateId/details.billing.total_Pc_Wt.label': 'Total Pièces TTC',
  '/estimates/:estimateId/details.billing.total_Pc_Wt.value': '{total_Pc_Wt, number, ::currency/EUR}',
  '/estimates/:estimateId/details.billing.total_Mo_Wt.label': 'Total Main d’Oeuvre TTC',
  '/estimates/:estimateId/details.billing.total_Mo_Wt.value': '{total_Mo_Wt, number, ::currency/EUR}',
  '/estimates/:estimateId/details.billing.result.total_Wt.label': 'Total TTC',
  '/estimates/:estimateId/details.billing.result.total_Wt.value': '{total_Wt, number, ::currency/EUR}',

  '/estimates/:estimateId/details.history.title': 'Historique',
  '/estimates/:estimateId/details.history.dateCreated': '{ dateCreated, date, ::yyyMMddHm }',
  '/estimates/:estimateId/details.history.userName': '{ userName }',

  '/estimates/:estimateId/details.dirty.disclaimer': 'Des modifications ont été effectuées sur l’intervention',
  '/estimates/:estimateId/details.dirty.cancel': 'Annuler les modifications',
  '/estimates/:estimateId/details.dirty.save': 'J’enregistre les modifications',

  // /planning
  '/planning.pageTitle': 'Planning',
  '/planning.sharedPlanning.title': 'Plannings partagés',
  '/planning.tasks.new': 'Créer une tâche',
  '/planning.interventions.express': 'Créer une intervention expresse',
  '/planning.interventions.new': 'Créer une intervention',
  '/planning.filter.user.label': 'Filtrer par utilisateur',
  '/planning.filter.user.placeholder': 'Tous les utilisateurs',
  '/planning.filter.shopId.label': 'Filtrer par atelier',
  '/planning.filter.shopId.all': 'Tous les ateliers',
  '/planning.filter.shopId.placeholder': 'Tous les ateliers',
  '/planning.filter.showBlocked.label': 'Utilisateurs bloqués',
  '/planning.filter.display': 'Afficher',
  '/planning.main.schedule.date.label': 'Filtrer par date',
  '/planning.legend.title': 'Légende',
  '/planning.legend.planning-intervention-scheduled': 'Intervention Programmée',
  '/planning.legend.planning-intervention-done': 'Intervention Terminée',
  '/planning.legend.planning-intervention-returned': 'Intervention facturée',
  '/planning.legend.planning-intervention-express': 'Intervention facturée en expresse',
  '/planning.legend.planning-intervention-closed': 'Intervention Cloturée',
  '/planning.legend.planning-tasks': 'Tâche',
  '/planning.legend.planning-unavailabilities': 'Indisponibilité',

  '/planning/:userId.pageTitle': 'Planning de { userName, select, undefined {-} other {{userName}} }', // de { userName }
  '/planning/:userId.tasks.new': 'Créer une tâche',
  '/planning/:userId.interventions.express': 'Créer une intervention en expresse',
  '/planning/:userId.interventions.new': 'Créer une intervention',
  '/planning/:userId.main.schedule.date.label': 'Date',
  '/planning/:userId.main.schedule.column.header': '{ date, date, ::ccccc } { date, date, ::dMMM }',
  '/planning/:userId.legend.title': 'Légende',
  '/planning/:userId.legend.planning-intervention-scheduled': 'Intervention Programmée',
  '/planning/:userId.legend.planning-intervention-done': 'Intervention Terminée',
  '/planning/:userId.legend.planning-intervention-returned': 'Intervention facturée',
  '/planning/:userId.legend.planning-intervention-express': 'Intervention facturée en expresse',
  '/planning/:userId.legend.planning-intervention-closed': 'Intervention Cloturée',
  '/planning/:userId.legend.planning-tasks': 'Tâche',
  '/planning/:userId.legend.planning-unavailabilities': 'Indisponibilité',

  // Tasks
  '/tasks/new.pageTitle': 'Créer une tâche',
  '/tasks/new.who.title': 'Qui',
  '/tasks/new.shopId.label': 'Atelier',
  '/tasks/new.shopId.placeholder': 'Sélectionner ...',
  '/tasks/new.userId.label': 'Technicien',
  '/tasks/new.userId.placeholder': 'Sélectionner ...',
  '/tasks/new.userId.value': '{ firstName } { lastName }',
  '/tasks/new.userId.filter.placeholder': 'Rechercher ...',
  '/tasks/new.when.title': 'Quand',
  '/tasks/new.date.label': 'Jour',
  '/tasks/new.time.label': 'Heure',
  '/tasks/new.time.placeholder': 'Sélectionner ...',
  '/tasks/new.time.filter.placeholder': 'Rechercher ...',
  '/tasks/new.duration.label': 'Durée',
  '/tasks/new.duration.hours.placeholder': '0H',
  '/tasks/new.duration.minutes.placeholder': '0 min',
  '/tasks/new.what.title': 'Quoi',
  '/tasks/new.description.label': 'Description',
  '/tasks/new.description.placeholder': 'Description ...', // TODO:
  '/tasks/new.actions.submit': 'Créer',
  '/tasks/new.actions.cancel': 'Annuler',
  '/tasks/new.dirty.dismiss': 'J’ai compris',

  '/tasks/:taskId/details.pageTitle': 'Tâche',
  '/tasks/:taskId/details.who.title': 'Qui',
  '/tasks/:taskId/details.shopId.label': 'Atelier',
  '/tasks/:taskId/details.shopId.placeholder': 'Sélectionner ...',
  '/tasks/:taskId/details.userId.label': 'Technicien',
  '/tasks/:taskId/details.userId.placeholder': 'Sélectionner ...',
  '/tasks/:taskId/details.userId.value': '{ firstName } { lastName }',
  '/tasks/:taskId/details.userId.filter.placeholder': 'Rechercher ...',
  '/tasks/:taskId/details.when.title': 'Quand',
  '/tasks/:taskId/details.date.label': 'Jour',
  '/tasks/:taskId/details.time.label': 'Heure',
  '/tasks/:taskId/details.time.placeholder': 'Sélectionner ...',
  '/tasks/:taskId/details.time.filter.placeholder': 'Rechercher ...',
  '/tasks/:taskId/details.duration.label': 'Durée',
  '/tasks/:taskId/details.what.title': 'Quoi',
  '/tasks/:taskId/details.description.label': 'Description',
  '/tasks/:taskId/details.description.placeholder': 'Description ...', // TODO:
  '/tasks/:taskId/details.actions.submit': 'Editer',
  '/tasks/:taskId/details.actions.cancel': 'Fermer',
  '/tasks/:taskId/details.actions.remove': 'Supprimer',
  '/tasks/:taskId/details.dirty.dismiss': 'J\'ai compris',
  '/tasks/:taskId/details.saved': 'Sauvegardé',

  '/tasks/:taskId/details.confirm.title': 'Supprimer la tâche',
  '/tasks/:taskId/details.confirm.dismiss': 'Annuler',
  '/tasks/:taskId/details.confirm.action': 'Confirmer',

  // /invoices
  '/invoices.pageTitle': 'Liste des factures',
  '/invoices.noresult': 'Pas de facture à afficher',
  '/invoices.initializing': 'Chargement des données en cours...',
  '/invoices.filters.filter.label': 'Recherche',
  '/invoices.filters.filter.placeholder': ' ',
  '/invoices.filters.shopId.label': 'Filtrer par atelier',
  '/invoices.filters.shopId.all': 'Tous les ateliers',
  '/invoices.filters.dateStart.label': 'A partir du',
  '/invoices.filters.dateStart.placeholder': 'Choisir une date',
  '/invoices.filters.dateEnd.label': 'Jusqu\'au',
  '/invoices.filters.dateEnd.placeholder': 'Choisir une date',
  '/invoices.result.header.date': 'Date et heure',
  '/invoices.result.header.interventionId': 'ID',
  '/invoices.result.header.velocenterIdentifier': 'Identifiant Velocenter vélo',
  '/invoices.result.header.userName': 'Client',
  '/invoices.result.header.totalNt': 'Total HT',
  '/invoices.result.header.totalWt': 'Total TTC',
  '/invoices.result.header.btn.download': 'Facture',
  '/invoices.result.item.date': '{ date, date }',
  '/invoices.result.item.interventionId': '{ interventionId }',
  '/invoices.result.item.velocenterIdentifier': '{ velocenterIdentifier }',
  '/invoices.result.item.userName': '{ userName }',
  '/invoices.result.item.totalNt': '{ total_Nt, number, ::currency/EUR }',
  '/invoices.result.item.totalWt': '{ total_Wt, number, ::currency/EUR }',
  '/invoices.result.item.btn.download': 'Télécharger',

  // /services
  '/services.pageTitle': 'Les formules Velocenter',
  '/services.disclaimer.text': 'A chaque expert du vélo, la formule sur-mesure adaptée à ses besoins de terrain.',
  '/services.disclaimer.button': 'Je veux en savoir plus',
  '/services.formule.basic.label': 'Pack Lancement',
  '/services.formule.premium.label': 'Pack Complet',
  '/services.formule.custom.label': 'Pack Sur-mesure',
  '/services.formule.network.label': 'Pack Réseau',
  '/services.formule.basic.desc': 'L’essentiel pour un technicien indépendant',
  '/services.formule.premium.desc': 'L’essentiel pour un atelier cycle',
  '/services.formule.custom.desc': 'Du sur-mesure pour un atelier cycle ou magasin de sport',
  '/services.formule.network.desc': 'Du sur-mesure pour un réseau d’ateliers ou de magasins',
  '/services.formule.adv1.label': 'Profil visible sur velocenter.com',
  '/services.formule.adv2.label': 'Planning des réservations et gestion des notifications et rappels de rendez-vous',
  '/services.formule.adv3.label': 'Personnalisation du profil et du planning velocenter.com',
  '/services.formule.adv4.label': 'Suivi des réparations des réparateurs',
  '/services.formule.adv5.label': 'Gestion des clients de l\'atelier',
  '/services.formule.adv6.label': 'Gestion du parc de vélos de l\'atelier',
  '/services.formule.adv7.label': 'Gestion de la facturation',
  '/services.formule.adv8.label': 'Intégration à votre logiciel de caisse',
  '/services.formule.adv9.label': 'Assistance sur-mesure',
  '/services.formule.adv10.label': 'Gestion d\'un réseau d\'ateliers de 2 à 1000 entités',
  '/services.formule.limit.label': 'Limite d\'utilisateur',
  '/services.formule.limit.value': `{ limit, select,
    1 {1 utilisateur}
    all { de 1 a * utilisateurs}
    other {{ limit } utilisateurs}
  }`,
  '/services.formule.price.value': `{ price, select,
    ask {sur demande}
    1 {{ price, number }}
    other {{ price, number, ::currency/EUR }}
  }`,
  '/services.formule.contact': 'Mettre à niveau',

  // /contact
  '/contact.pageTitle': 'Contactez-nous',
  '/contact.email.label': 'Mon adresse mail',
  '/contact.email.placeholder': ' ',
  '/contact.phoneNumber.label': 'Mon numéro de téléphone',
  '/contact.phoneNumber.placeholder': ' ',
  '/contact.askType.label': 'Typologie de demande',
  '/contact.askType.placeholder': 'Choisir...',
  '/contact.askType.option.general': 'Question à propos du site',
  '/contact.askType.option.bug': 'Remonter un bug',
  '/contact.askType.option.rgpd': 'Exercer mes droits (RGPD)',
  '/contact.askType.option.intervention': 'Problème avec une intervention',
  '/contact.askType.option.equipment': 'Problème avec un vélo',
  '/contact.askType.option.client': 'Problème avec un client',
  '/contact.askType.option.service': 'Je souhaite faire évoluer mon offre de service',
  '/contact.askType.option.other': 'Autre',
  '/contact.interventionId.label': 'ID de l\'intervention',
  '/contact.interventionId.placeholder': ' ',
  '/contact.equipmentId.label': 'Identification Vélocenter du vélo',
  '/contact.equipmentId.placeholder': ' ',
  '/contact.description.label': 'Mon message',
  '/contact.description.placeholder': 'Dites-nous en plus...',
  '/contact.description.default.premium': 'Bonjour, je souhaite faire évoluer mon compte vers une offre premium. Pouvez-vous me recontacter svp ?',
  '/contact.dirty.disclaimer': ' ',
  '/contact.dirty.save': 'J\'envoie ma demande',

  // /account
  '/account.pageTitle': 'Votre profil a changé ?',
  '/account.firstName.label': 'Mon prénom',
  '/account.lastName.label': 'Mon nom',
  '/account.email.label': 'Mon email',
  '/account.submit': 'Je modifie',
  '/account.saved': 'Profil sauvegardé !',

  // /faq
  '/faq.pageTitle': 'Foire Aux Questions (FAQ)',
  '/faq.contact': 'Je n\'ai pas trouvé la réponse à ma question',
  '/faq.questions': `
    <Accordion>
      <AccordionTitle>Devenir réparateur Vélocenter</AccordionTitle>
      <AccordionContent>
        <title>Je souhaite être listé sur Vélocenter, comment faire ? </title>
        <p>Il suffit de se rendre sur la page des offres de service et de choisir</p>
        <title>Quelles offres de services proposez-vous ?</title>
        <p>Nous proposons 4 offres de services permettant de répondre aux attentes de chacun de nos réparateurs ateliers ou techniciens indépendants.</p>
        <title>Nous sommes un atelier avec plusieurs techniciens, existe t'il une offre de service pour nous ? </title>
        <p>Oui, notre pack Complet correspondra à vos attentes.</p>
        <title>Nous sommes un réseau de plusieurs ateliers, existe t'il une offre de service pour nous ? </title>
        <p>Oui, notre pack Réseau correspondra à vos attentes et vous permettra de gérer l'intégralité de votre réseau facilement.</p>
        <title>Je suis un technicien/mécanicien indépendant, existe t'il une offre de service pour moi ?</title>
        <p>Oui, notre pack Lancement fera amplement l'affaire pour gérer la relation avec vos clients et l'intégralité de vos réparations.</p>
      </AccordionContent>
    </Accordion>
    <Accordion>
      <AccordionTitle>Combien ca coûte ?</AccordionTitle>
      <AccordionContent>
        <title>Prélevez-vous une commission sur chaque réparation ?</title>
        <p>Non, vous définissez vos tarifs et aucune commission ne sera prélevée</p>
        <title>Quels sont les frais cachés ?</title>
        <p>Il n'y en a pas ! Nos offres de services comprennent un tarif mensuel et nous ne prélevons ni commission, ni frais de gestion, ni frais plancher.</p>
        <title>Dois-je payer plus si j'ai plusieurs techniciens ?</title>
        <p>Non, chaque offre de service comprend un nombre de technicien suffisant pour couvrir vos besoins au quotidien.</p>
        <title>J'ai besoin que Vélocenter soit connecté à mon système de caisse, ou à mon système de stock </title>
        <p>Nous pouvons, sur devis, vous mettre en place les connecteurs spécifiques pour permettre une utilisation complète de votre système.</p>
      </AccordionContent>
    </Accordion>
  `,

  // /admin

  '/admin/networks.pageTitle': 'Réseaux',
  '/admin/networks.new': 'Nouveau',
  '/admin/networks.initializing': 'Chargement des données en cours...',
  '/admin/networks.result.header.networkId': 'Id',
  '/admin/networks.result.item.networkId': '{ networkId }',
  '/admin/networks.result.header.networkName': 'Nom',
  '/admin/networks.result.item.networkName': '{ networkName }',
  '/admin/networks.result.header.btn.view': ' ',
  '/admin/networks.result.item.btn.view': 'Editer',

  // /COMPONENTS --------------------------------

  '@velogik/component-confirm-transition.title': 'Modifications non enregistrées',
  '@velogik/component-confirm-transition.disclaimer': 'Vous avez effectué des modifications sur cette page, et elles ne sont pas encore enregistrées.',
  '@velogik/component-confirm-transition.actions.stay': 'Rester sur la page',
  '@velogik/component-confirm-transition.actions.change': 'Changer de page',

  // WeekScheduler
  'WeekScheduler.monday': 'Lundi',
  'WeekScheduler.tuesday': 'Mardi',
  'WeekScheduler.wednesday': 'Mercredi',
  'WeekScheduler.thursday': 'Jeudi',
  'WeekScheduler.friday': 'Vendredi',
  'WeekScheduler.saturday': 'Samedi',
  'WeekScheduler.sunday': 'Dimanche',
  'WeekScheduler.am.open.label': 'Matin (ouverture)',
  'WeekScheduler.am.close.label': 'Matin (fermeture)',
  'WeekScheduler.pm.open.label': 'Après-midi (ouverture)',
  'WeekScheduler.pm.close.label': 'Après-midi (fermeture)',
  'WeekScheduler.am.open.placeholder': 'Choisir un horaire',
  'WeekScheduler.am.close.placeholder': 'Choisir un horaire',
  'WeekScheduler.pm.open.placeholder': 'Choisir un horaire',
  'WeekScheduler.pm.close.placeholder': 'Choisir un horaire',

  // AddressInput
  'AddressInput.noResult': 'Pas de résultat',
  'AddressInput.loading': 'Chargement en cours...',

  // DayInput
  'DayInput.value': '{ value, date }',

  // Itvcode
  'Itvcode.quantity.label': 'Quantité',
  'Itvcode.comments.label': 'Commentaire',
  'Itvcode.comments.placeholder': ' ',
  'Itvcode.dontCharge.label': 'Ne pas facturer',
  'Itvcode.asv.label': 'Pris en charge SAV',

  // ResultInfos
  'ResultInfos.total': `{ total, select,
    0 {aucun}
    1 {1 résultat}
    other {{total} résultats}
  }`,
  'ResultInfos.ipp': 'Résultats par page :',

  // ResultPagination
  'ResultPagination.previous': 'Page précedente',
  'ResultPagination.next': 'Page suivante',

  // PlanningItem
  // -- Intervention
  'PlanningItem.Intervention.title': 'Acceder à l\'intervention',
  'PlanningItem.Intervention.interventionId.tooltipLabel': 'Intervention :',
  'PlanningItem.Intervention.interventionId.modalLabel': 'ID :',
  'PlanningItem.Intervention.interventionId.value': '{ interventionId }',
  'PlanningItem.Intervention.date.label': 'Date :',
  'PlanningItem.Intervention.date.value': '{ date, date, ::yyyyMMddHm }',
  'PlanningItem.Intervention.duration.label': 'Durée :',
  'PlanningItem.Intervention.duration.value': '{ duration, number, ::unit/minute unit-width-full-name }',
  'PlanningItem.Intervention.userName.label': 'Client :',
  'PlanningItem.Intervention.userName.value': '{ userName }',
  'PlanningItem.Intervention.phoneNumber.label': 'Téléphone :',
  'PlanningItem.Intervention.phoneNumber.value': `{ phoneNumber, select,
    null {N/A}
    other {<tel>{ phoneNumber }</tel>}
  }`,
  'PlanningItem.Intervention.location.label': 'Lieu :',
  'PlanningItem.Intervention.location.value': `{ location, select,
    shop {En atelier - }
    home {A domicile - }
    other {{ location }}
  }{address}`,
  'PlanningItem.Intervention.equipment.label': 'Infos Vélo :',
  'PlanningItem.Intervention.service.label': 'Détail de prestation :',
  'PlanningItem.Intervention.service.value': '{ services }{ servicesDetails, select, undefined {} other { - }}{ servicesDetails }',
  'PlanningItem.Intervention.equipmentReceivable.label': `{ isBikeReceived, select,
    true {Vélo réceptionné :}
    other {Vélo non réceptionné en atelier}
  }`,
  'PlanningItem.Intervention.equipmentReceivable.value': `{ isBikeReceived, select,
    true {le { dateBikeReceipt, date, ::ccccc } { dateBikeReceipt, date, ::dMMM } à {dateBikeReceipt, date, ::Hm} par { firstName } { lastName }}
    other {}
  }`,
  'PlanningItem.Intervention.dateSchedule.label': 'Date de restitution estimée :',
  'PlanningItem.Intervention.dateSchedule.value': 'le { dateScheduledReturn, date, ::ccccc } { dateScheduledReturn, date, ::dMMM } à {dateScheduledReturn, date, ::Hm}',

  'PlanningItem.Intervention.go': 'Voir',

  // -- Task
  'PlanningItem.Task.title': 'Acceder à la tâche',
  'PlanningItem.Task.taskId.tooltipLabel': 'Tâche :',
  'PlanningItem.Task.taskId.modalLabel': 'Tâche :',
  'PlanningItem.Task.taskId.value': '{ taskId }',
  'PlanningItem.Task.date.label': 'Date :',
  'PlanningItem.Task.date.value': '{ date, date, ::yyyyMMddHm }',
  'PlanningItem.Task.duration.label': 'Durée :',
  'PlanningItem.Task.duration.value': '{ duration, number, ::unit/minute unit-width-full-name }',
  'PlanningItem.Task.description.label': 'Description :',
  'PlanningItem.Task.description.value': '{ description }',
  'PlanningItem.Task.go': 'Voir',

  // ERRORS ------------------------------
  'error.front.cannotLoadConfig': 'Erreur pendant le chargement des types de velos',
  'error.front.notMatch': 'Les mots de passe ne correspondent pas',
  'error.front.missingPassword': 'Le mot de passe est manquant',
  'error.front.networkError': 'Erreur réseau',
  'error.front.noNetworks': 'Vous n\'avez pas de réseau',

  'error.ws.preventUpdate.equipmentForNewClient': 'Ce { equipmentType } est lié au compte usager de { userName } ! Confirmez-vous que votre usager est le nouveau propriétaire de ce { equipmentType } ?',

  'error.ws.unknown': 'Erreur inconnu',
  'error.ws.unexpected': 'Erreur inattendue ! Notre équipe technique a été informée de votre problème',
  'error.ws.technical': 'Erreur technique',
  'error.ws.invalidOrExpiredToken': 'Votre session est expirée',
  'error.ws.passwordTooShort': 'Le mot de passe est trop court',
  'error.ws.wrongResetCode': 'Mauvais lien de réinitialisation de mot de passe',
  'error.ws.resetCodeExpired': 'Le lien de réinitialisation a expiré',
  'error.ws.resetCodeAlreadyUsed': 'Vous avez déjà réinitialisé votre mot de passe avec ce lien de réinitialisation',
  'error.ws.accessDenied': 'Vous n’avez pas accès à cette fonctionnalité',
  'error.ws.locationIdIsMissing': 'Vous devez choisir un type d’intervention',
  'error.ws.servicesDetailsIdIsMissing': 'Vous devez choisir une typologie de demande',
  'error.ws.additionalIsMissing': 'Vous devez choisir s’il s’agit d’une révision complémentaire',
  'error.ws.itvcodesIsEmpty': 'Vous devez choisir au moins un code UO',
  'error.ws.itvcodesIsMissing': 'Vous devez choisir au moins un code UO',
  'error.ws.userIdPlannedIsMissing': 'Vous devez choisir un technicien',
  'error.ws.datePlannedIsMissing': 'Vous devez choisir une date et une heure de début',
  'error.ws.remindClientIsMissing': 'Vous devez choisir si vous souhaitez prévenir votre client',
  'error.ws.returnConditionsIsMissing': 'Vous devez préciser à votre client les conditions de restitution',
  'error.ws.clientValidationIsMissing': 'Votre client doit obligatoirement être d’accord avec votre facturation',
  'error.ws.returnValidationIsMissing': 'Vous devez confirmer que le vélo a été restitué en bon état',
  'error.ws.reinitPleaseWait': 'Vous devez patienter 10 min entre 2 demandes de mot de passe',
  'error.ws.lastNameIsEmpty': 'Le nom de famille est manquant',
  'error.ws.lastNameIsMissing': 'Le nom de famille est manquant',
  'error.ws.emailIsEmpty': 'L’email est manquant',
  'error.ws.emailIsMissing': 'L’email est manquant',
  'error.ws.badEmailFormat': 'Le format de l’email n’est pas correct.',
  'error.ws.clientEmailAlreadyExist': 'L’adresse email est utilisé par un autre client.',
  'error.ws.firstNameIsEmpty': 'Le prénom est manquant',
  'error.ws.firstNameIsMissing': 'Le prénom est manquant',
  'error.ws.profileSlugIsMissing': 'Le profil de droit est manquant pour cet utilisateur',
  'error.ws.shopIdIsMissing': 'L’atelier lié est manquant',
  'error.ws.limitUserMaxReached': 'Vous avez atteint la limite d’utilisateur possible',
  'error.ws.accountLocked': 'Votre compte est bloqué !',
  'error.ws.streetNumberIsMissing': 'L’adresse est manquante',
  'error.ws.billingStreetIsEmpty': 'La rue est manquante',
  'error.ws.unknownUser': 'L’utilisateur est inconnu',
  'error.ws.shopNameIsMissing': 'Le nom de l’atelier est manquant',
  'error.ws.shopEmailIsMissing': 'L’email de l’atelier est manquant',
  'error.ws.serviceOfferIsMissing': 'L’offre de service de l’atelier est manquante',
  'error.ws.limitUserMaxIsMissing': 'La limite d\'utilisateur max. est manquante',
  'error.ws.wrongLimitUserMax': 'La limite d\'utilisateur max. est invalide',
  'error.ws.emailAlreadyUsed': 'Cet email est déjà rattaché à un utilisateur existant',
  'error.ws.itvcodeIdentifierDuplicate': 'Vous avez déjà renseigné une MO ou PR avec ce code unique',
  'error.ws.incompatibleRepairServicesAndBookableServicesValues': 'Il y a une incohérence entre les prestations proposées et ce que peut réserver un usager',
  'error.ws.returnConditionsIsEmpty': 'Vous devez indiquer les conditions pour venir récupérer le vélo',
  'error.ws.missingLegalForBillingGen': 'Opération impossible ! Toutes les informations légales de l\'atelier ne sont pas remplises',
  'error.ws.shopCompanyNumberIsEmpty': 'Le numéro de SIRET est manquant',
  'error.ws.preventUpdate.clientNeedsReattachment': 'Le client est rattaché à un autre atelier. Êtes-vous sûr de vouloir le rattacher à votre atelier ?',
  'error.ws.identificationCodeIsEmpty': 'L’identifiant du vélo est manquant',
  'error.ws.brandIsEmpty': 'La marque du vélo est manquante',
  'error.ws.customNameIsEmpty': 'Le nom personnalisé du vélo est manquante',
  'error.ws.rearSprocketTooLong': 'La valeur du nombre de pignon à l’arrière est incorrecte',
  'error.ws.frontSprocketTooLong': 'La valeur du nombre de pignon à l’avant est incorrecte',
  'error.ws.wrongDateFormat': 'Le format de la date est incorrect',
  'error.ws.equipmentTypeIsMissing': 'Veuillez sélectionner un type de vélo',
  'error.ws.identificationSystemIsMissing': 'Veuillez sélectionner un type d’identification',
  'error.ws.brandIsMissing': 'La marque du vélo est manquante',
  'error.ws.customNameIsMissing': 'Le nom personnalisé du vélo est manquante',
  'error.ws.priceWtIsEmpty': 'Tout les prix doivent être rempli',
  'error.ws.durationIsEmpty': 'Tout les durées doivent être rempli',
  'error.ws.billingStreetNumberIsMissing': 'L’adresse de facturation est manquante',
  'error.ws.notSyncableShop': 'Cet atelier n’est pas synchronisable',
  'error.ws.wrongShopId': 'Erreur: Mauvais atelier',
  'error.ws.imageNotSvg': 'L’image doit être au format SVG',
  'error.ws.descriptionIsMissing': 'La description est manquante',
  'error.ws.cannotSelfUpdate': 'Il n’est pas possible d’editer son propre profil',
  'error.ws.userIdIsMissing': 'Le technicien est manquant',
  'error.ws.wrongEquipmentIdentifier': 'L’identifiant du Vélo est incorrect',
  'error.ws.idIsEmpty': 'L’identifiant du Vélo est manquant',
  'error.ws.shopNameIsEmpty': 'Le nom de l\'atelier est manquant',
  'error.ws.shopLegalNameIsEmpty': 'La raison sociale de l\'atelier est manquante',
  'error.ws.vatRateMoIsEmpty': 'Le taux de TVA main d\'oeuvre est manquant',
  'error.ws.vatRatePcIsEmpty': 'Le taux de TVA piece est manquant',
  'error.ws.servicesDescIsMissing': 'La description des services est manquante',
  'error.ws.phoneNumberIsMissing': 'Le numéro de téléphone est manquant',
  'error.ws.shopEmailIsEmpty': 'L\'email de contact est manquant',
  'error.ws.duplicateEquipmentIdentifierFound': 'Plusieurs occurences de ce vélo sont présentes dans cette affaire Velocare. Velocenter ne sait pas encore gérer ce cas d’usage. ',
  'error.ws.cancelationReasonIsEmpty': 'La raison de l’annulation doit être completé',
  'error.ws.wrongIntervention': 'Cette intervention n’existe pas',
  'error.ws.invalidLatLngFormat': 'Adresse incorrect',
  'error.ws.invalidPhoneFormat': 'Le format du numéro de téléphone est invalide.',
  'error.ws.billingPostalCodeIsEmpty': 'Le code postal est manquant dans l\'adresse',
  'error.ws.brandAlreadySet': 'Cette marque est déjà définit. Veuillez éditer cette dernière',
  'error.ws.velocareFamilyIdIsMissing': 'La famille est manquante',
  'error.ws.equipmentNameIsMissing': 'Le nom du vélo est manquant',
  'error.ws.resendEstimatePleaseWait': 'Merci de patienter 10 min avant d\'envoyer un nouvel email',
  'error.ws.errorSendingEstimateEmail': 'Une erreur s\'est produite lors de l\'envoi de l\'email',
  'error.ws.duplicateOnSentEstimate': 'Le devis doit être au status envoyé afin de le dupliquer',

  'error.ws.itvcodesInvalid': 'Cette intervention contient des codes interventions qui ne peuvent pas être facturés au client.',
  'error.ws.dontChargeNotBoolean': 'Le champ "Ne pas facturer" contient une valeure invalide',
  'error.ws.itvcodeDontExist': 'Ce code d’intervention n’est pas facturable',
  'error.ws.duplicateItvcodes': 'Ce code d’intervention est en double. Veuillez utiliser la quantité',

  'error.ws.dotSeparatorRequiredForPrice': 'Les valeurs décimales doivent être séparées par des . et non pas par des ,',
  'error.ws.emptyRecord': 'Le fichier d’import comporte des informations manquantes',

  'error.ws.missingEmailOrPassword': 'Votre email ou votre mot de passe est absent',
  'error.ws.wrongEmailOrPassword': 'Votre email ou votre mot de passe est incorrect',
  'error.ws.accessDeniedNoUniqueShop': 'Votre compte utilisateur est attaché à plusieurs ateliers, vous ne pouvez donc pas créer d’intervention dans cette version de Velocenter.',

  'error.ws.missingParameters': 'Un paramètre est manquant (cette erreur n’est pas censée arriver)',
  'error.ws.networkIdIsMissing': 'Le choix du réseau est obligatoire pour un Administrateur Réseau',
  'error.ws.dateScheduledReturnValueTooSmall': 'La date de restitution doit être superieur à la date de planification',
  'error.ws.invalidUserIdPlannedValue': 'Impossible d\'assigner l\'intervention à cet utilisateur',

  'error.ws.itvcodeIdentifierIsEmpty': 'Le code unique est requis',
  'error.ws.itvcodeLabelIsEmpty': 'La description est requise',
  'error.ws.existingItvcodeIdentifier': 'Le code unique existe déjà',
  'error.ws.adminCannotArchive': 'Un administrateur ne peux pas archiver',

  'error.ws.itvcodesSyncInvalid': `Erreur sur le code {id} - {label} : { reason, select,
    missingCAB {Code barre absent pour la pièce}
    missingTarif {Tarif absent pour la piece}
    other {raison inconnu : { reason }}
  }`,

  'error.component.title': 'Erreur fatale',
  'error.component.content': 'Je crois bien que vous avez trouvé un bug ! Ne vous inquiétez pas, Velocenter a automatiquement généré un rapport d’erreur qui devrait nous arriver prochainement.',
  'error.component.traceSent': 'Le rapport d’erreur a bien été reçu. Nous allons corriger votre problème au plus vite !',
}

export default messages
