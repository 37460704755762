import { useState, } from 'react'
import { useLocation, } from 'react-router-dom'
import { useApi, } from 'react-rest-api'

import { useApp, } from '@velogik/component-app'
import { Banner, } from '@velogik/component-banner'
import { Block, } from '@velogik/component-block'
import { Input, } from '@velogik/component-input'
import { useT, useIntl, useFormatError, } from '@velogik/component-intl'
import { Page, } from '@velogik/component-page'
import { PhoneInput, } from '@velogik/component-phone-input'
import { SelectInput, } from '@velogik/component-select-input'
import { Form, useFormReducer, FORM_STATUS, } from '@velogik/component-form'
import { STATUS, } from '@velogik/helper-promise'

export function Contact () {
  const t = useT()
  const intl = useIntl()
  const location = useLocation()
  const { appState, } = useApp()
  const api = useApi()
  const formatError = useFormatError()

  const [dataState, dataDispatch] = useFormReducer(FORM_STATUS.INITIALIZED, {
    email: appState.user ? appState.user.email : '',
    askType: location.state ? location.state.type : undefined,
    description: location.state && location.state.description ? t({ id: `description.default.${location.state.description}` }) : undefined,
  })
  const [postState, setPostState] = useState({ status: STATUS.IDLE })

  const askTypes = appState.user
    ? ['general', 'bug', 'rgpd', 'intervention', 'equipment', 'client', 'service', 'other']
    : ['general', 'bug', 'rgpd', 'service', 'other']

  function submit () {
    setPostState({ status: STATUS.LOADING })
    api.post('/velocenter/contact', { body: JSON.stringify(dataState.current) })
      .then(() => setPostState({ status: STATUS.LOADED }))
      .catch((payload) => setPostState({ status: STATUS.ERROR, payload }))
  }

  return (
    <Page>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>
      <Page.Content>
        <Form onSubmit={submit}
          state={dataState} dispatcher={dataDispatch}
          disabled={[STATUS.LOADING, STATUS.LOADED].includes(postState.status)}>
          <Form.Content>
            <Block>
              <Block.Content>
                <Input name="email"
                  label={t({ id: 'email.label' })}
                  disabled={appState.user}
                  placeholder={t({ id: 'email.placeholder' })} />
                <PhoneInput name="phoneNumber"
                  label={t({ id: 'phoneNumber.label' })}
                  placeholder={t({ id: 'phoneNumber.placeholder' })}
                  displayInitialValueAsLocalNumber={true}
                  defaultCountry={intl.locale.toUpperCase()} />
              </Block.Content>
            </Block>
            <Block>
              <Block.Content>
                <SelectInput name="askType"
                  label={t({ id: 'askType.label' })}
                  placeholder={t({ id: 'askType.placeholder' })}
                  options={askTypes.map(askType => ({
                    value: askType, label: t({ id: `askType.option.${askType}` })
                  }))} />
                {dataState.current.askType === 'intervention' && <Input name="interventionId"
                  label={t({ id: 'interventionId.label' })}
                  placeholder={t({ id: 'interventionId.placeholder' })} />}
                {dataState.current.askType === 'equipment' && <Input name="equipmentId"
                  label={t({ id: 'equipmentId.label' })}
                  placeholder={t({ id: 'equipmentId.placeholder' })} />}
                <Input name="description" type="textarea"
                  label={t({ id: 'description.label' })}
                  placeholder={t({ id: 'description.placeholder' })} />
              </Block.Content>
            </Block>
          </Form.Content>

          {postState.status === STATUS.LOADED && <Banner sticky
            type="success"
            description={t({ id: 'success.text' })}
          />}

          {postState.status === STATUS.ERROR && <Banner sticky
            type="danger"
            description={formatError(postState.payload)}
            actions={[
              { label: t({ id: 'dirty.dismiss' }), outlined: true,
                onClick: () => setPostState({ status: STATUS.IDLE }) }
            ]}
          />}
          {[STATUS.IDLE, STATUS.LOADING].includes(postState.status) && <Banner sticky
            description={t({ id: 'dirty.disclaimer' })}
            actions={[
              { label: t({ id: 'dirty.save' }),
                type: 'submit',
                disabled: postState.status === STATUS.LOADING || dataState.status !== FORM_STATUS.DIRTY, },
            ]} />}
        </Form>
      </Page.Content>
    </Page>
  )
}
