import { useEffect, useReducer, } from 'react'
import { Link, } from 'react-router-dom'

import { useApp, } from '@velogik/component-app'
import { Button, } from '@velogik/component-button'
import { useT, } from '@velogik/component-intl'
import { Page, } from '@velogik/component-page'
import { join, } from '@velogik/helper-classnames'

import media from 'styles/_media.module.scss'
import style from './Services.module.scss'

const medias = window.matchMedia(`(min-width: ${media['breakpoint-desktop']})`)

const services = {
  advantages: [
    { name: 'adv1' },
    { name: 'adv2' },
    { name: 'adv3' },
    { name: 'adv4' },
    { name: 'adv5' },
    { name: 'adv6' },
    { name: 'adv7' },
    { name: 'adv8' },
    { name: 'adv9' },
    { name: 'adv10' },
  ],
  formules: [
    {
      name: 'basic',
      advantages: {
        adv1: true,
        adv2: true,
        adv3: true,
      },
      limit: 1,
      price: 85,
    },
    {
      name: 'premium',
      advantages: {
        adv1: true,
        adv2: true,
        adv3: true,
        adv4: true,
        adv5: true,
        adv6: true,
        adv7: true,
      },
      limit: 5,
      price: 85,
    },
    {
      name: 'custom',
      advantages: {
        adv1: true,
        adv2: true,
        adv3: true,
        adv4: true,
        adv5: true,
        adv6: true,
        adv7: true,
        adv8: true,
        adv9: true,
      },
      limit: 'all',
      price: 'ask',
    },
    {
      name: 'network',
      advantages: {
        adv1: true,
        adv2: true,
        adv3: true,
        adv4: true,
        adv5: true,
        adv6: true,
        adv7: true,
        adv8: true,
        adv9: true,
        adv10: true,
      },
      limit: 'all',
      price: 'ask',
    },
  ]
}

function servicesReducer(state, action) {
  switch (action) {
    case 'formules':
    case 'advantages':
      return { ...state, current: state[action] }
    default: return state
  }
}

function servicesInit({ services, type, userService, }) {
  const state = {
    formules: {
      columns: [{ id: 'main', Header: () => null, }],
      rows: services.formules.map(formule => ({
        name: formule.name,
        type: formule.name === userService ? 'current' : undefined,
        Header: ({ t }) => (
          <div className={style.rowHeader}>
            <div className={style.label}>{t({ id: `formule.${formule.name}.label` })}</div>
            <div className={style.desc}>{t({ id: `formule.${formule.name}.desc` })}</div>
          </div>
        ),
        columns: services.advantages
          .map(advantage => ({
            id: advantage.name,
            Component: ({ t }) => (
              <div className={join(style.column, style.advantage, formule.advantages[advantage.name] && style.got)}>
                {t({ id: `formule.${advantage.name}.label` }, formule)}
              </div>
            ),
          }))
          .concat([{
            id: 'limit',
            Component: ({ t }) => (
              <div className={join(style.column, style.limit)}>
                {t({ id: 'formule.limit.value' }, formule)}
              </div>
            ),
          }, {
            id: 'price',
            Component: ({ t }) => (
              <div className={join(style.column, style.price)}>
                {t({ id: 'formule.price.value' }, formule)}
              </div>
            ),
          }, formule.name !== userService && {
            id: 'button',
            Component: ({ t }) => (
              <Button tag={Link}
                to={{
                  pathname: '/contact',
                  state: { type: 'service', description: formule.name, },
                }}
                className={join(style.column, style.contact)}>
                {t({ id: 'formule.contact' })}
              </Button>
            ),
          }].filter(_ => _))
      }))
      ,
    },
    advantages: {
      columns: services.formules.map(formule => ({
        id: formule.name,
        Header: ({ t }) => (
          <div className={join(style.colHeader, style.column, userService === formule.name && style.current)}>
            <div className={style.label}>{t({ id: `formule.${formule.name}.label` })}</div>
            <div className={style.desc}>{t({ id: `formule.${formule.name}.desc` })}</div>
            {formule.name !== userService && (
              <Button tag={Link}
                to={{
                  pathname: '/contact',
                  state: { type: 'service', description: formule.name, },
                }}
                className={style.contact}>
                {t({ id: 'formule.contact' })}
              </Button>
            )}
          </div>
        ),
      })),
      rows: services.advantages
        .map(advantage => ({
          name: advantage.name,
          type: 'advantage',
          Header: ({ t }) => (
            <div className={style.rowHeader}>{t({ id: `formule.${advantage.name}.label` })}</div>
          ),
          columns: services.formules.map(formule => ({
            id: `${advantage.name}.${formule.name}`,
            Component: () => (
              <div className={join(style.column, userService === formule.name && style.current, formule.advantages[advantage.name] && style.got)} />
            ),
          }))
        }))
        .concat([{
          name: 'limit',
          type: 'limit',
          Header: ({ t }) => (
            <div className={style.rowHeader}>{t({ id: 'formule.limit.label' })}</div>
          ),
          columns: services.formules.map(formule => ({
            id: formule.name,
            Component: ({ t }) => (
              <div className={join(style.column, userService === formule.name && style.current)}>
                {t({ id: 'formule.limit.value' }, formule)}
              </div>
            ),
          }))
        }])
    },
  }

  return {
    services,
    ...state,
    current: state[type || 'formules']
  }
}

export function Services() {
  const t = useT()
  const { appState, } = useApp()

  const [servicesState, servicesDispatch] = useReducer(servicesReducer, {
    services,
    type: medias.matches ? 'advantages' : 'formules',
    userService: appState.user.serviceOffer
  }, servicesInit)

  function handleMediaChange(e) {
    return servicesDispatch(e.matches ? 'advantages' : 'formules')
  }

  useEffect(() => {
    handleMediaChange(medias)
    medias.addEventListener('change', handleMediaChange)
    return () => medias.removeEventListener('change', handleMediaChange)
  }, [])

  return (
    <Page className={style.page}>
      <Page.Header>
        <Page.Header.Title>{t({ id: 'pageTitle' })}</Page.Header.Title>
      </Page.Header>
      <Page.Content>
        <div className={style.table}>
          <div className={style.headers}>
            <div className={style.disclaimer}>
              <div>{t({ id: 'disclaimer.text' })}</div>
              <Button tag="a" href="https://velocenter.com/backoffice" className={style.button}>{t({ id: 'disclaimer.button' })}</Button>
            </div>
            {servicesState.current.columns.map(column => (
              <column.Header key={column.id} t={t} />
            ))}
          </div>
          <div className={style.content}>
            {servicesState.current.rows.map(row => (
              <div key={row.name}
                className={join(style.row, style[row.type])}>
                <row.Header t={t} />
                {row.columns.map(column => <column.Component key={column.id} t={t} />)}
              </div>
            ))}
          </div>
        </div>
      </Page.Content>
    </Page>
  )
}
