import React from 'react'
import ReactDOM from 'react-dom'

import { ErrorBoundary, } from '@velogik/component-error-boundary'
import { ErrorGlobal, } from '@velogik/component-error-global'

import '@velogik/component-address-input/dist/index.css'
import '@velogik/component-alert/dist/index.css'
import '@velogik/component-banner/dist/index.css'
import '@velogik/component-block/dist/index.css'
import '@velogik/component-button/dist/index.css'
import '@velogik/component-button-icon/dist/index.css'
import '@velogik/component-confirm-transition/dist/index.css'
import '@velogik/component-filters/dist/index.css'
import '@velogik/component-file-input/dist/index.css'
import '@velogik/component-form/dist/index.css'
import '@velogik/component-image-input/dist/index.css'
import '@velogik/component-input/dist/index.css'
import '@velogik/component-label/dist/index.css'
import '@velogik/component-link/dist/index.css'
import '@velogik/component-loading/dist/index.css'
import '@velogik/component-modal/dist/index.css'
import '@velogik/component-number-input/dist/index.css'
import '@velogik/component-notifications/dist/index.css'
import '@velogik/component-page/dist/index.css'
import '@velogik/component-page/dist/index.css'
import '@velogik/component-phone-input/dist/index.css'
import '@velogik/component-result-infos/dist/index.css'
import '@velogik/component-result-pagination/dist/index.css'
import '@velogik/component-select-input/dist/index.css'

import 'fonts'
import 'index.css'

import { App, } from 'App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      render={ErrorGlobal}
      alert={sendAlert}
      parseError={parseError}
      fallbackEmail={process.env.REACT_APP_FALLBACK_EMAIL}
    >
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

function sendAlert (body) {
  return process.env.REACT_APP_DISABLE_SEND_REPORT
    ? Promise.resolve().then(() => console.log(body.err))
    : fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/velocenter/alert`, {
      method: 'POST',
      headers: {
        'X-Velocenter-Apikey': process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(body)
    }).then(response => (response.ok ? response.json() : response.json().then(res => Promise.reject(res)))
      .then(response => response && response.code ? Promise.reject(response) : Promise.resolve(response)))
      .catch(response => Promise.reject(response || { code: 'unexpected' }))
}

function parseError (error, errorInfo) {
  return (
    `:warning: *Fatal Error !!* :warning:
Page path: ${window.location.href}
User Agent: ${navigator.userAgent}
> ${error.toString().split('\n').join('\n> ')}
\`\`\`${errorInfo.componentStack}\`\`\``
  )
}
